import styled from 'styled-components';

export const CardText = styled('div')`
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const Item = styled('div')`
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const RowLotes = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const ItemLote = styled('div')``;
