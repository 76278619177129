import action from '../helpers/rest-client';

export class ErrorOrders extends Error {}

class OrdersService {
  public async getOrderssAll(filters) {
    const queryString = new URLSearchParams(filters).toString();
    const sort = '';

    return action
      .Get({
        url: `${process.env.REACT_APP_API_URL}/orders/all?${!!queryString ? `${queryString}&${sort}` : sort}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.warn(error);
        // throw new ErrorOrders(error.response.status);
      });
  }
}

const orders = new OrdersService();
export default orders;
