import { faComment } from '@fortawesome/free-solid-svg-icons';
import { Col, Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkDataPermission } from '../../../components/CanRBac';

// Mixins
import { CardBodyCustom, Heading, Title } from '../mixins';

const ObsForm = ({ currentUser, isEditable, handleChange, observations }) => {
  return (
    <Col lg="6">
      <Card>
        <Heading>
          <FontAwesomeIcon icon={faComment} />
          <Title>Observaciones</Title>
        </Heading>

        <CardBodyCustom>
          <div className="project-widgets mt-2 infoCard">
            <div className="item-group item-group-observations dmx-underline">
              <div className="item-label">Observaciones:</div>
              <div className="item-value">
                <textarea
                  name="observations"
                  value={observations}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  disabled={
                    checkDataPermission(currentUser?.userType, 'np:edit:product:observations') ? !isEditable : true
                  }
                />
              </div>
            </div>
          </div>
        </CardBodyCustom>
      </Card>
    </Col>
  );
};

export default ObsForm;
