import { call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import { SIGN_IN_SUCCESS, SIGN_OUT_SUCCESS } from '../types/auth';
import {
  GET_USER_MAIN_INFORMATION,
  GET_USER_MAIN_INFORMATION_SUCCESS,
  GET_USER_MAIN_INFORMATION_ERROR,
  UPDATE_USER_MAIN_INFORMATION,
  UPDATE_USER_MAIN_INFORMATION_SUCCESS,
  UPDATE_USER_MAIN_INFORMATION_ERROR,
  RESET_USER_MAIN_INFORMATION,
} from '../types/user';

function* saveUserInformation({ payload }) {
  yield put({ type: GET_USER_MAIN_INFORMATION_SUCCESS, payload });
}

function* updateUserInformation({ payload }) {
  try {
    const response = yield call(services.user.updateUserInformation, payload);
    yield put({ type: UPDATE_USER_MAIN_INFORMATION_SUCCESS, response });
  } catch {
    yield put({ type: UPDATE_USER_MAIN_INFORMATION_ERROR, payload: null });
  }
}

function* getUserInformation() {
  try {
    const payload = yield call(services.user.getUserInformation);
    yield put({ type: GET_USER_MAIN_INFORMATION_SUCCESS, payload });
  } catch {
    yield put({ type: GET_USER_MAIN_INFORMATION_ERROR, payload: null });
  }
}

function* resetUserInformation() {
  yield put({ type: RESET_USER_MAIN_INFORMATION });
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(SIGN_IN_SUCCESS, saveUserInformation);
  yield takeLatest<any>(GET_USER_MAIN_INFORMATION, getUserInformation);
  yield takeLatest<any>(UPDATE_USER_MAIN_INFORMATION, updateUserInformation);
  yield takeLatest<any>(SIGN_OUT_SUCCESS, resetUserInformation);
}
