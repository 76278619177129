/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Col, Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Switch
import { ToggleSwitch } from 'react-dragswitch';

// Mixins
import { CardBodyCustom, Heading, Title } from '../mixins'

// Styles
import 'react-dragswitch/dist/index.css';

const ClientForm = ({ client }) => {
  return (
    <Col lg="6">
      <Card>
        <Heading>
          <FontAwesomeIcon icon={faUser} />
          <Title>Cliente</Title>
        </Heading>

        <CardBodyCustom>
          <div className="project-widgets mt-2 infoCard">
            <div className="item-group dmx-underline">
              <div className="item-label">Código de Cliente:</div>
              <div className="item-value">{client.code || ''}</div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">Cliente:</div>
              <div className="item-value">{client.brandName}</div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">Razón Social:</div>
              <div className="item-value">{client.companyName}</div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">CUIT:</div>
              <div className="item-value">{client.cuit || ''}</div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">E-mail:</div>
              <div className="item-value">{client.email || ''}</div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">Provincia:</div>
              <div className="item-value">{client.province || ''}</div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">Localidad:</div>
              <div className="item-value">{client.locality || ''}</div>
            </div>

            {/* <div className="form-check">
              <label className="form-check-label pt-3" htmlFor="anmat">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="anmat"
                  disabled
                  checked={client.anmat || false}
                />
                ANMAT
              </label>
            </div> */}

            <div className="item-group dmx-underline">
              <div className="item-label">ANMAT:</div>

              <label className="form-check-label" htmlFor="takeAway">
                <ToggleSwitch onColor="red" checked={client.anmat || false} disabled />
              </label>
            </div>
          </div>
        </CardBodyCustom>
      </Card>
    </Col>
  );
};

export default ClientForm;
