import styled from 'styled-components'

export const CardBody = styled('div')`
  background-color: #eee;
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 8px;
`

export const StepsInput = styled('input')`
  color: hsl(0, 0%, 50%);
  font-size: 14px;
  padding: 0px 8px;
`