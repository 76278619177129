import React from 'react';
import ReactDOM from 'react-dom';
import Pages from './pages';
import './assets/styles/index.scss';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import AuthProvider from './context/AuthContext';
import createStore from './store';

const store = createStore();

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
  const user = localStorage.getItem('user');
  let [resource, config] = args;
  // request interceptor here
  if (user) {
    Object.assign(config?.headers as Headers, { __user: user });
  }
  const response = await originalFetch(resource, config);
  // response interceptor here
  return response;
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <Pages />
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
