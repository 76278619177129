import React from 'react';

const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 footer-copyright">
            <p className="mb-0">Copyright {currentYear} © Innen All rights reserved.</p>
          </div>
          <div className="col-md-6" />
        </div>
      </div>
    </footer>
  )
};

export default Footer;
