import { getToken } from '../utils/authUtils';

export default function getClients() {
  const auth = `Bearer ${getToken()}`;

  return fetch(`${process.env.REACT_APP_API_URL}/clients/all`, {
    method: 'get',
    headers: new Headers({
      Authorization: auth,
    }),
  }).then((response) => response.json());
}
