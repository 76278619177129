const getTotalIngress = (moves, units) => {
  let totalIngress = 0;

  for (let move of moves) {
    if (move.type === 'INGRESO') {
      if (units > 0) {
        totalIngress += parseInt(move.units);
      } else {
        totalIngress += move.totalMeters;
      }
    }
  }

  return totalIngress;
};

export default getTotalIngress;
