// @ts-ignore
/* eslint no-underscore-dangle: 0 */
import { useState, useEffect } from 'react';
import StepZilla from 'react-stepzilla';
import CustomerInformation from './CustomerInformation';
import LogisticsInformation from './LogisticsInformation';
import BillingInformation from './BillingInformation';
import Observations from './Observations';
import useClientsFetcher from '../../hooks/useClientsFetcher';
import useSellersFetcher from '../../hooks/useSellersFetcher';
import './NewOrderNote.scss';
import Spinner from '../../components/Spinner';
import useEmployeesFetcher from '../../hooks/useEmployeesFetcher';

const NewOrderNote = () => {
  const clients = useClientsFetcher();
  const sellers = useSellersFetcher();
  const employees = useEmployeesFetcher();
  const [isDisable, setIsDisable] = useState(false)
  if (clients.isLoading || sellers.isLoading || employees.isLoading) {
    <Spinner size="large" isPageLoader />;
  }

  let handlers = [];
  if (!employees.isLoading) {
    handlers = employees.data.docs.filter((employee) => employee.userType === 'productionHandler' || employee.userType === 'handler');
  }

  const handlerData = handlers?.map((handler) => ({
    label: handler.name,
    value: handler._id,
  }));

  const steps = [
    {
      name: 'Cliente',
      component: <CustomerInformation data={clients} setIsDisable={setIsDisable} />,
    },
    {
      name: 'Logística',
      component: <LogisticsInformation handlerData={handlerData} setIsDisable={setIsDisable} />,
    },
    {
      name: 'Facturación',
      component: <BillingInformation data={sellers} />,
    },
    {
      name: 'Observaciones',
      component: <Observations />,
    },
  ];
  useEffect(() => {
    setIsDisable(false)
  }, [])


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card dmx-card-mt">
              <div className="card-header">
                <h5>Nueva Nota de Pedido</h5>
              </div>
              <div className="card-body">
                <StepZilla
                  steps={steps}
                  showSteps
                  showNavigation={isDisable}
                  backButtonText="Volver"
                  nextButtonText="Siguiente"
                  nextButtonCls="btn btn-prev btn-primary btn-lg m-1 pull-right"
                  backButtonCls="btn btn-next btn-primary btn-lg m-1 pull-left"
                  prevBtnOnLastStep
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewOrderNote;
