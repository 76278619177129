import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { ProductionOrderDto } from '../../../../../types/production-order';

// components
import Feedstocks from './components/Feedstocks';
import Batchs from './components/Batchs';
import MovesModal from './components/ModalMovements';

// mixins
import { Divider } from '../../styles';

// types
import { Batch } from '../../../../../types/batch';

interface ComponentProps {
  productionOrder: ProductionOrderDto;
  dataFeedstock: {} | undefined;
}

const Component = ({ dataFeedstock }: ComponentProps) => {
  const [modal, setModal] = useState(false);
  // batchs
  const [batchStockSelected, setBatchStockSelected] = useState<Batch>();

  const handleBatchStockSelection = (data) => {
    setBatchStockSelected(data);
    setModal(true);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  // Utilizar handleFeedstock() para poder GUARDAR los nuevos cambios

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle tag="h4">Materia Prima</CardTitle>

          <Divider />

          <Feedstocks dataFeedstock={dataFeedstock} />

          <Divider />

          <Batchs handleBatchStockSelection={handleBatchStockSelection} />
        </CardBody>
      </Card>

      <MovesModal batchStockSelected={batchStockSelected} isOpen={modal} toggle={toggleModal} />
    </>
  );
};

const state = ({ productionOrderStore }) => {
  const { data: productionOrder } = productionOrderStore.productionOrder;

  return {
    productionOrder,
  };
};

export default connect(state)(Component);
