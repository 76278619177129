import {
  initialState,
  Actions,
  State,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_ERROR,
} from '../types/product';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case GET_ALL_PRODUCTS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
