import {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  RESET_UPDATE_USER_PROFILE,
  ResetUpdateUserProfile,
  GetUserProfile,
  UpdateUserProfile,
  ResetUserProfile,
} from '../types/profile';

import {
  GET_USER_MAIN_INFORMATION,
  UPDATE_USER_MAIN_INFORMATION,
  RESET_USER_MAIN_INFORMATION,
  GetUserMainInformation,
  UpdateUserMainInformation,
  ResetUserMainInformation,
} from '../types/user';

export const getUserInformation = (): GetUserMainInformation => {
  return { type: GET_USER_MAIN_INFORMATION, payload: null };
};

export const updateUserInformation = (payload): UpdateUserMainInformation => {
  return { type: UPDATE_USER_MAIN_INFORMATION, payload };
};

export const resetUserStore = (): ResetUserMainInformation => {
  return { type: RESET_USER_MAIN_INFORMATION, payload: null };
};

export const getUserProfile = (): GetUserProfile => {
  return { type: GET_USER_PROFILE, payload: null };
};

export const updateUserProfile = (payload): UpdateUserProfile => {
  return { type: UPDATE_USER_PROFILE, payload };
};

export const resetUpdateUserProfile = (): ResetUpdateUserProfile => {
  return { type: RESET_UPDATE_USER_PROFILE, payload: null };
};
