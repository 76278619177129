import { useEffect, useState } from 'react';
import Select, { components, SingleValueProps } from 'react-select';
import { ProductionOrderState, productionOrderStates } from './options';

// icons
import { Edit, X } from 'react-feather';

// mixins
import { SelectStateContainer, ButtonEdit, State } from './mixins';
import CanRBac, { checkDataPermission } from '../../../../../components/CanRBac';
import { connect } from 'react-redux';

const SingleValue = ({ children, ...props }: SingleValueProps<ProductionOrderState>) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
);

const permitedStatesByUserType = {
  admin: ['requested', 'pending', 'inProgress', 'done', 'archived'],
  feedstockAdmin: ['pending', 'inProgress', 'done'],
  productionAdmin: ['requested', 'inProgress', 'done', 'archived'],
  productionHandler: ['requested', 'inProgress', 'done', 'archived'],
  cutter: ['pending', 'inProgress', 'done'],
  inplantAdmin: ['requested', 'inProgress', 'done', 'archived'],
  viewer: ['requested', 'pending', 'inProgress', 'done'],
  handler: ['requested', 'pending', 'inProgress', 'done', 'archived'],
};

const Component = ({ state, handleChangeState, currentUser, productionOrder }) => {
  const [edit, setEdit] = useState(true);
  const [states, setStates] = useState<ProductionOrderState[]>();

  const customStyles = {
    singleValue: (base) => ({
      ...base,
      padding: 5,
      borderRadius: 5,
      color: 'black',
      display: 'flex',
    }),
    valueContainer: (provided) => ({
      ...provided,
      width: '160px',
    }),
  };

  const toggleState = () => {
    setEdit(!edit);
  };

  const checkIsPermitedState = (evualuatedStates) => {
    return (
      evualuatedStates.filter((evualuatedState) =>
        permitedStatesByUserType[currentUser?.userType].includes(evualuatedState),
      ).length > 0
    );
  };

  useEffect(() => {
    const availableStates = productionOrderStates.filter((productionOrderState) => {
      //Solicitada > Actualiza solo a En Progreso
      let permitedStates = ['inProgress'];
      if (
        state === 'requested' &&
        permitedStates.includes(productionOrderState.value) &&
        checkIsPermitedState(permitedStates)
      ) {
        return true;
      }

      //Pendiente > Actualiza solo a En Progreso
      permitedStates = ['inProgress'];
      if (
        state === 'pending' &&
        permitedStates.includes(productionOrderState.value) &&
        checkIsPermitedState(permitedStates)
      ) {
        return true;
      }

      //En Progreso > Actualiza solo a Finalizada o Pendiente
      permitedStates = ['done'];
      if (
        state === 'inProgress' &&
        permitedStates.includes(productionOrderState.value) &&
        checkIsPermitedState(permitedStates)
      ) {
        return true;
      }

      //En Progreso > Actualiza solo a Finalizada o Pendiente
      permitedStates = ['pending'];
      if (
        state === 'inProgress' &&
        permitedStates.includes(productionOrderState.value) &&
        checkIsPermitedState(permitedStates)
      ) {
        return true;
      }

      //Finalizada > Actualiza solo a Archivada
      permitedStates = ['archived', ...(currentUser?.userType === 'admin' ? ['inProgress'] : [])];
      if (
        state === 'done' &&
        permitedStates.includes(productionOrderState.value) &&
        checkIsPermitedState(permitedStates)
      ) {
        return true;
      }

      return false;
    });
    setStates(availableStates);
  }, [productionOrderStates, state, currentUser, productionOrder]);

  const emptyState = () => {
    if (state && state !== '') {
      return (
        <State>
          {
            productionOrderStates.filter((productionOrderState) => {
              return productionOrderState.value === state;
            })[0].label
          }
        </State>
      );
    }

    return null;
  };

  const selectState = () => {
    return (
      <Select
        name="state"
        styles={customStyles}
        placeholder={'Seleccione un Estado'}
        //components={{ SingleValue }}
        onMenuClose={() => setEdit(true)}
        isSearchable={false}
        options={states}
        onChange={(e) => {
          handleChangeState(e);
        }}
      />
    );
  };

  return (
    <SelectStateContainer>
      <CanRBac
        role={currentUser?.userType}
        perform="op:edit:state"
        data={{ status: state }}
        yes={() => (
          <ButtonEdit type="button" onClick={() => toggleState()} outline size="xs">
            {!edit ? <X /> : <Edit />}
          </ButtonEdit>
        )}
      />

      {edit ? emptyState() : selectState()}
    </SelectStateContainer>
  );
};

const state = ({ userStore, productionOrderStore }) => {
  const { data: productionOrder } = productionOrderStore.productionOrder;
  const { data: currentUser } = userStore.information;

  return {
    currentUser,
    productionOrder,
  };
};

export default connect(state)(Component);
