import auth from './auth';
import user from './user';
import productionOrder from './production-order';
import productionOrderBag from './production-order-bags';
import rawMaterial from './raw-material';
import orders from './orders';
import statics from './statics';
import workshop from './workshop';
import workshopOrder from './workshopOrder';
import customer from './customer';
import product from './product';

const services = {
  auth,
  user,
  productionOrder,
  productionOrderBag,
  rawMaterial,
  orders,
  statics,
  workshop,
  workshopOrder,
  customer,
  product,
};

export default services;
