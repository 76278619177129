// @ts-ignore
/* eslint no-underscore-dangle: 0 */
import { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import StepZilla from 'react-stepzilla';

// hooks
import useProductsFetcher from '../../../hooks/useProductsFetcher';
import useFeedstockFetcher from '../../../hooks/useFeedstockFetcher';

// components
import Spinner from '../../../components/Spinner';
import Breadcrumb from '../../../components/common/breadcrumb';
import ProductInformation from './components/ProductInformation';
import PlantInformation from './components/PlantInformation';
import Observations from './components/Observations';

// store
import { getProductionOrderAll } from '../../../store/actions/production-order';
import { ToastContainer } from 'react-toastify';

const NewOrderProduction = ({ productionOrderList }) => {
  const dispatch = useDispatch();
  const products = useProductsFetcher();
  const feedstocks = useFeedstockFetcher();
  const totalOrdenes = productionOrderList?.length;

  // useEffect(() => {
  //   dispatch(getProductionOrderAll({ state: 'requested,pending,inProgress,done' }));
  // }, []);

  if (products.isLoading) {
    <Spinner size="large" isPageLoader />;
  }

  const steps = [
    {
      name: 'Productos',
      component: <ProductInformation products={products} feedstocks={feedstocks} />,
    },
    {
      name: 'Fabrica',
      component: <PlantInformation />,
    },
    {
      name: 'Observaciones',
      component: <Observations totalOrdenes={totalOrdenes} />,
    },
  ];

  return (
    <>
      <div className="container-fluid d-flex justify-content-end ">
        <div className="page-header">
          <Breadcrumb title="Nueva Orden de Producción" parent="Ordenes" parentLink="/orderproduction" />
        </div>
      </div>

      <div className="container">
        <div className="card dmx-card-mt">
          <div className="card-header">
            <h5>Nueva Orden de Producción</h5>
          </div>

          <div className="card-body">
            <StepZilla
              steps={steps}
              showSteps
              backButtonText="Volver"
              nextButtonText="Siguiente"
              nextButtonCls="btn btn-prev btn-primary btn-lg m-1 pull-right"
              backButtonCls="btn btn-next btn-primary btn-lg m-1 pull-left"
              prevBtnOnLastStep
            />
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

const state = ({ productionOrderStore }) => {
  const { data: productionOrderList } = productionOrderStore.productionOrderAll;

  return {
    productionOrderList,
  };
};

export default connect(state)(NewOrderProduction);
