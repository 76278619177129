import { parseJSON } from './parseJSON';
import { getToken } from '../utils/authUtils';
import { POST } from '../constant';

const postFeedstock = (data) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/feedstock`, {
      method: POST,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(parseJSON)
      .then((res) => {
        if (!res.ok) {
          return reject(res.json.errors);
        }
        return resolve(res.json);
      })
      .catch((error) => {
        return reject({
          networkError: error.message,
        });
      });
  });
};

export default postFeedstock;
