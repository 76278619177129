import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .page-production-order-details {
    padding-top: 22px;
  }
`;

export const Divider = styled('div')`
  height: 1px;
  background-color: #ccc;
  margin-bottom: 15px;
`;

export const PageHeader = styled('div')`
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > ol {
    background-color: transparent;
  }
`;

export const BeadContainer = styled('div')``;

export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin: 30px 0px 20px;
`;

export const ButtonCustom = styled('button')`
  line-height: 36px;
  padding: 0px 20px;
`;
