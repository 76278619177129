import {
  GET_PRODUCTION_ORDER_ALL,
  GET_PRODUCTION_ORDER_ALL_RESET,
  GetProductionOrderAll,
  GetProductionOrderAllReset,
  GET_PRODUCTION_ORDER,
  GET_PRODUCTION_ORDER_RESET,
  GetProductionOrder,
  GetProductionOrderReset,
  PATCH_PRODUCTION_ORDER,
  PATCH_PRODUCTION_ORDER_RESET,
  PatchProductionOrder,
  PatchProductionOrderReset,
  PRODUCTION_ORDER_UPDATE_CURRENT,
  ProductionOrderUpdateCurrent,
  SET_PRODUCTION_ORDER_READED,
  SetProductionOrderReaded,
  DELETE_PRODUCTION_ORDER,
  DeleteProductionOrder,
  RESET_DELETE_PRODUCTION_ORDER,
  ResetDeleteProductionOrder,
  ResetUpdateProductionOrder,
  RESET_UPDATE_PRODUCTION_ORDER,
} from '../types/production-order';

export const getProductionOrderAll = (filters): GetProductionOrderAll => {
  return {
    type: GET_PRODUCTION_ORDER_ALL,
    payload: filters,
  };
};

export const getProductionOrderAllReset = (): GetProductionOrderAllReset => {
  return {
    type: GET_PRODUCTION_ORDER_ALL_RESET,
    payload: null,
  };
};

export const getProductionOrder = (payload): GetProductionOrder => {
  return {
    type: GET_PRODUCTION_ORDER,
    payload,
  };
};

export const getProductionOrderReset = (): GetProductionOrderReset => {
  return {
    type: GET_PRODUCTION_ORDER_RESET,
    payload: null,
  };
};

export const patchProductionOrder = (payload): PatchProductionOrder => {
  return {
    type: PATCH_PRODUCTION_ORDER,
    payload,
  };
};

export const patchProductionOrderReset = (): PatchProductionOrderReset => {
  return {
    type: PATCH_PRODUCTION_ORDER_RESET,
    payload: null,
  };
};

export const productionOrderUpdateCurrent = (payload): ProductionOrderUpdateCurrent => {
  return {
    type: PRODUCTION_ORDER_UPDATE_CURRENT,
    payload,
  };
};

export const setProductionOrderReaded = (): SetProductionOrderReaded => {
  return {
    type: SET_PRODUCTION_ORDER_READED,
    payload: null,
  };
};

export const deleteProductionOrder = (id): DeleteProductionOrder => {
  return {
    type: DELETE_PRODUCTION_ORDER,
    payload: id,
  };
};

export const resetDeleteProductionOrder = (): ResetDeleteProductionOrder => {
  return {
    type: RESET_DELETE_PRODUCTION_ORDER,
    payload: null,
  };
};

export const resetUpdateProductionOrder = (): ResetUpdateProductionOrder => {
  return {
    type: RESET_UPDATE_PRODUCTION_ORDER,
    payload: null,
  };
};
