import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SweetAlert from 'sweetalert2';
import Spinner from '../components/Spinner';
import { connect, useDispatch } from 'react-redux';
import { getUserInformation, resetUserStore } from '../store/actions/user';
import services from '../services';

/**
 * User inactive tolerance
 * Amount of time in minutes that a user can be inactive
 */
const USER_INACTIVE_TOLERANCE = process.env.REACT_APP_USER_INACTIVE_TOLERANCE || 30;

const startTimer = (coutdownDate, callback = () => {}, id) => {
  const now = new Date().getTime();
  const distance = coutdownDate - now;

  // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  // const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  // const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  // const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  if (distance > 0) {
    //console.log(`${id} Remaining Time: ${days}:${hours}:${minutes}:${seconds}`);
  } else {
    callback();
  }
};

export const AuthContext = React.createContext({
  hasValidToken: false as boolean,
});

export const Component = ({
  children,
  signInError,
  signInLoading,
  signInSuccess,
  signOutLoading,
  signOutError,
  signOutSuccess,
}) => {
  const dispatch = useDispatch();
  const [ready, setReady] = useState<boolean>(false);
  const [isInactive, setIsInactive] = useState<boolean>(false);
  const [hasValidToken, setHasValidToken] = useState<boolean>(false);

  let userInterval;
  let tokenInterval;

  const fireMessage = () => {
    SweetAlert.fire({
      title: 'Hemos notado que te fuiste',
      text: 'Por seguridad finalizamos tu sesión.',
      showCancelButton: false,
      confirmButtonText: 'Confirmar',
      allowEscapeKey: false,
      preConfirm: () => {
        window.location.assign('/login');
      },
    });
  };

  const whenTokenExpired = (intervalInstance) => {
    clearInterval(intervalInstance);
    if (isInactive) {
      //console.log('Usuario Inactivo y Token Expirado - Cerramos sesion');
      services.auth.signOut();
      fireMessage();
    } else {
      //console.log('Token Expirado - Renovamos token');
      services.auth.refreshToken();
    }
  };

  const whenUserIsInactive = (intervalInstance) => {
    clearInterval(intervalInstance);
    setIsInactive(true);
    services.auth.signOut();
    fireMessage();
  };

  const createInactiveIntervalInstance = () => {
    const fecha = moment().add(USER_INACTIVE_TOLERANCE, 'minutes').format();
    const coutdownDate = new Date(fecha).getTime();
    const instance = setInterval(() => startTimer(coutdownDate, () => whenUserIsInactive(instance), 'User'), 1000);
    return () => {
      clearInterval(instance);
    };
  };

  const createTokenExpirationIntervalInstance = () => {
    const coutdownDate = services.auth.getTokenExpirationTime()?.getTime();
    const instance = setInterval(() => startTimer(coutdownDate, () => whenTokenExpired(instance), 'Token'), 1000);
    return () => {
      clearInterval(instance);
    };
  };

  const userAuthenticated = () => {
    const isUserAuthenticated = services.auth.userAuthenticated();

    setHasValidToken(isUserAuthenticated);
    setReady(true);

    if (isUserAuthenticated) {
      dispatch(getUserInformation());
    } else {
      dispatch(resetUserStore());
    }
  };

  useEffect(() => {
    if (hasValidToken) {
      userInterval = createInactiveIntervalInstance();
      tokenInterval = createTokenExpirationIntervalInstance();
    }

    document.addEventListener('click', () => {
      if (typeof userInterval === 'function') {
        userInterval();
        userInterval = createInactiveIntervalInstance();
      }

      if (typeof tokenInterval === 'function') {
        tokenInterval();
        tokenInterval = createTokenExpirationIntervalInstance();
      }
    });

    return () => {
      document.removeEventListener('click', userInterval);
    };
  }, [hasValidToken]);

  useEffect(() => {
    userAuthenticated();
  }, [signInSuccess]);

  useEffect(() => {
    if (!!signInError) {
      setHasValidToken(false);
    }
  }, [signInError]);

  if (!ready) {
    return null;
  }

  return <AuthContext.Provider value={{ hasValidToken }}>{children}</AuthContext.Provider>;
};

const state = ({ authStore }) => {
  const { loading: signInLoading, error: signInError, success: signInSuccess } = authStore.signin.states;
  const { loading: signOutLoading, error: signOutError, success: signOutSuccess } = authStore.signout.states;
  return {
    signInLoading,
    signInError,
    signInSuccess,
    signOutLoading,
    signOutError,
    signOutSuccess,
  };
};

export default connect(state)(Component);
