export const typeOptions: readonly TypeOption[] = [
  { value: 'semi-elaborado', label: 'Semi Elaborado' },
  { value: 'semi-elaborado-2', label: 'Semi Elaborado 2' },
  { value: 'in plant', label: 'In Plant' },
];

export interface TypeOption {
  value: string;
  label: string;
  color?: string;
}
