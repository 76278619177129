import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { ToggleSwitch } from 'react-dragswitch';
import { useHistory } from 'react-router';
import SweetAlert from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import DataTableExtensions from '@innenpkg/react-data-table-component-extensions';
import '@innenpkg/react-data-table-component-extensions/dist/index.css';
import { rules } from '../../components/CanRBac/rbac-rules';
import Select from 'react-select';

// Styles
import './OrderNotes.scss';
import 'react-dragswitch/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';

// Icons
import { faArchive, faTimesCircle, faEdit, faFileAlt, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';
import SpinnerButton from '../../components/Button';
// import getOrderNotes from '../../services/getOrderNotes';

// Custom components
import Spinner from '../../components/Spinner';
import CanRBac from '../../components/CanRBac';
import { statusColors, statusOptions } from '../../constant/index';

// Hooks
import { useOrderNotesPaginatedFetcher } from '../../hooks/useOrderNotesFetcher';
import deleteOrderNote from '../../services/deleteOrderNote';
import patchOrderNote from '../../services/patchOrderNote';

import { filterDataByPermissions } from '../../utils/permissionsUtil';
// import { notifications } from '../../constant';
import useClientsFetcher from '../../hooks/useClientsFetcher';

const OrderNotes = ({ currentUser }) => {
  const [isFetching, setIsFetching] = useState(false);
  const orderNotes = useOrderNotesPaginatedFetcher();
  const clients = useClientsFetcher();

  const [data, setData] = useState([]);
  const [archivedFilter, setArchivedFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [toggleCleared] = useState(false);
  const [checked, setChecked] = useState(false);
  const [page, setPage] = useState(0);
  const [itemsForPage, setItemsForPage] = useState();
  const [selectStatus, setSelectStatus] = useState('');
  const [lastClients, setLastClient] = useState({ label: 'Todos los clientes', value: '' });
  const [lastStatus, setLastStatus] = useState({ label: 'Todos los estados', value: '' });

  let clientOptions = [{ label: 'Todos los clientes', value: '' }];
  let statesOptions = [{ label: 'Todos los estados', value: '' }];

  useEffect(() => {
    const localData = localStorage.getItem('modifyStatus');
    const order_status = localData ? localData : rules[currentUser?.userType]?.order_status;

    const isArchived = JSON.parse(localStorage.getItem('archived'))
      ? JSON.parse(localStorage.getItem('archived'))
      : false;

    if (currentUser?.userType === 'seller' || currentUser?.userType === 'handler') {
      orderNotes.setUserType(currentUser?.userType);
      orderNotes.setUserId(currentUser._id);
      orderNotes.setStatus(order_status);
      orderNotes.setN_Archived(isArchived);
      orderNotes.setReload(true);
    } else {
      currentUser?.userType === 'admin' ? orderNotes.setIsAdmin('true') : orderNotes.setIsAdmin('');
      orderNotes.setUserType(currentUser?.userType);
      orderNotes.setUserId('');
      orderNotes.setStatus(order_status);
      orderNotes.setN_Archived(isArchived);
      orderNotes.setReload(true);
    }
  }, []);

  const handleChangePage = (page) => {
    orderNotes.setPage(parseInt(page));
    setPage(parseInt(page));
    orderNotes.setReload(true);
    localStorage.setItem('lastPage', page);
  };

  const handleChangeItemsPerPege = (currentRowsPerPage) => {
    orderNotes.setLimit(currentRowsPerPage);
    setItemsForPage(currentRowsPerPage);
    orderNotes.setReload(true);
  };

  const handleRefresh = async () => {
    orderNotes.setReload(true);
  };

  function compare(a, b) {
    const orderA = Date.parse(a.createDate);
    const orderB = Date.parse(b.createDate);

    let comparison = 0;
    if (orderA > orderB) {
      comparison = -1;
    } else if (orderA < orderB) {
      comparison = 1;
    }
    return comparison;
  }

  useEffect(() => {
    const newData = orderNotes.data.sort(compare);
    filtersSave();
    setData(filterDataByPermissions(currentUser, newData));
  }, [orderNotes.data]);

  useEffect(() => {
    getLastPage();
  }, []);

  const filtersSave = () => {
    const statusList = [...new Set(orderNotes?.data?.map((order) => order.status))].join(',').replace('archived,', '');
    const dataLocalStorage = localStorage.getItem('modifyCustomerFilter');
    const statusLocalStorage = localStorage.getItem('modifyStatus');
    const archivedLocalStorage = localStorage.getItem('modifyArchivedFilter');

    if (statusLocalStorage === null) {
      setStatusFilter(statusList);
    } else {
      setStatusFilter(statusLocalStorage);
      setSelectStatus(statusLocalStorage);
    }
    if (archivedLocalStorage === 'archived') {
      setArchivedFilter('archived');
      setChecked(true);
    } else {
      setChecked(false);
      setArchivedFilter(statusList);
    }
  };

  const changeClientsFilter = (option) => {
    setLastClient(option);
    orderNotes.setReload(true);
    setPage(1);
    orderNotes.setPage(1);
    orderNotes.setClientId(option.value);
  };

  const changeStatusFilter = (option) => {
    setLastStatus(option);
    orderNotes.setReload(true);
    setPage(1);
    orderNotes.setPage(1);
    if (option.value) {
      orderNotes.setStatus(option.value);
    } else {
      orderNotes.setStatus(rules[currentUser?.userType]?.order_status);
    }
  };

  useEffect(() => {
    const clients = localStorage.getItem('lastClient');
    if (clients) {
      const ready = JSON.parse(clients);
      changeClientsFilter(ready);
    }
  }, []);

  useEffect(() => {
    const status = localStorage.getItem('lastStatus');
    if (status) {
      const ready = JSON.parse(status);
      changeStatusFilter(ready);
    }
  }, []);

  useEffect(() => {
    const lastPage = localStorage.getItem('lastPage');
    if (lastPage) {
      handleChangePage(lastPage);
    }
  }, []);

  if (clients.data && !clients.isLoading) {
    clientOptions.push(...clients.data.map((client) => ({ label: client.brandName, value: client._id })));
  }

  if (rules[currentUser.userType]?.order_status) {
    statesOptions.push(
      ...rules[currentUser?.userType]?.order_status?.map((state) => ({
        label: statusOptions[state],
        value: state,
      })),
    );
  }
  const states = [...new Set(orderNotes.data.map((order) => order.status))];
  if (states.includes('archived')) {
    states.splice(states.indexOf('archived'), 1);
  }

  const getLastPage = () => {
    const lastPageLS = localStorage.getItem('lastPage');
    const itemsForPageLS = localStorage.getItem('itemsForPage');

    if (Number.isNaN(parseInt(lastPageLS)) && Number.isNaN(parseInt(itemsForPageLS))) {
      setPage(1);
      setItemsForPage(10);
      orderNotes.setPage(1);
      orderNotes.setLimit(10);
    } else if (!Number.isNaN(parseInt(lastPageLS)) && Number.isNaN(parseInt(itemsForPageLS))) {
      setPage(parseInt(lastPageLS));
      setItemsForPage(10);
      orderNotes.setPage(parseInt(lastPageLS));
      orderNotes.setLimit(10);
    } else if (!Number.isNaN(parseInt(itemsForPageLS)) && Number.isNaN(parseInt(lastPageLS))) {
      setPage(1);
      setItemsForPage(parseInt(itemsForPageLS));
      orderNotes.setPage(1);
      orderNotes.setLimit(parseInt(itemsForPageLS));
    } else {
      setPage(parseInt(lastPageLS));
      setItemsForPage(parseInt(itemsForPageLS));
      orderNotes.setPage(parseInt(lastPageLS));
      orderNotes.setLimit(parseInt(itemsForPageLS));
    }
  };

  async function executeDeleteAction(orderId) {
    try {
      const res = await deleteOrderNote(orderId);
      if (res.msg === 'DELETED') {
        const orderIndex = orderNotes.data.findIndex((order) => order._id === orderId);
        orderNotes.data.splice(orderIndex, 1);
        setData(filterDataByPermissions(currentUser, orderNotes.data));

        toast.success('La nota de pedido ha sido borrada.', { autoClose: 3000 });
      } else {
        toast.error(`La nota no se pudo borrar porque ocurrió un error:${res.msg}`, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(`Errror al tratar de borrar una nota. ${error.msg}`, { autoClose: 3000 });
    }
  }

  const handleDeleteAction = (orderId) => {
    SweetAlert.fire({
      title: '¿Desea borrar la nota de pedido?',
      text: 'Una vez eliminada, no podrá ser recuperada.',
      showCancelButton: true,
      confirmButtonText: 'Sí quiero',
      cancelButtonText: 'No quiero',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        executeDeleteAction(orderId);
      }
    });
  };

  const history = useHistory();

  const handlePrintAction = (orderId) => {
    history.push(`/printorder?id=${orderId}`);
  };

  const handleEditAction = (orderId) => {
    history.push(`/orderdetail?id=${orderId}&edit=${true}`);
  };

  const executeArchiveAction = (orderId) => {
    const orderIndex = orderNotes.data.findIndex((order) => order._id === orderId);
    const order = orderNotes.data[orderIndex];

    order.status = 'archived';
    orderNotes.data[orderIndex] = order;

    patchOrderNote(order).then((res) => {
      if (res.errors) {
        toast.error(`La nota no se pudo archivar porque ocurrió un error:${res.errors.msg}`, { autoClose: 3000 });
      } else {
        setData(filterDataByPermissions(currentUser, orderNotes.data));
        toast.success('La nota de pedido ha sido archivada.', { autoClose: 3000 });
      }
    });
  };

  const handleArchiveAction = (orderId) => {
    SweetAlert.fire({
      title: '¿Desea archivar la nota de pedido?',
      text: 'Una vez archivada, solo podrá verla usando los filtros.',
      showCancelButton: true,
      confirmButtonText: 'Sí, quiero',
      cancelButtonText: 'No quiero',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        executeArchiveAction(orderId);
      }
    });
  };

  const modifyArchivedFilter = (on) => {
    const newArchivedFilter = on ? 'archived' : states.join(',');
    localStorage.setItem('modifyArchivedFilter', newArchivedFilter);
    setArchivedFilter(newArchivedFilter);
  };

  useEffect(() => {
    localStorage.setItem('lastClient', JSON.stringify(lastClients));
  }, [lastClients]);

  useEffect(() => {
    localStorage.setItem('lastStatus', JSON.stringify(lastStatus));
  }, [lastStatus]);

  const clientRow = (row) => {
    if (row.client) {
      if (row.watchers && row.watchers.indexOf(currentUser._id) === -1) {
        return <strong data-tag="allowRowEvents">{row.client.brandName}</strong>;
      }
      return <div className="pepe">{row.client.brandName}</div>;
    }
    return '';
  };

  const actionButtons = ({ _id: id, status, handler }) => (
    <>
      <div
        id="printAction"
        className="text-secondary p-1 dmx-action-button mr-1"
        data-toggle="tooltip"
        data-placement="top"
        title="Ver nota de pedido"
      >
        <FontAwesomeIcon icon={faFileAlt} onClick={() => handlePrintAction(id)} />
      </div>
      <CanRBac
        role={currentUser?.userType}
        perform="np:edit"
        data={{ status, handler, currentUser }}
        yes={() => (
          <>
            <div
              id="editAction"
              className="text-secondary p-1 dmx-action-button mr-1"
              data-toggle="tooltip"
              data-placement="top"
              title="Editar nota de pedido"
            >
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEditAction(id)} />
            </div>
          </>
        )}
      />
      <CanRBac
        role={currentUser?.userType}
        perform="np:archive"
        data={{ status }}
        yes={() => (
          <div
            id="archiveAction"
            className="text-secondary p-1 dmx-action-button mr-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Archivar nota de pedido"
          >
            <FontAwesomeIcon icon={faArchive} id={id} onClick={() => handleArchiveAction(id)} />
          </div>
        )}
      />
      <CanRBac
        role={currentUser?.userType}
        perform="np:delete"
        data={{ status }}
        yes={() => (
          <div
            id="deleteAction"
            className="text-danger p-1 dmx-action-button mr-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Borrar nota de pedido"
          >
            <FontAwesomeIcon icon={faTimesCircle} onClick={() => handleDeleteAction(id)} />
          </div>
        )}
      />
    </>
  );

  const paginationText = {
    rowsPerPageText: 'Registros por página:',
    rangeSeparatorText: 'de',
  };

  // Datatable
  const columns = [
    {
      name: 'Nro',
      selector: 'orderNoteId',
      sortable: true,
      minWidth: '12%',
      maxWidth: '12%',
    },
    {
      name: 'Cliente',
      sortable: true,
      cell: (row) => {
        return clientRow(row);
      },
    },
    {
      name: '%',
      selector: 'deliveryPercent',
      minWidth: '11%',
      maxWidth: '11%',
      sortable: true,
    },
    {
      name: 'Solicitud',
      cell: (row) => <p>{moment(row.createDate).format('DD/MM/YYYY')}</p>,
    },
    {
      name: 'Primera entrega',
      sortable: true,
      cell: (row) => <p>{moment(row.firstDeliveryDate).format('DD/MM/YYYY')}</p>,
    },
    {
      name: 'Estado',
      selector: 'status',
      sortable: true,
      cell: (row) => (
        <span className="badge p-2" style={{ backgroundColor: statusColors[row.status] }}>
          {statusOptions[row.status]}
        </span>
      ),
    },
    {
      name: 'Acciones',
      cell: (row) => {
        return actionButtons(row);
      },
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const handleRowClicked = (row) => {
    localStorage.setItem('lastPage', page);
    history.push(`/orderdetail?id=${row._id}&edit=${false}`);
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: '4px',
        paddingRight: '4px',
      },
    },
    cells: {
      style: {
        paddingLeft: '4px',
        paddingRight: '55px',
      },
    },
  };

  if (orderNotes.isLoading) {
    return <Spinner size="large" isPageLoader />;
  }

  const newOrderNote = () => {
    return (
      <Link className="btn btn-success btn-create" to="/neworder">
        <FontAwesomeIcon icon={faPlus} /> Nueva Nota de Pedido
      </Link>
    );
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card dmx-card-mt shadow-sm p-15">
              <div className="row">
                <div className="col-md-4 col-lg-3 mb-15">
                  <Select
                    value={lastClients}
                    options={clientOptions}
                    defaultValue={clientOptions[0]}
                    onChange={(option) => {
                      localStorage.setItem('lastPage', page);
                      changeClientsFilter(option);
                    }}
                  />
                </div>
                <div className="col-md-4 col-lg-3">
                  <Select
                    value={lastStatus}
                    options={statesOptions}
                    defaultValue={statesOptions[0]}
                    onChange={(option) => {
                      localStorage.setItem('lastPage', page);
                      changeStatusFilter(option);
                    }}
                  />
                </div>
                <div className="col-md-4 col-lg-3 col-toggle">
                  <ToggleSwitch
                    onColor="blue"
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e);
                      orderNotes.setN_Archived(e);
                      orderNotes.setPage(1);
                      setPage(1);
                      orderNotes.setReload(true);
                      modifyArchivedFilter(e);
                      setData(orderNotes.data);
                    }}
                  />

                  <span>Mostrar archivadas</span>
                </div>

                <div className="col-md-12 col-lg-3 col-btn-create">
                  <CanRBac role={currentUser?.userType} perform="np:create" yes={() => newOrderNote()} />
                </div>
              </div>
            </div>

            <div className="card dmx-card-mt shadow-sm">
              <div className="card-body datatable-react">
                <div className="d-flex flex-row-reverse">
                  <SpinnerButton
                    type="button"
                    onClick={() => handleRefresh()}
                    isLoading={isFetching}
                    disabled={isFetching}
                  >
                    <FontAwesomeIcon icon={faSyncAlt} /> Refrescar
                  </SpinnerButton>
                </div>
                <DataTableExtensions
                  {...tableData}
                  export={false}
                  print={false}
                  filterHidden={false}
                  filterPlaceholder="Buscar..."
                >
                  <DataTable
                    paginationComponentOptions={paginationText}
                    pagination
                    customStyles={customStyles}
                    columns={columns}
                    data={data}
                    striped
                    center
                    paginationServer
                    paginationDefaultPage={page}
                    paginationTotalRows={orderNotes.pagination.totalDocs}
                    paginationPerPage={itemsForPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeItemsPerPege}
                    responsive
                    persistTableHead
                    clearSelectedRows={toggleCleared}
                    highlightOnHover
                    noDataComponent={<div className="dmx-empty-table">No hay registros para mostrar</div>}
                    onRowClicked={handleRowClicked}
                  />
                </DataTableExtensions>
              </div>

              <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(OrderNotes);
