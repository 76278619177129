import action from '../helpers/rest-client';

export class ErrorProductionOrderBags extends Error { }

class ProductionOrderBagsService {
  public async create(payload) {
    return action
      .Post({
        url: `${process.env.REACT_APP_API_URL}/bags`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getAll({ idProductionOrder, idWorkshop }) {
    const filterByOrderProductionId = !!idProductionOrder ? 'production_order=' + idProductionOrder + '&' : ''
    const filterByWorkshopId = !!idWorkshop ? 'workshop=' + idWorkshop : ''

    return action
      .Get({
        url: `${process.env.REACT_APP_API_URL}/bags/all?${filterByOrderProductionId}${filterByWorkshopId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getOne({ _id }) {
    return action
      .Get({
        url: `${process.env.REACT_APP_API_URL}/bags/${_id}`,
      })
      .then((response) => {
        return { response: response.data[0] };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async updateOne(payload) {
    return action
      .Patch({
        url: `${process.env.REACT_APP_API_URL}/bags/${payload._id}`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async deleteOne(payload) {
    return action
      .Del({
        url: `${process.env.REACT_APP_API_URL}/bags/${payload}`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const productionOrderBags = new ProductionOrderBagsService();
export default productionOrderBags;
