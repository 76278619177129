import { connect } from 'react-redux';
import { checkDataPermission } from '../../../../../components/CanRBac';
import { ProductionOrderDto } from '../../../../../types/production-order';
import { User } from '../../../../../types/user';
import { CuttingTable, Label, Input } from './styles';

interface ComponentProps {
  productionOrder: ProductionOrderDto;
  currentUser: User;
  handleChangeOnCuttingTable: (checked: boolean) => void;
}
const Component = ({ productionOrder, handleChangeOnCuttingTable, currentUser }: ComponentProps) => {
  if (!currentUser || !productionOrder) {
    return null;
  }

  return (
    <CuttingTable>
      <Label>
        <Input
          type="checkbox"
          name="cuttingTable"
          disabled={
            !checkDataPermission(currentUser.userType, 'op:edit:cuttingTable', { status: productionOrder?.state })
          }
          defaultChecked={productionOrder?.cuttingTable}
          onChange={(e) => handleChangeOnCuttingTable(e.target.checked)}
        />
        En mesa de corte
      </Label>
    </CuttingTable>
  );
};

const state = ({ productionOrderStore, userStore }) => {
  const { data: currentUser } = userStore.information;
  const { data: productionOrder } = productionOrderStore.productionOrder;

  return {
    currentUser,
    productionOrder,
  };
};

export default connect(state)(Component);
