import { WorkshopOrderDto } from '../../types/workshop';

export const NEW_WORKSHOP = 'NEW_WORKSHOP';
export const NEW_WORKSHOP_SUCCESS = 'NEW_WORKSHOP_SUCCESS';
export const NEW_WORKSHOP_ERROR = 'NEW_WORKSHOP_ERROR';
export const RESET_NEW_WORKSHOP = 'RESET_NEW_WORKSHOP';

export const EDIT_WORKSHOP = 'EDIT_WORKSHOP';
export const EDIT_WORKSHOP_SUCCESS = 'EDIT_WORKSHOP_SUCCESS';
export const EDIT_WORKSHOP_ERROR = 'EDIT_WORKSHOP_ERROR';
export const RESET_EDIT_WORKSHOP = 'RESET_EDIT_WORKSHOP';

export const GET_WORKSHOP = 'GET_WORKSHOP';
export const GET_WORKSHOP_SUCCESS = 'GET_WORKSHOP_SUCCESS';
export const GET_WORKSHOP_ERROR = 'GET_WORKSHOP_ERROR';

export const GET_ALL_WORKSHOPS = 'GET_ALL_WORKSHOPS';
export const GET_ALL_WORKSHOPS_SUCCESS = 'GET_ALL_WORKSHOPS_SUCCESS';
export const GET_ALL_WORKSHOPS_ERROR = 'GET_ALL_WORKSHOPS_ERROR';
export const GET_ALL_WORKSHOPS_RESET = 'GET_ALL_WORKSHOPS_RESET';

export interface NewWorkshop {
  type: typeof NEW_WORKSHOP;
  payload: WorkshopOrderDto;
}

export interface NewWorkshopSuccess {
  type: typeof NEW_WORKSHOP_SUCCESS;
  payload: null;
}

export interface NewWorkshopError {
  type: typeof NEW_WORKSHOP_ERROR;
  payload: boolean | string;
}

export interface ResetNewWorkshop {
  type: typeof RESET_NEW_WORKSHOP;
  payload: null;
}

export interface EditWorkshop {
  type: typeof EDIT_WORKSHOP;
  payload: WorkshopOrderDto;
}

export interface EditWorkshopSuccess {
  type: typeof EDIT_WORKSHOP_SUCCESS;
  payload: null;
}

export interface EditWorkshopError {
  type: typeof EDIT_WORKSHOP_ERROR;
  payload: boolean | string;
}

export interface ResetEditWorkshop {
  type: typeof RESET_EDIT_WORKSHOP;
  payload: null;
}

export interface GetWorkshop {
  type: typeof GET_WORKSHOP;
  payload: { idWorkshop: string };
}

export interface GetWorkshopSuccess {
  type: typeof GET_WORKSHOP_SUCCESS;
  payload: null;
}

export interface GetWorkshopError {
  type: typeof GET_WORKSHOP_ERROR;
  payload: boolean | string;
}

export interface GetAllWorkshops {
  type: typeof GET_ALL_WORKSHOPS;
  payload: { [key: string]: string | number };
}

export interface GetAllWorkshopsSuccess {
  type: typeof GET_ALL_WORKSHOPS_SUCCESS;
  payload: null;
}

export interface GetAllWorkshopsError {
  type: typeof GET_ALL_WORKSHOPS_ERROR;
  payload: boolean | string;
}

export interface GetAllWorkshopsReset {
  type: typeof GET_ALL_WORKSHOPS_RESET;
  payload: null;
}

export interface State {
  workshop: {
    data: WorkshopOrderDto | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  workshops: {
    data: WorkshopOrderDto[] | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  newWorkshop: {
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  editWorkshop: {
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
}

export const initialState: State = {
  workshop: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  workshops: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  newWorkshop: {
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  editWorkshop: {
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
};

export type Actions =
  | NewWorkshop
  | NewWorkshopSuccess
  | NewWorkshopError
  | ResetNewWorkshop
  | EditWorkshop
  | EditWorkshopSuccess
  | EditWorkshopError
  | ResetEditWorkshop
  | GetWorkshop
  | GetWorkshopSuccess
  | GetWorkshopError
  | GetAllWorkshops
  | GetAllWorkshopsSuccess
  | GetAllWorkshopsError
  | GetAllWorkshopsReset;
