import { useState, useEffect } from 'react';
import getFeedstock from '../services/getFeedstock';

const useFeedstockFetcher = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getFeedstock()
      .then((orders) => {
        setData(orders);
        setIsLoading(false);
        setIsError(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return { data, isLoading, isError };
};

export default useFeedstockFetcher;
