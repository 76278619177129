import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { noRecords, moveTypes } from '../../../../constant';
import './style.scss';
import Select from 'react-select';
import { customStyles } from './settings';

// components
import PrintLabel from '../../../../components/PrintLabel';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faArrowRight, faArrowLeft, faCommentDots, faEdit } from '@fortawesome/free-solid-svg-icons';

// permisos
import { checkDataPermission } from '../../../../components/CanRBac';

const MoveList = ({
  currentDataMP,
  currentUser,
  data,
  productData,
  productName,
  isEditable,
  updateMove,
  deleteMove,
  toggle,
  isAllowed,
  hasUnits,
}) => {
  const [orderDate, setOrderDate] = useState();

  const handleChange = (moveIndex, name, value) => {
    let updatedMove = data.movementsDetail[moveIndex];
    updatedMove.moveIndex = moveIndex;

    if (name === 'date') {
      updatedMove[name] = new Date(value).toISOString();
    } else {
      updatedMove[name] = value;
    }

    if (name === 'reels' || name === 'meters') {
      const { reels, meters } = updatedMove;
      updatedMove.totalMeters = reels * meters;
    }

    updateMove(updatedMove);
  };

  const handleEditMove = (batchIndex, moveIndex) => {
    let updatedMove = data.movementsDetail[moveIndex];
    updatedMove.moveIndex = moveIndex;
    updatedMove.editAllowed = true;
    updateMove(updatedMove);
  };

  const handleDelete = (batchIndex, moveIndex) => {
    deleteMove(batchIndex, moveIndex);
  };

  const handleObservations = (moveId, moveIndex) => {
    toggle(moveId, moveIndex);
  };

  const actionButtons = (row, moveIndex) => {
    const observationButton = (
      <div
        id="observations"
        className="text-danger p-1 dmx-action-button"
        data-toggle="tooltip"
        data-placement="top"
        title="Ver/Editar observaciones"
      >
        <FontAwesomeIcon
          icon={faCommentDots}
          color={row.observations ? 'black' : 'gray'}
          onClick={() => handleObservations(row.id, moveIndex)}
        />
      </div>
    );

    const editButton = (
      <div className="text-secondary p-1 dmx-action-button">
        <FontAwesomeIcon icon={faEdit} onClick={() => handleEditMove(row.batchIndex, moveIndex)} />
      </div>
    );

    const deleteButton = (
      <div
        id="deleteBatch"
        className="text-danger p-1 dmx-action-button"
        data-toggle="tooltip"
        data-placement="top"
        title="Borrar lote"
        hidden={!isEditable}
      >
        <FontAwesomeIcon icon={faTimesCircle} onClick={() => handleDelete(row.batchIndex, moveIndex)} />
      </div>
    );

    const barcodeButton = <PrintLabel currentDataMP={currentDataMP} data={data} productName={productName} moveIndex={moveIndex} />;

    return (
      <>
        {observationButton}
        {isEditable && checkDataPermission(currentUser?.userType, 'mp:edit:stockControl') ? editButton : ''}
        {isEditable && checkDataPermission(currentUser?.userType, 'mp:edit:stockControl') ? deleteButton : ''}
        {row.type === 'INGRESO' ? barcodeButton : ''}
      </>
    );
  };

  const moveTypeOptions = [
    { value: 'INGRESO', label: 'INGRESO' },
    { value: 'EGRESO', label: 'EGRESO' },
  ];

  const productOptions = productData?.map((product) => {
    return { label: product.name, value: product._id };
  });

  const columns = [
    // Esta columna muestra el ícono de los movimiento existentes.
    {
      name: '',
      sortable: false,
      minWidth: '1%',
      maxWidth: '1%',
      cell: (row) => {
        let icon = <FontAwesomeIcon icon={faArrowRight} color="green" />;

        if (row.type === moveTypes.egress) {
          icon = <FontAwesomeIcon icon={faArrowLeft} color="red" />;
        }

        return row.isNew === undefined ? <p>{icon}</p> : '';
      },
    },
    {
      name: 'Movimiento',
      sortable: false,
      center: false,
      grow: 3,
      cell: (row, index) => {
        return (
          <Select
            name="type"
            menuPosition="fixed"
            className="basic-multi-select w-100"
            placeholder="Tipo de movimiento"
            defaultValue={{ label: row.type, value: row.type }}
            options={moveTypeOptions}
            isDisabled={!isEditable || !isAllowed(row)}
            onChange={(option) => handleChange(index, 'type', option.value)}
          />
        );
      },
    },
    {
      name: 'Fecha',
      selector: 'date',
      sortable: false,
      center: false,
      grow: 3.5,
      cell: (row, index) => {
        return (
          <div className="subrow-datepicker">
            <input
              type="date"
              className="form-control dmx-input w-100"
              id="date"
              value={row.date ? row.date.slice(0, 10) : ''}
              disabled={!isEditable || !isAllowed(row)}
              onChange={(e) => handleChange(index, 'date', e.target.value)}
            />
          </div>
        );
      },
    },
    {
      name: 'Rollos',
      sortable: false,
      center: false,
      grow: 1,
      omit: hasUnits,
      cell: (row, index) => (
        <input
          type="number"
          min="0"
          className="form-control dmx-input w-100"
          name="reels"
          defaultValue={row.reels || 0}
          disabled={!isEditable || !isAllowed(row)}
          onChange={(event) => handleChange(index, event.target.name, event.target.value)}
        />
      ),
    },
    {
      name: 'Metros',
      sortable: false,
      center: false,
      grow: 1,
      omit: hasUnits,
      cell: (row, index) => (
        <input
          type="number"
          min="0"
          className="form-control dmx-input w-100"
          name="meters"
          defaultValue={row.meters || 0}
          disabled={!isEditable || !isAllowed(row)}
          onChange={(event) => handleChange(index, event.target.name, event.target.value)}
        />
      ),
    },
    {
      name: 'Metros Totales',
      sortable: false,
      center: false,
      grow: 0.8,
      omit: hasUnits,
      cell: (row) => (
        <input
          type="number"
          min="0"
          className="form-control dmx-input w-100"
          name="meters"
          value={row.totalMeters.toFixed(2) || (row.reels * row.meters).toFixed(2)}
          disabled={true}
        />
      ),
    },
    {
      name: 'Tela Restante',
      sortable: false,
      center: false,
      grow: 0.8,
      omit: hasUnits,
      cell: (row, index) => (
        <input
          type="number"
          min="0"
          className="form-control dmx-input w-100"
          name="cuttingReturn"
          defaultValue={row.cuttingReturn || ''}
          disabled={!isEditable || !isAllowed(row) || row.type === 'INGRESO'}
          onChange={(event) => handleChange(index, event.target.name, event.target.value)}
        />
      ),
    },
    {
      name: 'Unidades',
      sortable: false,
      center: false,
      grow: 1,
      omit: !hasUnits,
      cell: (row, index) => (
        <input
          type="number"
          min="0"
          className="form-control dmx-input w-100"
          name="units"
          defaultValue={row.units || 0}
          disabled={!isEditable || row.isNew === undefined}
          onChange={(event) => handleChange(index, event.target.name, event.target.value)}
        />
      ),
    },
    {
      name: 'Prenda',
      sortable: false,
      center: false,
      grow: 3,
      cell: (row, index) => {
        return (
          <Select
            menuPosition="fixed"
            className="basic-multi-select w-100"
            placeholder="Tipo de movimiento"
            defaultValue={row.garment ? { label: row.garment.name, value: row.garment._id } : {}}
            options={productOptions}
            isDisabled={!isEditable || !isAllowed(row) || row.type === 'INGRESO'}
            onChange={(option) => handleChange(index, 'garment', option.value)}
          />
        );
      },
    },
  ];

  columns.push({
    sortable: false,
    center: false,
    grow: 3,
    cell: (row, index) => actionButtons(row, index),
  });

  const orderMoviments = () => {
    const dataMoviments = data.movementsDetail;
    const order = dataMoviments.sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }

      return 0;
    });
    setOrderDate(order);
  };

  useEffect(() => {
    orderMoviments();
  }, []);

  return (
    <DataTable
      customStyles={customStyles}
      columns={columns}
      data={orderDate}
      noDataComponent={<div className="p-3">{noRecords}</div>}
    />
  );
};

export default MoveList;
