import styled from 'styled-components';

export const ProductionOrder = styled('div')`
  &.table-responsive {
    table {
      thead {
        height: 65px;
        background: #eeeeee;
      }

      tbody {
        tr {
          border-bottom: 0px;

          &.product-cut {
            border-bottom: 0px;
          }

          td {
            padding: 5px 0px;
            vertical-align: top;
          }
        }
      }
    }
  }
`;
