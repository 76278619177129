import { useState } from 'react';
import Select from 'react-select';
import { Edit, X } from 'react-feather';
import Spinner from '../../../../components/Spinner'
import { SelectStateContainer, ButtonEdit, State } from './mixins';
import { connect } from 'react-redux';

const workshopStates = [{ value: 'inactive', label: 'Inactivo' }, { value: 'active', label: 'Activo' }]

const Component = ({ handleChangeState, workshop, editedWorkshopStates }) => {
  const [edit, setEdit] = useState(true);

  const customStyles = {
    singleValue: (base) => ({
      ...base,
      padding: 5,
      borderRadius: 5,
      color: 'black',
      display: 'flex',
    }),
    valueContainer: (provided) => ({
      ...provided,
      width: '160px',
    }),
  };

  const toggleState = () => {
    setEdit(!edit);
  };

  const selectState = () => {
    return (
      <Select
        name="state"
        styles={customStyles}
        placeholder={'Seleccione un Estado'}
        onMenuClose={() => setEdit(true)}
        isSearchable={false}
        defaultValue={workshopStates?.filter(workshopState => workshopState?.value === workshop?.state)}
        options={workshopStates}
        onChange={(e) => {
          handleChangeState(e);
        }}
      />
    );
  };

  const emptyState = () => {
    if (workshop?.state && workshop?.state !== '') {
      return (
        <State>
          {editedWorkshopStates.loading ?
            <Spinner size={'small'} /> :
            workshopStates.filter((workshopState) => {
              return workshopState.value === workshop.state;
            })[0].label
          }
        </State>
      );
    }

    return null;
  };

  return (
    <SelectStateContainer>
      <ButtonEdit type="button" onClick={() => toggleState()} outline size="xs">
        {!edit ? <X /> : <Edit />}
      </ButtonEdit>

      {edit ? emptyState() : selectState()}
    </SelectStateContainer>
  );
};

const state = ({ workshopStore }) => {
  const { data: workshop } = workshopStore.workshop;
  const { states: editedWorkshopStates } = workshopStore.editWorkshop;
  return {
    workshop,
    editedWorkshopStates,
  };
};

export default connect(state)(Component);
