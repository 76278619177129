import { ProductionOrderBag } from '../../types/production-order-bag';

export const CREATE_PRODUCTION_ORDER_BAG = 'CREATE_PRODUCTION_ORDER_BAG';
export const CREATE_PRODUCTION_ORDER_BAG_SUCCESS = 'CREATE_PRODUCTION_ORDER_BAG_SUCCESS';
export const CREATE_PRODUCTION_ORDER_BAG_ERROR = 'CREATE_PRODUCTION_ORDER_BAG_ERROR';
export const CREATE_PRODUCTION_ORDER_BAG_RESET = 'CREATE_PRODUCTION_ORDER_BAG_RESET';

export const GET_PRODUCTION_ORDER_BAG_ALL = 'GET_PRODUCTION_ORDER_BAG_ALL';
export const GET_PRODUCTION_ORDER_BAG_ALL_SUCCESS = 'GET_PRODUCTION_ORDER_BAG_ALL_SUCCESS';
export const GET_PRODUCTION_ORDER_BAG_ALL_ERROR = 'GET_PRODUCTION_ORDER_BAG_ALL_ERROR';
export const GET_PRODUCTION_ORDER_BAG_ALL_RESET = 'GET_PRODUCTION_ORDER_BAG_ALL_RESET';

export const GET_PRODUCTION_ORDER_BAG = 'GET_PRODUCTION_ORDER_BAG';
export const GET_PRODUCTION_ORDER_BAG_SUCCESS = 'GET_PRODUCTION_ORDER_BAG_SUCCESS';
export const GET_PRODUCTION_ORDER_BAG_ERROR = 'GET_PRODUCTION_ORDER_BAG_ERROR';
export const GET_PRODUCTION_ORDER_BAG_RESET = 'GET_PRODUCTION_ORDER_BAG_RESET';

export const UPDATE_PRODUCTION_ORDER_BAG = 'UPDATE_PRODUCTION_ORDER_BAG';
export const UPDATE_PRODUCTION_ORDER_BAG_SUCCESS = 'UPDATE_PRODUCTION_ORDER_BAG_SUCCESS';
export const UPDATE_PRODUCTION_ORDER_BAG_ERROR = 'UPDATE_PRODUCTION_ORDER_BAG_ERROR';
export const UPDATE_PRODUCTION_ORDER_BAG_RESET = 'UPDATE_PRODUCTION_ORDER_BAG_RESET';

export const DELETE_PRODUCTION_ORDER_BAG = 'DELETE_PRODUCTION_ORDER_BAG';
export const DELETE_PRODUCTION_ORDER_BAG_SUCCESS = 'DELETE_PRODUCTION_ORDER_BAG_SUCCESS';
export const DELETE_PRODUCTION_ORDER_BAG_ERROR = 'DELETE_PRODUCTION_ORDER_BAG_ERROR';
export const DELETE_PRODUCTION_ORDER_BAG_RESET = 'DELETE_PRODUCTION_ORDER_BAG_RESET';

export const SELECTED_PRODUCTION_ORDER_BAG = 'SELECTED_PRODUCTION_ORDER_BAG';
export const SELECTED_PRODUCTION_ORDER_BAG_RESET = 'SELECTED_PRODUCTION_ORDER_BAG_RESET';

export const PRODUCTION_ORDER_BAG_UPDATE_CURRENT = 'PRODUCTION_ORDER_BAG_UPDATE_CURRENT';

export interface ProductionOrderBagUpdateCurrent {
  type: typeof PRODUCTION_ORDER_BAG_UPDATE_CURRENT;
  payload: ProductionOrderBag;
}

export interface GetProductionOrderBagAll {
  type: typeof GET_PRODUCTION_ORDER_BAG_ALL;
  payload: { idProductionOrder?: string, idWorkshop?: string };
}
export interface GetProductionOrderBagAllSuccess {
  type: typeof GET_PRODUCTION_ORDER_BAG_ALL_SUCCESS;
  payload: ProductionOrderBag[];
}
export interface GetProductionOrderBagAllError {
  type: typeof GET_PRODUCTION_ORDER_BAG_ALL_ERROR;
  payload: boolean | string;
}
export interface GetProductionOrderBagAllReset {
  type: typeof GET_PRODUCTION_ORDER_BAG_ALL_RESET;
  payload: null;
}

export interface GetProductionOrderBag {
  type: typeof GET_PRODUCTION_ORDER_BAG;
  payload: { id: string };
}
export interface GetProductionOrderBagSuccess {
  type: typeof GET_PRODUCTION_ORDER_BAG_SUCCESS;
  payload: ProductionOrderBag;
}
export interface GetProductionOrderBagError {
  type: typeof GET_PRODUCTION_ORDER_BAG_ERROR;
  payload: boolean | string;
}
export interface GetProductionOrderBagReset {
  type: typeof GET_PRODUCTION_ORDER_BAG_RESET;
  payload: null;
}

export interface UpdateProductionOrderBag {
  type: typeof UPDATE_PRODUCTION_ORDER_BAG;
  payload: ProductionOrderBag;
}
export interface UpdateProductionOrderBagSuccess {
  type: typeof UPDATE_PRODUCTION_ORDER_BAG_SUCCESS;
  payload: ProductionOrderBag;
}
export interface UpdateProductionOrderBagError {
  type: typeof UPDATE_PRODUCTION_ORDER_BAG_ERROR;
  payload: boolean | string;
}
export interface UpdateProductionOrderBagReset {
  type: typeof UPDATE_PRODUCTION_ORDER_BAG_RESET;
  payload: null;
}

export interface DeleteProductionOrderBag {
  type: typeof DELETE_PRODUCTION_ORDER_BAG;
  payload: { id: string };
}
export interface DeleteProductionOrderBagSuccess {
  type: typeof DELETE_PRODUCTION_ORDER_BAG_SUCCESS;
  payload: null;
}
export interface DeleteProductionOrderBagError {
  type: typeof DELETE_PRODUCTION_ORDER_BAG_ERROR;
  payload: boolean | string;
}
export interface DeleteProductionOrderBagReset {
  type: typeof DELETE_PRODUCTION_ORDER_BAG_RESET;
  payload: null;
}

export interface SelectedProductionOrderBag {
  type: typeof SELECTED_PRODUCTION_ORDER_BAG;
  payload: { idBag: string };
}
export interface SelectedProductionOrderBagReset {
  type: typeof SELECTED_PRODUCTION_ORDER_BAG_RESET;
  payload: null;
}

export interface CreateProductionOrderBag {
  type: typeof CREATE_PRODUCTION_ORDER_BAG;
  payload: {
    date: Date;
    bags: number;
    units: number;
    opID: string;
    productID: string;
  };
}
export interface CreateProductionOrderBagSuccess {
  type: typeof CREATE_PRODUCTION_ORDER_BAG_SUCCESS;
  payload: null;
}
export interface CreateProductionOrderBagError {
  type: typeof CREATE_PRODUCTION_ORDER_BAG_ERROR;
  payload: boolean | string;
}
export interface CreateProductionOrderBagReset {
  type: typeof CREATE_PRODUCTION_ORDER_BAG_RESET;
  payload: null;
}

export interface State {
  productionOrderBagAll: {
    data: ProductionOrderBag[] | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  productionOrderBag: {
    data: ProductionOrderBag | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  updateProductionOrderBag: {
    data: ProductionOrderBag | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  createProductionOrderBag: {
    data: ProductionOrderBag | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  deleteProductionOrderBag: {
    data: ProductionOrderBag[] | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  selectedProductionOrderBag: {
    data: { idBag: string } | null;
  };
}

export const initialState: State = {
  productionOrderBagAll: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  productionOrderBag: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  updateProductionOrderBag: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  createProductionOrderBag: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  deleteProductionOrderBag: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  selectedProductionOrderBag: {
    data: null,
  },
};

export type Actions =
  | GetProductionOrderBagAll
  | GetProductionOrderBagAllSuccess
  | GetProductionOrderBagAllError
  | GetProductionOrderBagAllReset
  | GetProductionOrderBag
  | GetProductionOrderBagSuccess
  | GetProductionOrderBagError
  | GetProductionOrderBagReset
  | UpdateProductionOrderBag
  | UpdateProductionOrderBagSuccess
  | UpdateProductionOrderBagError
  | UpdateProductionOrderBagReset
  | DeleteProductionOrderBag
  | DeleteProductionOrderBagSuccess
  | DeleteProductionOrderBagError
  | DeleteProductionOrderBagReset
  | CreateProductionOrderBag
  | CreateProductionOrderBagSuccess
  | CreateProductionOrderBagError
  | CreateProductionOrderBagReset
  | ProductionOrderBagUpdateCurrent
  | SelectedProductionOrderBag
  | SelectedProductionOrderBagReset;
