import styled from 'styled-components';
import QRCode from 'qrcode.react';

export const ButtonPrintContainer = styled('div')`
  margin: 0px 15px;
  cursor: pointer;
`;

export const ContainerComponent = styled('div')`
  display: none;
  position: relative;
`;

export const Sticker = styled('div')`
  margin: 25px;
`;

export const Separator = styled('div')`
  width: 100%;
  height: 10px;
  border-bottom: 10px solid #000;
  margin: 25px 0px;
`;

// heading print
export const Heading = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  margin-bottom: 70px;
  position: relative;
`;

export const CurrentDate = styled('div')`
  font-size: 7mm;
  position: absolute;
  right: 0px;
  top: 0px;
`;

export const LogoContainer = styled('div')``;

export const Image = styled('img')`
  margin-bottom: 5px;
`;
export const LogoClaim = styled('div')`
  font-size: 9mm;
`;

// body print
export const Body = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const QRCodeCustom = styled(QRCode)`
  margin-bottom: 30px;
`;

export const ProductWrapper = styled('div')`
  max-width: 43%;
  text-align: right;
  border-right: 10px solid #000;
  margin-right: 50px;
  padding-right: 50px;
`;

export const ProductName = styled('div')`
  font-size: 11mm;
  font-weight: 600;
  text-align: right;
`;

export const ProductCode = styled('div')`
  font-size: 11mm;
  font-weight: 600;
`;

export const CantMts = styled('div')`
  width: 100%;
  font-size: 24mm;
  font-weight: 400;
  text-align: center;
`;

export const Lote = styled('div')`
  width: 100%;
  font-size: 24mm;
  font-weight: 600;
  text-align: center;
`;

// Footer
export const Footer = styled('div')`
  margin-top: 200px;
  position: absolute;
  left: 0px;
  bottom: 0px;
`;

export const BarcodeContainer = styled('div')`
  margin-top: 10px;
`;

export const QRCodeInnen = styled(QRCode)`
  display: inline-block;
  vertical-align: middle;
`;
export const LogoInnen = styled('img')`
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
  filter: grayscale(50%);
`;
