import {
  initialState,
  Actions,
  State,
  GET_FEED_STOCK,
  GET_FEED_STOCK_SUCCESS,
  GET_FEED_STOCK_ERROR,
  GET_ALL_FEED_STOCK,
  GET_ALL_FEED_STOCK_SUCCESS,
  GET_ALL_FEED_STOCK_ERROR,
  PATCH_FEED_STOCK,
  PATCH_FEED_STOCK_SUCCESS,
  PATCH_FEED_STOCK_ERROR,
} from '../types/raw-material';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_FEED_STOCK:
      return {
        ...state,
        allFeedStock: {
          data: [],
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_FEED_STOCK_SUCCESS:
      return {
        ...state,
        allFeedStock: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_FEED_STOCK_ERROR:
      return {
        ...state,
        allFeedStock: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_FEED_STOCK:
      return {
        ...state,
        feedStock: {
          data: [],
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_FEED_STOCK_SUCCESS:
      return {
        ...state,
        feedStock: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_FEED_STOCK_ERROR:
      return {
        ...state,
        feedStock: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case PATCH_FEED_STOCK:
      return {
        ...state,
        updateFeedStock: {
          data: [],
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case PATCH_FEED_STOCK_SUCCESS:
      return {
        ...state,
        updateFeedStock: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case PATCH_FEED_STOCK_ERROR:
      return {
        ...state,
        updateFeedStock: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    default:
      return state;
  }
};

export default reducers;
