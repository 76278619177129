import {
  GET_FEED_STOCK,
  GetFeedStock,
  PATCH_FEED_STOCK,
  PatchFeedStock,
  GET_ALL_FEED_STOCK,
  GetAllFeedStock,
  GET_ALL_FEED_STOCK_CATEGORIES,
  GetAllFeedStockCategories,
} from '../types/raw-material';

export const getAllFeedStockCategories = (): GetAllFeedStockCategories => {
  return {
    type: GET_ALL_FEED_STOCK_CATEGORIES,
    payload: null,
  };
};

export const getAllFeedStock = (): GetAllFeedStock => {
  return {
    type: GET_ALL_FEED_STOCK,
    payload: null,
  };
};

export const getFeedStock = (payload): GetFeedStock => {
  return {
    type: GET_FEED_STOCK,
    payload,
  };
};

export const patchFeedStock = (payload): PatchFeedStock => {
  return {
    type: PATCH_FEED_STOCK,
    payload,
  };
};
