/* eslint-disable */
import React, { useState } from 'react';

import { Button } from 'reactstrap';
import { Edit, X } from 'react-feather';
import { StyledSelect } from '../StyledSelect';

import './style.scss';

const formatOptionLabel = ({ value, label, color }) => (
  <div className="badge p-2" style={{ backgroundColor: color }}>
    {label}
  </div>
);

const SelectState = ({ statusOptions, statusColors, handleStatusChange, actualStatus, isEditable }) => {
  const [modifyingStatus, setModifyingStatus] = useState(false);

  const statusOpt = Object.entries(statusOptions).map((e) => ({ value: e[0], label: e[1], color: statusColors[e[0]] }));

  const statusChange = (option) => {
    if (handleStatusChange) {
      handleStatusChange(option.value);
    }
  };

  return (
    <>
      {modifyingStatus ? (
        <div className="select-state-aligned">
          <Button size="xs" color="link" onClick={() => setModifyingStatus(!modifyingStatus)}>
            <X size="24" />
          </Button>
          <StyledSelect
            name="status"
            formatOptionLabel={formatOptionLabel}
            options={statusOpt}
            onChange={statusChange}
            value={
              statusOpt &&
              statusOpt.find((option) => {
                return option.value === actualStatus;
              })
            }
          />
        </div>
      ) : (
        <div className="select-state-flex">
          {isEditable ? (
            <Button size="xs" color="link" onClick={() => setModifyingStatus(!modifyingStatus)}>
              <Edit size="24" />
            </Button>
          ) : null}
          <div className="badge p-2" style={{ backgroundColor: statusColors[actualStatus] }}>
            {statusOptions && statusOptions[actualStatus]}
          </div>
        </div>
      )}
    </>
  );
};

export default SelectState;
