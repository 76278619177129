import { call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';

import { GET_WORKSHOPS, GET_WORKSHOPS_SUCCESS, GET_WORKSHOPS_ERROR } from '../types/statics/workshop';

/**
 * Pending Task
 * Check if store is filled before call to BFF.
 */

function* getWorkshops({ payload }) {
  try {
    const { response, error } = yield call(services.statics.getWorkshops);

    if (error) {
      yield put({ type: GET_WORKSHOPS_ERROR, payload: error });
    } else {
      yield put({ type: GET_WORKSHOPS_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: GET_WORKSHOPS_ERROR, payload: 'Error inesperado al buscar los talleres' });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_WORKSHOPS, getWorkshops);
}
