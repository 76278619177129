import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import RawMaterialForm from './form';
import useFeedstockCategoriesFetcher from '../../../hooks/useFeedstockCategoriesFetcher';
import Spinner from '../../../components/Spinner';

const RawMaterialModal = ({ icon, isOpen, title, toggle, updateDataTable, errorMsg, successMsg }) => {
  const rawMaterialCategories = useFeedstockCategoriesFetcher();
  const [data, setData] = useState();
  // const { action, attributes, data } = props;

  // console.log(rawMaterialCategories);

  useEffect(() => {
    const newData = rawMaterialCategories.data;
    setData(newData);
  }, [rawMaterialCategories.data]);

  if (rawMaterialCategories.isLoading || data === null) {
    return <Spinner />;
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="dmx-modal-width">
      <ModalHeader toggle={toggle}>
        {icon} {title}
      </ModalHeader>
      <ModalBody>
        <RawMaterialForm
          data={data}
          toggle={toggle}
          updateDataTable={updateDataTable}
          errorMsg={errorMsg}
          successMsg={successMsg}
        />
      </ModalBody>
    </Modal>
  );
};

export default RawMaterialModal;
