import React from 'react';
import { connect } from 'react-redux';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkDataPermission } from '../../../components/CanRBac';

const ObsForm = ({ currentUser, isEditable, handleChange, observations }) => {
  return (
    <div className="project-widgets mt-2 infoCard">
      <div className="media dmx-delimeter dmx-headerCard">
        <div className="justify justify-start">
          <span className="mr-2">
            <FontAwesomeIcon icon={faComment} />
          </span>
          <h5 className="m-0 font-weight-bold">Observaciones</h5>
        </div>
      </div>
      <div className="project-widgets mt-2 infoCard">
        <div className="mb-1">
          <textarea
            name="observations"
            className="font-weight-bold"
            value={observations}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            isDisabled={checkDataPermission(currentUser?.userType, 'np:edit:product:observations') ? !isEditable : true}
          />
        </div>
      </div>
    </div>
  );
};

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(ObsForm);
