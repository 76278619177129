import { useEffect, useState } from 'react';
import { ProductionOrderDto } from '../../../../../types/production-order';
import { Batches } from './styles';

interface ComponentProps {
  productionOrder: ProductionOrderDto;
}

const Component = ({ productionOrder }: ComponentProps) => {
  const [feedstocks, setFeedstocks] = useState<{ label: string; value: string }[]>();

  useEffect(() => {
    if (!!productionOrder) {
      setFeedstocks(productionOrder?.feedstock?.map((item) => ({ label: item.name, value: item._id })));
    }
  }, [productionOrder]);

  return (
    <Batches className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Materia Prima</th>
            <th>Prioritario</th>
          </tr>
        </thead>

        <tbody>
          {feedstocks?.map((feedstock, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{feedstock?.label}</td>
                <td>{feedstock?.value === productionOrder?.principalFeedstockCode ? 'SI' : 'NO'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Batches>
  );
};

export default Component;
