import {
  initialState,
  Actions,
  State,
  GET_ORDERS_ALL,
  GET_ORDERS_ALL_SUCCESS,
  GET_ORDERS_ALL_ERROR,
  GET_ORDERS_ALL_RESET,
} from '../types/orders';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ORDERS_ALL:
      return {
        ...state,
        ordersAll: {
          data: [],
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ORDERS_ALL_SUCCESS:
      return {
        ...state,
        ordersAll: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ORDERS_ALL_ERROR:
      return {
        ...state,
        ordersAll: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_ORDERS_ALL_RESET:
      return {
        ...state,
        ordersAll: {
          data: [],
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    default:
      return state;
  }
};

export default reducers;
