import styled from 'styled-components';

export const Tabs = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

export const EmptyState = styled('div')`
  padding: 3rem;
  text-align: center;
  font-size: large;
  color: #00000044;
  font-weight: bolder;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Button = styled('button')<{ active }>`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: none;
  outline: 0px;
  cursor: pointer;

  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;

  background-color: ${({ active }) => (active ? '#4466f2' : '#e5e5e5')};
  color: ${({ active }) => (active ? 'white' : 'black')};

  &:hover {
    background-color: ${({ active }) => (active ? '#4466f2' : '#d0d0d0')};
  }

  &:focus,
  &:active {
    outline: 0;
  }
`;
