import { DateContainer, DateGroup, Label, Date } from './mixins'

const Component = ({ dateCreation, updateDate, dateCut }) => {
  const showDateCut = dateCut !== ''

  return(
    <DateContainer>
      <DateGroup>
        <Label>Creado</Label>
        <Date>{dateCreation}</Date>
      </DateGroup>

      <DateGroup>
        <Label>Actualizado</Label>
        <Date>{updateDate}</Date>
      </DateGroup>

      {showDateCut && (
        <DateGroup>
          <Label>Corte</Label>
          <Date>{dateCut}</Date>
        </DateGroup>
      )}
    </DateContainer>
  )
}

export default Component
