import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';

// styles
import { ComponentContainer } from './styles';

// types
import { ProductionOrderDto } from '../../../../../../../../../types/production-order';

// store
import { patchProductionOrder } from '../../../../../../../../../store/actions/production-order';
import { toast } from 'react-toastify';
import { checkDataPermission } from '../../../../../../../../../components/CanRBac';
import { User } from '../../../../../../../../../types/user';

interface ComponentProps {
  dataFeedstock: any;
  productionOrder: ProductionOrderDto;
  currentUser: User;
}

const Component = ({ dataFeedstock, productionOrder, currentUser }: ComponentProps) => {
  const dispatch = useDispatch();

  const [selectedFeedstocks, setSelectedFeedstocks] = useState<
    { label: string; value: string; extra: { stock: number } }[]
  >(
    productionOrder?.feedstock?.map((item) => {
      return { label: item?.name, value: item?._id, extra: { stock: item?.stock } };
    }) || [],
  );

  /**
   * notRemovedBatches()
   * Luego de eliminar una materia prima es necesario quitar el/los lotes asociados
   * La funcion nos permite obtener un listado de los ids de lotes que deben permanecer,
   * aquellos que no figuren en el array resultante son los que se eliminaran.
   * @returns string[]
   */
  const notRemovedBatches = () => {
    const feedstocksIds = selectedFeedstocks.map((feedstockSelected) => feedstockSelected.value);

    const batchesIds = productionOrder?.batch.map((item) => item._id);

    /** Obtenemos los feedstocks que van a ser eliminados */
    const removedFeedstocks = productionOrder?.feedstock?.filter((item) => !feedstocksIds.includes(item._id));

    /** Obtenemos el id de cada uno de los batches que pertenecen a los feedstocks que van a ser eliminados */
    const batchesToBeRemovedIds = removedFeedstocks
      .map((item) => item.stockControl) //Obtenemos los batches de cada uno de los feedstock elimiandos
      .flat()
      .map((item) => item._id); //Obtenemos el id de cada uno de los batches eliminados

    return batchesIds.filter((item) => !batchesToBeRemovedIds.includes(item));
  };

  const saveSelectedFeedstocks = () => {
    const feedstocksSelectedIds = selectedFeedstocks.map((feedstockSelected) => feedstockSelected.value);
    const id = productionOrder?._id;
    dispatch(
      patchProductionOrder({ ...productionOrder, batch: notRemovedBatches(), feedstock: feedstocksSelectedIds, id }),
    );
  };

  const feedStock = dataFeedstock?.filter(
    (feedstock) => feedstock?.feedstockCategoryId?.productionType === 'FEEDSTOCK',
  );
  const supplies = dataFeedstock?.filter((supply) => supply?.feedstockCategoryId?.productionType === 'SUPPLY');

  const options = [
    {
      label: 'MATERIA PRIMA',
      options: feedStock?.map((feedstock) => ({
        label: feedstock?.name,
        value: feedstock?._id,
        extra: { stock: feedstock?.stock },
      })),
    },
    {
      label: 'INSUMOS',
      options: supplies?.map((supply) => ({
        label: supply?.name,
        value: supply?._id,
      })),
    },
  ];

  return (
    <ComponentContainer>
      <Row>
        <Col md="9" sm="12">
          <Select
            className="w-100"
            menuPortalTarget={document.body}
            menuPosition="fixed"
            value={selectedFeedstocks}
            options={options}
            placeholder="Elija la materia prima"
            isDisabled={
              !checkDataPermission(currentUser.userType, 'op:edit:feedStock', { status: productionOrder?.state })
            }
            onChange={(value, actionMeta) => {
              const isDeletion = value.length < selectedFeedstocks.length;

              if (actionMeta.action === 'select-option' && actionMeta.option?.extra?.stock <= 0) {
                toast.info(`${actionMeta.option?.label} no cuenta con lotes o metros disponibles`, { autoClose: 3000 });
              }

              if (isDeletion) {
                const selectedIds = value.map((batch) => batch?.value);
                const removedId = selectedFeedstocks.filter((batch) => !selectedIds.includes(batch?.value))[0].value;
                const removedFeedStockMovements = productionOrder?.feedstock?.filter((feedStock) => {
                  const feedStockMovements = feedStock?.stockControl.filter((batch) => {
                    const movements = batch?.movementsDetail.filter((movement) => {
                      return movement?.productionOrderId === productionOrder?._id;
                    });

                    return movements?.length > 0 && feedStock?._id === removedId;
                  });

                  return feedStockMovements?.length > 0;
                });

                // Comprobamos que el/los batches eliminados no posean Egresos de materia prima.
                // En caso de contar con egresos se evita su eliminacion.

                if (removedFeedStockMovements?.length <= 0) {
                  setSelectedFeedstocks(value);
                } else {
                  toast.info('Debes eliminar los lotes selecionados para eliminar la materia prima.', {
                    autoClose: 3000,
                  });
                }
              } else {
                setSelectedFeedstocks(value);
              }
            }}
            isMulti
          />
        </Col>

        <Col md="3" sm="12">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              saveSelectedFeedstocks();
            }}
            disabled={
              !checkDataPermission(currentUser.userType, 'op:edit:feedStock', { status: productionOrder?.state })
            }
          >
            Confirmar
          </button>
        </Col>
      </Row>
    </ComponentContainer>
  );
};

const state = ({ productionOrderStore, userStore }) => {
  const { data: currentUser } = userStore.information;
  const { data: productionOrder } = productionOrderStore.productionOrder;

  return {
    currentUser,
    productionOrder,
  };
};

export default connect(state)(Component);
