import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

// icons
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Pages
import RawMaterialList from './List';
import RawMaterialModal from './Modal';

// Components
import Spinner from '../../components/Spinner';

// Styles
import './styles.scss';

// Hooks
import useFeedstockFetcher from '../../hooks/useFeedstockFetcher';

// Services
import getFeedstock from '../../services/getFeedstock';

// Permisos
import CanRBac from '../../components/CanRBac';

const RawMaterial = ({ currentUser }) => {
  const rawMaterials = useFeedstockFetcher();
  const [data, setData] = useState();
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState();
  const [itemsForPage, setItemsForPage] = useState();

  const updateDataTable = () => {
    getFeedstock().then((result) => {
      setData(result);
    });
  };

  useEffect(() => {
    const newData = rawMaterials.data;
    setData(newData);
  }, [rawMaterials.data]);

  useEffect(() => {
    getLastPage();
  }, []);

  if (rawMaterials.isLoading) {
    return <Spinner size="large" isPageLoader />;
  }
  const getLastPage = () => {
    const lastPageLS = localStorage.getItem('lastPageRawMaterial');
    const itemsForPageLS = localStorage.getItem('itemsForPageRawMaterial');

    if (lastPageLS === null || itemsForPageLS === null) {
      setPage(1);
      setItemsForPage(10);
    } else if (lastPageLS == 1) {
      setPage(1);
      setItemsForPage(itemsForPageLS);
    } else {
      setPage(lastPageLS);
      setItemsForPage(itemsForPageLS);
    }
  };
  const eventLastPage = (page) => {
    setPage(page);
  };
  const eventItemsForPage = (currentRowsPerPage) => {
    setItemsForPage(currentRowsPerPage);
  };
  const postLastPageLocal = () => {
    localStorage.setItem('lastPageRawMaterial', page);
    localStorage.setItem('itemsForPageRawMaterial', itemsForPage);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleNewRawMaterial = () => {
    toggleModal();
    postLastPageLocal();
  };

  const toastError = (msg) => {
    toast.error(msg, { autoClose: 3000 });
  };

  const toastSuccess = (msg) => {
    toast.success(msg, { autoClose: 3000 });
  };

  return (
    <>
      <div className="container-fluid page-raw-material">
        <div className="page-header">
          <CanRBac
            role={currentUser?.userType}
            perform="mp:create"
            yes={() => (
              <button type="button" onClick={handleNewRawMaterial} className="btn btn-success btn-create">
                <FontAwesomeIcon icon={faPlus} /> Nueva Materia Prima
              </button>
            )}
          />
        </div>

        <div className="card dmx-card-mt">
          <RawMaterialList
            data={data}
            updateDataTable={updateDataTable}
            errorMsg={toastError}
            successMsg={toastSuccess}
            page={page}
            itemsForPage={itemsForPage}
            eventLastPage={eventLastPage}
            eventItemsForPage={eventItemsForPage}
            postLastPageLocal={postLastPageLocal}
          />

          <RawMaterialModal
            isOpen={modal}
            toggle={toggleModal}
            title="Creación de materia prima"
            updateDataTable={updateDataTable}
            icon={<FontAwesomeIcon icon={faPlus} />}
            errorMsg={toastError}
            successMsg={toastSuccess}
          />

          <ToastContainer
            position="bottom-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </>
  );
};

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(RawMaterial);
