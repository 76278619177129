import { Row, Col } from 'reactstrap';
import Selection from './components/selection';
import Table from './components/table';

// mixins
import { Item, CardText } from '../../mixins';

const Component = ({ handleBatchStockSelection }) => {
  return (
    <CardText>
      <Row>
        <Col>
          <Item>Lotes:</Item>
        </Col>
      </Row>

      <Selection />

      <Table onBatchStockSelection={handleBatchStockSelection} />
    </CardText>
  );
};

export default Component;
