import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import user from './user';
import profile from './profile';
import productionOrder from './production-order';
import productionOrderBag from './production-order-bag';
import rawMaterial from './raw-material';
import orders from './orders';
import modal from './modal';
import statics from './statics';
import workshop from './workshop';
import workshopOrder from './workshop-order';
import customer from './customer';
import product from './product';

const createRootReducer = (history: any) =>
  combineReducers({
    routerStore: connectRouter(history),
    userStore: user,
    profileStore: profile,
    authStore: auth,
    productionOrderStore: productionOrder,
    productionOrderBagStore: productionOrderBag,
    rawMaterialStore: rawMaterial,
    ordersStore: orders,
    modalStore: modal,
    staticsStore: statics,
    workshopStore: workshop,
    workshopOrderStore: workshopOrder,
    customerStore: customer,
    productStore: product,
  });

export default createRootReducer;
