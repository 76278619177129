import { all } from 'redux-saga/effects';
import auth from './auth';
import user from './user';
import profile from './profile';
import productionOrder from './production-order';
import rawMaterial from './raw-material';
import orders from './orders';
import statics from './statics';
import workshop from './workshop';
import workshopOrder from './workshop-order';
import productionOrderBag from './production-order-bag';
import customer from './customer';
import product from './product';

export default function* rootSaga() {
  yield all([
    auth(),
    user(),
    profile(),
    productionOrder(),
    rawMaterial(),
    orders(),
    statics(),
    workshop(),
    workshopOrder(),
    productionOrderBag(),
    customer(),
    product(),
  ]);
}
