import {
  initialState,
  Actions,
  State,
  NEW_WORKSHOP,
  NEW_WORKSHOP_SUCCESS,
  NEW_WORKSHOP_ERROR,
  RESET_NEW_WORKSHOP,
  EDIT_WORKSHOP,
  EDIT_WORKSHOP_SUCCESS,
  EDIT_WORKSHOP_ERROR,
  RESET_EDIT_WORKSHOP,
  GET_WORKSHOP,
  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOP_ERROR,
  GET_ALL_WORKSHOPS,
  GET_ALL_WORKSHOPS_SUCCESS,
  GET_ALL_WORKSHOPS_ERROR,
  GET_ALL_WORKSHOPS_RESET,
} from '../types/workshop';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_WORKSHOPS:
      return {
        ...state,
        workshops: {
          data: [],
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_WORKSHOPS_SUCCESS:
      return {
        ...state,
        workshops: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_WORKSHOPS_ERROR:
      return {
        ...state,
        workshops: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_ALL_WORKSHOPS_RESET:
      return {
        ...state,
        workshops: {
          data: [],
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case GET_WORKSHOP:
      return {
        ...state,
        workshop: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_WORKSHOP_SUCCESS:
      return {
        ...state,
        workshop: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_WORKSHOP_ERROR:
      return {
        ...state,
        workshop: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_WORKSHOP:
      return {
        ...state,
        newWorkshop: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_WORKSHOP_SUCCESS:
      return {
        ...state,
        newWorkshop: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_WORKSHOP_ERROR:
      return {
        ...state,
        newWorkshop: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_WORKSHOP:
      return {
        ...state,
        newWorkshop: initialState.newWorkshop,
      };
    case EDIT_WORKSHOP:
      return {
        ...state,
        editWorkshop: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case EDIT_WORKSHOP_SUCCESS:
      return {
        ...state,
        editWorkshop: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case EDIT_WORKSHOP_ERROR:
      return {
        ...state,
        editWorkshop: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_EDIT_WORKSHOP:
      return {
        ...state,
        editWorkshop: initialState.editWorkshop,
      };
    default:
      return state;
  }
};

export default reducers;
