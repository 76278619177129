import React from 'react';

// Fontawesome Icons
import {
  faPhoneAlt,
  faMapMarkerAlt,
  faEnvelope,
  faUserTie,
  faBuilding,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utils
import { getTotalBultos } from '../../utils/partialDeliveryUtils';

// Styles
import './Invoice.scss';

// Pictures
import logo from '../../assets/images/logo.png';

// My components
import ProductDetail from './ProductDetail';

// Main Function
const Invoice = ({ order }) => {
  // Icons
  const iconPhone = <FontAwesomeIcon icon={faPhoneAlt} />;
  const iconAddress = <FontAwesomeIcon icon={faMapMarkerAlt} />;
  const iconEmail = <FontAwesomeIcon icon={faEnvelope} />;
  const iconClient = <FontAwesomeIcon icon={faUserTie} />;
  const iconBrandName = <FontAwesomeIcon icon={faBuilding} />;
  const iconWarning = <FontAwesomeIcon icon={faExclamationTriangle} />;

  // Data
  const { orderNoteId, orderNumber, createDate, takeAway, observations, orderDeliveryAddress, invoiced } = order;
  const { brandName, companyName, address, email, phone, code, anmat } = order.client;
  const sellerName = order.seller ? order.seller.name : '';
  const handlerName = order.handler ? order.handler.name : '';
  const locale = 'es-ES';
  const options = {
    dateStyle: 'full',
    timeStyle: 'long',
  };
  const formatedDate = new Date(createDate).toLocaleString(locale, options).toString().replace(' GMT-3', '');

  // View
  return (
    <div className="component-invoice invoice-margins">
      <div className="header-pdf table-responsive">
        <table className="table">
          <tbody>
            <tr>
              {/* Logo */}
              <td>
                <img src={logo} alt="" />
              </td>

              {/* Info de Nota de pedido */}
              <td>
                <h6 className="text-uppercase">
                  {iconWarning} <span className="font-weight-bold">USO INTERNO</span> <br />
                  NOTA DE PEDIDO #{orderNoteId} <br />
                  ORDEN DE COMPRA #{orderNumber} <br />
                  {formatedDate}
                </h6>
              </td>
            </tr>

            <tr>
              {/* Info de cliente */}
              <td>
                <p>
                  {iconClient} {companyName} <br />
                  {iconBrandName} {brandName} <br />
                  {iconAddress} {address} <br />
                  {iconPhone} {phone} <br />
                  {iconEmail} {email}
                </p>
              </td>

              {/* Info de factura */}
              <td>
                <p>
                  <strong>Código cliente:</strong> {code} <br />
                  <strong>Dirección de entrega:</strong> {orderDeliveryAddress} <br />
                  <strong>Anmat:</strong> {anmat ? 'SÍ' : 'NO'} <br />
                  <strong>Excel:</strong> {invoiced ? 'SÍ' : 'NO'} <br />
                  <strong>Vendedor:</strong> {sellerName} <br />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Info de artículos */}
      <ProductDetail order={order} noProductsText="No hay productos para mostrar" />

      {/* Info adicional */}
      <div className="footer-pdf table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td>
                <strong>Observaciones:</strong> {observations}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Cantidad de bultos:</strong> {getTotalBultos(order)}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Preparó:</strong> {handlerName}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Controló:</strong> {handlerName}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Retira:</strong> {takeAway ? 'SÍ' : 'NO'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Footer / Info de Dimex */}
      <div className="brand-contact-pdf">
        <div>{iconAddress} Tronador 418, (CP C1427CRJ) Ciudad Autónoma De Buenos Aires</div>
        <div>{iconPhone} +54 (11) 4553-9898/3711</div>
        <div>{iconEmail} deposito@dimex.com.ar</div>
      </div>
    </div>
  );
};

export default Invoice;
