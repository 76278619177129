import { useRef, useState, useEffect } from 'react'
import Barcode from 'react-barcode'
import ReactToPrint from 'react-to-print'
import { Row, Col } from 'reactstrap'

// images
import LogoDimex from '../../../../../assets/images/logo.png'
import LogoInnenImage from '../../../../../assets/images/logo-innen.jpeg'

import { 
  ContainerComponent,
  Heading,
  CurrentDate,
  LogoContainer,
  Image,
  LogoClaim,
  Body,
  Wrapper,
  ProductWrapper,
  ProductName,
  ProductCode,
  QRCodeCustom,
  Separator,
  CantMts,
  Lote,
  BarcodeContainer,
  Footer,
  QRCodeInnen,
  LogoInnen,

  ButtonPrintContainer,
  Sticker,
} from './mixins'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

const BarcodeBatch = ({ data }) => {
  const componentRef = useRef()
  const [currentDate, setCurrentDate] = useState('')

  const hasBarcode = data?.productionBatch.length === 1

  // get current date
  const current = new Date()
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`

  // get total units with format
  const unitsTotal = data?.quantity
  const formatUnits = unitsTotal?.toLocaleString(undefined, { maximumFractionDigits: 2 }).replace(',', '.')

  const productName = data?.product?.name === null ? '' : data?.product?.name
  const productCode = data?.product?.code === null ? '' : data?.product?.code 

  // get current url
  const currentURL = window.location.href

  const printInvoiceButton = (
    <FontAwesomeIcon title="Imprimir Barcode" icon={faPrint} color={'grey'} />
  )

  useEffect(() => {
    setCurrentDate(date)
  }, [date])

  if (!hasBarcode) {
    return null
  }

  return (
    <>
      <ButtonPrintContainer>
        <ReactToPrint pageStyle="@page { size: 105mm 148mm }" trigger={() => printInvoiceButton} content={() => componentRef.current} />
      </ButtonPrintContainer>

      <ContainerComponent>
        <Sticker ref={componentRef}>
          <Heading>
            <CurrentDate>Fecha: {currentDate}</CurrentDate>
            
            <LogoContainer>
              <Image src={LogoDimex} width="290" alt="Logo Dimex"/>
              <LogoClaim>Uso Confidencial</LogoClaim>
            </LogoContainer>
          </Heading>

          <Body>
            <Wrapper>
              <ProductWrapper>
                <ProductName>{productName}</ProductName>
                <ProductCode>{productCode}</ProductCode>
              </ProductWrapper>
  
              <QRCodeCustom size={200} value={currentURL} includeMargin={false} />
            </Wrapper>
            <Separator />

            {formatUnits !== undefined && (
              <>
                <CantMts>{formatUnits} {formatUnits <= 1 ? 'UD' : 'UDS'}</CantMts>
                <Separator />
              </>
            )}

            {data?.productionBatch[0].barcode !== '' && <Lote>{data?.productionBatch[0].barcode}</Lote>}
            <Separator />

            <BarcodeContainer>
              <Barcode
                width={3}
                height={200}
                fontSize={40}
                value={data?.productionBatch[0].barcode || ''}
                format={"CODE128"}
              />
            </BarcodeContainer>
          </Body>

          <Footer>
            <QRCodeInnen size={100} value="www.innen.com.ar" />
            <LogoInnen width="200px" src={LogoInnenImage} alt="Logo Innen" />
          </Footer>
        </Sticker>
      </ContainerComponent>
    </>
  )
}
  
export default BarcodeBatch
