import { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import SweetAlert from 'sweetalert2';
import MermaModal from '../components/MermaModal';
import { Container, Row, Col, Button } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';

// constants
import { notifications, PARTIAL_DELIVERY_DATE } from '../../../constant';

// components`
import Spinner from '../../../components/Spinner';
import Breadcrumb from '../../../components/common/breadcrumb';
import CardRawMaterial from './components/CardRawMaterial';
import CardBatch from './components/CardBatch';
import Observations from './components/Observations';
import SelectState from './components/SelectState';
import CanRBac from '../../../components/CanRBac';
import Bags from './components/CardBags';
import CuttingTable from './components/CuttingTable';
import Statistics from '../components/Statistics';

// store
import {
  deleteProductionOrder,
  getProductionOrder,
  patchProductionOrder,
  setProductionOrderReaded,
} from '../../../store/actions/production-order';
import { getOrdersAll } from '../../../store/actions/orders';

// Services
import getFeedstock from '../../../services/getFeedstock';

// helpers
import { getUrlParamByName } from '../../../helpers/get-url-param';
import useBatchesFetcher from '../../../hooks/useBatchesFetcher';

// mixins
import { GlobalStyle, PageHeader, BeadContainer, ButtonContainer, ButtonCustom } from './styles';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { ProductionOrderDto } from '../../../types/production-order';
import { User } from '../../../types/user';
import { showModal } from '../../../store/actions/modal';

interface ComponentProps {
  currentUser;
  productionOrder: ProductionOrderDto;
  allProductionOrderBags;
  productionOrderStates;
  updateOrder;
  updateOrderStates;
  orders;
  deleteOrderStates;
  match;
}

const PageOrderProductionDetail = ({
  currentUser,
  productionOrder,
  allProductionOrderBags,
  productionOrderStates,
  updateOrder,
  updateOrderStates,
  orders,
  deleteOrderStates,
  match,
}: ComponentProps) => {
  const [differenceOfUnits, setDifferenceOfUnits] = useState<number>(0);
  const [observations, setObservations] = useState<string>();
  const [state, setState] = useState<string>();
  const [dateCut, setDateCut] = useState<string>();
  const [client, setClient] = useState<string>();
  const [quantity, setQuantity] = useState<number>();
  const [merma, setMerma] = useState<{ cutDifference: number; workshopDifference: number; comments: string }>();
  const [priority, setPriority] = useState<boolean>();
  const [handler, setHandler] = useState<User>();
  const [type, setType] = useState<string>();
  const [quality, setQuality] = useState<string>();
  const [cuttingTable, setOnCuttingTable] = useState<boolean>();
  const [expiration, setExpiration] = useState<string | null>();
  const [dataFeedstock, setDataFeedstock] = useState();
  const [countOrders, setCountOrders] = useState(1);
  const [ordersSelected, setOrdersSelected] = useState<{
    _id: string;
    label: string;
    value: string;
    client: string;
    clientId: string;
  }>();

  const dispatch = useDispatch();
  const history = useHistory();
  const currentDate = moment().locale('es').format(PARTIAL_DELIVERY_DATE);
  const batches = useBatchesFetcher();

  const handleDeleteProductionOrder = () => {
    SweetAlert.fire({
      title: 'Eliminar Orden de Producción',
      html: '¿Estas seguro de eliminar la Orden de Producción? <br/><strong>ID: ' + productionOrder?._id + '</strong>',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      allowEscapeKey: false,
      preConfirm: () => {
        dispatch(deleteProductionOrder(productionOrder?._id));
      },
    });
  };

  const updateDataTable = () => {
    getFeedstock().then((result) => {
      setDataFeedstock(result);
    });
  };

  const handleChangeState = (e) => {
    setState(e.value);
    setOnCuttingTable(false);

    if (e.label === 'Corte') {
      setDateCut(currentDate);
    } else {
      setDateCut('');
    }
  };

  const handleChangeOrder = (value) => {
    if (value.length <= 0) {
      setCountOrders(0);
    }
    setOrdersSelected(value);
    setClient(value.clientId);
  };

  const handleChangeMerma = (value) => {
    setMerma(value);

    setTimeout(() => {
      handleSaveData();
    }, 100);
  };

  const handleChangeExpirationDate = (value) => {
    setExpiration(!!value ? new Date(value).toISOString() : null);
  };

  const orderNoteSave = !ordersSelected ? productionOrder?.orderNote : ordersSelected;

  const handleSaveData = () => {
    const update = () => {
      dispatch(
        patchProductionOrder({
          id: productionOrder._id,
          observations,
          state,
          quantity,
          type,
          client,
          dateCut,
          merma,
          orderNote: countOrders === 0 ? [] : orderNoteSave,
          priority,
          cuttingTable,
          handler: handler || productionOrder?.handler?._id,
          qualityBatch: quality,
          expirationDate: expiration,
        }),
      );
    };

    if (state !== 'done') {
      update();
    } else {
      if (!!allProductionOrderBags && allProductionOrderBags?.length > 0) {
        const unitsInDeposit = allProductionOrderBags
          ?.filter((productionOrder) => productionOrder.status === 'inDeposit')
          .map((bag) => bag.units)
          .reduce((a, b) => a + b, 0);

        setDifferenceOfUnits(productionOrder.quantity - unitsInDeposit);

        if (productionOrder.quantity > unitsInDeposit && merma?.comments === '') {
          dispatch(showModal('modal-merma'));
        } else {
          update();
        }
      } else {
        toast.error(`La OP no cuenta con bolsones. Carga los bolsones en la OP para poder finalizar la OP.`, {
          autoClose: 3000,
        });
      }
    }
  };

  useEffect(() => {
    if (match.params) {
      dispatch(getProductionOrder(match.params.id));
    }

    dispatch(
      getOrdersAll({
        status: 'new,processing,ready,approved,preparation,released_partially,released',
      }),
    );
  }, [match]);

  useEffect(() => {
    if (!!productionOrder) {
      setState(productionOrder?.state);
      setObservations(productionOrder?.observations);
      setDateCut(productionOrder?.dateCut);
      setClient(productionOrder?.client);
      setQuantity(productionOrder?.quantity);
      setPriority(productionOrder?.priority);
      setHandler(productionOrder?.handler);
      setType(productionOrder?.type);
      setQuality(productionOrder?.qualityBatch);
      setOnCuttingTable(productionOrder?.cuttingTable);
      setMerma(productionOrder?.merma);
    }

    if (!productionOrder?.watchers?.includes(currentUser._id)) {
      dispatch(setProductionOrderReaded());
    }
  }, [productionOrder]);

  useEffect(() => {
    if (updateOrderStates?.success) {
      toast.success(notifications.tableUpdateSuccess, { autoClose: 3000 });
      setState(updateOrder?.state);
    }
  }, [updateOrderStates]);

  useEffect(() => {
    updateDataTable();
  }, []);

  useEffect(() => {
    if (deleteOrderStates.success) {
      history.push('/orderproduction');
    }
  }, [deleteOrderStates]);

  if (productionOrderStates.loading || batches.isLoading || !productionOrder) {
    return <Spinner size="large" isPageLoader />;
  }

  return (
    <>
      <GlobalStyle />

      <Container fluid className="page-production-order-details">
        <Row>
          <Col>
            <Statistics filtersSelected={{ productionOrders: [productionOrder._id] }} startFiltering={true} />
          </Col>
        </Row>

        <div style={{ height: 15 }} />

        <PageHeader>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 10, width: '50%' }}>
            <BeadContainer>
              <Breadcrumb title="Detalle de Orden de Producción" parent="Ordenes" parentLink="/orderproduction" />
            </BeadContainer>
            <b style={{ fontSize: '20px' }}>{`OP ${productionOrder.code}`}</b>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', gap: 10, width: '50%' }}>
            <CuttingTable handleChangeOnCuttingTable={setOnCuttingTable} />
            <SelectState handleChangeState={handleChangeState} state={state} />

            <Link className="btn btn-primary" to={`/print-production-order/${productionOrder?._id}`}>
              <FontAwesomeIcon icon={faFileAlt} /> Imprimir
            </Link>
          </div>
        </PageHeader>

        <div style={{ height: 15 }} />

        <Row>
          <Col xl="6" sm="6">
            <CardRawMaterial
              dateCut={dateCut}
              orders={orders}
              handleChangeOrder={handleChangeOrder}
              handleChangeQuantity={setQuantity}
              handleChangePrority={setPriority}
              handleChangeDeposit={setHandler}
              handleChangeType={setType}
              handleChangeQuality={setQuality}
              handleChangeExpirationDate={handleChangeExpirationDate}
              handleChangeOnCuttingTable={setOnCuttingTable}
            />
          </Col>

          <Col xl="6" sm="6">
            <CardBatch dataFeedstock={dataFeedstock} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Bags />
          </Col>
        </Row>

        <Row>
          <Col>
            <Observations onChange={setObservations} />
          </Col>
        </Row>

        <CanRBac
          role={currentUser?.userType}
          perform="op:edit"
          data={{ status: productionOrder?.state }}
          yes={() => (
            <>
              <Row>
                <Col>
                  <ButtonContainer>
                    <ButtonCustom
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSaveData}
                      style={{ marginRight: '15px' }}
                    >
                      Guardar
                    </ButtonCustom>

                    {productionOrder?.state === 'requested' && (
                      <Button outline color="danger" onClick={handleDeleteProductionOrder}>
                        {deleteOrderStates.loading ? <Spinner size="small" /> : 'Eliminar'}
                      </Button>
                    )}
                  </ButtonContainer>
                </Col>
              </Row>

              <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </>
          )}
        />
      </Container>

      <MermaModal handleChangeMerma={handleChangeMerma} productionOrder={productionOrder} />
    </>
  );
};

const state = ({ productionOrderBagStore, productionOrderStore, userStore, ordersStore }) => {
  const { data: currentUser } = userStore.information;
  const { data: productionOrder, states: productionOrderStates } = productionOrderStore.productionOrder;
  const { data: updateOrder, states: updateOrderStates } = productionOrderStore.updateOrder;
  const { data: deleteOrder, states: deleteOrderStates } = productionOrderStore.deleteProductionOrder;
  const { data: orders, states: ordersStates } = ordersStore.ordersAll;
  const { data: allProductionOrderBags } = productionOrderBagStore.productionOrderBagAll;

  return {
    currentUser,
    productionOrder,
    productionOrderStates,
    updateOrder,
    updateOrderStates,
    allProductionOrderBags,
    orders,
    ordersStates,
    deleteOrder,
    deleteOrderStates,
  };
};

export default connect(state)(PageOrderProductionDetail);
