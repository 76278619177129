import { getToken } from '../utils/authUtils';
import { GET } from '../constant';

export default function getAttachmentFile(id) {
  return fetch(`${process.env.REACT_APP_API_URL}/files/${id}`, {
    method: GET,
    headers: new Headers({
      Authorization: `Bearer ${getToken()}`,
    }),
  }).then((response) => response.json());
}
