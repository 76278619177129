export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const GET_ALL_PRODUCTS_ERROR = 'GET_ALL_PRODUCTS_ERROR';

export interface GetAllProducts {
  type: typeof GET_ALL_PRODUCTS;
  payload: any;
}
export interface GetAllProductsSuccess {
  type: typeof GET_ALL_PRODUCTS_SUCCESS;
  payload: null;
}
export interface GetAllProductsError {
  type: typeof GET_ALL_PRODUCTS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetAllProducts | GetAllProductsSuccess | GetAllProductsError;
