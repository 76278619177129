import action from '../helpers/rest-client';

export class ErrorRawMaterial extends Error {}

class RawMaterialService {
  public async getAllFeedStockCategories() {
    return action
      .Get({
        url: `${process.env.REACT_APP_API_URL}/feedstockCategory/all`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.warn(error);
        // throw new ErrorProductionOrder(error.response.status);
      });
  }

  public async getAllFeedStock() {
    return action
      .Get({
        url: `${process.env.REACT_APP_API_URL}/feedstock/all`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.warn(error);
        // throw new ErrorProductionOrder(error.response.status);
      });
  }

  public async getFeedStock(payload) {
    return action
      .Get({
        url: `/feedstock/${payload}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.warn(error);
        // throw new ErrorProductionOrder(error.response.status);
      });
  }

  public async patchFeedStock(payload) {
    return action
      .Patch({
        url: `/feedstock/${payload.id}`,
        body: payload,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.warn(error);
        // throw new ErrorProductionOrder(error.response.status);
      });
  }
}

const rawMaterial = new RawMaterialService();
export default rawMaterial;
