import Barcode from 'react-barcode';

function Feedstock({ data }) {
  const { barcode, latestUpdate, reels, meters, fabricType, fabricColor, fabricGrammage } = data;
  return (
    <>
      <div className="text-center">
        <Barcode width={2} height={50} value={barcode} format={'CODE128'} />
      </div>
      <div className="container">
        <div className="row">
          <div className="col text-left">
            <h5>Última actualización:</h5>
            <h5>Cantidad de rollos:</h5>
            <h5>Cantidad de metros:</h5>
            <h5>Tipo de tela:</h5>
            <h5>Color de tela:</h5>
            <h5>Gramaje de tela:</h5>
          </div>
          <div className="col text-left">
            <h5>{latestUpdate}</h5>
            <h5>{reels}</h5>
            <h5>{meters}</h5>
            <h5>{fabricType}</h5>
            <h5>{fabricColor}</h5>
            <h5>{fabricGrammage}</h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default Feedstock;
