import { getToken } from '../utils/authUtils';

async function deleteAttachment(fileId, orderId, type) {
  const data = { attachmentFileName: fileId, orderId, type };
  return fetch(`${process.env.REACT_APP_API_URL}/files`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
}

export default deleteAttachment;
