import styled from 'styled-components';
export const ButtonLoadMore = styled('button')`
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: none;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }

  &:focus,
  &:active,
  &:hover {
    outline: 0;
  }
`
export const ColumnDropp = styled('div') <{ isOpened }>`
  padding: 0px 10px;
  height: 100%;
  width: ${({ isOpened }) => {
    return isOpened ? 'calc(1320px / 4)' : '80px';
  }};
  transition: width 0.1s ease-in-out;
`;

export const ColumnInformation = styled('div')`
  display: flex;
  align-items: center;
  transform: rotate(90deg);
`;

export const ColHeader = styled('div') <{ isOpened }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isOpened }) => (isOpened ? 'space-between' : 'center')};
  font-size: 14px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: rgb(237 237 237 / 70%);
  border-radius: 6px;

  strong {
    text-transform: uppercase;
  }

  span {
    font-size: 12px;
  }

  &:hover {
    .toogle-column {
      opacity: 1;
    }
  }
`;

export const ColContent = styled('div') <{ isOpened }>`
  min-height: 250px;
  height: calc(100% - 54px);
  ${({ isOpened }) => (isOpened ? '' : 'background-color: rgb(237 237 237 / 70%);')}
  border-radius: 5px;
  padding-top: ${({ isOpened }) => (isOpened ? '0px' : '28px')};

  & > a:last-child > div {
    margin-bottom: 0px;
  }
`;

export const ToggleColumn = styled('button') <{ isOpened }>`
  font-size: 14px;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #332727;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  opacity: ${({ isOpened }) => (isOpened ? '0' : '1')};
  position: relative;
  transition: opacity 0.2s ease-in-out;
`;

export const Total = styled('div')`
  width: min-content;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #313131;
  color: white;
  font-size: 11px;
  border-radius: 4px;
  flex-shrink: 0;
  padding: 0 5px;
`;

export const Name = styled('div')`
  color: #4f5762;
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0 8px;
`;

export const HeaderTotal = styled('div') <{ isOpened }>`
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: ${({ isOpened }) => (isOpened ? 'space-between' : 'center')};
`;
