import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import DmxForm from './DmxForm';

const DmxModal = (props) => {
  const { action, attributes, data, icon = '', isOpen, title, toggle } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="dmx-modal-width">
      <ModalHeader toggle={toggle}>
        {icon} {title}
      </ModalHeader>
      <ModalBody>
        <DmxForm data={data} action={action} attributes={attributes} />
      </ModalBody>
    </Modal>
  );
};

export default DmxModal;
