import styled from 'styled-components';
import Spinner from '../../../../components/Spinner'
import pattern from '../../../../assets/images/icons/pattern.svg';
import warehouse from '../../../../assets/images/icons/warehouse.svg';
import workshop from '../../../../assets/images/icons/tailoring.svg';
import transit from '../../../../assets/images/icons/sack.svg';

export const Loading = styled(Spinner) <{ color: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 10;
  background-color: #ffffff3b;
  backdrop-filter: blur(3px);

  .spinner {
    border-left-color: ${({ color }) => color};
    width: 42px;
    height: 42px;
  }
`

export const Statistics = styled('div')`
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  row-gap: 20px;

  figure {
    margin: 0px;
    margin-right: 15px;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    padding: 20px;
    flex-shrink: 0;

    &:before {
      content: '';
      display: block;
      mask-size: 100%;
      mask-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }
  }

  .statistics-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.cut {
      background-color: rgba(115, 103, 240, 0.12);

      figure {
        background-color: rgba(115, 103, 240, 0.12);

        &:before {
          mask-image: url(${pattern});
          background: #7367f0;
        }
      }
    }

    &.workshop {
      background-color: rgba(0, 207, 232, 0.12);

      figure {
        background-color: rgba(0, 207, 232, 0.12);

        &:before {
          mask-image: url(${workshop});
          background: #00cfe8;
        }
      }
    }

    &.transit {
      background-color: rgb(121 240 103 / 12%);

      figure {
        background-color: rgb(121 240 103 / 16%);

        &:before {
          mask-image: url(${transit});
          background: #76cd67;
        }
      }
    }

    &.warehouse {
      background-color: rgba(234, 84, 85, 0.12);

      figure {
        background-color: rgba(234, 84, 85, 0.12);

        &:before {
          mask-image: url(${warehouse});
          background: #ea5455;
        }
      }
    }

    .statistics-card-values {
      display: flex;
      alig-items: center;
      justify-content: flex-start;

      & > span {
        position: relative;
        width: 40%;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        margin-left: 10px;

        &:before {
          content: '';
          position: absolute;
          left: 0px;
          top: 0px;
          height: 100%;
          width: 1px;
          background: black;
        }
      }
    }

    .statistics-card-title {
      font-size: 20px;
      line-height: 18px;
      margin-bottom: 6px;
    }

    .statistics-card-percent {
      font-size: 40px;
      font-weight: bold;
      font-family: sans-serif;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      line-height: 34px;

      span {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }
`;
