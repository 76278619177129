import { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// components
import Spinner from '../../components/Spinner';
import DragAndDrop from '../../components/drag-and-drop';

// mixins
import { PageMain } from './mixins';

// store
import { getProductionOrderAll } from '../../store/actions/production-order';

const Component = ({ productionOrderList, productionOrderStates, currentUser }) => {
  const [data, setData] = useState([{}]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductionOrderAll({ state: 'requested,pending,inProgress,done' }));
  }, []);

  useEffect(() => {
    if (productionOrderStates.success) {
      setData(productionOrderList);
    }
  }, [productionOrderStates]);

  /**
   * Deribamos ordenes a sus respectivas columnas, segun su estado
   */
  const requestedOrder = data.filter((order: any) => order.state === 'requested');
  const pendingOrders = data.filter((order: any) => order.state === 'pending');
  const inProgressOrders = data.filter((order: any) => order.state === 'inProgress');
  const finishedOrders = data.filter((order: any) => order.state === 'done');
  const archivedOrders = data.filter((order: any) => order.state === 'archived');

  const columnsDrag = {
    [uuidv4()]: {
      id: 'requested',
      name: 'Solicitada',
      items: requestedOrder,
    },
    [uuidv4()]: {
      id: 'pending',
      name: 'Pendiente',
      items: pendingOrders,
    },
    [uuidv4()]: {
      id: 'inProgress',
      name: 'En Progreso',
      items: inProgressOrders,
    },
    [uuidv4()]: {
      id: 'done',
      name: 'Finalizada',
      items: finishedOrders,
    },
    [uuidv4()]: {
      id: 'archived',
      name: 'Archivada',
      items: archivedOrders,
    },
  };

  if (productionOrderStates.loading) {
    return <Spinner size="large" isPageLoader />;
  }

  return (
    <PageMain>
      <div className="container-fluid">
        <div className="card shadow-sm">
          <div className="card-body datatable-react">
            <DragAndDrop onDrop={() => {}} columnsDrag={columnsDrag} detailLink={'/orderproductiondetail'} />
          </div>
        </div>
      </div>
    </PageMain>
  );
};

const state = ({ productionOrderStore, userStore }) => {
  const { data: productionOrderList, states: productionOrderStates } = productionOrderStore.productionOrderAll;

  return {
    productionOrderStates,
    currentUser: userStore.information.data,
    productionOrderList,
  };
};

export default connect(state)(Component);
