import {
  NEW_WORKSHOP,
  NewWorkshop,
  EDIT_WORKSHOP,
  EditWorkshop,
  GET_WORKSHOP,
  GetWorkshop,
  GET_ALL_WORKSHOPS,
  GET_ALL_WORKSHOPS_RESET,
  GetAllWorkshops,
  GetAllWorkshopsReset,
  ResetNewWorkshop,
  RESET_NEW_WORKSHOP,
  ResetEditWorkshop,
  RESET_EDIT_WORKSHOP,
} from '../types/workshop';

export const newWorkshop = (payload): NewWorkshop => {
  return {
    type: NEW_WORKSHOP,
    payload,
  };
};

export const resetNewWorkshop = (): ResetNewWorkshop => {
  return {
    type: RESET_NEW_WORKSHOP,
    payload: null
  };
};

export const editWorkshop = (payload): EditWorkshop => {
  return {
    type: EDIT_WORKSHOP,
    payload,
  };
};

export const resetEditWorkshop = (): ResetEditWorkshop => {
  return {
    type: RESET_EDIT_WORKSHOP,
    payload: null
  };
};

export const getWorkshop = (payload): GetWorkshop => {
  return {
    type: GET_WORKSHOP,
    payload,
  };
};

export const getAllWorkshops = (payload: { [key: string]: string | number }): GetAllWorkshops => {
  return {
    type: GET_ALL_WORKSHOPS,
    payload,
  };
};

export const getAllWorkshopsReset = (): GetAllWorkshopsReset => {
  return {
    type: GET_ALL_WORKSHOPS_RESET,
    payload: null,
  };
};
