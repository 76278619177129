export const login = 'Login';
export const LOGIN = 'LOGIN';

// HTTP VERBS.
export const GET = 'GET';
export const POST = 'POST';
export const PATCH = 'PATCH';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

// MODAL ACTIONS
export const CREATE = 'create';
export const READ = 'read';
export const UPDATE = 'UPDATE';

export const statusColors = {
  new: '#9c9690',
  processing: '#ffcc00',
  ready: '#87a2c7',
  approved: '#3865a3',
  preparation: '#8000FF',
  released_partially: '#64a338',
  released: '#6cf70c',
  archived: '#e03b24',
};

export const statusOptions = {
  new: 'Nueva',
  processing: 'En Proceso',
  ready: 'Lista',
  approved: 'Aprobada',
  preparation: 'En Preparación',
  released_partially: 'Liberada Parcial',
  released: 'Liberada',
  archived: 'Archivada',
};

// DATA TABLE
export const noRecords = 'No hay registros para mostrar';
export const rowsPerPage = 'Registros por página:';
export const rangeSeparator = 'de';

// CURRENCY
export const ARS = 'AR$';
export const USD = 'U$D';

// RAW MATERIAL MODAL FORM LABELS
export const rawMaterialAttributeLabel = {
  color: 'Color',
  grammage: 'Gramaje',
  material: 'Material',
  provider: 'Proveedor',
  type: 'Tipo',
  width: 'Ancho',
};

// DATES FORMATES
export const PARTIAL_DELIVERY_DATE = 'DD MMM YYYY'; // 01 Jan 1970

export const notifications = {
  tableUpdateSuccess: 'Los datos han sido actualizados.',
  tableUpdateError: 'Ha ocurrido un error:',
};

export const moveTypes = {
  ingress: 'INGRESO',
  egress: 'EGRESO',
};

export const BARCODE_QUICKSEARCH_NO_DATA_FOUND = '-';
