import action from '../helpers/rest-client';

export class ErrorStatics extends Error {}

class StaticsService {
  public async getWorkshops() {
    return action
      .Get({
        url: `/workshops`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        console.warn(error);
        return { error };
      });
  }
}

const statics = new StaticsService();
export default statics;
