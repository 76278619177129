import { put, call, all, select, takeLatest } from 'redux-saga/effects';
import {
  GET_PRODUCTION_ORDER_BAG_ALL,
  GET_PRODUCTION_ORDER_BAG_ALL_SUCCESS,
  GET_PRODUCTION_ORDER_BAG_ALL_ERROR,
  GET_PRODUCTION_ORDER_BAG,
  GET_PRODUCTION_ORDER_BAG_SUCCESS,
  GET_PRODUCTION_ORDER_BAG_ERROR,
  DELETE_PRODUCTION_ORDER_BAG,
  DELETE_PRODUCTION_ORDER_BAG_SUCCESS,
  DELETE_PRODUCTION_ORDER_BAG_ERROR,
  UPDATE_PRODUCTION_ORDER_BAG,
  UPDATE_PRODUCTION_ORDER_BAG_SUCCESS,
  UPDATE_PRODUCTION_ORDER_BAG_ERROR,
  CREATE_PRODUCTION_ORDER_BAG,
  CREATE_PRODUCTION_ORDER_BAG_SUCCESS,
  CREATE_PRODUCTION_ORDER_BAG_ERROR,
  CREATE_PRODUCTION_ORDER_BAG_RESET,
  PRODUCTION_ORDER_BAG_UPDATE_CURRENT,
  CreateProductionOrderBag,
} from '../types/production-order-bag';
import productionOrderBag from '../../services/production-order-bags';

const getAllBags = ({ productionOrderBagStore }) => productionOrderBagStore.productionOrderBagAll.data;

function* getProductionOrderBagAll({ payload }): any {
  try {
    const { error, response } = yield call(productionOrderBag.getAll, payload);

    if (error) {
      yield put({ type: GET_PRODUCTION_ORDER_BAG_ALL_ERROR, payload: error?.reponse?.status });
    } else {
      yield put({ type: GET_PRODUCTION_ORDER_BAG_ALL_SUCCESS, payload: response?.data });
    }
  } catch {
    yield put({ type: GET_PRODUCTION_ORDER_BAG_ALL_ERROR, payload: 'Error en saga' });
  }
}

function* getProductionOrderBag({ payload }): any {
  try {
    const { error, response } = yield call(productionOrderBag.getOne, payload);

    if (error) {
      yield put({ type: GET_PRODUCTION_ORDER_BAG_ERROR, payload: error });
    } else {
      yield put({ type: GET_PRODUCTION_ORDER_BAG_SUCCESS, payload: response });
    }
  } catch {
    yield put({ type: GET_PRODUCTION_ORDER_BAG_ERROR, payload: 'Error en saga' });
  }
}

function* updateProductionOrderBag({ payload }): any {
  try {
    const { error, response } = yield call(productionOrderBag.updateOne, payload);

    if (error) {
      yield put({ type: UPDATE_PRODUCTION_ORDER_BAG_ERROR, payload: error.reponse.status });
    } else {
      const bags = yield select(getAllBags);

      yield all([
        put({ type: UPDATE_PRODUCTION_ORDER_BAG_SUCCESS, payload: response }),
        put({ type: GET_PRODUCTION_ORDER_BAG_ALL, payload: { idProductionOrder: response?.ProductionOrder?._id } }),
        put({ type: GET_PRODUCTION_ORDER_BAG_ALL_SUCCESS, payload: null }),
      ]);
    }
  } catch {
    yield put({ type: UPDATE_PRODUCTION_ORDER_BAG_ERROR, payload: 'Error en saga' });
  }
}

function* createProductionOrderBag({ payload }) {
  try {
    const { error, response } = yield call(productionOrderBag.create, payload);

    if (error) {
      yield put({ type: CREATE_PRODUCTION_ORDER_BAG_ERROR, payload: error.response?.status });
    } else {
      const bags = yield select(getAllBags) || [];

      yield all([
        put({ type: CREATE_PRODUCTION_ORDER_BAG_SUCCESS, payload: response }),
        put({ type: GET_PRODUCTION_ORDER_BAG_ALL_SUCCESS, payload: [...bags, ...response] }),
      ]);
    }
  } catch {
    yield all([
      put({ type: CREATE_PRODUCTION_ORDER_BAG_RESET, payload: null }),
      put({ type: CREATE_PRODUCTION_ORDER_BAG_ERROR, payload: 'Error en saga' }),
    ]);
  }
}

function* deleteProductionOrderBag({ payload }): any {
  try {
    const { error, response } = yield call(productionOrderBag.deleteOne, payload);

    if (error) {
      yield put({ type: DELETE_PRODUCTION_ORDER_BAG_ERROR, payload: error.reponse.status });
    } else {
      const bags = yield select(getAllBags);
      yield all([
        put({ type: DELETE_PRODUCTION_ORDER_BAG_SUCCESS, payload: response }),
        put({
          type: GET_PRODUCTION_ORDER_BAG_ALL_SUCCESS,
          payload: bags.filter((bag) => bag._id !== payload),
        }),
      ]);
    }
  } catch {
    yield put({ type: DELETE_PRODUCTION_ORDER_BAG_ERROR, payload: 'Error en saga' });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_PRODUCTION_ORDER_BAG_ALL, getProductionOrderBagAll);
  yield takeLatest<any>(GET_PRODUCTION_ORDER_BAG, getProductionOrderBag);
  yield takeLatest<any>(UPDATE_PRODUCTION_ORDER_BAG, updateProductionOrderBag);
  yield takeLatest<CreateProductionOrderBag>(CREATE_PRODUCTION_ORDER_BAG, createProductionOrderBag);
  yield takeLatest<any>(DELETE_PRODUCTION_ORDER_BAG, deleteProductionOrderBag);
}
