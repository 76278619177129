/* eslint no-underscore-dangle: 0 */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useContext } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { checkDataPermission } from '../../../../components/CanRBac';
import { NewProductionOrderContext } from '../../../../context/NewProductionOrderContext';
import { User } from '../../../../types/user';
import { StepsInput } from './mixins';

interface ComponentProps {
  currentUser: User;
}

const Component = ({ currentUser }: ComponentProps) => {
  const [context, setContext] = useContext(NewProductionOrderContext);

  const typesData = [
    {
      label: 'Semi Elaborado',
      value: 'semi-elaborado',
    },
    {
      label: 'Semi Elaborado 2',
      value: 'semi-elaborado-2',
    },
    {
      label: 'In Plant',
      value: 'in plant',
    },
  ];

  const workshopsData = [
    {
      label: '1',
      value: '1',
    },
    {
      label: '2',
      value: '2',
    },
    {
      label: '3',
      value: '3',
    },
  ];

  const plantsData = [
    {
      label: 'Giradot',
      value: 'Giradot',
    },
  ];

  const handleOnChange = (name, value) => {
    if (name === 'workshop') {
      setContext({
        ...context,
        origin: {
          type: 'semi-elaborado',
          workshop: value,
        },
      });
    } else if (name === 'plant') {
      setContext({
        ...context,
        origin: {
          type: 'in plant',
          plant: value,
        },
      });
    } else {
      setContext({
        ...context,
        origin: {
          type: '',
        },
        [name]: value,
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row mt-4">
              <div className="col-md-12 mb-3">
                <label htmlFor="productName" className="form-label">
                  Tipo
                </label>

                <Select
                  defaultValue={typesData.filter((option) => option.value === context.type)}
                  id="type"
                  placeholder="Seleccione un producto"
                  options={typesData.filter((type) =>
                    checkDataPermission(currentUser.userType, 'op:create:type', { type: type.value }),
                  )}
                  autoComplete
                  onChange={(option) => handleOnChange('type', option.value)}
                />
              </div>
              {/*<div className="col-md-12 mb-3">
                <label htmlFor="productName" className="form-label">
                  Taller
                </label>
                <Select
                  defaultValue={context.type === 'semi-elaborado' ? workshopsData.filter((option) => option.value === context.origin.workshop) : plantsData.filter((option) => option.value === context.origin.plant)}
                  value={context.type === 'semi-elaborado' ? workshopsData.filter((option) => option.value === context.origin.workshop) : plantsData.filter((option) => option.value === context.origin.plant)}
                  name={context.type === 'semi-elaborado' ? 'workshop' : 'plant'}
                  placeholder={context.type === 'semi-elaborado' ? 'Seleccione un taller' : 'Seleccione una fabrica'}
                  options={context.type === 'semi-elaborado' ? workshopsData : plantsData}
                  autoComplete
                  onChange={(option) => handleOnChange(context.type === 'semi-elaborado' ? 'workshop' : 'plant', option.value)}
                />
              </div>*/}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const state = ({ userStore }) => {
  const { data: currentUser } = userStore.information;

  return {
    currentUser,
  };
};

export default connect(state)(Component);
