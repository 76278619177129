import { call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';

import { GET_ALL_PRODUCTS, GET_ALL_PRODUCTS_SUCCESS, GET_ALL_PRODUCTS_ERROR } from '../types/product';

/**
 * Pending Task
 * Check if store is filled before call to BFF.
 */

function* getAllProducts() {
  try {
    const { response, error } = yield call(services.product.getAll);

    if (error) {
      yield put({ type: GET_ALL_PRODUCTS_ERROR, payload: error });
    } else {
      yield put({ type: GET_ALL_PRODUCTS_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: GET_ALL_PRODUCTS_ERROR, payload: 'Error al buscar los productos.' });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_ALL_PRODUCTS, getAllProducts);
}
