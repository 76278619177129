import action from '../helpers/rest-client';

export class ErrorWorkshopOrder extends Error {}

class WorkshopOrderService {
  public async create(payload) {
    return action
      .Post({
        url: `${process.env.REACT_APP_API_URL}/workshopOrders`,
        body: payload,
      })
      .then((response) => {
        return { response: response.data[0] };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getAll(filters) {
    const queryString = new URLSearchParams(filters).toString();

    return action
      .Get({
        url: `${process.env.REACT_APP_API_URL}/workshopOrders/?${!!queryString ? queryString : ''}`,
      })
      .then((response) => {
        return { response: response.data };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getOne(workshopOrderId) {
    return action
      .Get({
        url: `${process.env.REACT_APP_API_URL}/workshopOrders/${workshopOrderId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async updateOne(payload) {
    return action
      .Patch({
        url: `${process.env.REACT_APP_API_URL}/workshopOrders/${payload._id}`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const workshopOrder = new WorkshopOrderService();
export default workshopOrder;
