import { noRecords, rowsPerPage, rangeSeparator } from '../../../constant/index';

export const paginationText = {
  rowsPerPageText: rowsPerPage,
  rangeSeparatorText: rangeSeparator,
};

export const noDataComponent = noRecords;


export const columns = [
  {
    name: 'Código',
    selector: 'code',
    sortable: true,
    grow: 1,
  },
 // {
  //  name: 'Nombre',
  //  selector: 'name' ,
  // sortable: true,
 //   grow: 3,
 // },
  // {
  //   name: 'Material',
  //   selector: 'material',
  //   sortable: true,
  // },
  // {
  //   name: 'Tipo',
  //   selector: 'type',
  //   sortable: true,
  // },
  // {
  //   name: 'Gramaje',
  //   selector: 'grammage',
  //   sortable: true,
  // },
  // {
  //   name: 'Color',
  //   selector: 'color',
  //   sortable: true,
  // },
  // {
  //   name: 'Ancho',
  //   selector: 'width',
  //   sortable: true,
  //   cell: (row) => (row.width ? `${row.width} m` : ''),
  // },
// {
  //  name: 'Stock',
  //  selector: 'stock',
  //  sortable: true,
  //  grow: 1,
  //  right: true,
 // },
  // {
  //   name: 'Medidas',
  //   selector: 'measures',
  //   sortable: true,
  //   cell: (row) => (row.measures ? row.measures.replace('cm', ' cm') : ''),
  // },
];
