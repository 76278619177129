import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Filemanager from '../OrderDetail/FilesForm';

const DmxModal = (props) => {
  const { data, icon = '', isOpen, title, toggle, refetch, viewOnly } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="dmx-modal-width">
      <ModalHeader toggle={toggle}>
        {icon} {title || data?.name || ''}
      </ModalHeader>
      <ModalBody>
        <Filemanager
          isEditable={!viewOnly}
          orderId={data?._id}
          orderNoteId={data?._id}
          files={data?.attachments || []}
          type="product"
          refetch={refetch}
        />
      </ModalBody>
    </Modal>
  );
};

export default DmxModal;
