import { connect } from 'react-redux';
import { ProductionOrderDto } from '../../../../../types/production-order';
import Footer from '../Footer';
import Header from '../Header';
import Observation from '../Observations';
import ProductionOrder from '../ProductionOrder';
import Batchs from '../Batchs';
import Spinner from '../../../../../components/Spinner';

import { Page } from './styles';

interface ComponentProps {
  productionOrder: ProductionOrderDto;
  productionOrderStates: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

const Component = ({ productionOrder, productionOrderStates }: ComponentProps) => {
  const locale = 'es-ES';
  const options: { dateStyle; timeStyle: 'full' | 'long' | 'medium' | 'short' | undefined } = {
    dateStyle: 'full',
    timeStyle: 'long',
  };

  const formatedDate = new Date(productionOrder?.createDate)
    .toLocaleString(locale, options)
    .toString()
    .replace(' GMT-3', '');

  if (!productionOrder || productionOrderStates.loading) {
    return <Spinner size="large" isPageLoader />;
  }

  return (
    <Page>
      <Header>
        <p>ORDEN DE PRODUCCIÓN #{productionOrder?.code}</p>
        <p>{formatedDate}</p>
      </Header>
      <div style={{ height: '1px', backgroundColor: '#eee', margin: '25px 0px 15px' }} />
      <ProductionOrder productionOrder={productionOrder} />
      <div style={{ height: '30px' }} />
      <Batchs productionOrder={productionOrder} />
      <div style={{ height: '100px' }} />
      <Observation observations={productionOrder.observations} />
      <div style={{ height: '30px' }} />
      <Footer />
    </Page>
  );
};

const state = ({ productionOrderStore }) => {
  const { data: productionOrder, states: productionOrderStates } = productionOrderStore.productionOrder;

  return {
    productionOrder,
    productionOrderStates,
  };
};

export default connect(state)(Component);
