import styled from 'styled-components';

export const CuttingTable = styled('div')`
  display: flex;
  align-items: center;
`;

export const Label = styled('span')`
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
`;

export const Input = styled('input')`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  vertical-align: -2px;
`;
