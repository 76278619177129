import React from 'react';
import Select from 'react-select';

export const StyledSelect = (props) => {
  const newProps = { ...props };

  const onChange = (selected, options) => {
    if (props.onChange) {
      props.onChange(selected, options);
    }
    if (props.name && props.formik) {
      props.formik.setFieldValue(props.name, selected && selected.value);
    }
  };

  const onBlur = (event) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
    if (props.name && props.formik) {
      props.formik.setFieldTouched(props.name);
    }
  };

  newProps.onChange = onChange;
  newProps.onBlur = onBlur;

  return (
    <div style={{width: '100%'}}>

      <Select
        
        {...newProps}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: 'primary',
          },
        })}
        styles={{
          option: (provided, state) => {
            const newOpt = {
              ...provided,
              textAlign: 'center',
            };
            if (state.isSelected || state.isFocused) {
              return {
                ...newOpt,
                backgroundColor: 'var(--primary)',
                color: 'var(--white)',
                ':focus': {
                  backgroundColor: 'var(--dark)',
                  color: 'var(--white)',
                },
                ':hover': {
                  backgroundColor: 'var(--dark)',
                  color: 'var(--white)',
                },
              };
            }
            return newOpt;
          },
          control: (provided, state) => {
            const result = {
              ...provided,
            };
            if (state.isSelected || state.isFocused) {
              return {
                ...provided,
                ':hover': {
                  color: 'white',
                  borderColor: 'var(--primary)',
                  boxShadow: '0 0 0 0.2rem rgb(68 102 242 / 25%)',
                },
                color: 'white',
                borderColor: 'var(--primary)',
                boxShadow: '0 0 0 0.2rem rgb(68 102 242 / 25%)',
              };
            }
            return result;
          },
          valueContainer: (base) => ({
            ...base,
            textAlign: 'center',
          }),
        }}
      />
    </div>
  );
};

StyledSelect.propTypes = Select.propTypes;
