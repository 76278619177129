import { getToken } from '../utils/authUtils';

export default function getSellers() {
  return fetch(`${process.env.REACT_APP_API_URL}/users?filter=seller&fields=userType`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${getToken()}`,
    }),
  }).then((response) => response.json());
}
