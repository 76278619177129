/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './MaterialDetail.scss';
import { Container, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';

// sweetalert
import SweetAlert from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';

// components
import FeedstockBox from './FeedstockBox';
import FeedstockPlusBox from './FeedstockPlusBox';
import ObservationModal from './BatchBox/Modal';
import Spinner from '../../components/Spinner';
import Breadcrumb from '../../components/common/breadcrumb';
import BatchBox from './BatchBox';

// hooks
import useFeedstockIdFetcher from '../../hooks/useFeedstockIdFetcher';
import useProductsFetcher from '../../hooks/useProductsFetcher';

// services
import patchMaterialDetail from '../../services/patchMaterialDetail';

import Filemanager from '../OrderDetail/FilesForm';

const Materialdetail = ({ currentUser }) => {
  let feedstockId = new URLSearchParams(useLocation().search);
  feedstockId = feedstockId.get('id');
  let isEditable = new URLSearchParams(useLocation().search);
  isEditable = isEditable.get('edit') === 'true';

  // consts
  const feedstockNote = useFeedstockIdFetcher(feedstockId);
  const products = useProductsFetcher();

  // Redirect
  const history = useHistory();

  // states
  const [data, setData] = useState({
    _id: '',
    code: '',
    color: '',
    feedstockCategoryId: {
      _id: '',
      name: '',
    },
    grammage: '',
    measures: '',
    minimumAmount: 0,
    stock: 0,
    name: '',
    provider: '',
    type: '',
    width: '',
    hasUnits: false,
    readyForSave: false,
  });

  const [attributes, setAttributes] = useState();
  const [modal, setModal] = useState();
  const [moveIndex, setMoveIndex] = useState(0);
  const [batchIndex, setBatchIndex] = useState(0);
  const [atributtesChange, setAtributtesChange] = useState({
    color: '',
    grammage: '',
    type: '',
    width: '',
  });

  const toggleModal = (move_id, move_idx) => {
    const { stockControl } = data;
    let batch_index;
    for (let i = 0; i < stockControl.length; i++) {
      if (stockControl[i].movementsDetail.find((move) => move.id === move_id)) {
        batch_index = i;
        break;
      }
    }
    setMoveIndex(move_idx);
    setBatchIndex(batch_index);
    setModal(!modal);
  };

  const updateObsevation = (observation, batchIndex, moveIndex) => {
    const newData = _.clone(data);
    newData.stockControl[batchIndex].movementsDetail[moveIndex].observations = observation;
    setData(newData);
  };

  const refreshIndexes = () => {
    let newData = _.clone(data);
    if (newData.stockControl) {
      newData.stockControl.forEach((batch, bIndex) => {
        batch.batchIndex = bIndex;
        batch.movementsDetail.forEach((move, mIndex) => {
          move.batchIndex = bIndex;
          move.moveIndex = mIndex;
        });
      });
    }
  };

  const updateMaterialStock = () => {
    const { stockControl } = data;
    let newStock = 0;

    if (stockControl) {
      stockControl.forEach((batch) => (newStock += batch.mpMeters));
      setData({ ...data, stock: newStock });
    }
  };

  const updateBatch = (newData) => {
    const updatedStockControl = { ...data, stockControl: newData };
    setData(updatedStockControl);
  };

  const deleteBatch = (batchIndex) => {
    const stockControl = [...data.stockControl];
    stockControl.splice(batchIndex, 1);
    setData({ ...data, stockControl });
    refreshIndexes();
  };

  const newMove = (batchIndex) => {
    const stockControl = [...data.stockControl];
    const newMove = {
      id: uuidv4(),
      cuttingReturn: 0,
      date: new Date(Date.now()).toISOString(),
      meters: 0,
      observations: '',
      receipt: '',
      reels: 0,
      totalMeters: 0,
      type: 'INGRESO',
      isNew: true,
      batchIndex: batchIndex,
    };

    stockControl[batchIndex].movementsDetail.push(newMove);
    setData({ ...data, stockControl });
  };

  const getTotalIngress = (moves) => {
    let totalIngress = 0;
    for (let move of moves) {
      if (move.type === 'INGRESO') {
        if (data.hasUnits) {
          totalIngress += parseInt(move.units);
        } else {
          totalIngress += move.totalMeters;
        }
      }
    }
    return totalIngress;
  };

  const getTotalEgress = (moves) => {
    let totalEgress = 0;
    for (let move of moves) {
      if (move.type === 'EGRESO') {
        if (data.hasUnits) {
          totalEgress += parseInt(move.units);
        } else {
          totalEgress += move.totalMeters;
        }
      }
    }

    return totalEgress;
  };

  const getMpMeters = (moves) => {
    let totalIngress = getTotalIngress(moves);
    let totalEgress = getTotalEgress(moves);
    return totalIngress - totalEgress;
  };

  const getOpMeters = (moves) => {
    return getTotalEgress(moves);
  };

  const updateMove = (updatedMove) => {
    const { batchIndex, moveIndex } = updatedMove;
    const { stockControl } = data;
    stockControl[batchIndex].movementsDetail[moveIndex] = { ...updatedMove };
    stockControl[batchIndex].mpMeters = getMpMeters(stockControl[batchIndex].movementsDetail);
    stockControl[batchIndex].opMeters = getOpMeters(stockControl[batchIndex].movementsDetail);
    setData({ ...data, stockControl });
    updateMaterialStock();
  };

  const deleteMove = (batchIndex, moveIndex) => {
    const newData = _.clone(data);
    newData.stockControl[batchIndex].movementsDetail.splice(moveIndex, 1);
    newData.stockControl[batchIndex].mpMeters = getMpMeters(newData.stockControl[batchIndex].movementsDetail);
    newData.stockControl[batchIndex].opMeters = getOpMeters(newData.stockControl[batchIndex].movementsDetail);
    setData({ ...newData });
    refreshIndexes();
  };

  const handleChange = (entityName, value) => {
    setData({
      ...data,
      [entityName]: value,
    });
  };

  const selectChange = (e) => {
    setAtributtesChange({ ...atributtesChange, [e.attribute]: e.value });
    // eslint-disable-next-line prefer-const
    const newfieldData = data ? { ...data } : {};
    newfieldData[e.attribute] = e.value;
    setData(newfieldData);
  };

  const nameBuilder = () => {
    const name = data.feedstockCategoryId.name;
    const color = atributtesChange.color || data.color || '';
    const type = atributtesChange.type || data.type || '';
    const grammage = atributtesChange.grammage || data.grammage || '';
    const width = atributtesChange.width || data.width || '';
    const regexp = /\s\s+/g;

    return `${name} ${type} ${color} ${grammage} ${width ? width + ' mts' : ''}`.replace(regexp, ' ').trim();
  };

  const addIdToRowTableData = (rowData) => {
    return (
      rowData.stockControl &&
      rowData?.stockControl?.map((item) => {
        return {
          ...item,
          id: uuidv4(),
        };
      })
    );
  };

  const unitsValueIsValid = () => {
    let unitsValueIsValid = true;
    const { stockControl } = data;
    stockControl.forEach((batch) => {
      batch.movementsDetail.forEach((move) => {
        if (move.isNew && unitsValueIsValid) {
          unitsValueIsValid = move.units > 0;
        }
      });
    });
    return unitsValueIsValid;
  };

  const reelValueIsValid = () => {
    let reelValueIsValid = true;
    const { stockControl } = data;
    stockControl.forEach((batch) => {
      batch.movementsDetail.forEach((move) => {
        if (move.isNew && reelValueIsValid) {
          reelValueIsValid = move.reels > 0;
        }
      });
    });
    return reelValueIsValid;
  };

  const meterValueIsValid = () => {
    let meterValueIsValid = true;
    const { stockControl } = data;
    stockControl.forEach((batch) => {
      batch.movementsDetail.forEach((move) => {
        if (move.isNew && meterValueIsValid) {
          meterValueIsValid = move.meters > 0;
        }
      });
    });
    return meterValueIsValid;
  };

  const summationEgress = (batch) => {
    let summation = 0;
    batch.movementsDetail.forEach((move) => {
      if (move.type === 'EGRESO') {
        summation += move.totalMeters;
      }
    });
    return summation;
  };

  const summationIngress = (batch) => {
    let summation = 0;
    batch.movementsDetail.forEach((move) => {
      if (move.type === 'INGRESO') {
        summation += move.totalMeters;
      }
    });
    return summation;
  };

  const areMoreIngressThanEgress = () => {
    let areMoreIngressThanEgress = true;
    const { stockControl } = data;
    stockControl.forEach((batch) => {
      if (areMoreIngressThanEgress) {
        let totalIngress = summationIngress(batch);
        let totalEgress = summationEgress(batch);
        let total = totalIngress - totalEgress;
        areMoreIngressThanEgress = total >= 0;
      }
    });
    return areMoreIngressThanEgress;
  };

  const saveOrder = () => {
    SweetAlert.fire({
      title: '¿Desea guardar los cambios?',
      // text: 'Leyenda debajo titulo',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (data.handler) {
          if (data.handler._id === '') {
            delete data.handler;
          }
        }

        setData({
          ...data,
          name: nameBuilder(),
          readyForSave: true,
        });

        delete data.attachments;

        // Validaciones
        let requiredFieldsAreOk = data.hasUnits ? unitsValueIsValid() : reelValueIsValid() && meterValueIsValid();
        let rawMeterialStockIsOk = areMoreIngressThanEgress();

        if (requiredFieldsAreOk && rawMeterialStockIsOk) {
          patchMaterialDetail(data).then((res) => {
            if (res.errors) {
              toast.error(`Ha ocurrido un error:${res.errors.msg}`, { autoClose: 3000 });
            } else {
              toast.success('La materia prima ha sido guardada.', { autoClose: 3000 });
              setTimeout(() => window.location.reload(), 3500);
            }
          });
        }

        if (!requiredFieldsAreOk) {
          if (data.hasUnits) {
            toast.error(`El campo unidades debe ser mayor a 0 (cero).`, { autoClose: 3000 });
          } else {
            toast.error(`El campo rollos y el campo metros deben ser mayor a 0 (cero).`, { autoClose: 3000 });
          }
        }

        if (!rawMeterialStockIsOk) {
          toast.error(`No puede tener más egresos que ingresos.`, { autoClose: 3000 });
        }
      }
    });
  };

  const jsonToArray = (json) => {
    const result = [];
    // eslint-disable-next-line no-restricted-syntax,guard-for-in,prefer-const
    for (let attribute in json) {
      const item = { name: attribute, value: json[attribute] };
      result.push(item);
    }
    return result;
  };

  useEffect(() => {
    const stockUpdate = (data.stockControl && data.stockControl[0] && data.stockControl[0].balance) || 0;

    setData({
      ...data,
      stock: stockUpdate,
    });
    updateMaterialStock();
  }, [data.stockControl]);

  useEffect(() => {
    const newData = _.clone(feedstockNote.data);
    if (newData) {
      newData.stockControl = addIdToRowTableData(newData);

      const newAttributes = jsonToArray(
        newData.feedstockCategoryId && newData.feedstockCategoryId.feedstockAttributesId,
      )
        .filter((item) => item.name !== '_id')
        .filter((item) => item.value)
        .filter((item) => item.value.length);

      setAttributes(newAttributes);
    }

    // Actualizo los metros totales.
    if (newData.stockControl !== undefined) {
      newData.stockControl.forEach((batch) =>
        batch.movementsDetail.forEach((move) => (move.totalMeters = move.reels * move.meters)),
      );
    }

    // Agrego el indice del lote al que pertenece cada movimiento en cada moviemiento.
    if (newData.stockControl) {
      newData.stockControl.forEach((batch, index) =>
        batch.movementsDetail.forEach((move) => {
          move.batchIndex = index;
        }),
      );
    }

    // Agrego Ids a los lotes y a los movimientos.
    if (newData.stockControl) {
      newData.stockControl.forEach((batch) => {
        batch.id = uuidv4();
        batch.movementsDetail.forEach((move) => {
          move.id = uuidv4();
        });
      });
    }

    setData({ ...newData });
  }, [feedstockNote.data]);

  if (feedstockNote.isLoading || products.isLoading) {
    return <Spinner size="large" isPageLoader />;
  }

  const { feedstockCategoryId } = data;

  return (
    <>
      <div className="page-material-detail">
        <Container fluid>
          <div className="page-header">
            <div className="page-header-left">
              <h5 className="pageTitle">CONTROL DE {feedstockCategoryId && feedstockCategoryId.name}</h5>
              <Breadcrumb title="Control de materia prima" parent="Materia Prima" parentLink="/rawmaterial" />
            </div>
          </div>

          <Row className="projectdetails">
            {data.feedstockCategoryId && (
              <FeedstockBox
                feedstockData={data}
                attributes={attributes}
                isEditable={isEditable}
                handleChange={handleChange}
                selectChange={selectChange}
              />
            )}

            <FeedstockPlusBox feedstockData={data} isEditable={isEditable} handleChange={handleChange} />
          </Row>

          <Row className="projectmore">
            <BatchBox
              data={data}
              setData={setData}
              productData={products.data}
              newMove={newMove}
              updateBatch={updateBatch}
              updateMove={updateMove}
              deleteMove={deleteMove}
              isEditable={isEditable}
              deleteBatch={deleteBatch}
              currentUser={currentUser}
              toggle={toggleModal}
              hasUnits={data.hasUnits}
            />
          </Row>

          <Row className="projectmore">
            <Filemanager
              isEditable={isEditable}
              orderId={feedstockId}
              orderNoteId={feedstockId}
              files={feedstockNote?.data?.attachments || []}
              type="feedstock"
            />
          </Row>

          <Row>
            <div className="container mb-4">
              <div className="row">
                <div className="col text-center">
                  {isEditable ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      disabled={!isEditable}
                      onClick={() => saveOrder()}
                    >
                      Guardar
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Row>
        </Container>

        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <ObservationModal
          data={data.stockControl[batchIndex ? batchIndex : 0]?.movementsDetail[moveIndex ? moveIndex : 0]}
          batchIndex={batchIndex}
          moveIndex={moveIndex}
          updateObsevation={updateObsevation}
          isOpen={modal}
          toggle={toggleModal}
          readOnly={!isEditable}
        />
      </div>
    </>
  );
};

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(Materialdetail);
