import React, { useState, createContext, useEffect } from 'react';
import useProfileFetcher from '../hooks/useProfileFetcher';

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const profile = useProfileFetcher();

  const [data, setData] = useState(profile);

  useEffect(() => {
    if (profile.data !== null) {
      setData(profile.data);
    }
  }, [profile.data]);

  return <ProfileContext.Provider value={[data, setData]}>{children}</ProfileContext.Provider>;
};
