import { useState } from 'react';
import moment from 'moment';

// Third Party Imports
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import DataTableExtensions from 'react-data-table-component-extensions';
import '@innenpkg/react-data-table-component-extensions/dist/index.css';
import SweetAlert from 'sweetalert2';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

// Icons
import { faTimesCircle, faEdit, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// My imports
import { columns, paginationText, noDataComponent } from './settings';
import CanRBac from '../../../components/CanRBac';
import SpinnerButton from '../../../components/Button';
import { notifications, PARTIAL_DELIVERY_DATE } from '../../../constant';

// Services
import deleteFeedstock from '../../../services/deleteFeedstock';

// Styles
import './List.scss';

const RawMaterialList = ({
  currentUser,
  data,
  updateDataTable,
  errorMsg,
  successMsg,
  page,
  itemsForPage,
  eventLastPage,
  eventItemsForPage,
  postLastPageLocal,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const lastUpdateDate = (row) => {
    return moment(row.modificationDate).locale('es').format(PARTIAL_DELIVERY_DATE);
  };

  // Redirect
  const history = useHistory();

  const handleRefresh = async () => {
    setIsFetching(true);
    try {
      await updateDataTable();
      successMsg(notifications.tableUpdateSuccess);
    } catch (error) {
      errorMsg(`${notifications.tableUpdateError} ${error.msg}`);
    }
    setIsFetching(false);
    postLastPageLocal();
  };

  const handleEditAction = (materialId) => {
    history.push(`/materialdetail?id=${materialId}&edit=${true}`);
    postLastPageLocal();
  };

  const handleRowClicked = (materialId) => {
    history.push(`/materialdetail?id=${materialId}&edit=${false}`);
    postLastPageLocal();
  };

  const executeDeleteAction = (id) => {
    deleteFeedstock(id).then((res) => {
      if (res.msg === 'DELETED') {
        updateDataTable();
        successMsg('La materia prima ha sido borrado.');
      } else {
        errorMsg(`No se pudo borrar la materia prima porque ocurrió un error: ${res.msg}`);
      }
    });
  };

  const handleDelete = (id) => {
    SweetAlert.fire({
      title: '¿Desea borrar la materia prima?',
      text: 'Una vez eliminado no podrá ser recuperado.',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        executeDeleteAction(id);
      }
    });
  };

  const canBeRemoved = (row) => {
    /**
     * Solo se pueden eliminar aquellas Materias Primas que
     * no posean lotes o que sus lotes no posean movimientos de ningun tipo.
     */

    let hasMovements = false;

    for (const stockControl of row.stockControl) {
      const batchWithMovements = stockControl.movementsDetail.length > 0;

      if (batchWithMovements) {
        hasMovements = true;
      }
    }

    if (hasMovements) {
      toast.info('No es posible borrar la materia prima porque dispone de lotes con movimientos.', {
        showCancelButton: true,
        faTimesCircle,
        autoClose: 3000,
      });
    } else {
      handleDelete(row._id);
    }
  };

  const actionButtons = (row) => (
    <>
      <CanRBac
        role={currentUser?.userType}
        perform="mp:edit"
        yes={() => (
          <>
            <div className="text-secondary p-1 dmx-action-button">
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEditAction(row._id)} />
            </div>
          </>
        )}
      />
      <CanRBac
        role={currentUser?.userType}
        perform="mp:delete"
        yes={() => (
          <>
            <div className="text-danger p-1 dmx-action-button">
              <FontAwesomeIcon icon={faTimesCircle} onClick={() => canBeRemoved(row)} />
            </div>
          </>
        )}
      />
    </>
  );

  const cols = [
    ...columns,
    {
      name: 'Nombre',
      sortable: true,
      grow: 3,
      cell: (row) => {
        return (
          <p>
            {row.feedstockCategoryId.name} {row.type} {row.grammage} {row.color}{' '}
            {row.width === null || row.width === 'OTRO' ? null : row.width}{' '}
            {row.measures === null || row.measures === '' ? null : row.measures}{' '}
          </p>
        );
      },
    },
    {
      name: 'Última modificación',
      minWidth: '18%',
      sortable: true,
      cell: (row) => <p>{lastUpdateDate(row)}</p>,
    },
    {
      name: 'Stock',
      cell: (row, index) => {
        return row.stockControl
          .map((control) => {
            return control.movementsDetail
              .map((movement) => (movement.type === 'INGRESO' ? movement.totalMeters : -movement.totalMeters))
              .reduce((a, b) => a + b, 0);
          })
          .reduce((a, b) => a + b, 0);
      },
      sortable: true,
      grow: 1,
      right: true,
    },
    {
      name: 'Acciones',
      cell: (row) => actionButtons(row),
      grow: 0.5,
      right: true,
    },
  ];

  return (
    <div className="card-body datatable-react">
      <div className="d-flex flex-row-reverse">
        <SpinnerButton type="button" onClick={() => handleRefresh()} isLoading={isFetching} disabled={isFetching}>
          <FontAwesomeIcon icon={faSyncAlt} /> Refrescar
        </SpinnerButton>
      </div>
      <DataTableExtensions
        columns={cols}
        data={data}
        export={false}
        print={false}
        filterHidden={false}
        filterPlaceholder="Buscar..."
      >
        <DataTable
          columns={columns}
          data={data}
          pagination
          striped
          center
          persistTableHead
          highlightOnHover
          paginationDefaultPage={page}
          paginationPerPage={itemsForPage}
          onChangePage={eventLastPage}
          onChangeRowsPerPage={eventItemsForPage}
          paginationComponentOptions={paginationText}
          noDataComponent={noDataComponent}
          onRowClicked={(row) => handleRowClicked(row._id)}
        />
      </DataTableExtensions>
    </div>
  );
};

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(RawMaterialList);
