import { useState, useEffect } from 'react';
import getPackedTypes from '../services/getPackedTypes';

const usePackedTypesFetcher = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getPackedTypes()
      .then((products) => {
        setData(products);
        setIsLoading(false);
        setIsError(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return { data, isLoading, isError };
};

export default usePackedTypesFetcher;
