import { getToken } from '../utils/authUtils';

export default function getPackedTypes() {
  return fetch(`${process.env.REACT_APP_API_URL}/packed/all`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${getToken()}`,
    }),
  }).then((response) => response.json());
}
