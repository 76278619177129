import { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

// icons
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { ModalHeaderCustom, InputGroup, InputCustom, LabelCustom, SpinnerButtonCustom, Icon } from './mixins';

const generateMPIncrementalId = (data) => {
  let incrementalNumber = 1;
  const nameFeedStock = data.feedstockCategoryId?.name.substring(0, 4);
  const grammage = data.grammage;
  const color = data.color && data.color.replace(/\s/g, '');
  const fullName = `${nameFeedStock}${grammage || ''}${color || ''}`;
  incrementalNumber += data.stockControl.length;
  return `${fullName}00${incrementalNumber}`;
};

const Component = ({ isOpen, toggle, data, setData }) => {
  const [receipt, setReceipt] = useState('');
  const [provider, setProvider] = useState('');
  const [providerBatch, setProviderBatch] = useState('');

  const resetValuesNewBatch = () => {
    setReceipt('');
    setProvider('');
    setProviderBatch('');
  };

  const handleChangeReceipt = (value) => {
    setReceipt(value);
  };

  const handleChangeProvider = (value) => {
    setProvider(value);
  };

  const handleChangeProviderBatch = (value) => {
    setProviderBatch(value);
  };

  const handleCreateBatch = () => {
    const stockControl = [...data.stockControl];
    const id = uuidv4(); // consultar con Ger si este id sigue siendo util
    const newBatch = {
      batchId: (id.slice(-5) + '-' + generateMPIncrementalId(data)).toUpperCase(),
      barcode: (id.slice(-5) + '-' + generateMPIncrementalId(data)).toUpperCase(),
      id: id,
      isNew: true,
      movementsDetail: [],
      mpMeters: 0,
      opMeters: 0,
      provider: provider,
      providerBatch: providerBatch,
      receipt: receipt,
    };
    stockControl.push(newBatch);
    setData({ ...data, stockControl });
    toggle();
    resetValuesNewBatch();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeaderCustom toggle={toggle}>
        <Icon icon={faPlus} color="green" />
        Nuevo Lote
      </ModalHeaderCustom>

      <ModalBody>
        <InputGroup>
          <LabelCustom>Lote</LabelCustom>
          <InputCustom
            name="batchId"
            className="form-control dmx-input w-100 notext"
            defaultValue={generateMPIncrementalId(data)}
            disabled
          />
        </InputGroup>

        <InputGroup>
          <LabelCustom>Remito</LabelCustom>
          <InputCustom
            name="receipt"
            className="form-control dmx-input w-100 notext"
            onChange={(e) => handleChangeReceipt(e.target.value)}
          />
        </InputGroup>

        <InputGroup>
          <LabelCustom>Proveedor</LabelCustom>
          <InputCustom
            name="provider"
            className="form-control dmx-input w-100 notext"
            onChange={(e) => handleChangeProvider(e.target.value)}
          />
        </InputGroup>

        <InputGroup>
          <LabelCustom>Proveedor Lote</LabelCustom>
          <InputCustom
            name="mpMeters"
            className="form-control dmx-input w-100 notext"
            onChange={(e) => handleChangeProviderBatch(e.target.value)}
          />
        </InputGroup>

        {/* Acá va el barcode cuando este deployado en develop */}

        <SpinnerButtonCustom
          type="submit"
          className="btn btn-primary btn-block dmx-modal-save-button"
          onClick={() => handleCreateBatch()}
        >
          Crear Lote
        </SpinnerButtonCustom>
      </ModalBody>
    </Modal>
  );
};

export default Component;
