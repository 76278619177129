import moment from 'moment';
import { faCube, faCalendar } from '@fortawesome/free-solid-svg-icons';

// constants
import { PARTIAL_DELIVERY_DATE } from '../../../../constant';
import hightPriority from '../../../../assets/images/icons/hight-priority.svg';

import {
  DisabledCard,
  LinkCard,
  CardContent,
  State,
  DateOrigen,
  Batch,
  Product,
  Wrapper,
  Quantity,
  Icon,
  InLine,
  Priority,
  PercentBar,
  PercentValue,
  Sonar,
  PoStates,
} from './mixins';
import { connect } from 'react-redux';

import pattern from '../../../../assets/images/icons/pattern.svg';
import warehouse from '../../../../assets/images/icons/warehouse.svg';
import workshop from '../../../../assets/images/icons/tailoring.svg';
import CanRBac from '../../../CanRBac';
import { ProductionOrderDto } from '../../../../types/production-order';

interface ComponentProps {
  provided;
  item: ProductionOrderDto;
  detailLink;
  snapshot;
  currentUser;
}

const Content = (props) => {
  const { provided, item, currentUser } = props;

  const date = moment(item?.createDate).locale('es').format(PARTIAL_DELIVERY_DATE);

  const totalUnits = item?.quantity;

  const cutBags = item?.bags
    ?.filter((bag) => bag?.status !== 'inDeposit')
    .reduce((previousValue, currentValue) => {
      return previousValue + currentValue.units;
    }, 0);

  const workshopBags = item?.bags
    .filter((bag) => bag?.status === 'inWorkshop')
    .reduce((previousValue, currentValue) => {
      return previousValue + currentValue?.units;
    }, 0);

  const depositBags = item?.bags
    .filter((bag) => bag?.status === 'inDeposit')
    .reduce((previousValue, currentValue) => {
      return previousValue + currentValue?.units;
    }, 0);

  return (
    <CardContent
      {...(provided && { ref: provided?.innerRef, ...provided?.draggableProps, ...provided?.dragHandleProps })}
    >
      <InLine style={{ marginBottom: '6px' }}>
        <DateOrigen>
          <Icon icon={faCalendar} color="#999" /> {date}
        </DateOrigen>

        <PoStates>
          {item?.code && <State>#{item?.code}</State>}
          <State type={item?.type}>{item?.type}</State>

          {item?.cuttingTable && <Sonar />}

          {item?.priority && (
            <Priority>
              {' '}
              <img src={hightPriority} alt="Hight Priority" width="20px" />
            </Priority>
          )}
        </PoStates>
      </InLine>

      <Product readed={item?.watchers && item?.watchers?.includes(currentUser?._id)}>{item?.product?.name}</Product>

      <div className="porcent-bar">
        <InLine style={{ marginTop: '6px' }}>
          <Quantity>
            <img src={pattern} alt="warehouse" width="15px" />{' '}
            {totalUnits > 0 && `${cutBags} de ${totalUnits} unidades`}
          </Quantity>
          <PercentValue>{totalUnits > 0 && cutBags > 0 ? ((cutBags * 100) / totalUnits).toFixed(2) : 0}%</PercentValue>
        </InLine>

        <PercentBar completed={totalUnits > 0 && cutBags > 0 ? (cutBags * 100) / totalUnits : 0}></PercentBar>

        {/* En Taller */}
        <InLine style={{ marginTop: '6px' }}>
          <Quantity>
            <img src={workshop} alt="warehouse" width="15px" /> {cutBags > 0 && `${workshopBags} en Taller`}
          </Quantity>
          <PercentValue>
            {cutBags > 0 && workshopBags > 0 ? ((workshopBags * 100) / cutBags).toFixed(2) : 0}%
          </PercentValue>
        </InLine>

        <PercentBar completed={cutBags > 0 && workshopBags > 0 ? (workshopBags * 100) / cutBags : 0}></PercentBar>

        {/* En Deposito */}
        <InLine style={{ marginTop: '6px' }}>
          <Quantity>
            <img src={warehouse} alt="warehouse" width="15px" />
            {cutBags > 0 && `${depositBags} en Deposito`}
          </Quantity>
          <PercentValue>
            {cutBags > 0 && depositBags > 0 ? ((depositBags * 100) / cutBags).toFixed(2) : 0}%
          </PercentValue>
        </InLine>

        <PercentBar completed={cutBags > 0 && depositBags > 0 ? (depositBags * 100) / cutBags : 0}></PercentBar>
      </div>
    </CardContent>
  );
};

const Component = (props: ComponentProps) => {
  const { detailLink, snapshot, item, currentUser } = props;

  return (
    <CanRBac
      role={currentUser?.userType}
      perform="op:view"
      data={{ status: item?.state }}
      yes={() => {
        return (
          <LinkCard
            {...props}
            to={`${detailLink}/${item?._id}`}
            draggingOver={snapshot.draggingOver}
            isDragging={snapshot.isDragging}
          >
            <Content {...props} />
          </LinkCard>
        );
      }}
      no={() => {
        return (
          <DisabledCard>
            <Content {...props} />
          </DisabledCard>
        );
      }}
    />
  );
};

const state = ({ userStore }) => {
  const { data: currentUser } = userStore.information;
  return {
    currentUser,
  };
};
export default connect(state)(Component);
