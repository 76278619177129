import styled from 'styled-components'

export const SelectContainer = styled('div')`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  margin-top: 10px;

  @media (min-width: 1024px) {
    margin-top: 0px;
    margin-left: 15px;
  }
`
