export const customStyles = {
  headCells: {
    style: {
      paddingLeft: '4px', // override the cell padding for head cells
      paddingRight: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: '4px', // override the cell padding for data cells
      paddingRight: '4px',
    },
  },
};

// export const columns = [
//   {
//     name: '#',
//     sortable: false,
//     center: false,
//     minWidth: '3%',
//     maxWidth: '3%',
//     cell: (row, index) => {
//       return index + 1;
//     },
//   },
//   {
//     name: 'Lote',
//     sortable: false,
//     center: false,
//     cell: (row) => {
//       return row.batchId.toUpperCase();
//     },
//   },
//   {
//     name: 'mpMeters',
//     sortable: false,
//     center: false,
//     cell: (row) => (
//       <input
//         type="number"
//         name="mpMeters"
//         defaultValue={row.mpMeters || 0}
//         className="form-control dmx-input w-100 notext"
//         disabled
//       />
//     ),
//   },
//   {
//     name: 'opMeters',
//     sortable: false,
//     center: false,
//     cell: (row) => (
//       <input
//         type="number"
//         name="opMeters"
//         defaultValue={row.opMeters || 0}
//         className="form-control dmx-input w-100 notext"
//         disabled
//       />
//     ),
//   },
// ];
