/* eslint-disable no-underscore-dangle */
import { checkDataPermission } from '../components/CanRBac';

export const filterOptByPermissions = (currentUser, statusOptions) => {
  const value = Object.entries(statusOptions).filter((item) =>
    checkDataPermission(currentUser?.userType, 'np:edit:state', {
      status: item[0],
    }),
  );
  return value && Object.fromEntries(value);
};

export const filterDataByPermissions = (currentUser, data) => {
  return data.filter((item) =>
    checkDataPermission(currentUser?.userType, 'np:view', {
      sellerId: item.seller._id,
      handlerId: item.handler ? item.handler._id : 0,
      userId: currentUser._id,
      status: item.status,
    }),
  );
};
