import { User } from '../../types/user';

export const RESET_USER_MAIN_INFORMATION = 'RESET_USER_MAIN_INFORMATION';

export const GET_USER_MAIN_INFORMATION = 'GET_USER_MAIN_INFORMATION';
export const GET_USER_MAIN_INFORMATION_SUCCESS = 'GET_USER_MAIN_INFORMATION_SUCCESS';
export const GET_USER_MAIN_INFORMATION_ERROR = 'GET_USER_MAIN_INFORMATION_ERROR';

export const UPDATE_USER_MAIN_INFORMATION = 'UPDATE_USER_MAIN_INFORMATION';
export const UPDATE_USER_MAIN_INFORMATION_SUCCESS = 'UPDATE_USER_MAIN_INFORMATION_SUCCESS';
export const UPDATE_USER_MAIN_INFORMATION_ERROR = 'UPDATE_USER_MAIN_INFORMATION_ERROR';

export interface GetUserMainInformation {
  type: typeof GET_USER_MAIN_INFORMATION;
  payload: null;
}

export interface GetUserMainInformationSuccess {
  type: typeof GET_USER_MAIN_INFORMATION_SUCCESS;
  payload: User;
}

export interface GetUserMainInformationError {
  type: typeof GET_USER_MAIN_INFORMATION_ERROR;
  payload: boolean | string;
}

export interface UpdateUserMainInformation {
  type: typeof UPDATE_USER_MAIN_INFORMATION;
  payload: null;
}

export interface UpdateUserMainInformationSuccess {
  type: typeof UPDATE_USER_MAIN_INFORMATION_SUCCESS;
  payload: User;
}

export interface UpdateUserMainInformationError {
  type: typeof UPDATE_USER_MAIN_INFORMATION_ERROR;
  payload: boolean | string;
}

export interface ResetUserMainInformation {
  type: typeof RESET_USER_MAIN_INFORMATION;
  payload: null;
}

export interface State {
  information: {
    data: User | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateInformation: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  information: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateInformation: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetUserMainInformation
  | GetUserMainInformationSuccess
  | GetUserMainInformationError
  | UpdateUserMainInformation
  | UpdateUserMainInformationSuccess
  | UpdateUserMainInformationError
  | ResetUserMainInformation;
