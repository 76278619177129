import styled from 'styled-components';

export const Kanban = styled('div')`
  position: relative;

  .kanban-loader {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
`;
