import { Row, Col } from 'reactstrap';

// components
import Selection from './components/selection';
import Table from './components/table';

// mixins
import { Item, CardText } from '../../mixins';
import { useState } from 'react';

const Component = ({ dataFeedstock }) => {
  return (
    <CardText>
      <Row>
        <Col>
          <Item>Materia Prima:</Item>
        </Col>
      </Row>

      <Selection dataFeedstock={dataFeedstock} />
      <Table />
    </CardText>
  );
};

export default Component;
