/* eslint no-underscore-dangle: 0 */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useContext } from 'react';
import Select from 'react-select';
import Spinner from '../../components/Spinner';
import { NewOrderNoteContext } from '../../context/NewOrderNoteContext';

const BillingInformation = (props) => {
  const { isLoading } = props;
  const [context, setContext] = useContext(NewOrderNoteContext);

  if (isLoading) {
    return <Spinner size="large" isPageLoader />;
  }

  // const sellerOptions = data.data.docs.map((seller) => ({
  //   value: seller._id,
  //   label: seller.name,
  //   seller: {
  //     _id: seller._id,
  //     role: seller.role,
  //     userType: seller.userType,
  //     name: seller.name,
  //     email: seller.email,
  //     verified: seller.verified,
  //     verification: seller.verification,
  //     createdAt: seller.createdAt,
  //     updatedAt: seller.updatedAt,
  //   },
  // }));

  const paymentCurrencyOptions = [
    { value: 'pesos', label: 'Pesos' },
    { value: 'dollars', label: 'Dolares' },
  ];

  const handleOnChange = (name, value) => {
    if (name === 'seller') {
      setContext({
        ...context,
        seller: value,
      });
    } else {
      setContext({
        ...context,
        [name]: value,
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row mt-4">
              {/* <div className="col-md-12 mb-3">
                <label htmlFor="seller">Vendedor</label>
                <Select
                  defaultValue={sellerOptions.filter((option) => option.value === seller._id)}
                  inputId="seller"
                  placeholder="Seleccione un vendedor"
                  options={sellerOptions}
                  onChange={(options) => handleOnChange('seller', options.seller)}
                />
              </div> */}
              <div className="col-md-12 mb-3">
                <label htmlFor="currency">Moneda</label>
                <Select
                  defaultValue={paymentCurrencyOptions.filter((option) => option.value === 'pesos')}
                  id="paymentCurrency"
                  placeholder="Seleccione una moneda"
                  options={paymentCurrencyOptions}
                  onChange={(options) => handleOnChange('paymentCurrency', options.value)}
                />
              </div>
              {/* <div className="col-md-12 mb-3">
                <label htmlFor="paymentType">Forma de pago</label>
                <input
                  value={paymentType}
                  id="paymentType"
                  className="form-control"
                  type="text"
                  placeholder="Ingrese la forma de pago"
                  onChange={(event) => handleOnChange('paymentType', event.target.value)}
                />
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BillingInformation;
