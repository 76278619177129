import { CSSProperties } from 'react';
import './styles.scss';

interface ComponentProps {
  name: string;
  value?: string | number;
  type?: string;
  style?: CSSProperties;
  disabled?: boolean;
  min?: number;
  max?: number;
  error?: {};
  touched?: {};
  placeholder?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  onKeyPress?: (event: Event) => void;
  autocomplete?: 'off' | 'on' | 'new-password';
  options?: {
    label?: string;
  };
}

const Component = ({
  options,
  error,
  touched,
  type,
  style,
  max,
  onFocus,
  onBlur,
  onChange,
  onKeyPress,
  ...rest
}: ComponentProps) => {
  const initialValues = {
    label: '',
  };

  const { label } = { ...initialValues, ...options };

  const handleOnChange = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnKeyPress = (event, callback) => {
    !!callback && callback(event);
  };

  return (
    <div className={`form-group ${type === 'hidden' ? 'hidden' : ''}`} style={style}>
      {!!label && <label className="col-form-label pt-0">{label}</label>}

      <input
        className={`form-control ${!!error && !!touched ? 'error-field' : ''}`}
        onChange={(event) => handleOnChange(event, onChange)}
        onBlur={(event) => handleOnBlur(event, onBlur)}
        onFocus={(event) => handleOnFocus(event, onFocus)}
        onKeyPress={(event) => handleOnKeyPress(event, onKeyPress)}
        type={type}
        onWheel={(event) => event.currentTarget.blur()}
        {...(type === 'date' && { max: max || '9999-12-31' })}
        {...rest}
      />

      {!!error && !!touched && <div className="input-feedback">{error}</div>}
    </div>
  );
};

export default Component;
