import { put, call, all, select, takeLatest } from 'redux-saga/effects';
import services from '../../services';

import {
  NEW_WORKSHOP,
  NEW_WORKSHOP_SUCCESS,
  NEW_WORKSHOP_ERROR,
  EDIT_WORKSHOP,
  EDIT_WORKSHOP_SUCCESS,
  EDIT_WORKSHOP_ERROR,
  GET_WORKSHOP,
  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOP_ERROR,
  GET_ALL_WORKSHOPS,
  GET_ALL_WORKSHOPS_SUCCESS,
  GET_ALL_WORKSHOPS_ERROR,
  GET_ALL_WORKSHOPS_RESET,
} from '../types/workshop';

const allWorkshops = ({ workshopStore }) => workshopStore.workshops.data;
const selectedWorkshop = ({ workshopStore }) => workshopStore.workshop.data;

function* newWorkshop({ payload }): any {
  try {
    const { response, error } = yield call(services.workshop.create, payload);

    if (error) {
      yield put({ type: NEW_WORKSHOP_ERROR, payload: error.message });
    } else {
      const workshops = yield select(allWorkshops);

      yield all([
        put({ type: NEW_WORKSHOP_SUCCESS, payload: response }),
        put({ type: GET_ALL_WORKSHOPS_SUCCESS, payload: [...workshops, response] })
      ]);
    }
  } catch (error: any) {
    yield put({ type: NEW_WORKSHOP_ERROR, payload: error.message });
  }
}

function* editWorkshop({ payload }): any {
  try {
    const { response, error } = yield call(services.workshop.updateOne, payload);

    if (error) {
      yield put({ type: EDIT_WORKSHOP_ERROR, payload: error.message });
    } else {
      const workshop = yield select(selectedWorkshop);

      yield all([
        put({ type: EDIT_WORKSHOP_SUCCESS, payload: response }),
        put({ type: GET_WORKSHOP_SUCCESS, payload: { ...workshop, ...response } })
      ]);
    }
  } catch (error: any) {
    yield put({ type: EDIT_WORKSHOP_ERROR, payload: error.message });
  }
}

function* getWorkshop({ payload }): any {
  try {
    const { response, error } = yield call(services.workshop.getOne, payload);

    if (error) {
      yield put({ type: GET_WORKSHOP_ERROR, payload: error });
    } else {
      yield put({ type: GET_WORKSHOP_SUCCESS, payload: response });
    }
  } catch (error: any) {
    yield put({ type: GET_WORKSHOP_ERROR, payload: 'Error al buscar el taller solicitado.' });
  }
}

function* getAllWorkshops({ payload }): any {
  const { response, error } = yield call(services.workshop.getAll, payload);

  if (error) {
    yield put({ type: GET_ALL_WORKSHOPS_ERROR, payload: error.message });
  } else {
    yield put({ type: GET_ALL_WORKSHOPS_SUCCESS, payload: response });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEW_WORKSHOP, newWorkshop);
  yield takeLatest<any>(EDIT_WORKSHOP, editWorkshop);
  yield takeLatest<any>(GET_WORKSHOP, getWorkshop);
  yield takeLatest<any>(GET_ALL_WORKSHOPS, getAllWorkshops);
}
