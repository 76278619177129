import { User, LogOut } from 'react-feather';
import { signOut } from '../../../store/actions/auth';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';

import './userMenu.scss';

const UserMenu = () => {
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(signOut())
  }
  
  return (
    <div className="onhover-dropdown">
      <div className="media align-items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="profile-avatar"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
          <circle cx="12" cy="7" r="4" />
        </svg>

        <div className="dotted-animation">
          <span className="animate-circle" />
          <span className="main-circle" />
        </div>
        
        <ul className="dropdown-user onhover-show-div shadow-sm">
          <li>
            <Link to="/profile">
              <User />
              Mi Perfil
            </Link>
          </li>
          <li>
            <a onClick={handleSignOut}>
              <LogOut />
              Cerrar Sesión
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withRouter(UserMenu);
