import {
  initialState,
  Actions,
  State,
  GET_USER_MAIN_INFORMATION,
  GET_USER_MAIN_INFORMATION_SUCCESS,
  GET_USER_MAIN_INFORMATION_ERROR,
  UPDATE_USER_MAIN_INFORMATION,
  UPDATE_USER_MAIN_INFORMATION_SUCCESS,
  UPDATE_USER_MAIN_INFORMATION_ERROR,
  RESET_USER_MAIN_INFORMATION,
} from '../types/user';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_USER_MAIN_INFORMATION:
      return {
        ...state,
        information: {
          data: state.information.data,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case GET_USER_MAIN_INFORMATION_SUCCESS:
      return {
        ...state,
        information: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case GET_USER_MAIN_INFORMATION_ERROR:
      return {
        ...state,
        information: {
          data: null,
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case UPDATE_USER_MAIN_INFORMATION:
      return {
        ...state,
        updateInformation: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case UPDATE_USER_MAIN_INFORMATION_SUCCESS:
      return {
        ...state,
        updateInformation: {
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case UPDATE_USER_MAIN_INFORMATION_ERROR:
      return {
        ...state,
        updateInformation: {
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case RESET_USER_MAIN_INFORMATION:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
