import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Form from '../../../../../../../components/Form';
import Input from '../../../../../../../components/Form/Input';
import Select from '../../../../../../../components/Form/Select';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Spinner from '../../../../../../../components/Spinner';
import { hideModal, showModal } from '../../../../../../../store/actions/modal';
import {
  createProductionOrderBag,
  createProductionOrderBagReset,
} from '../../../../../../../store/actions/production-order-bag';
import { useEffect, useState } from 'react';
import { ProductionOrderDto } from '../../../../../../../types/production-order';
import './styles.scss';

const name = 'create-production-order-in-bags-modal';

const newBagsValidationSchema = Yup.object().shape({
  bags: Yup.number().min(1, 'El valor mínimo es 1.').required('Este campo es requerido.'),
  units: Yup.number().min(1, 'El valor mínimo es 1.').required('Este campo es requerido.'),
  workshop: Yup.string(),
  status: Yup.string(),
});

const newBagsValues = {
  bags: '',
  units: '',
  workshop: '',
  status: 'inDeposit',
};

interface ComponentProps {
  states: { label: string; value: string }[];
  visibleModal: string | null;
  productionOrder: ProductionOrderDto;
  createProductionOrderBagsStates: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };
  allProductionOrderBags;
  allProductionOrderBagsStates;
  workshops;
}

const Component = ({
  visibleModal,
  productionOrder,
  createProductionOrderBagsStates,
  allProductionOrderBags,
  allProductionOrderBagsStates,
  workshops,
}: ComponentProps) => {
  const [bags, setBags] = useState<number>(0);
  const [units, setUnits] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const dispatch = useDispatch();

  const handleOnSubmit = (data) => {
    dispatch(
      createProductionOrderBag({
        ...data.values,
        date: new Date().toString(),
        opID: productionOrder?._id,
        productID: productionOrder?.product?._id,
        productCode: productionOrder?.product?.code,
      }),
    );
  };

  const availablesWorkshops = () => {
    const workshopsInBagsOut = allProductionOrderBags
      .filter((productionOrder) => productionOrder?.status !== 'inDeposit')
      .map((productionOrder) => productionOrder?.workshop?._id);

    const allWorkshops = workshops?.data
      .filter((workshop) => workshopsInBagsOut.includes(workshop._id))
      .map((workshop) => ({ label: workshop?.name, value: workshop?._id }));

    const defaultValue = allWorkshops[0];

    return {
      workshops: allWorkshops,
      defaultValue,
    };
  };

  const setTotalOfBags = () => {
    const bagTotal = allProductionOrderBags
      ?.filter((productionOrder) => productionOrder.status === 'inDeposit')
      .map((bag) => bag.units)
      .reduce((a, b) => a + b, 0);
    setTotal(bags * units + bagTotal);
  };

  useEffect(() => {
    setBags(0);
    setUnits(0);
    setTotalOfBags();
  }, [visibleModal]);

  useEffect(() => {
    setTotalOfBags();
  }, [bags, units, productionOrder]);

  useEffect(() => {
    if (createProductionOrderBagsStates.success) {
      toast.success('Los bolsones se crearon correctamente', { autoClose: 3000 });
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(createProductionOrderBagReset());
      }, 3500);
    }

    if (createProductionOrderBagsStates.error) {
      toast.error('Error al crear bolsones.', { autoClose: 3000 });
      setTimeout(() => {
        dispatch(createProductionOrderBagReset());
      }, 3500);
    }
  }, [createProductionOrderBagsStates]);

  return (
    <>
      <Button color="primary" size="md" style={{ marginTop: '15px' }} onClick={() => dispatch(showModal(name))}>
        Agregar bolsones
      </Button>

      <Modal
        fullscreen="sm"
        size="sm"
        scrollable
        centered
        isOpen={visibleModal === name}
        toggle={() => dispatch(hideModal())}
        className="production-order-new-bags-modal"
      >
        <ModalHeader toggle={() => dispatch(hideModal())}>Alta de bolsones</ModalHeader>
        <ModalBody>
          <Form
            id="create-production-order-bags-form"
            rules={newBagsValidationSchema}
            values={{ ...newBagsValues, workshop: availablesWorkshops()?.defaultValue?.value }}
            onSubmit={handleOnSubmit}
          >
            <Input
              type="number"
              name="bags"
              min={1}
              options={{
                label: 'Número de bolsones',
              }}
              disabled={createProductionOrderBagsStates?.loading}
              onChange={(event) => setBags(event.currentTarget.value)}
            />
            <Input
              type="number"
              name="units"
              min={1}
              options={{
                label: 'Unidades por bolsón',
              }}
              disabled={createProductionOrderBagsStates?.loading}
              onChange={(event) => setUnits(event.currentTarget.value)}
            />

            <Select
              name="workshop"
              placeholder="Taller"
              defaultValue={availablesWorkshops().defaultValue}
              items={availablesWorkshops().workshops}
              options={{
                label: 'Taller',
              }}
              disabled={createProductionOrderBagsStates?.loading}
            />
          </Form>
          <div className={`bags-creation ${Number(total) > Number(productionOrder?.quantity) ? 'error' : ''}`}>
            {total} unidades de {productionOrder?.quantity} <br />
            {Number(total) > Number(productionOrder?.quantity) && 'superas el limite de unidades a fabricar.'}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="m-b-15"
            color="primary"
            type="button"
            disabled={createProductionOrderBagsStates?.loading}
            outline
            onClick={() => dispatch(hideModal())}
          >
            Cancelar
          </Button>{' '}
          <Button
            color="primary"
            type="submit"
            disabled={
              createProductionOrderBagsStates?.loading ||
              total === 0 ||
              Number(total) > Number(productionOrder?.quantity)
            }
            form="create-production-order-bags-form"
          >
            {createProductionOrderBagsStates?.loading ? <Spinner /> : 'Crear bolsones'}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const state = ({ modalStore, productionOrderBagStore, staticsStore, productionOrderStore }) => {
  const { visibleModal } = modalStore;
  const { data: workshops } = staticsStore.workshops;
  const { states: createProductionOrderBagsStates } = productionOrderBagStore.createProductionOrderBag;
  const { data: productionOrder } = productionOrderStore.productionOrder;
  const { data: allProductionOrderBags, states: allProductionOrderBagsStates } =
    productionOrderBagStore.productionOrderBagAll;

  return {
    visibleModal,
    createProductionOrderBagsStates,
    productionOrder,
    allProductionOrderBags,
    allProductionOrderBagsStates,
    workshops,
  };
};

export default connect(state)(Component);
