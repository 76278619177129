import { useState, useEffect } from 'react';
import getSellers from '../services/getSellers';

const useSellersFetcher = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getSellers()
      .then((sellers) => {
        setData(sellers);
        setIsLoading(false);
        setIsError(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return { data, isLoading, isError };
};

export default useSellersFetcher;
