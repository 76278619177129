import { connect } from 'react-redux';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Observations } from './styles';
import { ProductionOrderDto } from '../../../../../types/production-order';
import { User } from '../../../../../types/user';
import { checkDataPermission } from '../../../../../components/CanRBac';

interface ComponentProps {
  onChange: (value) => void;
  currentUser: User;
  productionOrder: ProductionOrderDto;
}

const Component = ({ onChange, productionOrder, currentUser }: ComponentProps) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Observaciones</CardTitle>
        <Observations
          name="observations"
          defaultValue={productionOrder?.observations}
          onChange={(event) => onChange(event.target.value)}
          disabled={
            !checkDataPermission(currentUser.userType, 'op:edit:observations', {
              status: productionOrder?.state,
            })
          }
        />
      </CardBody>
    </Card>
  );
};

const state = ({ productionOrderStore, userStore }) => {
  const { data: currentUser } = userStore.information;
  const { data: productionOrder } = productionOrderStore.productionOrder;

  return {
    currentUser,
    productionOrder,
  };
};

export default connect(state)(Component);
