import { useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { checkDataPermission } from '../../../../../../../components/CanRBac';

// mixins
import { SelectContainer } from './mixins';

const Component = ({ orders, defaultValue, handleChangeOrder, currentUser, productionOrder, isSearchable = false }) => {
  const ordersOptions =
    orders &&
    orders.map((order) => ({
      _id: order?._id,
      label: order?.orderNoteId,
      value: order?.orderNoteId,
      client: order?.client?.companyName,
      clientId: order?.client?._id,
    }));

  const defaultValueOrigin = defaultValue?.map((order) => {
    return {
      _id: order?._id,
      label: order?.orderNoteId,
      value: order?.orderNoteId,
      client: order?.client?.companyName,
      clientId: order?.client?._id,
    };
  });

  const customStyles = {
    singleValue: (base) => ({
      ...base,
      padding: 5,
      borderRadius: 5,
      color: 'black',
      display: 'flex',
    }),
    valueContainer: (provided) => ({
      ...provided,
      width: '300px',
    }),
  };

  return (
    <SelectContainer>
      <Select
        name="selectType"
        styles={customStyles}
        isSearchable={isSearchable}
        options={ordersOptions}
        isDisabled={
          !checkDataPermission(currentUser.userType, 'op:edit:poInformation', { status: productionOrder?.state })
        }
        defaultValue={defaultValueOrigin}
        onChange={(value) => handleChangeOrder(value)}
      />
    </SelectContainer>
  );
};

const state = ({ userStore, productionOrderStore }) => {
  const { data: productionOrder } = productionOrderStore.productionOrder;
  const { data: currentUser } = userStore.information;

  return {
    currentUser,
    productionOrder,
  };
};

export default connect(state)(Component);
