import { ProductionOrderDto } from '../../types/production-order';

export const GET_PRODUCTION_ORDER_ALL = 'GET_PRODUCTION_ORDER_ALL';
export const GET_PRODUCTION_ORDER_ALL_SUCCESS = 'GET_PRODUCTION_ORDER_ALL_SUCCESS';
export const GET_PRODUCTION_ORDER_ALL_ERROR = 'GET_PRODUCTION_ORDER_ALL_ERROR';
export const GET_PRODUCTION_ORDER_ALL_RESET = 'GET_PRODUCTION_ORDER_ALL_RESET';

export const GET_PRODUCTION_ORDER = 'GET_PRODUCTION_ORDER';
export const GET_PRODUCTION_ORDER_SUCCESS = 'GET_PRODUCTION_ORDER_SUCCESS';
export const GET_PRODUCTION_ORDER_ERROR = 'GET_PRODUCTION_ORDER_ERROR';
export const GET_PRODUCTION_ORDER_RESET = 'GET_PRODUCTION_ORDER_RESET';

export const PATCH_PRODUCTION_ORDER = 'PATCH_PRODUCTION_ORDER';
export const PATCH_PRODUCTION_ORDER_SUCCESS = 'PATCH_PRODUCTION_ORDER_SUCCESS';
export const PATCH_PRODUCTION_ORDER_ERROR = 'PATCH_PRODUCTION_ORDER_ERROR';
export const PATCH_PRODUCTION_ORDER_RESET = 'PATCH_PRODUCTION_ORDER_RESET';

export const SET_PRODUCTION_ORDER_READED = 'SET_PRODUCTION_ORDER_READED';
export const SET_PRODUCTION_ORDER_READED_SUCCESS = 'SET_PRODUCTION_ORDER_READED_SUCCESS';
export const SET_PRODUCTION_ORDER_READED_ERROR = 'SET_PRODUCTION_ORDER_READED_ERROR';

export const DELETE_PRODUCTION_ORDER = 'DELETE_PRODUCTION_ORDER';
export const DELETE_PRODUCTION_ORDER_SUCCESS = 'DELETE_PRODUCTION_ORDER_SUCCESS';
export const DELETE_PRODUCTION_ORDER_ERROR = 'DELETE_PRODUCTION_ORDER_ERROR';

export const RESET_DELETE_PRODUCTION_ORDER = 'RESET_DELETE_PRODUCTION_ORDER';
export const RESET_UPDATE_PRODUCTION_ORDER = 'RESET_UPDATE_PRODUCTION_ORDER';

export const PRODUCTION_ORDER_UPDATE_CURRENT = 'PRODUCTION_ORDER_UPDATE_CURRENT';

export interface DeleteProductionOrder {
  type: typeof DELETE_PRODUCTION_ORDER;
  payload: number;
}

export interface DeleteProductionOrderSuccess {
  type: typeof DELETE_PRODUCTION_ORDER_SUCCESS;
  payload: null;
}

export interface DeleteProductionOrderError {
  type: typeof DELETE_PRODUCTION_ORDER_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteProductionOrder {
  type: typeof RESET_DELETE_PRODUCTION_ORDER;
  payload: null;
}

export interface ResetUpdateProductionOrder {
  type: typeof RESET_UPDATE_PRODUCTION_ORDER;
  payload: null;
}

export interface ProductionOrderUpdateCurrent {
  type: typeof PRODUCTION_ORDER_UPDATE_CURRENT;
  payload: ProductionOrderDto;
}

export interface GetProductionOrderAll {
  type: typeof GET_PRODUCTION_ORDER_ALL;
  payload?: { [key: string]: string | number }[];
}

export interface GetProductionOrderAllSuccess {
  type: typeof GET_PRODUCTION_ORDER_ALL_SUCCESS;
  payload: null;
}

export interface GetProductionOrderAllError {
  type: typeof GET_PRODUCTION_ORDER_ALL_ERROR;
  payload: boolean | string;
}

export interface GetProductionOrderAllReset {
  type: typeof GET_PRODUCTION_ORDER_ALL_RESET;
  payload: null;
}

export interface GetProductionOrder {
  type: typeof GET_PRODUCTION_ORDER;
  payload: null;
}

export interface GetProductionOrderSuccess {
  type: typeof GET_PRODUCTION_ORDER_SUCCESS;
  payload: null;
}

export interface GetProductionOrderError {
  type: typeof GET_PRODUCTION_ORDER_ERROR;
  payload: boolean | string;
}

export interface GetProductionOrderReset {
  type: typeof GET_PRODUCTION_ORDER_RESET;
  payload: null;
}

export interface PatchProductionOrder {
  type: typeof PATCH_PRODUCTION_ORDER;
  payload: null;
}

export interface PatchProductionOrderSuccess {
  type: typeof PATCH_PRODUCTION_ORDER_SUCCESS;
  payload: null;
}

export interface PatchProductionOrderError {
  type: typeof PATCH_PRODUCTION_ORDER_ERROR;
  payload: boolean | string;
}

export interface PatchProductionOrderReset {
  type: typeof PATCH_PRODUCTION_ORDER_RESET;
  payload: null;
}

export interface SetProductionOrderReaded {
  type: typeof SET_PRODUCTION_ORDER_READED;
  payload: null;
}

export interface SetProductionOrderReadedSuccess {
  type: typeof SET_PRODUCTION_ORDER_READED_SUCCESS;
  payload: null;
}

export interface SetProductionOrderReadedError {
  type: typeof SET_PRODUCTION_ORDER_READED_ERROR;
  payload: boolean | string;
}

export interface State {
  productionOrderAll: {
    data: ProductionOrderDto[] | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  productionOrder: {
    data: ProductionOrderDto | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  updateOrder: {
    data: ProductionOrderDto[] | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  deleteProductionOrder: {
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
}

export const initialState: State = {
  productionOrderAll: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  productionOrder: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  updateOrder: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  deleteProductionOrder: {
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
};

export type Actions =
  | ResetUpdateProductionOrder
  | ResetDeleteProductionOrder
  | DeleteProductionOrder
  | DeleteProductionOrderSuccess
  | DeleteProductionOrderError
  | GetProductionOrderAll
  | GetProductionOrderAllSuccess
  | GetProductionOrderAllError
  | GetProductionOrderAllReset
  | GetProductionOrder
  | GetProductionOrderSuccess
  | GetProductionOrderError
  | GetProductionOrderReset
  | PatchProductionOrder
  | PatchProductionOrderSuccess
  | PatchProductionOrderError
  | PatchProductionOrderReset
  | SetProductionOrderReaded
  | SetProductionOrderReadedSuccess
  | SetProductionOrderReadedError
  | ProductionOrderUpdateCurrent;
