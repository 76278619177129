/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { Col, Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';

// Mixins
import { CardBodyCustom, Heading, Title } from '../mixins';

// Swtich
import { ToggleSwitch } from 'react-dragswitch';
import 'react-dragswitch/dist/index.css';

// Select
import Select from 'react-select';
import { checkDataPermission } from '../../../components/CanRBac';

const currencies = [
  { value: 'pesos', label: 'Pesos' },
  { value: 'dollars', label: 'Dolares' },
];

// StyleSelect
const styleSelect = {
  control: (base) => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
    },
  }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#007bff' : 'transparent',
      color: isFocused ? '#fff' : '#000',
      cursor: isFocused ? 'pointer' : 'default',
    };
  },
};

const BillingForm = ({
  currentUser,
  isEditable,
  handleChange,
  seller,
  sellersData,
  currency,
  invoiced,
  purchaseCondition,
  orderPurchaseCondition,
  purchaseConditions,
  orderNumber,
}) => {
  const [checked, setChecked] = useState(false);
  const purchaseConditionsData = purchaseConditions?.data?.map((condition) => ({
    label: condition.code ? `${condition.code} - ${condition.description}` : `${condition.description}`,
    value: {
      // eslint-disable-next-line no-underscore-dangle
      _id: condition._id,
      code: condition.code,
      description: condition.description,
    },
  }));

  return (
    <Col lg="6">
      <Card>
        <Heading>
          <FontAwesomeIcon icon={faFileInvoice} />
          <Title>Facturación</Title>
        </Heading>

        <CardBodyCustom>
          <div className="mt-2 infoCard">
            <div className="item-group dmx-underline">
              <div className="item-label">Vendió:</div>
              <div className="item-value">
                <Select
                  isDisabled={checkDataPermission(currentUser?.userType, 'np:edit:billing:seller') ? !isEditable : true}
                  className="font-weight-bold"
                  onChange={(options) => handleChange('seller', options.seller)}
                  defaultValue={{ label: seller.name }}
                  maxMenuHeight={180}
                  options={sellersData}
                  styles={styleSelect}
                />
              </div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">Moneda:</div>
              <div className="item-value">
                <Select
                  isDisabled={
                    checkDataPermission(currentUser?.userType, 'np:edit:billing:currency') ? !isEditable : true
                  }
                  onChange={(e) => handleChange('currency', e.value)}
                  className="font-weight-bold"
                  defaultValue={currencies.find((item) => item.value === currency)}
                  options={currencies}
                  styles={styleSelect}
                />
              </div>
            </div>

            {/* <div className="mb-1 dmx-underline">
              <span className="text-muted">Forma de pago: </span>
              <input
                className="font-weight-bold p-2"
                name="orderPaymentType"
                value={orderPaymentType}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                disabled={!isEditable}
              />
            </div> */}

            <div className="item-group dmx-underline ">
              <div className="item-label">Condición de venta:</div>
              <div className="item-value">
                <Select
                  isDisabled={
                    checkDataPermission(currentUser?.userType, 'np:edit:billing:orderPurchaseCondition')
                      ? !isEditable
                      : true
                  }
                  onChange={(e) => handleChange('orderPurchaseCondition', e.label)}
                  className="font-weight-bold"
                  maxMenuHeight={90}
                  defaultValue={purchaseConditionsData.find(
                    (item) =>
                      item.label === (orderPurchaseCondition != null ? orderPurchaseCondition : purchaseCondition),
                  )}
                  options={purchaseConditionsData}
                  styles={styleSelect}
                />
              </div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">N° de Orden de Compra:</div>
              <div className="item-value">
                <input
                  disabled={
                    checkDataPermission(currentUser?.userType, 'np:edit:billing:orderNumber') ? !isEditable : true
                  }
                  className="font-weight-bold p-2"
                  name="orderNumber"
                  maxLength="20"
                  maxMenuHeight={90}
                  value={orderNumber}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
            </div>

            {/* <div className="mb-1 form-check">
              <label className="form-check-label pt-3" htmlFor="invoiced">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="invoiced"
                  name="invoiced"
                  disabled={!isEditable}
                  checked={invoiced || false}
                  onChange={(e) => handleChange('invoiced', e.target.checked)}
                />
                Excel
              </label>
            </div> */}

            <div className="item-group dmx-underline">
              <div className="item-label">Excel:</div>
              <div className="item-value">
                <ToggleSwitch
                  disabled={checkDataPermission(currentUser?.userType, 'np:edit:billing:invoiced') ? !isEditable : true}
                  id="invoiced"
                  onColor="blue"
                  checked={invoiced || checked}
                  onChange={(e) => {
                    setChecked(e);
                    handleChange('invoiced', e);
                  }}
                />
              </div>
            </div>
          </div>
        </CardBodyCustom>
      </Card>
    </Col>
  );
};

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(BillingForm);
