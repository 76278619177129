import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import ObsForm from '../ObsForm';

// Style
import './Modal.scss';

const ProductObservationModal = ({
  isOpen,
  isEditable,
  title,
  toggle,
  observationIndex,
  observations,
  handleProductChange,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="dmx-modal-width product-observation-modal">
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon={faCommentDots} /> {title}
      </ModalHeader>
      <ModalBody>
        <ObsForm
          handleProductChange={handleProductChange}
          observations={observations}
          observationIndex={observationIndex}
          isEditable={isEditable}
        />
      </ModalBody>
    </Modal>
  );
};

export default ProductObservationModal;
