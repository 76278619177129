import Barcode from 'react-barcode';
import { Badge } from 'reactstrap';

import './BarcodeQuickSearch.scss';

function Production({ data }) {
  const { barcode, latestUpdate, name, quantity, status } = data;
  const stateColors = {
    Solicitada: { backgroundColor: '#845EC2', color: '#F9F871' },
    Corte: { backgroundColor: '#4E8397', color: '#FFC75F' },
    'En Tránsito': { backgroundColor: '#FF6F91', color: '#FBEAFF' },
    Manufactura: { backgroundColor: '#C34A36', color: '#D5CABD' },
    Deposito: { backgroundColor: '#FFC75F', color: '#845EC2' },
  };

  return (
    <>
      <div className="text-center">
        <Barcode width={2} height={50} value={barcode} format={'CODE128'} />
      </div>
      <div className="container">
        <div className="row">
          <div className="col text-left">
            <h5>Última actualización:</h5>
            <h5>Producto:</h5>
            <h5>Unidades:</h5>
            <h5>Estado:</h5>
          </div>
          <div className="col text-left">
            <h5>{latestUpdate || '-'}</h5>
            <h5>{name || '-'}</h5>
            <h5>{quantity || '-'}</h5>
            <h5>
              <Badge style={stateColors[status]}>{status}</Badge>
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default Production;
