import { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowRight, faArrowLeft, faEye, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { ToggleSwitch } from 'react-dragswitch';
import Select from 'react-select';

import NewWorkshopModal from '../New';
import EditWorkshopModal from '../Edit';

import Input from '../../../components/Form/Input';
import Button from '../../../components/Button'
import SpinnerButton from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import { WorkshopDto, WorkshopOrderDto } from '../../../types/workshop';
import { getAllWorkshops, resetNewWorkshop } from '../../../store/actions/workshop';
import { hideModal, showModal } from '../../../store/actions/modal';
import useProductCategoriesFetcher from '../../../hooks/useProductCategoriesFetcher';
import { toast, ToastContainer } from 'react-toastify';
import CanRBac from '../../../components/CanRBac';
import { User } from '../../../types/user';

const customStyles = {
  headCells: {
    style: {
      paddingLeft: '4px',
      paddingRight: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: '4px',
      paddingRight: '4px',
    },
  },
};

const bagStates = [
  {
    label: 'Entregas',
    value: 'deposit',
  },
  {
    label: 'Retiros',
    value: 'takeAway',
  },
];

const workshopOrderType = {
  deposit: { icon: faArrowRight, color: 'green' },
  takeAway: { icon: faArrowLeft, color: 'red' },
};

const columns = [
  {
    name: '#',
    cell: (row, index) => index + 1,
    sortable: false,
    grow: 0,
  },
  {
    name: 'Nombre del taller',
    selector: 'name',
    sortable: false,
    grow: 4,
  },
  {
    name: 'Domicilio',
    selector: 'address',
    sortable: false,
    grow: 4,
  },
  {
    name: 'Teléfono',
    selector: 'phone',
    sortable: false,
    grow: 4,
  },
  {
    name: 'Flete',
    selector: 'freight',
    sortable: false,
    grow: 4,
  },
  {
    name: 'Periodo de entrega',
    selector: 'deliveryPeriod',
    sortable: false,
    grow: 4,
  },
];

interface Filters {
  [key: string]: string | number;
}

interface SelectOption {
  value: string;
  label: string;
}

interface ComponentProps {
  workshops: WorkshopDto[];
  workshopsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  currentUser: User
}

const Component = ({ workshops, workshopsStates, currentUser }: ComponentProps) => {
  const initialFilters = { state: 'active' };
  const [filtersSelected, setFiltersSelected] = useState<Filters>({});
  const [workshopsItems, setWorkshopsItems] = useState<SelectOption[]>([]);
  const productCategories = useProductCategoriesFetcher();
  const history = useHistory();
  const dispatch = useDispatch();

  const setFilters = async () => {
    dispatch(getAllWorkshops(filtersSelected));
  };

  const setInitialFilters = () => {
    const filtersSaved = window.localStorage.getItem('workshopsFilters');

    if (!!filtersSaved && filtersSaved.length > 0) {
      setFiltersSelected(JSON.parse(filtersSaved));
    } else {
      setFiltersSelected(initialFilters);
    }
  };

  const productsCategories = useMemo(() => {
    return productCategories?.data?.map((productCategory: { name: string, _id: string }) => {
      return {
        label: productCategory.name,
        value: productCategory._id
      }
    })
  }, [productCategories])

  const handleRowClicked = (row) => {
    history.push(`/workshops/${row._id}`);
  };

  useEffect(() => {
    dispatch(getAllWorkshops(initialFilters));
    setInitialFilters();
  }, []);

  useEffect(() => {
    if (!!filtersSelected && Object.keys(filtersSelected).length > 0) {
      window.localStorage.setItem('workshopsFilters', JSON.stringify(filtersSelected));

      setFilters();
    }
  }, [filtersSelected]);

  useEffect(() => {
    if (!!workshops) {
      setWorkshopsItems([...workshops?.map((workshop) => ({ label: workshop.name, value: workshop._id }))]);
    }
  }, [workshops]);


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card dmx-card-mt shadow-sm m-b-15 p-15">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-2">
                    {!workshops ? (
                      <Spinner size={'small'} />
                    ) : (
                      <Input
                        name="name"
                        placeholder="Nombre del taller"
                        value={filtersSelected['name']}
                        onChange={(event) => {
                          if (!!event?.target?.value) {
                            setFiltersSelected({ ...filtersSelected, name: event?.target?.value });
                          } else {
                            delete filtersSelected['name'];
                            setFiltersSelected({ ...initialFilters, ...filtersSelected });
                          }
                        }}
                        style={{ margin: 0 }}
                      />
                    )}
                  </div>

                  <div className="col-md-3">
                    {!workshops ? (
                      <Spinner size={'small'} />
                    ) : (
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Categorías de productos"
                        options={productsCategories}
                        isClearable={true}
                        value={productsCategories.filter((productCategory) => {
                          return productCategory.value === filtersSelected['productCategory'];
                        })}
                        onChange={(option) => {
                          if (!!option?.value) {
                            setFiltersSelected({ ...filtersSelected, productCategory: option?.value });
                          } else {
                            delete filtersSelected['productCategory'];
                            setFiltersSelected({ ...initialFilters, ...filtersSelected });
                          }
                        }}
                      />
                    )}
                  </div>

                  <div className="col-sm-2">
                    {!workshops ? (
                      <Spinner size={'small'} />
                    ) : (
                      <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',
                        gap: '10px'
                      }}>
                        <ToggleSwitch
                          checked={filtersSelected.state === 'inactive'}
                          onColor="blue"
                          onChange={(e) => {
                            if (e) {
                              setFiltersSelected({ ...filtersSelected, state: 'inactive' });
                            } else {
                              delete filtersSelected['state'];
                              setFiltersSelected({ ...initialFilters, ...filtersSelected });
                            }
                          }}
                        />

                        <span>Mostrar inactivos</span>
                      </div>
                    )}
                  </div>

                  <div className="col-sm-5">
                    <div className="d-flex flex-row-reverse">
                      <CanRBac
                        role={currentUser?.userType}
                        perform='ws:create'
                        yes={() => {
                          return (
                            <Button type="button" color="success" onClick={() => dispatch(showModal('modal-new-workshop'))}>
                              <FontAwesomeIcon icon={faPlus} /> Nuevo Taller
                            </Button>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card shadow-sm">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    {!workshops || workshopsStates.loading ? (
                      <Spinner size={'large'} />
                    ) : (
                      <div className="data-table-container">
                        <DataTable
                          data={workshops}
                          columns={columns}
                          //pagination
                          striped
                          paginationServer
                          responsive
                          persistTableHead
                          highlightOnHover
                          //paginationComponentOptions={paginationText}
                          customStyles={customStyles}
                          // center
                          // paginationDefaultPage={page}
                          // paginationTotalRows={orderNotes.pagination.totalDocs}
                          // paginationPerPage={itemsForPage}
                          // onChangePage={handleChangePage}
                          // onChangeRowsPerPage={handleChangeItemsPerPege}
                          // clearSelectedRows={toggleCleared}
                          noDataComponent={<div className="dmx-empty-table">No hay registros para mostrar.</div>}
                          onRowClicked={handleRowClicked}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NewWorkshopModal />

      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

const states = ({ workshopStore, userStore }) => {
  const { data: workshops, states: workshopsStates } = workshopStore.workshops;
  const { data: currentUser } = userStore.information;

  return {
    workshops,
    workshopsStates,
    currentUser
  };
};

export default connect(states)(Component);
