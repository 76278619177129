import DataTable from 'react-data-table-component';
import TableEmptyState from '../../../../../../../components/TableEmptyState';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import NewBagsIn from '../NewBagsIn';
import Spinner from '../../../../../../../components/Spinner';
import { noRecords } from '../../../../../../../constant';
import Select from 'react-select';
import { customStyles } from './settings';
import qrCode from '../../../../../../assets/images/icons/qr-code.png';
// components
import PrintLabel from '../PrintLabel';
import BulkActions from '../BulkActions';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faArrowRight, faArrowLeft, faCommentDots, faEdit } from '@fortawesome/free-solid-svg-icons';
import { connect, useDispatch } from 'react-redux';
import { ProductionOrderDto } from '../../../../../../../types/production-order';
import { useEffect, useMemo, useState } from 'react';
import {
  deleteProductionOrderBag,
  deleteProductionOrderBagReset,
  getProductionOrderBagAll,
  setSelectedBag,
  updateProductionOrderBag,
} from '../../../../../../../store/actions/production-order-bag';
import { ProductionOrderBag, WorkshopOrderBagDto } from '../../../../../../../types/production-order-bag';
import { getWorkshops } from '../../../../../../../store/actions/statics';
import { patchProductionOrder } from '../../../../../../../store/actions/production-order';
import bags from '../../../../../../../services/production-order-bags';
import { Button } from 'reactstrap';
import { checkDataPermission } from '../../../../../../../components/CanRBac';
import { User } from '../../../../../../../types/user';
import { showModal } from '../../../../../../../store/actions/modal';

// permisos
//import { checkDataPermission } from '../../../../components/CanRBac';

const states = [
  // { label: 'En transito', value: 'inTransit' },
  // { label: 'En taller', value: 'inWorkshop' },
  { label: 'En depósito', value: 'inDeposit' },
];

interface ComponentProps {
  currentUser: User;
  workshops;
  productionOrder: ProductionOrderDto;
  allProductionOrderBags: WorkshopOrderBagDto[];
  allProductionOrderBagsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  updateProductionOrderBagStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  createProductionOrderBagStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  deleteProductionOrderBagStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

interface HandleChangeProps {
  data: ProductionOrderBag;
  name: string;
  value: string | number;
  index: number;
}

const Component = ({
  currentUser,
  workshops,
  productionOrder,
  allProductionOrderBags,
  allProductionOrderBagsStates,
  updateProductionOrderBagStates,
  createProductionOrderBagStates,
  deleteProductionOrderBagStates,
}: ComponentProps) => {
  const [allWorkshops, setAllWorkshops] = useState<{ label: string; value: string }[]>([]);
  const [selectedBags, setSelectedBags] = useState<WorkshopOrderBagDto[]>([]);
  const [updatedField, setUpdatedField] = useState<HandleChangeProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  var t;

  const keyDown = (callback) => {
    if (t) {
      clearTimeout(t);
      t = setTimeout(callback, 1000);
    } else {
      t = setTimeout(callback, 1000);
    }
  };

  const handleChange = ({ data, name, value, index }: HandleChangeProps) => {
    dispatch(updateProductionOrderBag({ ...data, [name]: value }));
  };

  const handleDelete = (id, index) => {
    SweetAlert.fire({
      title: 'Eliminar Bolsón',
      html: '¿Estas seguro de eliminar el Bolsón? <br/><strong>ID: ' + id + '</strong>',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      allowEscapeKey: false,
      preConfirm: () => {
        dispatch(deleteProductionOrderBag(id));
      },
    });
  };

  const actionButtons = (row, elementIndex) => {
    const addComment = (
      <div
        id="add-observation"
        className="text-secondary p-1 dmx-action-button persist-btn"
        data-toggle="tooltip"
        data-placement="top"
        title="Agregar una observación"
        style={{ position: 'relative' }}
      >
        <FontAwesomeIcon
          title="Agregar una observacion"
          aria-hidden="true"
          icon={faCommentDots}
          onClick={() => {
            dispatch(showModal('modal-observaciones'));
            dispatch(setSelectedBag(row));
          }}
        />
        {row.observations && (
          <div
            style={{
              width: '7px',
              height: '7px',
              backgroundColor: '#4366f1',
              outline: '1px solid white',
              borderRadius: '100%',
              position: 'absolute',
              top: '3px',
              right: '2px',
            }}
          />
        )}
      </div>
    );

    const allWorkshops = workshops?.data?.map((workshop) => ({ label: workshop?.name, value: workshop?._id }));

    const deleteButton = (
      <div
        id="delete-bag"
        className="text-danger p-1 dmx-action-button"
        data-toggle="tooltip"
        data-placement="top"
        title="Eliminar bolsón"
      >
        <FontAwesomeIcon icon={faTimesCircle} onClick={() => handleDelete(row?._id, elementIndex)} />
      </div>
    );

    const barcodeButton = (
      <div
        id="barcode-bag"
        className="text-secondary p-1 dmx-action-button"
        data-toggle="tooltip"
        data-placement="top"
        title="Código QR"
        style={{ cursor: 'pointer' }}
      >
        <PrintLabel
          data={{
            QualityBatch: productionOrder?.qualityBatch,
            ExpirationDate: productionOrder?.expirationDate,
            Type: productionOrder?.type,
            PoBatchId: productionOrder?._id,
            PoBatchCode: productionOrder?.code,
            PoBagId: row?._id,
            ProductCode: row?.code,
            units: row?.units,
            workshop: allWorkshops.filter((workshop) => workshop?.value === row?.workshop?._id)[0]?.label,
            productName: productionOrder?.product.name,
            date: row?.date,
          }}
          index={elementIndex}
        />
      </div>
    );

    return (
      <>
        {addComment}
        {barcodeButton}
        {deleteButton}
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: 'ID',
        sortable: false,
        center: false,
        grow: 1,
        cell: (row, _index) => {
          return (
            <input type="string" className="form-control dmx-input w-100" id="id" value={row._id} disabled readOnly />
          );
        },
      },
      {
        name: 'Fecha',
        selector: 'date',
        sortable: false,
        center: false,
        grow: 2,
        cell: (row, index) => {
          return (
            <div className="subrow-datepicker">
              <input
                type="date"
                className="form-control dmx-input w-100"
                id="date"
                name="date"
                value={row?.date ? row?.date?.slice(0, 10) : ''}
                disabled={isLoading}
                onChange={(event) =>
                  setUpdatedField({
                    data: row,
                    name: event?.target?.name,
                    value: event?.target?.value.slice(0, 10),
                    index,
                  })
                }
              />
            </div>
          );
        },
      },
      {
        name: 'Unidades',
        sortable: false,
        center: false,
        grow: 1,
        cell: (row, index) => (
          <input
            type="number"
            min="1"
            className="form-control dmx-input w-100"
            name="units"
            defaultValue={row.units || 1}
            disabled
            readOnly
            onWheel={(event) => event?.currentTarget?.blur()}
            onKeyUp={(event) => {
              const value = event?.currentTarget?.value;
              const name = event?.currentTarget?.name;

              const setFieldValue = () => {
                if (!!value) {
                  if (Number(value) > 0) {
                    const total =
                      allProductionOrderBags
                        ?.filter((bag) => bag._id !== row._id)
                        .map((bag) => bag.units)
                        .reduce((a, b) => a + Number(b), 0) + Number(value);

                    if (total <= productionOrder.quantity) {
                      setUpdatedField({
                        data: row,
                        name,
                        value,
                        index,
                      });
                    } else {
                      event.currentTarget.value = row.units || 1;
                      toast.info(`Las unidades ingresadas superan al total de unidades a frabricar.`, {
                        autoClose: 3000,
                      });
                    }
                  } else {
                    toast.error('Las unidades deben ser mayor a 0 (cero)', { autoClose: 3000 });
                  }
                }
              };

              if (event?.key === 'Backspace') {
                keyDown(setFieldValue);
              } else {
                setFieldValue();
              }
            }}
          />
        ),
      },
      {
        name: 'Estado',
        sortable: false,
        center: false,
        grow: 2,
        cell: (row, index) => {
          return (
            <Select
              menuPosition="fixed"
              className="basic-multi-select w-100"
              placeholder="Estado"
              defaultValue={states.filter((state) => state?.value === row.status)}
              options={states}
              name="status"
              disabled={isLoading}
              onChange={(option) => setUpdatedField({ data: row, name: 'status', value: option?.value, index })}
            />
          );
        },
      },
      {
        name: 'Taller',
        sortable: false,
        center: false,
        grow: 2,
        cell: (row, index) => {
          if (!workshops?.data) {
            return null;
          }

          const workshopsInBagsOut = allProductionOrderBags
            .filter((productionOrder) => productionOrder?.status !== 'inDeposit')
            .map((productionOrder) => productionOrder?.workshop?._id);

          const allWorkshops = workshops?.data
            .filter((workshop) => workshopsInBagsOut?.includes(workshop?._id))
            .map((workshop) => ({ label: workshop?.name, value: workshop?._id }));
          const defaultValue = allWorkshops?.filter((workshop) => workshop?.value === row?.workshop?._id);

          return (
            <Select
              menuPosition="fixed"
              className="basic-multi-select w-100"
              placeholder="Taller"
              defaultValue={defaultValue}
              options={allWorkshops}
              name="workshop"
              disabled={isLoading}
              onChange={(option) => setUpdatedField({ data: row, name: 'workshop', value: option?.value, index })}
            />
          );
        },
      },
    ],
    [allProductionOrderBags, allWorkshops, workshops, isLoading],
  );

  columns.push({
    name: '',
    sortable: false,
    center: false,
    grow: 1,
    cell: (row, index) => actionButtons(row, index),
  });

  useEffect(() => {
    setIsLoading(
      allProductionOrderBagsStates.loading ||
      updateProductionOrderBagStates.loading ||
      createProductionOrderBagStates.loading ||
      deleteProductionOrderBagStates.loading,
    );
  }, [
    allProductionOrderBagsStates,
    updateProductionOrderBagStates,
    createProductionOrderBagStates,
    deleteProductionOrderBagStates,
  ]);

  useEffect(() => {
    if (deleteProductionOrderBagStates.success) {
      toast.success('El bolsón se elimino correctamente', { autoClose: 3000 });
      setTimeout(() => {
        dispatch(deleteProductionOrderBagReset());
      }, 3500);
    }

    if (deleteProductionOrderBagStates.error) {
      toast.error('Error al eliminar el bolsón.', { autoClose: 3000 });
      setTimeout(() => {
        dispatch(deleteProductionOrderBagReset());
      }, 3500);
    }
  }, [deleteProductionOrderBagStates]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (updatedField?.name === 'status') {
        // const bagsOutOfDeposit = allProductionOrderBags
        //   .map((bag) => {
        //     if (bag._id === updatedField.data._id) {
        //       return { ...bags, status: updatedField.value };
        //     }

        //     return bag;
        //   })
        //   .filter((bag) => bag.status !== 'inDeposit');

        // if (bagsOutOfDeposit.length <= 0) {
        //   dispatch(patchProductionOrder({ ...productionOrder, id: productionOrder._id, state: 'done' }));
        // }

        updatedField && handleChange(updatedField);
      } else {
        updatedField && handleChange(updatedField);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [updatedField]);

  useEffect(() => {
    !workshops && dispatch(getWorkshops());
  }, []);

  useEffect(() => {
    const workshopsInBagsOut = allProductionOrderBags
      .filter((productionOrder) => productionOrder?.status !== 'inDeposit')
      .map((productionOrder) => productionOrder?.workshop);

    setAllWorkshops(
      workshops?.data
        .filter((workshop) => workshopsInBagsOut?.includes(workshop._id))
        .map((workshop) => ({ label: workshop?.name, value: workshop?._id })),
    );
  }, [workshops]);

  if (!allProductionOrderBags || !workshops) {
    return null;
  }

  return (
    <>
      <BulkActions setSelectedBags={setSelectedBags} selectedBags={selectedBags} workshops={allWorkshops} />

      <div style={{ height: '15px' }} />

      <DataTable
        disabled={
          isLoading || !checkDataPermission(currentUser.userType, 'op:edit:bags', { status: productionOrder.state })
        }
        customStyles={customStyles}
        columns={columns}
        data={allProductionOrderBags.filter((productionOrder) => productionOrder.status === 'inDeposit')}
        noDataComponent={<TableEmptyState>No hay bolsones en deposito.</TableEmptyState>}
        onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => setSelectedBags(selectedRows)}
        selectableRows
      />

      {isLoading && (
        <Spinner style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />
      )}

      {productionOrder?.state === 'inProgress' &&
        checkDataPermission(currentUser.userType, 'op:edit:bags', { status: productionOrder.state }) && (
          <NewBagsIn states={states} />
        )}
    </>
  );
};

const state = ({ productionOrderStore, productionOrderBagStore, staticsStore, userStore }) => {
  const { data: currentUser } = userStore.information;
  const { data: workshops } = staticsStore.workshops;
  const { data: productionOrder } = productionOrderStore.productionOrder;
  const { data: allProductionOrderBags, states: allProductionOrderBagsStates } =
    productionOrderBagStore.productionOrderBagAll;
  const { data: updateProductionOrderBag, states: updateProductionOrderBagStates } =
    productionOrderBagStore.updateProductionOrderBag;
  const { data: createProductionOrderBag, states: createProductionOrderBagStates } =
    productionOrderBagStore.createProductionOrderBag;
  const { data: deleteProductionOrderBag, states: deleteProductionOrderBagStates } =
    productionOrderBagStore.deleteProductionOrderBag;

  return {
    currentUser,
    workshops,
    productionOrder,
    allProductionOrderBags,
    allProductionOrderBagsStates,
    updateProductionOrderBag,
    updateProductionOrderBagStates,
    createProductionOrderBag,
    createProductionOrderBagStates,
    deleteProductionOrderBag,
    deleteProductionOrderBagStates,
  };
};

export default connect(state)(Component);
