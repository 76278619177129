import { Button, ButtonProps } from 'reactstrap';
import Spinner from '../Spinner';


interface ComponentProps extends ButtonProps {
  isLoading?: boolean
}

const Component = ({ isLoading, children, ...rest }: ComponentProps) => {
  return (
    <Button color="primary" disabled={isLoading || rest.disabled} {...rest}>
      {isLoading ? <Spinner size="small" /> : children}
    </Button>
  );
}

export default Component;
