import { faPhoneAlt, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Footer } from './styles';
const Component = () => {
  return (
    <Footer>
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td className="text-center">
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Tronador 418, (CP C1427CRJ) Ciudad Autónoma De Buenos Aires
              </td>
            </tr>
            <tr>
              <td className="text-center">
                <FontAwesomeIcon icon={faPhoneAlt} /> +54 (11) 4553-9898/3711
              </td>
            </tr>
            <tr>
              <td className="text-center">
                <FontAwesomeIcon icon={faEnvelope} /> deposito@dimex.com.ar
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Footer>
  );
};

export default Component;
