import { Formik } from 'formik';
import slugify from 'slugify';
import * as Yup from 'yup';
import React from 'react';
import './styles.scss';

interface ComponentProps {
  children;
  id;
  rules?;
  values?;
  onSubmit;
  validateOnChange?;
  validateOnBlur?;
}

const Component = ({
  children,
  onSubmit,
  rules = Yup.object().shape({}),
  id,
  values: initValues = {},
  ...rest
}: ComponentProps) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      onSubmit={(values, actions) => {
        if (!!onSubmit) onSubmit({ values, actions });
      }}
      validationSchema={rules}
      {...rest}
    >
      {({ touched, errors, values, setFieldValue, handleChange, handleBlur, handleReset, handleSubmit }) => {
        return (
          <form className="theme-form" onReset={handleReset} onSubmit={handleSubmit} id={id}>
            {React.Children.map(children, (child) => {
              if (!child) return null;
              const { name } = child.props;

              return !!name
                ? React.createElement(child.type, {
                    ...{
                      ...child.props,
                      value: values[name],
                      error: errors[name],
                      touched: touched[name],
                      key: slugify(name),
                      onChange: (event) => {
                        handleChange(event);
                        !!child.props.onChange && child.props.onChange(event);
                      },
                      onBlur: handleBlur,
                      setFieldValue: setFieldValue,
                    },
                  })
                : child;
            })}
          </form>
        );
      }}
    </Formik>
  );
};

export default Component;
