import styled from 'styled-components'
import { ModalHeader } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SpinnerButton from '../../../../components/Button'

export const ModalHeaderCustom = styled(ModalHeader)`
  & > h5 {
    font-weight: 600;
  }
`

export const InputGroup = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0px;
  }
`

export const LabelCustom = styled('label')`
  font-size: 16px;
  font-weight: 600;
`

export const InputCustom = styled('input')`
  padding: 0px 15px;
  line-height: 32px;
  border-radius: 8px;
  border: 1px solid #999;
`

export const SpinnerButtonCustom = styled(SpinnerButton)`
  margin: 30px auto 30px;
`

export const Icon = styled(FontAwesomeIcon)`
  margin-right: 15px;
`

