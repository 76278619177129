import Header from '../common/header';
import Footer from '../common/footer';

const Component = ({ children }) => (
  <div className="page-wrapper">
    <div className="page-body-wrapper">
      <Header />
      <div className="page-body">{children}</div>
      <Footer />
    </div>
  </div>
);

export default Component;
