import { User } from '../../types/user';

export const RESET_USER_PROFILE = 'RESET_USER_PROFILE';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR';
export const RESET_UPDATE_USER_PROFILE = 'RESET_UPDATE_USER_PROFILE';

export interface GetUserProfile {
  type: typeof GET_USER_PROFILE;
  payload: null;
}

export interface GetUserProfileSuccess {
  type: typeof GET_USER_PROFILE_SUCCESS;
  payload: User;
}

export interface GetUserProfileError {
  type: typeof GET_USER_PROFILE_ERROR;
  payload: boolean | string;
}

export interface UpdateUserProfile {
  type: typeof UPDATE_USER_PROFILE;
  payload: null;
}

export interface UpdateUserProfileSuccess {
  type: typeof UPDATE_USER_PROFILE_SUCCESS;
  payload: User;
}

export interface UpdateUserProfileError {
  type: typeof UPDATE_USER_PROFILE_ERROR;
  payload: boolean | string;
}

export interface ResetUserProfile {
  type: typeof RESET_USER_PROFILE;
  payload: null;
}

export interface ResetUpdateUserProfile {
  type: typeof RESET_UPDATE_USER_PROFILE;
  payload: null;
}

export interface State {
  information: {
    data: User | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateInformation: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  information: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateInformation: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetUserProfile
  | GetUserProfileSuccess
  | GetUserProfileError
  | UpdateUserProfile
  | UpdateUserProfileSuccess
  | UpdateUserProfileError
  | ResetUpdateUserProfile
  | ResetUserProfile;
