import Message from './Message';
import Feedstock from './Feedstock';
import Production from './Production';
import Spinner from '../Spinner';

import './BarcodeQuickSearch.scss';

function SearchResult({ data, isSearching }) {
  if (isSearching) {
    return (
      <>
        <div className="text-center">
          <Spinner size="medium" />
          <Message message={'Buscando...'} />
        </div>
      </>
    );
  }

  if (data === null || data === undefined) {
    return <Message message={'No hay registros con ese código de barras'} />;
  }

  if (data.origin === 'FEEDSTOCK') {
    return <Feedstock data={data} />;
  }

  if (data.origin === 'PRODUCTION') {
    return <Production data={data} />;
  }
}

export default SearchResult;
