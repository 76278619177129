import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';

// types
import { User } from '../../../../../../../../../types/user';
import { ProductionOrderDto } from '../../../../../../../../../types/production-order';

// store
import { patchProductionOrder } from '../../../../../../../../../store/actions/production-order';
import { toast, ToastContainer } from 'react-toastify';
import { checkDataPermission } from '../../../../../../../../../components/CanRBac';

const createBatchLabel = (batch) => {
  const { batchId } = batch;
  return `${batchId.charAt(0).toUpperCase() + batchId.slice(1)}`;
};

interface ComponentProps {
  currentUser: User;
  productionOrder: ProductionOrderDto;
  updateOrderStates: any;
  updateOrderData: any;
}

const Component = ({ productionOrder, updateOrderStates, updateOrderData, currentUser }: ComponentProps) => {
  const dispatch = useDispatch();

  const [selectedBatches, setSelectedBatches] = useState<{ label: string; value: string }[]>([]);

  const [options, setOptions] = useState<{ label: string; value: string }[]>();

  const saveSelectedBatches = () => {
    const batchsSelectedIds = selectedBatches?.map((bactchSelected) => bactchSelected?.value);
    const id = productionOrder?._id;
    dispatch(patchProductionOrder({ ...productionOrder, batch: batchsSelectedIds, id }));
  };

  useEffect(() => {
    setSelectedBatches(
      productionOrder?.batch?.map((batch) => ({ label: createBatchLabel(batch), value: batch?._id, data: batch })) ||
        [],
    );

    if (productionOrder?.feedstock && productionOrder?.feedstock?.map((item) => item?.stockControl?.length > 0)) {
      // filtramos por feedstock que tengan stockControl
      const feedstockWithStockcontrol = productionOrder?.feedstock?.filter(
        (feedstock) => feedstock?.stockControl?.length > 0,
      );

      // filtramos por stockControl que tengan movementsDetails
      const stockcontrolWithMovementsDetail = feedstockWithStockcontrol
        .map((item) => item?.stockControl?.filter((stockcontrol) => stockcontrol?.movementsDetail?.length > 0))
        .flat();

      // filtramos por movementsDetails que tengan mp meters disponibles
      const movementsDetailWithMpMeters = stockcontrolWithMovementsDetail
        .map((item) => item)
        .filter((item) => item?.mpMeters > 0);

      setOptions(
        movementsDetailWithMpMeters?.map((batch) => ({
          label: createBatchLabel(batch),
          value: batch?._id,
        })),
      );
    }
  }, [productionOrder]);

  useEffect(() => {
    if (updateOrderStates?.success) {
      if (updateOrderData?.feedstock && updateOrderData?.feedstock?.map((item) => item?.stockControl?.length > 0)) {
        // filtramos por feedstock que tengan stockControl
        const feedstockWithStockcontrol = updateOrderData.feedstock?.filter(
          (feedstock) => feedstock?.stockControl?.length > 0,
        );

        // filtramos por stockControl que tengan movementsDetails
        const stockcontrolWithMovementsDetail = feedstockWithStockcontrol
          .map((item) => item?.stockControl?.filter((stockcontrol) => stockcontrol?.movementsDetail.length > 0))
          .flat();

        // filtramos por movementsDetails que tengan mp meters disponibles
        const movementsDetailWithMpMeters = stockcontrolWithMovementsDetail
          .map((item) => item)
          .filter((item) => item?.mpMeters > 0);

        setOptions(
          movementsDetailWithMpMeters?.map((batch) => ({
            label: createBatchLabel(batch),
            value: batch?._id,
          })),
        );
      }
    }
  }, [updateOrderStates?.success]);

  return (
    <div className="mb-3">
      <Row>
        <Col md="9" sm="12">
          <Select
            className="w-100"
            menuPortalTarget={document.body}
            menuPosition="fixed"
            value={selectedBatches}
            options={options}
            placeholder="Elija el lote"
            isDisabled={
              (currentUser.userType === 'productionAdmin' && productionOrder?.state === 'requested') || (currentUser.userType === 'productionHandler' && productionOrder?.state === 'requested') ||
              !checkDataPermission(currentUser.userType, 'op:edit:batch', { status: productionOrder?.state })
            }
            onChange={(value) => {
              const isDeletion = value.length < selectedBatches?.length;

              if (isDeletion) {
                const selectedIds = value.map((batch) => batch?.value);
                const removedId = selectedBatches?.filter((batch) => !selectedIds.includes(batch?.value))[0].value;
                const batchWithEgress = productionOrder?.batch?.filter((batch) => {
                  const movements = batch?.movementsDetail.filter(
                    (movement) => movement?.productionOrderId === productionOrder?._id,
                  );

                  return movements?.length > 0 && batch?._id === removedId;
                });

                // Comprobamos que el/los batches eliminados no posean Egresos de materia prima.
                // En caso de contar con egresos se evita su eliminacion.

                // const batchWithEgress = allProductionOrderEgress.filter(egress => egress.);
                if (batchWithEgress?.length <= 0) {
                  setSelectedBatches(value);
                } else {
                  toast.info('Debes eliminar los egresos para eliminar el lote.', { autoClose: 3000 });
                }
              } else {
                setSelectedBatches(value);
              }
            }}
            isMulti
          />
        </Col>

        <Col md="3" sm="12">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              saveSelectedBatches();
            }}
            disabled={
              (currentUser.userType === 'productionAdmin' && productionOrder?.state === 'requested') ||
              !checkDataPermission(currentUser.userType, 'op:edit:batch', { status: productionOrder?.state })
            }
          >
            Confirmar
          </button>
        </Col>
      </Row>
    </div>
  );
};

const state = ({ productionOrderStore, userStore }) => {
  const { data: currentUser } = userStore.information;
  const { data: productionOrder } = productionOrderStore.productionOrder;
  const { data: updateOrderData } = productionOrderStore.updateOrder;
  const { states: updateOrderStates } = productionOrderStore.updateOrder;

  return {
    currentUser,
    productionOrder,
    updateOrderData,
    updateOrderStates,
  };
};

export default connect(state)(Component);
