/* eslint-disable */
import React, { useState, useEffect } from 'react';

// Third Party Imports
import { v4 as uuidv4 } from 'uuid';
import _ from 'underscore';

// My Imports
import postFeedstock from '../../../services/postFeedstock';
import SelectGroup from '../../../components/SelectGroup';
import { rawMaterialAttributeLabel } from '../../../constant';
import SpinnerButton from '../../../components/Button';

// Mixins
import { SelectCustom, Label } from './mixins';

// Style
import './Modal.scss';

const RawMaterialForm = ({ data, toggle, updateDataTable, errorMsg, successMsg }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emptyFieldsExist, setEmptyFieldsExist] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);
  const [feedstock, setFeedstock] = useState({
    feedstockCategoryId: null,
    feedstockCategoryLabel: null,
    type: null,
    grammage: null,
    color: null,
    provider: null,
    creationDate: null,
    width: null,
    measures: null,
  });

  const options = data.map((option) => {
    return {
      attributes: {
        ...option.feedstockAttributesId,
      },
      id: option._id,
      label: option.name,
      value: option._id,
    };
  });

  /**
   * formatAttributes retorna el json de atributos en el formato json que necesito:
   * un array de objetos con clave name (tipo string) y value (tipo array).
   */
  const formatAttributes = (rawData) => {
    const entries = Object.entries(rawData.attributes).filter((items) => Array.isArray(items[1]));

    const formattedAttributes = entries.map((atrrItem) => {
      const selectValues = atrrItem[1].map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      return {
        id: uuidv4(),
        name: atrrItem[0],
        label: rawMaterialAttributeLabel[atrrItem[0]],
        values: selectValues,
      };
    });

    return formattedAttributes;
  };

  const cleanFeedstockState = () => {
    setFeedstock({
      feedstockCategoryId: null,
      feedstockCategoryLabel: null,
      type: null,
      grammage: null,
      color: null,
      provider: null,
      creationDate: null,
      width: null,
      measures: null,
    });
  };

  const handleCategoryChange = (categoryData) => {
    cleanFeedstockState();

    const newSelectedCat = formatAttributes(categoryData).filter((attribute) => attribute.values.length > 0);
    setSelectedCat(newSelectedCat);

    const newFeedstock = {
      ...feedstock,
      feedstockCategoryId: categoryData.value,
      feedstockCategoryLabel: categoryData.label,
      creationDate: new Date(Date.now()).toISOString(),
      originType: 'FEEDSTOCK',
    };

    setFeedstock(newFeedstock);

    setAttributes(newSelectedCat.map((attribute) => attribute.name));
  };

  const handleStockChange = (newStock) => {
    const newFeedstock = {
      ...feedstock,
      minimumAmount: newStock,
    };
    setFeedstock(newFeedstock);
  };

  const handleAttributeChange = (attributeName, attributeData) => {
    const { value } = attributeData;
    const newFeedstock = {
      ...feedstock,
      [attributeName]: value,
    };
    setFeedstock(newFeedstock);
  };

  const getCategoryAttributes = () => {
    return Object.entries(feedstock)
      .slice(2)
      .filter((entry) => attributes.join().includes(entry[0]));
  };

  const emptyAttributeExist = () => {
    const categoryAttributes = getCategoryAttributes();
    return categoryAttributes.flat().some((field) => field === null);
  };

  const emptyCategoryExist = () => {
    return Object.entries(feedstock)
      .slice(0, 2)
      .flat()
      .some((field) => field === null);
  };

  const emptyFieldExist = () => {
    return emptyAttributeExist() || emptyCategoryExist();
  };
  {
    /* creacion materia prima */
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (emptyFieldExist()) {
      setIsSubmitting(false);
      setEmptyFieldsExist(true);
    } else {
      try {
        await postFeedstock(feedstock);
        setIsSubmitting(false);
        updateDataTable();
        toggle();
        successMsg('La materia prima ha sido creada.');
      } catch (error) {
        setIsSubmitting(false);
        if (error.msg === 'FEEDSTOCK_ALREADY_EXISTS') {
          errorMsg(`La materia prima que está intentando crear ya existe.`);
        }
      }
    }
  };

  useEffect(() => {
    if (!emptyFieldExist()) {
      setEmptyFieldsExist(false);
    }
  }, [feedstock]);

  return (
    <form className="theme-form" onSubmit={(e) => handleSubmit(e)}>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <Label>* Categoría:</Label>

            <SelectCustom
              placeholder={'Seleccione la categoría'}
              name="category"
              options={options}
              onChange={(option) => handleCategoryChange(option)}
            />

            <SelectGroup data={selectedCat} onChange={handleAttributeChange} />
          </div>
        </div>
      </div>

      {emptyFieldsExist ? (
        <div className="dmx-input-feedback">
          Hay campos sin completar. Por favor, complete todos los campos antes de continuar.
        </div>
      ) : (
        ''
      )}

      <div className="form-group form-row mt-3 mb-0">
        <SpinnerButton
          isLoading={isSubmitting}
          type="submit"
          className="btn btn-primary btn-block dmx-modal-save-button"
        >
          Guardar
        </SpinnerButton>
      </div>
    </form>
  );
};

export default RawMaterialForm;
