import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DisabledCard = styled('div')<{ draggingOver }>`
  color: #999;
  display: block;

  &:hover {
    color: #999;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  opacity: 0.5;

  ${({ draggingOver }) => (draggingOver ? 'opacity: .5;' : '')};
`;

export const LinkCard = styled(Link)<{ draggingOver }>`
  color: #999;
  display: block;

  &:hover {
    color: #999;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  ${({ draggingOver }) => (draggingOver ? 'opacity: .5;' : '')};
`;

export const CardContent = styled('div')`
  display: block;
  overflow: hidden;
  text-decoration: none;
  border-radius: 6px;
  border: 1px solid #dbdbdb;
  position: static;
  margin-bottom: 5px;
  transition: all 0.2s ease-out;
  padding: 10px;
  background-color: white;
  box-shadow: rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 5%) 0px 2px 4px;

  .porcent-bar {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    .porcent-bar {
      display: block;
    }
    background-color: rgb(232 232 232);
    box-shadow: 0px -1px 15px 0px rgb(0 0 0 / 10%);
  }
`;

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  @media (min-width: 1370px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

const colors = {
  'in plant': {
    color: '#224af0',
    background: '#dee4ff',
  },
  'semi-elaborado': {
    color: '#c2122f',
    background: '#ffdae0',
  },
  'semi-elaborado-2': {
    color: '#c2122f',
    background: '#ffdae0',
  },
};

export const State = styled('div')`
  width: max-content;
  max-width: 90px;
  // border: 1px solid ${({ type }) => colors[type]?.background};
  background-color: ${({ type }) => colors[type]?.background};
  color: ${({ type }) => colors[type]?.color};
  font-size: 12px;
  line-height: 16px;
  padding: 2px 4px;
  margin-right: 5px;
  border-radius: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (min-width: 885px) {
    max-width: 200px;
  }

  @media (min-width: 1370px) {
    margin-bottom: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }
`;

export const DateOrigen = styled('div')`
  font-size: 12px;
  color: #888;
`;

export const Icon = styled(FontAwesomeIcon)``;

export const Batch = styled('div')`
  font-size: 12px;
  color: #888;
`;

export const Product = styled('div')`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 6px;
  color: #000;
  white-space: pre-wrap;
  word-break: break-word;
  font-weight: ${(props) => (!props.readed ? '600' : '500')};
`;

export const Item = styled('div')`
  display: flex;
  align-items: center;

  & > svg {
    width: 15px;
    margin-right: 10px;
  }
`;

export const Name = styled('div')`
  line-height: 24px;
`;

export const Quantity = styled('div')`
  font-size: 12px;
  color: #888;
  margin-bottom: 5px;

  img {
    margin-right: 5px;
  }
`;

export const InLine = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Priority = styled('div')``;

export const PercentValue = styled('div')`
  font-size: 12px;
  color: #888;
`;

export const PercentBar = styled('div')<{ completed }>`
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: gray;

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: ${(props) => props.completed}%;
    background-color: rgb(108, 155, 125);
  }
`;
// rgb(225, 111, 100) red
// rgb(203, 148, 51) yellow
// rgb(108, 155, 125) green

const sonarWave = keyframes`from {
  opacity: 0.5;
}
to {
  transform: scale(2.5);
  opacity: 0;
}`;

export const PoStates = styled('div')`
  display: flex;
  align-items: center;
`;
/* The circle */
export const Sonar = styled('div')`
  position: relative;
  margin: 0px 10px 0px 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(60 201 111);
  z-index: 0;

  &:last-child {
    margin-right: 0px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    background-color: rgb(60 201 111);
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    animation: ${sonarWave} 2s linear infinite;
  }
`;
