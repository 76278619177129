import { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import SweetAlert from 'sweetalert2';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from 'reactstrap';
import ChangeState from '../components/ChangeState'
import { GlobalStyle, PageHeader, BeadContainer, Divider } from './styles';
import Spinner from '../../../components/Spinner';
import Breadcrumb from '../../../components/common/breadcrumb';
import { editWorkshop, getWorkshop, resetEditWorkshop } from '../../../store/actions/workshop';
import Edit from '../Edit'
import BagsTransit from '../components/BagsTransit'
import { toast, ToastContainer } from 'react-toastify';
import CanRBac from '../../../components/CanRBac';
import { User } from '../../../types/user';
import workshop from '../../../services/workshop';

interface ComponentProps {
  currentWorkshop;
  currentWorkshopStates;
  match;
  currentUser: User
}

const PageOrderProductionDetail = ({
  currentWorkshop,
  currentWorkshopStates,
  match,
  currentUser
}: ComponentProps) => {
  const dispatch = useDispatch();

  const handleDeleteWorkshop = () => {
    SweetAlert.fire({
      title: 'Eliminar Orden de Producción',
      html: '¿Estas seguro de eliminar la Orden de Producción? <br/><strong>ID: ' + currentWorkshop?._id + '</strong>',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      allowEscapeKey: false,
      preConfirm: () => {
        //dispatch(deleteWorkshop(currentWorkshop._id));
      },
    });
  };

  const handleSaveData = () => {
    //dispatch(updateWorkshop(currentWorkshop._id));
  };

  useEffect(() => {
    if (match.params) {
      dispatch(getWorkshop({ idWorkshop: match.params.idWorkshop }));
    }
  }, [match]);

  if (currentWorkshopStates?.loading) {
    return <Spinner size="large" isPageLoader />;
  }

  return (
    <>
      <GlobalStyle />

      <div style={{ height: 22 }} />

      <Container fluid className="page-workshop-details">
        <PageHeader>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 10, width: '50%' }}>
            <BeadContainer>
              <Breadcrumb title="Taller" parent="Talleres" parentLink="/workshops/list" />
            </BeadContainer>
            <b style={{ fontSize: '20px' }}>{currentWorkshop?.name}</b>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', gap: 10, width: '50%' }}>
            <CanRBac
              role={currentUser?.userType}
              perform='ws:edit'
              yes={() => <ChangeState handleChangeState={(state) => { dispatch(editWorkshop({ ...currentWorkshop, state: state?.value })) }} />}
            />

            <Link className="btn btn-primary" to={`/workshops/print/${currentWorkshop?._id}`}>
              <FontAwesomeIcon icon={faFileAlt} /> Imprimir
            </Link>
          </div>
        </PageHeader>

        <div style={{ height: 15 }} />

        <Row>
          <Col sm="4">
            <Card>
              <CardBody>
                <CardTitle tag="h4" className="card-bags">
                  Detalle del taller
                </CardTitle>

                <Divider />

                <Edit />
              </CardBody>
            </Card>
          </Col>

          <Col sm="8">
            <Card>
              <CardBody>
                <CardTitle tag="h4" className="card-bags">
                  Productos
                </CardTitle>

                <Divider />

                <BagsTransit />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

const state = ({ workshopStore, userStore }) => {
  const { data: currentWorkshop, states: currentWorkshopStates } = workshopStore.workshop;
  const { data: currentUser } = userStore.information;

  return {
    currentWorkshop,
    currentWorkshopStates,
    currentUser
  };
};

export default connect(state)(PageOrderProductionDetail);
