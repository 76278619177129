/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint no-underscore-dangle: 0 */
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';

// services
import postProductionOrder from '../../../../services/postProductionOrder';

// context
import { NewProductionOrderContext } from '../../../../context/NewProductionOrderContext';

const generateOPIncrementalId = ({ context, totalOrdenes }) => {
  const incrementalNumber = totalOrdenes + 1;
  const nameProduct = context?.product?.productCategoryLabel?.substring(0, 4);
  const fabric = context?.product?.fabric || '';
  const grammage = context?.product?.grammage || '';
  const color = context?.product?.color || '';
  const fullName = `${nameProduct}${fabric}${grammage !== null && grammage}${color}00${incrementalNumber}`;
  return fullName;
};

const Observations = ({ totalOrdenes }) => {
  const [context, setContext] = useContext(NewProductionOrderContext);
  const history = useHistory();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const transformContextToOrder = () => {
    const order = {
      createDate: Date.now,
      product: context.product._id,
      feedstock: context.feedstock,
      batch: context.batch,
      quantity: context.quantity,
      type: context.type,
      origin: {
        type: context.type,
        workshop: context.origin.workshop
      },
      productionBatch: {
        barcode: generateOPIncrementalId({ context, totalOrdenes }),
        batchId: generateOPIncrementalId({ context, totalOrdenes }),
        units: context.quantity,
      },
      observations: context.observations,
      state: context.state,
      originType: context.originType,
    };

    if (context.type === 'semi-elaborado') {
      order.origin.workshop = context.origin.workshop;
    } else {
      order.origin.plant = context.origin.plant;
    }

    return order;
  };

  function getRequiredFormFields() {
    return [
      { name: 'Producto', isEmpty: context.product._id },
      { name: 'Materia Prima', isEmpty: context.feedstock },
      { name: 'Unidades a fabricar', isEmpty: context.quantity },
      { name: 'Lote', isEmpty: context.batch._id },
      { name: 'Tipo', isEmpty: context.type },
      // {
      //   name: 'Taller/Fabrica',
      //   isEmpty: context.type === 'semi-elaborado' ? context.origin.workshop : context.origin.plant,
      // },
    ];
  }

  function emptyFieldExist() {
    const fields = getRequiredFormFields();
    const emptyField = (field) => field.isEmpty === '';

    return fields.some(emptyField);
  }

  function getEmptyFieldsList() {
    const fields = getRequiredFormFields();
    const emptyFields = fields.filter((field) => field.isEmpty === '');

    return emptyFields.map((emptyField) => emptyField.name);
  }

  async function CreateProductionOrder() {
    const newOrder = transformContextToOrder();

    try {
      const result = await postProductionOrder(newOrder);
      const { _id } = result;

      toast.success('La orden de producción ha sido creada. En unos instantes podrá editarla.', {
        onClose: () => history.push(`/orderproductiondetail/${_id}/?edit=true`), autoClose: 3000
      });
    } catch (error) {
      let errorMsg = error.msg;

      if (errorMsg === 'ORDER_ALREADY_EXISTS') {
        errorMsg = 'El número de orden ya existe.';
      }

      SweetAlert.fire({
        title: 'Un error ha ocurrido',
        html: `<p>No se ha podido crear la orden de producción por la siguiente razón:</p><pre>${errorMsg}</pre>`,
        confirmButtonText: 'Ok',
      });
    }
  }

  const handleOnClick = (event) => {
    event.preventDefault();

    if (emptyFieldExist()) {
      const emptyFieldsList = getEmptyFieldsList()
        .map((field) => `<li>${field}</li>`)
        .join('');

      SweetAlert.fire({
        title: 'Hay campos sin completar',
        html: `<p>La orden no puede ser creada con los siguientes campos vacíos:</p>
          <ul>${emptyFieldsList}</ul>`,
        confirmButtonText: 'Ok',
      });
    } else {
      setDisableSubmit(true);
      CreateProductionOrder();
    }
  };

  const handleOnChange = (name, value) => {
    setContext({ ...context, [name]: value });
  };

  const { observations } = context;

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row mt-4">
              <div className="col-md-12 mb-3">
                <label htmlFor="observations">Observaciones</label>

                <textarea
                  id="observations"
                  className="form-control"
                  placeholder="Escriba aquí sus observaciones"
                  value={observations}
                  onChange={(event) => handleOnChange('observations', event.target.value)}
                />
              </div>

              <button
                className="btn btn-primary r-7 btnsubmit"
                onClick={(event) => handleOnClick(event)}
                type="submit"
                disabled={disableSubmit}
              >
                Finalizar
              </button>

              <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Observations;
