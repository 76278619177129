import { GET_ORDERS_ALL, GET_ORDERS_ALL_RESET, GetOrdersAll, GetOrdersAllReset } from '../types/orders';

export const getOrdersAll = (filters): GetOrdersAll => {
  return {
    type: GET_ORDERS_ALL,
    payload: filters,
  };
};

export const getOrdersAllReset = (): GetOrdersAllReset => {
  return {
    type: GET_ORDERS_ALL_RESET,
    payload: null,
  };
};
