const getTotalEgress = (moves, units) => {
  let totalEgress = 0;

  for (let move of moves) {
    if (move.type === 'EGRESO') {
      if (units > 0) {
        totalEgress += parseInt(move.units);
      } else {
        totalEgress += move.totalMeters;
      }
    }
  }

  return totalEgress;
};

export default getTotalEgress;
