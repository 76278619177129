import styled from 'styled-components';

export const Page = styled('div')`
  overflow: hidden;
  padding: 30px 30px 94px;
  border-radius: 6px;
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 15%);
  background-color: white;
  height: 100%;

  .table-responsive {
    table {
      border-collapse: collapse;
      border-spacing: 0px;

      tr {
        td {
          padding: 0;
          border: 0;
        }
      }
    }
  }
`;
