import { SignInData, SignOutData, SignUpData } from '../../types/auth';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_ERROR = 'RECOVER_PASSWORD_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export interface SignIn {
  type: typeof SIGN_IN;
  payload: SignInData;
}
export interface SignInSuccess {
  type: typeof SIGN_IN_SUCCESS;
  payload: null;
}
export interface SignInError {
  type: typeof SIGN_IN_ERROR;
  payload: boolean | string;
}

export interface SignUp {
  type: typeof SIGN_UP;
  payload: SignUpData;
}
export interface SignUpSuccess {
  type: typeof SIGN_UP_SUCCESS;
  payload: null;
}
export interface SignUpError {
  type: typeof SIGN_UP_ERROR;
  payload: boolean | string;
}

export interface SignOut {
  type: typeof SIGN_OUT;
  payload: null;
}
export interface SignOutSuccess {
  type: typeof SIGN_OUT_SUCCESS;
  payload: null;
}
export interface SignOutError {
  type: typeof SIGN_OUT_ERROR;
  payload: boolean | string;
}
export interface RecoverPassword {
  type: typeof RECOVER_PASSWORD;
  payload: { email: string };
}
export interface RecoverPasswordSuccess {
  type: typeof RECOVER_PASSWORD_SUCCESS;
  payload: null;
}
export interface RecoverPasswordError {
  type: typeof RECOVER_PASSWORD_ERROR;
  payload: boolean | string;
}

export interface ResetPassword {
  type: typeof RESET_PASSWORD;
  payload: { id: string; password: string };
}
export interface ResetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS;
  payload: null;
}
export interface ResetPasswordError {
  type: typeof RESET_PASSWORD_ERROR;
  payload: boolean | string;
}
export interface State {
  signin: {
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  signup: {
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  signout: {
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  recoverPassword: {
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  resetPassword: {
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
}

export const initialState: State = {
  signin: {
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  signup: {
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  signout: {
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  recoverPassword: {
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  resetPassword: {
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
};

export type Actions =
  | SignIn
  | SignInSuccess
  | SignInError
  | SignUp
  | SignUpSuccess
  | SignUpError
  | SignOut
  | SignOutSuccess
  | SignOutError
  | RecoverPassword
  | RecoverPasswordSuccess
  | RecoverPasswordError
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordError;
