import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';

// Icons
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Hooks
import useOrderNoteFetcher from '../../hooks/useOrderNoteFetcher';

// My Imports
import Spinner from '../../components/Spinner';
import Invoice from '../../components/Invoice';
import Breadcrumb from '../../components/common/breadcrumb';

// Styles
import './PrintOrder.scss';

const PrintOrder = () => {
  const orderId = new URLSearchParams(window.location.search).get('id');
  const origin = new URLSearchParams(window.location.search).get('origin');
  const parentLink = origin === 'detail' ? `/orderdetail?id=${orderId}&edit=false` : '/orders';
  const order = useOrderNoteFetcher(orderId);
  const componentRef = useRef();

  if (order.isLoading || order.data === null) {
    return <Spinner size="large" isPageLoader />;
  }

  const printInvoiceButton = (
    <button className="btn btn-primary shadow-lg" type="button">
      <FontAwesomeIcon icon={faPrint} /> Imprimir
    </button>
  );

  return (
    <>
      <div className="page-order-detail">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="heading">
                <h5 className="pageTitle">Impresión de orden #{order.data.orderNoteId}</h5>
              </div>
            </div>

            <div className="col">
              <div className="page-header">
                <div className="m-r-15">
                <ReactToPrint  trigger={() => printInvoiceButton} content={() => componentRef.current} />

                </div>
                <Breadcrumb parentLink={parentLink} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-print-order">
        <div className="button-print-container"></div>

        <div className="card shadow-sm" ref={componentRef}>
          <Invoice order={order.data} />
        </div>
      </div>
    </>
  );
};

export default PrintOrder;
