import { connect } from 'react-redux';
import Select, { components, SingleValueProps } from 'react-select';
import { checkDataPermission } from '../../../../../../../components/CanRBac';
import { TypeOption, typeOptions } from '../../../../../../../constant/order-production-types';

// mixins
import { SelectContainer } from './mixins';

const SingleValue = ({ children, ...props }: SingleValueProps<TypeOption>) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
);

const Component = ({ type, handleChangeType, currentUser, productionOrder }) => {
  const customStyles = {
    singleValue: (base) => ({
      ...base,
      padding: 5,
      borderRadius: 5,
      color: 'black',
      display: 'flex',
    }),
    valueContainer: (provided) => ({
      ...provided,
      width: '160px',
    }),
  };

  const typeOptions2 = [
    { value: 'semi-elaborado', label: 'Semi Elaborado' },
    { value: 'semi-elaborado-2', label: 'Semi Elaborado 2' },
  ];

  return (
    <SelectContainer>
      <Select
        name="selectType"
        styles={customStyles}
        components={{ SingleValue }}
        isSearchable={false}
        options={
          currentUser.userType==='handler' ? typeOptions2:  typeOptions
        }
        isDisabled={
          currentUser.userType === 'inplantAdmin' ||
          !checkDataPermission(currentUser.userType, 'op:edit:poInformation', { status: productionOrder?.state })
        }
        onChange={(e) => handleChangeType(e.value)}
        defaultValue={{ label: type, value: type }}
      />
    </SelectContainer>
  );
};

const state = ({ userStore, productionOrderStore }) => {
  const { data: productionOrder } = productionOrderStore.productionOrder;
  const { data: currentUser } = userStore.information;

  return {
    currentUser,
    productionOrder,
  };
};

export default connect(state)(Component);
