import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Form } from 'reactstrap';
import _ from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faFileImage,
  faFilePdf,
  faFileAlt,
  faFileDownload,
  faTimesCircle,
  faFileWord,
  faFileExcel,
  faFileArchive,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import SweetAlert from 'sweetalert2';
import deleteAttachment from '../../../services/deleteAttachment';
import getAttachmentFile from '../../../services/getAttachmentFile';
import postFile from '../../../services/postFile';
import './Filesform.scss';
import BlockModal from './BlockModal';

const Filemanager = ({ isEditable, orderId, orderNoteId, files, type = 'order', refetch }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileList, setFileList] = useState(files);
  const [blockUI, setBlockUI] = useState(false);

  const toggleBlockUI = (bool) => {
    setBlockUI(bool);
  };

  const onFileUpload = () => {
    const myFiles = [...fileList];

    if (selectedFile !== null) {
      toggleBlockUI(true);
      postFile(selectedFile, orderId, orderNoteId, type).then((res) => {
        if (res.Location) {
          myFiles.push({ _id: '', attachmentUrl: res.Location, attachmentFileName: res.Key });
        }
        setFileList(myFiles);
        document.getElementById('upfile').value = '';
        toggleBlockUI(false);
        toast.success('Archivo subido satisfactoriamente', { autoClose: 3000 });
        if (refetch) {
          refetch();
        }
      });
    } else {
      toast.error('Por favor, seleccione al menos un archivo', { autoClose: 3000 });
    }
  };

  useEffect(() => {
    if (selectedFile) {
      onFileUpload();
    }
  }, [selectedFile]);

  function updateList(fileId) {
    const fileIndex = fileList.findIndex((f) => f.attachmentFileName === fileId);
    const newList = _.clone(fileList);
    newList.splice(fileIndex, 1);
    setFileList(newList);
  }

  const executeDeleteAction = (fileId) => {
    deleteAttachment(fileId, orderId, type).then((res) => {
      if (res) {
        getAttachmentFile(fileId).then((response) => {
          const message = response.errors.msg;
          if (message === 'The specified key does not exist.') {
            toast.success('Archivo borrado satisfactoriamente.', { autoClose: 3000 });
            updateList(fileId);
            if (refetch) {
              refetch();
            }
          }
        });
      } else {
        toast.error(`No se pudo borrar el archivo porque ocurrió un error:${res}`, { autoClose: 3000 });
      }
    });
  };

  const handleDeleteAction = (fileId) => {
    SweetAlert.fire({
      title: '¿Desea eliminar el archivo adjunto?',
      text: 'Una vez eliminado, no podrá ser recuperado.',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        executeDeleteAction(fileId);
      }
    });
  };

  const getFileExtension = (filename) => {
    return filename.split('.').pop();
  };

  // eslint-disable-next-line
  const cardFiles = fileList.map((data) => {
    const extension = getFileExtension(data.attachmentFileName);
    let icon = '';
    let isImage = 'false';

    switch (extension) {
      case 'png':
        icon = <FontAwesomeIcon icon={faFileImage} color="#143FEF" />;
        isImage = true;
        break;
      case 'bmp':
        icon = <FontAwesomeIcon icon={faFileImage} color="#143FEF" />;
        isImage = true;
        break;
      case 'jpg':
        icon = <FontAwesomeIcon icon={faFileImage} color="#143FEF" />;
        isImage = true;
        break;
      case 'jpeg':
        icon = <FontAwesomeIcon icon={faFileImage} color="#143FEF" />;
        isImage = true;
        break;
      case 'pdf':
        icon = <FontAwesomeIcon icon={faFilePdf} color="#AD0B00" />;
        isImage = false;
        break;
      case 'doc':
        icon = <FontAwesomeIcon icon={faFileWord} color="#2B579A" />;
        isImage = false;
        break;
      case 'docx':
        icon = <FontAwesomeIcon icon={faFileWord} color="#2B579A" />;
        isImage = false;
        break;
      case 'xls':
        icon = <FontAwesomeIcon icon={faFileExcel} color="#21A366" />;
        isImage = false;
        break;
      case 'xlsx':
        icon = <FontAwesomeIcon icon={faFileExcel} color="#21A366" />;
        isImage = false;
        break;
      case 'csv':
        icon = <FontAwesomeIcon icon={faFileExcel} color="#21A366" />;
        isImage = false;
        break;
      case 'zip':
        icon = <FontAwesomeIcon icon={faFileArchive} color="#292A2D" />;
        isImage = false;
        break;
      case 'rar':
        icon = <FontAwesomeIcon icon={faFileArchive} color="#292A2D" />;
        isImage = false;
        break;
      case '7z':
        icon = <FontAwesomeIcon icon={faFileArchive} color="#292A2D" />;
        isImage = false;
        break;
      default:
        icon = <FontAwesomeIcon icon={faFileAlt} color="#292A2D" />;
        isImage = false;
    }

    const getFilename = () => {
      let filename;

      if (type === 'feedstock' || type === 'product') {
        filename = data.attachmentFileName ? data.attachmentFileName.split('-')[1] : selectedFile.name;
      } else {
        filename = data.attachmentFileName ? data.attachmentFileName : selectedFile.name;
      }

      if (filename.length > 12) {
        filename = `${filename.substring(0, 10)}...`;
      }
      return filename;
      // filename = selectedFile.name;
      // return filename;
    };

    return (
      <li className="file-box m-5 dmx-custom-attachment" key={uuidv4()}>
        <div className="file-top dmx-custom-images">
          {isImage ? (
            <img
              data-toggle="tooltip"
              data-placement="top"
              title={data.attachmentOriginalName ? data.attachmentOriginalName : selectedFile.name}
              src={data.attachmentUrl}
              alt={data.attachmentOriginalName}
            />
          ) : (
            <span
              data-toggle="tooltip"
              data-placement="top"
              title={data.attachmentOriginalName ? data.attachmentOriginalName : selectedFile.name}
            >
              {icon}
            </span>
          )}
        </div>

        <h6 className="text-center">{getFilename()} </h6>

        <div className="file-bottom dmx-attachmentButtons">
          <div className="text-secondary p-1">
            <a href={data.attachmentUrl} key={uuidv4()}>
              <FontAwesomeIcon icon={faFileDownload} color="#4466F2" />
            </a>
          </div>

          {isEditable ? (
            <div className="text-danger p-1">
              <FontAwesomeIcon
                icon={faTimesCircle}
                onClick={() => handleDeleteAction(data.attachmentFileName, orderId)}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </li>
    );
  });

  const getFile = () => {
    document.getElementById('upfile').click();
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xl="12" md="12" className="box-col-12">
            <div className="file-content">
              <Card>
                <CardHeader>
                  <div className="media">
                    <div className="media-body text-right">
                      <h5 className="mb-3" style={{ float: 'left' }}>
                        {cardFiles.length > 0 ? 'ARCHIVOS' : 'NO HAY ARCHIVOS'}
                      </h5>

                      <Form className="d-inline-flex">
                        <button type="button" className="btn btn-primary" disabled={!isEditable} onClick={getFile}>
                          <FontAwesomeIcon icon={faPlus} /> Adjuntar archivo
                        </button>
                        <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
                          <input id="upfile" type="file" onChange={(e) => onFileChange(e)} />
                        </div>
                      </Form>
                    </div>
                  </div>
                </CardHeader>
                {cardFiles.length > 0 ? (
                  <CardBody className="file-manager">
                    <ul className="files">{cardFiles}</ul>
                  </CardBody>
                ) : (
                  <></>
                )}
              </Card>
            </div>
          </Col>
        </Row>
        <BlockModal isOpen={blockUI} toggle={toggleBlockUI} />
      </Container>
    </>
  );
};

export default Filemanager;
