import { useState, useEffect } from 'react';
import getOrderNotes, { getOrderNotesPaginated } from '../services/getOrderNotes';
import { omit } from 'underscore';

const useOrderNotesFetcher = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getOrderNotes()
      .then((orders) => {
        setData(orders);
        setIsLoading(false);
        setIsError(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return { data, isLoading, isError };
};

const useOrderNotesPaginatedFetcher = () => {
  const [data, setData] = useState({ data: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);
  const [n_Archived, setN_Archived] = useState(false);
  const [userType, setUserType] = useState('');
  const [userId, setUserId] = useState('');
  const [status, setStatus] = useState('');
  const [isAdmin, setIsAdmin] = useState('');
  const [clientId, setClientId] = useState('');

  useEffect(() => {
    if (reload) {
      localStorage.setItem('lastPage', page);
      localStorage.setItem('itemsForPage', limit);
      localStorage.setItem('archived', n_Archived);
      localStorage.setItem('clientId', clientId);
      setIsLoading(true);
      getOrderNotesPaginated({ limit, page, n_Archived, userType, userId, status, isAdmin, clientId })
        .then((orders) => {
          setData(orders);
          setIsLoading(false);
          setIsError(false);
          setReload(false);
        })
        .catch(() => {
          setIsLoading(false);
          setIsError(true);
          setReload(false);
        });
    }
  }, [page, limit, reload, userType, userId, status, clientId]);
  return {
    data: data.data,
    isLoading,
    isError,
    setLimit,
    setPage,
    pagination: omit(data, 'data'),
    setReload,
    setN_Archived,
    setUserType,
    setUserId,
    setStatus,
    setIsAdmin,
    setClientId,
  };
};

export { useOrderNotesPaginatedFetcher };
export default useOrderNotesFetcher;
