import React, { useState, useEffect } from 'react';
import { AlignLeft, Maximize, MoreHorizontal } from 'react-feather';
import { faBarcode, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

// components
import UserMenu from './userMenu';
import Sidebar from '../sidebar';

// import fullscreen
import { toggleFullScren } from '../../../hooks/useFullScreen';

import './Header.scss';
import BarcodeQuickSearch from '../../BarcodeQuickSearch';

const Header = () => {
  const [namePageTitle, setNamePageTitle] = useState('Titulo');
  const [openSidebar, setOpenSidebar] = useState('open');
  const { pathname } = window.location;
  const [headerbar, setHeaderbar] = useState(true);
  const [modal, setModal] = useState(false);

  const handleSidebar = () => {
    if (openSidebar === 'open') {
      setOpenSidebar('');
    }

    if (openSidebar === '') {
      setOpenSidebar('open');
    }
  };

  useEffect(() => {
    setOpenSidebar('open');

    if (pathname.includes('/orders')) {
      setNamePageTitle('Notas de pedido');
    } else if (pathname.includes('/clients')) {
      setNamePageTitle('Clientes');
    } else if (pathname.includes('/products')) {
      setNamePageTitle('Productos');
    } else if (pathname.includes('/rawmaterial')) {
      setNamePageTitle('Materia prima');
    } else if (pathname.includes('/orderproduction')) {
      setNamePageTitle('Orden de Producción');
    } else if (pathname.includes('/orderproductionnew')) {
      setNamePageTitle('Nueva Orden de Producción');
    } else if (pathname.includes('/cuts')) {
      setNamePageTitle('Cortes');
    } else if (pathname.includes('/searchbybarcode')) {
      setNamePageTitle('Buscar código de barras');
    } else if (pathname.includes('/workshop')) {
      setNamePageTitle('Talleres');
    } else {
      setNamePageTitle('');
    }
  }, [pathname]);

  return (
    <>
      <div className={`page-main-header ${openSidebar}`}>
        <div className="main-header-right">
          <div className="mobile-sidebar d-block">
            <div className="nav-wrapper-left media-body text-right">
              <button className="button-transparent" type="button" onClick={() => handleSidebar()}>
                <AlignLeft />
              </button>

              <h1 className="title-page">{namePageTitle}</h1>
            </div>
          </div>

          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
              <div>
                <li>
                  <button type="button" className="text-dark btn btn-link p-0 mr-4" onClick={() => setModal(true)}>
                    <FontAwesomeIcon icon={faBarcode} />
                  </button>
                </li>
                <li>
                  <button type="button" onClick={() => toggleFullScren()} className="text-dark btn btn-link p-0 mr-4">
                    <Maximize />
                  </button>
                </li>
              </div>
              <div>
                <UserMenu />
              </div>
            </ul>
          </div>
          <BarcodeQuickSearch isOpen={modal} toggle={() => setModal(!modal)} />
        </div>
      </div>

      <Sidebar openSidebar={openSidebar} />
    </>
  );
};
export default Header;
