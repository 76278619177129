import React from 'react';
import { Modal } from 'reactstrap';
import Spinner from '../../../components/Spinner';
import './Filesform.scss';

const BlockModal = (props) => {
  const { isOpen, toggle } = props;

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isOpen} toggle={toggle} centered className="customBlockModal">
        <Spinner size="large" isPageLoader />
      </Modal>
    </div>
  );
};

export default BlockModal;
