import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import NewOrderNoteProvider from '../context/NewOrderNoteContext';
import NewProductionOrderContext from '../context/NewProductionOrderContext';
import { ClientProvider } from '../context/ClientContext';
import { ProductProvider } from '../context/ProductContext';

import Layout from '../components/Layout';
import PrivateRoute from '../components/PrivateRoute';

import Login from './Login';
import OrderNotes from './OrderNotes';
import OrderDetail from './OrderDetail';
import Profile from './Profile';
import NewOrderNote from './NewOrderNote';
import Clients from './Clients';
import RawMaterial from './RawMaterial';
import MaterialDetail from './MaterialDetail';

import Products from './Products';
import PrintOrder from './PrintOrder';

import OrderProduction from './ProductionOrder/List';
import OrderProductionNew from './ProductionOrder/New';
import OrderProductionDetail from './ProductionOrder/Preview';
import PrintProductionOrder from './ProductionOrder/Print';

import Workshop from './Workshop';

import CutKanban from './CutKanban';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { User } from '../types/user';

interface ComponentProps {
  user: User;
}

const urls = {
  feedstockAdmin: '/rawmaterial',
  productionAdmin: '/orderproduction',
  cutter: '/orderproduction',
  inplantAdmin: '/orderproduction',
  default: '/orders',
};

const Component = ({ user }: ComponentProps) => {
  const [redirectUrl, setRedirectUrl] = useState(urls.default);

  const getRedirectUrl = async () => {
    const role = user.userType;
    setRedirectUrl(urls[role] || urls.default);
  };

  useEffect(() => {
    !!user && getRedirectUrl();
  }, [user]);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={() => <Redirect to={redirectUrl} />} />

        <Route exact path="/login" component={Login} />

        <PrivateRoute
          path="/orders"
          component={() => (
            <Layout>
              <OrderNotes />
            </Layout>
          )}
        />
        <PrivateRoute
          path="/workshops"
          component={() => (
            <Layout>
              <Workshop />
            </Layout>
          )}
        />

        <PrivateRoute
          path="/clients"
          component={() => (
            <Layout>
              <ClientProvider>
                <Clients />
              </ClientProvider>
            </Layout>
          )}
        />
        <PrivateRoute
          path="/profile"
          component={() => (
            <Layout>
              <Profile />
            </Layout>
          )}
        />
        <PrivateRoute
          path="/orderdetail"
          component={() => (
            <Layout>
              <OrderDetail />
            </Layout>
          )}
        />
        <PrivateRoute
          path="/neworder"
          component={() => (
            <Layout>
              <NewOrderNoteProvider>
                <NewOrderNote />
              </NewOrderNoteProvider>
            </Layout>
          )}
        />
        <PrivateRoute
          path="/products"
          component={() => (
            <Layout>
              <ProductProvider>
                <Products />
              </ProductProvider>
            </Layout>
          )}
        />
        <PrivateRoute
          path="/materialdetail"
          component={() => (
            <Layout>
              <MaterialDetail />
            </Layout>
          )}
        />
        <PrivateRoute
          path="/rawmaterial"
          component={() => (
            <Layout>
              <RawMaterial />
            </Layout>
          )}
        />
        <PrivateRoute exact path="/printorder" component={PrintOrder} />
        <PrivateRoute
          path="/orderproduction"
          component={() => (
            <Layout>
              <OrderProduction />
            </Layout>
          )}
        />
        <PrivateRoute
          path="/orderproductionnew"
          component={() => (
            <Layout>
              <NewProductionOrderContext>
                <OrderProductionNew />
              </NewProductionOrderContext>
            </Layout>
          )}
        />
        <PrivateRoute
          path="/orderproductiondetail/:id"
          component={(props) => (
            <Layout>
              <OrderProductionDetail {...props} />
            </Layout>
          )}
        />
        <PrivateRoute path="/print-production-order/:id" component={PrintProductionOrder} />
        <PrivateRoute
          path="/cuts"
          component={() => (
            <Layout>
              <CutKanban />
            </Layout>
          )}
        />
      </Switch>
    </Router>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore.information;
  return {
    user,
  };
};

export default connect(states)(Component);
