/* eslint no-underscore-dangle: 0 */
import { useState, createContext } from 'react';
import { connect } from 'react-redux';

export const NewOrderNoteContext = createContext();

const Context = ({ userId, children }) => {
  const [data, setData] = useState({
    client: {
      _id: '',
      brandName: '',
      companyName: '',
      cuit: '',
      phone: '',
      email: '',
      address: '',
      deliveryAddress: '',
      shipment: '',
      paymentType: '',
      deliveryTime: '',
    },
    seller: userId,
    hasTurn: '',
    deliveryAddress: '',
    firstDeliveryDate: new Date().toISOString(),
    deliveryType: 'none',
    paymentCurrency: 'pesos',
    paymentType: '',
    orderNoteId: 1,
    orderNoteNotes: '',
    handler: '',
  });

  return <NewOrderNoteContext.Provider value={[data, setData]}>{children}</NewOrderNoteContext.Provider>;
};

const state = ({ userStore }) => {
  const { _id: userId } = userStore.information.data;
  return {
    userId,
  };
};

export default connect(state)(Context);
