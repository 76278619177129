import React, { useState, createContext } from 'react';

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [product, setProduct] = useState({});
  const [toggle, setToggle] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);

  return (
    <ProductContext.Provider
      value={{
        productContext: [product, setProduct],
        modalContext: [toggle, setToggle],
        upModal: [uploadModal, setUploadModal],
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
