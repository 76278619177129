import './BarcodeQuickSearch.scss';

function Message({ message }) {
  return (
    <div className="no-data">
      <h5>{message}</h5>
    </div>
  );
}

export default Message;
