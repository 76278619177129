import { getToken } from '../utils/authUtils';
import { DELETE } from '../constant';

async function deleteProduct(productId) {
  return fetch(`${process.env.REACT_APP_API_URL}/products/${productId}`, {
    method: DELETE,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }).then((response) => response.json());
}

export default deleteProduct;
