import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { moveTypes } from '../../../../constant';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const moveTypeOptions = [
  { value: 'INGRESO', label: 'INGRESO' },
  { value: 'EGRESO', label: 'EGRESO' },
];

const customStyles = {
  headCells: {
    style: {
      paddingLeft: '4px',
      paddingRight: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: '4px',
      paddingRight: '4px',
    },
  },
};

interface ComponentProps {
  data?
}

const Component = ({ data }: ComponentProps) => {
  const columns = [{
    name: '',
    maxWidth: '48px',
    minWidth: '48px',
    center: true,
    cell: (row) => {
      let icon = <></>;

      if (row.status === 'inDeposit') {
        icon = <FontAwesomeIcon icon={faArrowLeft} color="red" />;
      }

      if (row.status === 'inWorkshop') {
        icon = <FontAwesomeIcon icon={faArrowRight} color="green" />;
      }

      if (row.status === 'inTransit') {
        icon = <FontAwesomeIcon icon={faArrowRight} color="green" />;
      }

      return icon
    },
  }, {
    name: 'Movimiento',
    cell: (row) => {
      let text = '';

      if (row.status === 'inDeposit') {
        text = 'Devolución del taller';
      }

      if (row.status === 'inWorkshop') {
        text = 'Entrega al taller';
      }

      if (row.status === 'inTransit') {
        text = 'En transito';
      }

      return <p>{text}</p>
    },
  },
  {
    name: 'Unidades',
    selector: 'units',
  }, {
    name: 'Orden de produccion',
    cell: (row) => {
      return <a href={`/orderproductiondetail/${row?.ProductionOrder?._id}`} target="_blank">
        OP{row?.ProductionOrder?.code}
      </a>
    },
  }]

  return <DataTable
    customStyles={customStyles}
    columns={columns}
    data={data.bags}
    noDataComponent={<div className="p-3">No hay registros para mostrar</div>}
    style={{ fontSize: 12 }}
  />

}

export default Component