import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import SearchResult from './SearchResult';
import getBatchWithBarcode from '../../services/getBatchWithBarcode';
import { BARCODE_QUICKSEARCH_NO_DATA_FOUND } from '../../constant';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit } from '@fortawesome/free-solid-svg-icons';
import CanRBac from '../CanRBac';
import { connect } from 'react-redux';

// Styles
import './BarcodeQuickSearch.scss';

function BarcodeQuickSearch({ isOpen, toggle, currentUser }) {
  const [data, setData] = useState();
  const [isSearching, setIsSearching] = useState(false);

  function formatDate(date) {
    const locale = 'es-SP';
    const options = {
      timeZone: 'UTC',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };

    return new Date(date).toLocaleString(locale, options).slice(0, 17); // Quito el ' GMT-3' de la cadena
  }

  function getLatestUpdate(batch) {
    return formatDate(batch.modificationDate);
  }

  function getReels(batch) {
    let reels = 0;

    if (!batch.stockControl.length) {
      return reels;
    }

    batch.stockControl.forEach((item) => {
      if (item.movementsDetail.length) {
        item.movementsDetail.forEach((movement) => {
          reels = movement.type === 'INGRESO' ? reels + movement.reels : reels - movement.reels;
        });
      }
    });

    return reels;
  }

  function getMeters(batch) {
    let meters = 0;

    if (!batch.stockControl.length) {
      return meters;
    }

    batch.stockControl.forEach((item) => {
      if (item.movementsDetail.length) {
        item.movementsDetail.forEach((movement) => {
          meters = movement.type === 'INGRESO' ? meters + movement.totalMeters : meters - movement.totalMeters;
        });
      }
    });

    return meters;
  }

  function getFabricType(batch) {
    return batch.feedstockCategoryId.name || BARCODE_QUICKSEARCH_NO_DATA_FOUND;
  }

  function getFabricColor(batch) {
    return batch.color || BARCODE_QUICKSEARCH_NO_DATA_FOUND;
  }

  function getFabricGrammage(batch) {
    return batch.grammage || BARCODE_QUICKSEARCH_NO_DATA_FOUND;
  }

  function isEmpty(string) {
    return string === '';
  }

  function getRawMaterialBatch(data) {
    return {
      _id: data._id,
      origin: data.stockControl[0].originType,
      latestUpdate: getLatestUpdate(data),
      reels: getReels(data),
      meters: getMeters(data),
      fabricType: getFabricType(data),
      fabricColor: getFabricColor(data),
      fabricGrammage: getFabricGrammage(data),
      editLink: `/materialdetail?id=${data._id}&edit=true`,
      viewLink: `/materialdetail?id=${data._id}&edit=false`,
    };
  }

  function getProductionOrderBatch(data) {
    return {
      _id: data._id,
      origin: data.stockControl[0].originType,
      latestUpdate: formatDate(data.updateDate),
      name: data.feedstock.name,
      quantity: data.quantity,
      status: data.state,
      editLink: null, //`/orderproductiondetail?id=${data._id}`,
      viewLink: `/orderproductiondetail/${data._id}`, // null,
    };
  }

  async function handleKeyPress(event) {
    if (event.key === 'Enter' && !isEmpty(event.target.value)) {
      setIsSearching(true);
      let newData;

      try {
        const batch = await getBatchWithBarcode(event.target.value);

        if (batch[0].stockControl[0].originType === 'FEEDSTOCK') {
          newData = getRawMaterialBatch(batch[0]);
        }

        if (batch[0].stockControl[0].originType === 'PRODUCTION') {
          newData = getProductionOrderBatch(batch[0]);
        }

        newData.barcode = event.target.value;
        newData.batchId = event.target.value;

        setData(newData);
      } catch (error) {
        setData(null);
      }

      setIsSearching(false);
    }
  }

  useEffect(() => {
    setData(null);
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="lg" centered autoFocus={false}>
        <ModalBody toggle={toggle}>
          <input
            className="form-control"
            type="text"
            autoFocus
            onKeyPress={handleKeyPress}
            placeholder="Ingrese un código de barras"
          />
        </ModalBody>
        <ModalBody>
          <SearchResult data={data} isSearching={isSearching} />
        </ModalBody>
        <ModalFooter>
          <Link to={data ? data.viewLink : ''} hidden={!data || !data.viewLink}>
            <button type="button" className="btn btn-primary">
              <FontAwesomeIcon icon={faEye} /> Ver
            </button>
          </Link>
          <CanRBac
            role={currentUser?.userType}
            perform="mp:edit"
            yes={() => (
              <Link to={data ? data.editLink : ''} hidden={!data || !data.editLink}>
                <button type="button" className="btn btn-primary">
                  <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
              </Link>
            )}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(BarcodeQuickSearch);
