import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import Form from './form';
import './Modal.scss';

const ObservationModal = ({ isOpen, toggle, data, batchIndex, moveIndex, updateObsevation, readOnly }) => {
  if (batchIndex === undefined && moveIndex === undefined) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="product-observation-modal">
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon={faCommentDots} /> Observaciones
      </ModalHeader>
      <ModalBody>
        <Form
          readOnly={readOnly}
          data={data}
          batchIndex={batchIndex}
          moveIndex={moveIndex}
          updateObsevation={updateObsevation}
        />
        <span>*Luego de editar, guardar los cambios en materia prima</span>
      </ModalBody>
    </Modal>
  );
};

export default ObservationModal;
