import {
  initialState,
  Actions,
  State,
  GET_PRODUCTION_ORDER_ALL,
  GET_PRODUCTION_ORDER_ALL_SUCCESS,
  GET_PRODUCTION_ORDER_ALL_ERROR,
  DELETE_PRODUCTION_ORDER,
  DELETE_PRODUCTION_ORDER_SUCCESS,
  DELETE_PRODUCTION_ORDER_ERROR,
  GET_PRODUCTION_ORDER_ALL_RESET,
  GET_PRODUCTION_ORDER,
  GET_PRODUCTION_ORDER_SUCCESS,
  GET_PRODUCTION_ORDER_ERROR,
  GET_PRODUCTION_ORDER_RESET,
  PATCH_PRODUCTION_ORDER,
  PATCH_PRODUCTION_ORDER_SUCCESS,
  PATCH_PRODUCTION_ORDER_ERROR,
  PATCH_PRODUCTION_ORDER_RESET,
  PRODUCTION_ORDER_UPDATE_CURRENT,
  RESET_DELETE_PRODUCTION_ORDER,
  RESET_UPDATE_PRODUCTION_ORDER,
} from '../types/production-order';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_PRODUCTION_ORDER_ALL:
      return {
        ...state,
        productionOrderAll: {
          data: [],
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_PRODUCTION_ORDER_ALL_SUCCESS:
      return {
        ...state,
        productionOrderAll: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_PRODUCTION_ORDER_ALL_ERROR:
      return {
        ...state,
        productionOrderAll: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case DELETE_PRODUCTION_ORDER:
      return {
        ...state,
        deleteProductionOrder: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case DELETE_PRODUCTION_ORDER_SUCCESS:
      return {
        ...state,
        deleteProductionOrder: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_PRODUCTION_ORDER_ERROR:
      return {
        ...state,
        deleteProductionOrder: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_PRODUCTION_ORDER:
      return {
        ...state,
        deleteProductionOrder: {
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case GET_PRODUCTION_ORDER_ALL_RESET:
      return {
        ...state,
        productionOrderAll: {
          data: [],
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case GET_PRODUCTION_ORDER:
      return {
        ...state,
        productionOrder: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_PRODUCTION_ORDER_SUCCESS:
      return {
        ...state,
        productionOrder: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_PRODUCTION_ORDER_ERROR:
      return {
        ...state,
        productionOrder: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case PRODUCTION_ORDER_UPDATE_CURRENT:
      return {
        ...state,
        productionOrder: {
          ...state.productionOrder,
          data: action.payload,
        },
      };
    case GET_PRODUCTION_ORDER_RESET:
      return {
        ...state,
        productionOrder: {
          data: null,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case PATCH_PRODUCTION_ORDER:
      return {
        ...state,
        updateOrder: {
          data: [],
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case PATCH_PRODUCTION_ORDER_SUCCESS:
      return {
        ...state,
        updateOrder: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case PATCH_PRODUCTION_ORDER_ERROR:
      return {
        ...state,
        updateOrder: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case PATCH_PRODUCTION_ORDER_RESET:
      return {
        ...state,
        updateOrder: {
          data: [],
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case RESET_UPDATE_PRODUCTION_ORDER:
      return {
        ...state,
        updateOrder: initialState.updateOrder,
      };
    default:
      return state;
  }
};

export default reducers;
