import { connect, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { hideModal } from '../../../store/actions/modal';

import { Formik } from 'formik';
import Button from '../../../components/Button';

import * as Yup from 'yup';
import { useEffect, useState } from 'react';

import Form from '../components/Form';
import { editWorkshop, resetEditWorkshop } from '../../../store/actions/workshop';
import { toast } from 'react-toastify';
import services from '../../../services';
import { WorkshopDto } from '../../../types/workshop';
import { User } from '../../../types/user';
import CanRBac from '../../../components/CanRBac';

let typingTimeout

const schemaValidation = (idWorkshop) => Yup.object().shape({
  name: Yup.string().required('Este campo es requerido.').test(
    "checkWorkshopExist",
    "El nombre del taller ya se encuentra en uso.",
    function async(name) {
      //clear timeout if user is typing and timeout is set
      return new Promise((resolve, reject) => {
        //delay until user stops typing
        //check if name is valid
        if (!name) return resolve(true);

        //clear timeout if user is typing and timeout is set
        if (typingTimeout) clearTimeout(typingTimeout);

        typingTimeout = setTimeout(async () => {
          const response = await services.workshop.getAll({ name }) as { response: WorkshopDto[] };
          const isSameWorkshop = response?.response.some(obj => obj._id === idWorkshop);

          if (response?.response && response?.response?.length > 0 && !isSameWorkshop) {
            return resolve(false);
          }

          if (!response?.response || response?.response?.length <= 0 || isSameWorkshop) {
            return resolve(true);
          }
        }, 1000);
      });
    }
  ),
  address: Yup.string().required('Este campo es requerido.'),
  phone: Yup.string().required('Este campo es requerido.'),
  contact: Yup.string().nullable(),
  deliveryPeriod: Yup.string().nullable(),
  freight: Yup.string().nullable(),
  ProductCategory: Yup.array().min(1, 'Este campo es requerido.').nullable(),
});

const initialValues = {
  name: '',
  address: '',
  phone: '',
  contact: '',
  freight: '',
  deliveryPeriod: '',
  ProductCategory: [],
};

interface ComponentProps {
  currentWorkshop;
  currentWorkshopStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  editedWorkshop;
  editedWorkshopStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  currentUser: User
}

const Component = ({
  currentWorkshop,
  currentWorkshopStates,
  editedWorkshop,
  editedWorkshopStates,
  currentUser
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const dispatch = useDispatch();

  const submitForm = ({ values, actions: formActions }) => {
    dispatch(editWorkshop(values))
  };

  useEffect(() => {
    if (editedWorkshopStates.success) {
      toast.success('El taller se edito correctamente', { autoClose: 3000 });
      setTimeout(() => {
        dispatch(resetEditWorkshop());
      }, 3500);
    }

    if (editedWorkshopStates.error) {
      toast.error('Error al editar taller.', { autoClose: 3000 });
      setTimeout(() => {
        dispatch(resetEditWorkshop());
      }, 3500);
    }
  }, [editedWorkshopStates]);

  return (
    <Formik
      initialValues={{ ...initialValues, ...currentWorkshop, ProductCategory: currentWorkshop?.ProductCategory?.map(item => item._id) }}
      onSubmit={(values, actions) => {
        if (submitForm) submitForm({ values, actions });
      }}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={() => schemaValidation(currentWorkshop?._id)}
      enableReinitialize
    >
      {(props) => {
        const { handleSubmit } = props;
        return (
          <form
            name="edit-workshop-form"
            id="edit-workshop-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            style={{
              width: '100%',
            }}
          >
            <Form {...props} loading={editedWorkshopStates.loading || currentWorkshopStates.loading} editMode />

            <CanRBac
              role={currentUser?.userType}
              perform='ws:edit'
              yes={() =>
                <Button isLoading={editedWorkshopStates.loading} type="submit" color="primary" form="edit-workshop-form">
                  Guardar cambios
                </Button>
              }
            />

          </form>
        );
      }}
    </Formik>
  );
};

const state = ({ workshopStore, userStore }) => {
  const { data: currentWorkshop, states: currentWorkshopStates } = workshopStore.workshop;
  const { data: editedWorkshop, states: editedWorkshopStates } = workshopStore.editWorkshop;
  const { data: currentUser } = userStore.information;

  return {
    currentWorkshop,
    currentWorkshopStates,
    editedWorkshop,
    editedWorkshopStates,
    currentUser
  };
};

export default connect(state)(Component);
