import {
  initialState,
  Actions,
  State,
  GET_PRODUCTION_ORDER_BAG_ALL,
  GET_PRODUCTION_ORDER_BAG_ALL_SUCCESS,
  GET_PRODUCTION_ORDER_BAG_ALL_ERROR,
  GET_PRODUCTION_ORDER_BAG_ALL_RESET,
  GET_PRODUCTION_ORDER_BAG,
  GET_PRODUCTION_ORDER_BAG_SUCCESS,
  GET_PRODUCTION_ORDER_BAG_ERROR,
  GET_PRODUCTION_ORDER_BAG_RESET,
  UPDATE_PRODUCTION_ORDER_BAG,
  UPDATE_PRODUCTION_ORDER_BAG_SUCCESS,
  UPDATE_PRODUCTION_ORDER_BAG_ERROR,
  UPDATE_PRODUCTION_ORDER_BAG_RESET,
  DELETE_PRODUCTION_ORDER_BAG,
  DELETE_PRODUCTION_ORDER_BAG_SUCCESS,
  DELETE_PRODUCTION_ORDER_BAG_ERROR,
  DELETE_PRODUCTION_ORDER_BAG_RESET,
  CREATE_PRODUCTION_ORDER_BAG,
  CREATE_PRODUCTION_ORDER_BAG_SUCCESS,
  CREATE_PRODUCTION_ORDER_BAG_ERROR,
  CREATE_PRODUCTION_ORDER_BAG_RESET,
  PRODUCTION_ORDER_BAG_UPDATE_CURRENT,
  SELECTED_PRODUCTION_ORDER_BAG,
  SELECTED_PRODUCTION_ORDER_BAG_RESET,
} from '../types/production-order-bag';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_PRODUCTION_ORDER_BAG_ALL:
      return {
        ...state,
        productionOrderBagAll: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_PRODUCTION_ORDER_BAG_ALL_SUCCESS:
      return {
        ...state,
        productionOrderBagAll: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_PRODUCTION_ORDER_BAG_ALL_ERROR:
      return {
        ...state,
        productionOrderBagAll: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_PRODUCTION_ORDER_BAG_ALL_RESET:
      return {
        ...state,
        productionOrderBagAll: initialState.productionOrderBagAll,
      };
    case GET_PRODUCTION_ORDER_BAG:
      return {
        ...state,
        productionOrderBag: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_PRODUCTION_ORDER_BAG_SUCCESS:
      return {
        ...state,
        productionOrderBag: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_PRODUCTION_ORDER_BAG_ERROR:
      return {
        ...state,
        productionOrderBag: {
          data: null,
          states: {
            loading: false,
            error: true,
            success: false,
          },
        },
      };
    case PRODUCTION_ORDER_BAG_UPDATE_CURRENT:
      return {
        ...state,
        productionOrderBag: {
          ...state.productionOrderBag,
          data: action.payload,
        },
      };
    case GET_PRODUCTION_ORDER_BAG_RESET:
      return {
        ...state,
        productionOrderBag: initialState.productionOrderBag,
      };
    case UPDATE_PRODUCTION_ORDER_BAG:
      return {
        ...state,
        updateProductionOrderBag: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_PRODUCTION_ORDER_BAG_SUCCESS:
      return {
        ...state,
        updateProductionOrderBag: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_PRODUCTION_ORDER_BAG_ERROR:
      return {
        ...state,
        updateProductionOrderBag: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case UPDATE_PRODUCTION_ORDER_BAG_RESET:
      return {
        ...state,
        updateProductionOrderBag: initialState.updateProductionOrderBag,
      };
    case CREATE_PRODUCTION_ORDER_BAG:
      return {
        ...state,
        createProductionOrderBag: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case CREATE_PRODUCTION_ORDER_BAG_SUCCESS:
      return {
        ...state,
        createProductionOrderBag: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case CREATE_PRODUCTION_ORDER_BAG_ERROR:
      return {
        ...state,
        createProductionOrderBag: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case CREATE_PRODUCTION_ORDER_BAG_RESET:
      return {
        ...state,
        createProductionOrderBag: initialState.createProductionOrderBag,
      };
    case DELETE_PRODUCTION_ORDER_BAG:
      return {
        ...state,
        deleteProductionOrderBag: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_PRODUCTION_ORDER_BAG_SUCCESS:
      return {
        ...state,
        deleteProductionOrderBag: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_PRODUCTION_ORDER_BAG_ERROR:
      return {
        ...state,
        deleteProductionOrderBag: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case DELETE_PRODUCTION_ORDER_BAG_RESET:
      return {
        ...state,
        deleteProductionOrderBag: initialState.deleteProductionOrderBag,
      };

    case SELECTED_PRODUCTION_ORDER_BAG:
      return {
        ...state,
        selectedProductionOrderBag: { data: action.payload },
      };
    case SELECTED_PRODUCTION_ORDER_BAG_RESET:
      return {
        ...state,
        selectedProductionOrderBag: initialState.selectedProductionOrderBag,
      };

    default:
      return state;
  }
};

export default reducers;
