/* eslint-disable jsx-a11y/label-has-associated-control */
// Unexpected dangling '_' in '_id'
/* eslint no-underscore-dangle: 0 */

import { useContext,useEffect } from 'react';
import es from 'date-fns/locale/es';
import Select from 'react-select';


import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { NewOrderNoteContext } from '../../context/NewOrderNoteContext';

const LogisticsInformation = ({ handlerData, setIsDisable }) => {
  const [context, setContext] = useContext(NewOrderNoteContext);
  registerLocale('es', es);

  const handleOnChange = (name, value) => {
    if (name === 'firstDeliveryDate') {
      let fecha = new Date(value)
      fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
      setContext({
        ...context,
        [name]: fecha.toISOString(),
      });
    } else {
      if (name === "handler") {
        setIsDisable(true)
      }
      setContext({
        ...context,
        [name]: value,
      });
    }
  };
  const disable = () => {
    if (context.handler !== '') {
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }
  }
  useEffect(() => {
    disable()

  }, [])


  const deliveryTypeOptions = [
    { value: 'none', label: 'Ninguno' },
    { value: 'single', label: 'Única' },
    { value: 'weekly', label: 'Semanal' },
    { value: 'biweekly', label: 'Quincenal' },
    { value: 'monthly', label: 'Mensual' },
    { value: 'bimonthly', label: 'Bimensual' },
    { value: 'quarterly', label: 'Trimestral' },
    { value: 'bianual', label: 'Semestral' },
    { value: 'yearly', label: 'Anual' },
  ];

  const { firstDeliveryDate } = context;
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row mt-4">
              <div className="col-md-12 mb-3">
                <label htmlFor="handler" className="dmx-form-label">
                  Depósito (Obligatorio)
                </label>
                <Select
                  placeholder="Elija un depósito"
                  id="handler"
                  defaultValue={handlerData.filter((option) => option.value === context.handler)}
                  options={handlerData}
                  onChange={(option) => handleOnChange('handler', option.value)}
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="firstDeliveryDate" className="dmx-form-label">
                  Fecha de primer entrega
                </label>
                <input
                  type="date"
                  className="form-control digits"
                  id="firstDeliveryDate"
                  value={firstDeliveryDate? firstDeliveryDate.slice(0, 10):""}
                  onChange={(e) => handleOnChange('firstDeliveryDate', e.target.value)}
                />
              </div>


              <div className="col-md-12 mb-3">
                <label htmlFor="deliveryType" className="dmx-form-label">
                  Tipo de entrega
                </label>
                <Select
                  defaultValue={deliveryTypeOptions.filter((option) => option.value === 'none')}
                  inputid="deliveryType"
                  options={deliveryTypeOptions}
                  placeholder="Seleccione el método de entrega"
                  onChange={(option) => handleOnChange('deliveryType', option.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LogisticsInformation;
