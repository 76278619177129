import {
  CSSProperties,
  FunctionComponent,
  ReactElement,
} from "react";
import Select, { ClearIndicatorProps } from "react-select";
import { useField } from "formik";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
    loading: false,
  },
};

interface Option {
  label: string;
  value: string | number;
}

export interface GroupedOptions {
  label: string;
  options: Option[];
}

const CustomClearText: FunctionComponent = () => <></>;
const ClearIndicator = (props: ClearIndicatorProps<Option, true>) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props) as CSSProperties}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

interface ComponentProps {
  name: string;
  placeholder?: string;
  items?: Option | (Option | GroupedOptions)[];

  forwardRef?;
  menuIsOpen?: boolean;
  style?: any;

  error?: {};
  touched?: {};

  defaultValue?: Option | (Option | GroupedOptions)[];

  // closeMenuOnSelect?: boolean;
  // components?: any;

  disabled?: boolean;

  onChange?: (data: any) => void;
  onBlur?: (data: any) => void;
  onFocus?: (data: any) => void;

  onOptionSelected?: (option, actionMeta: any) => void;
  isOptionDisabled?: (option: any, optionSelected: any) => boolean;

  isMulti?: boolean;
  isClearable?: boolean;

  options?: {
    classes?: any;
    label?: string;
    skin?: "base" | "gray";
    size?: "md";
    helperText?: ReactElement;
    before?: any;
    after?: any;
    marginBottom?: number;
    hidden?: boolean;
    noOptionsText?: string;
    loading?: boolean;
    css?: string;
  };
}

const Component = ({
  options,
  name,
  items,
  error,
  touched,
  forwardRef,
  onChange,
  onBlur,
  onFocus,
  onOptionSelected,
  isOptionDisabled,
  style,
  disabled,
  isMulti,
}: ComponentProps) => {
  const [field, meta, fieldHelpers] = useField({ name });

  const hasError = !!error && !!touched;

  const {
    label,
    loading,
    skin,
    size = "md",
    marginBottom,
    noOptionsText,
  } = {
    ...initialValue.options,
    ...options,
  };

  const getValue = () => {
    if (items) {
      const itemsFlat = (items as any[])
        .map((fieldOption) => {
          if (fieldOption.options) {
            return fieldOption.options;
          }

          return fieldOption;
        })
        .flat();

      return isMulti
        ? itemsFlat.filter((option) => field.value?.indexOf(option.value) >= 0)
        : itemsFlat.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : ("" as any);
    }
  };

  const handleOnChange = (option, actionMeta) => {
    if (isMulti) {
      const values = option.map((item) => item.value);
      const e = {
        target: {
          name: name,
          value: values,
        },
      };

      !!onChange && onChange(e);
    } else {
      const e = {
        target: {
          name: name,
          value: option.value,
        },
      };

      !!onChange && onChange(e);
    }

    if (!!onOptionSelected) {
      onOptionSelected(isMulti ? (option as Option[]) : (option as Option), {
        ...actionMeta,
        ...fieldHelpers,
      });
    }
  };

  return (

    <div className={`form-group`}>
      {!!label && <label className="col-form-label pt-0">{label}</label>}

      <Select
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: 'primary',
          },
        })}
        styles={{
          option: (provided, state) => {
            const newOpt = {
              ...provided,
            };

            if (state.isSelected || state.isFocused) {
              return {
                ...newOpt,
                backgroundColor: 'var(--primary)',
                color: 'var(--white)',

                ':focus': {
                  backgroundColor: 'var(--dark)',
                  color: 'var(--white)',
                },

                ':hover': {
                  backgroundColor: 'var(--dark)',
                  color: 'var(--white)',
                },
              };
            }

            return newOpt;
          },
          control: (provided, state) => {
            const result = {
              ...provided,
              borderRadius: '.5rem'
            };

            if (state.isSelected || state.isFocused) {
              return {
                ...provided,

                ':hover': {
                  color: 'white',
                  borderColor: '#80bdff',
                  boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                },
                ':focus': {
                  color: 'white',
                  borderColor: '#80bdff',
                  boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                },

                color: 'white',
                borderColor: 'var(--primary)',
                boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
              };
            }

            return result;
          },

          valueContainer: (base) => ({
            ...base,
            fontSize: '14px',
            overflowX: 'hidden',
            fontFamily: 'work-Sans, sans-serif',
            color: '#313131',
          }),
        }}
        {...field}
        options={items}
        classNamePrefix="select"
        menuPlacement="auto"
        isDisabled={disabled}
        onChange={handleOnChange}
        onBlur={onBlur}
        onFocus={onFocus}
        isOptionDisabled={isOptionDisabled}
        isMulti={isMulti}
        value={getValue()}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? noOptionsText : "No hay resultados"
        }
        {...(forwardRef && { ref: forwardRef })}
      />

      {!!error && !!touched && <div className="input-feedback">{error}</div>}
    </div>
  );
};

export default Component;
