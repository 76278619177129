import { useState, useEffect } from 'react';
import getClients from '../services/getClients';

const useClientsFetcher = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getClients()
      .then((clients) => {
        setData(clients);
        setIsLoading(false);
        setIsError(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return { data, isLoading, isError };
};

export default useClientsFetcher;
