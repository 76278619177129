import { getToken } from '../utils/authUtils';

const deleteOrderNote = (orderId) => {
  return fetch(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }).then((response) => response.json());
};

export default deleteOrderNote;
