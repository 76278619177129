import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import DmxForm from './DmxForm';

const DmxModal = (props) => {
  const { action, data, icon = '', isOpen, title, toggle, isEditable, purchaseConditions } = props;

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isOpen} toggle={toggle} centered className="dmx-modal-width">
        <ModalHeader toggle={toggle}>
          {icon} {title}
        </ModalHeader>
        <ModalBody>
          <DmxForm data={data} action={action} isEditable={isEditable} purchaseConditions={purchaseConditions} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DmxModal;
