/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import ObsForm from '../ObsForm';
import 'react-dragswitch/dist/index.css';

const FeedstockPlusBox = ({ feedstockData, isEditable, handleChange }) => {
  return (
    <Col xl="6" sm="6">
      <Card>
        <CardBody className="card-body-detail">
          <div className="project-widgets mt-2 infoCard">
            <div className="mb-1 dmx-underline">
              <span className="text-muted">Fecha de modificación: </span>
              <input
                className="font-weight-bold p-2"
                disabled
                name="modificationDate"
                value={
                  (feedstockData.modificationDate && moment(feedstockData.modificationDate).format('DD/MM/YYYY')) || ''
                }
              />
            </div>
          </div>
          <div className="project-widgets mt-2 infoCard">
            <div className="mb-1 dmx-underline">
              <span className="text-muted">Stock: </span>
              <input className="font-weight-bold p-2" name="stock" disabled value={feedstockData.stock || 0} />
            </div>
          </div>
          <ObsForm isEditable={isEditable} handleChange={handleChange} observations={feedstockData.observations} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default FeedstockPlusBox;
