import styled from 'styled-components';
import { Button } from 'reactstrap';

export const ButtonEdit = styled(Button)`
  border: 0px;
  margin-right: 10px;

  &:hover,
  &:focus {
    color: #1189c6;
    background-color: transparent !important;
    border-radius: transparent !important;
  }
`;

export const State = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  color: white;
  background-color: #4466f2;
  padding: 0px 15px;
  border-radius: 8px;
  min-width: 129px;
  text-align: center;
`;

export const SelectStateContainer = styled('div')`
  display: flex;
  align-items: center;
`;
