import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import '../../../pages/OrderDetail/OrderDetail.scss';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Breadcrumb = (props) => {
  const breadcrumb = props;

  return (
    <>
      <ol className="breadcrumb-style">
        <li className="breadcrumb-item">
          <Link to={breadcrumb.parentLink} className="sidebar-header active">
            <button
              className="btn btn-primary"
              type="button">
              <FontAwesomeIcon icon={faArrowLeft} />  Volver
              </button>
          </Link>
        </li>
        </ol>
    </>
  );
};

export default Breadcrumb;
