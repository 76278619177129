import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import List from './List';
import Preview from './Preview';
import Print from './Print'

const Component = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/list`} component={List} />
      <Route exact path={`${path}/:idWorkshop`} component={Preview} />
      <Route exact path={`${path}/print/:idWorkshop`} component={Print} />
      <Route path={`*`} render={() => <Redirect to={`${path}/list`} />} />
    </Switch>
  );
};

export default Component;
