/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint no-underscore-dangle: 0 */
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import SweetAlert from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import postOrderNote from '../../services/postOrderNote';
import { NewOrderNoteContext } from '../../context/NewOrderNoteContext';
import 'react-toastify/dist/ReactToastify.css';

const Observations = () => {
  const [context, setContext] = useContext(NewOrderNoteContext);
  const history = useHistory();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const transformContextToOrder = () => {
    const order = {
      deliveryPercent: 0,
      currency: context.paymentCurrency,
      paymentType: context.paymentType,
      client: context.client,
      orderNoteId: parseInt(context.orderNoteId, 10),
      deliveryAddress: context.deliveryAddress,
      firstDeliveryDate: context.firstDeliveryDate,
      deliveryType: context.deliveryType,
      seller: context.seller,
      status: 'processing',
      observations: context.orderNoteNotes,
      handler: context.handler,
    };
    return order;
  };

  function getRequiredFormFields() {
    return [
      { name: 'Nro de Nota de Pedido', isEmpty: context.orderNoteId },
      { name: 'Nombre (Razón Social)', isEmpty: context.client._id },
      // { name: 'Dirección de entrega', isEmpty: context.deliveryAddress },
      { name: 'Fecha de primer entrega', isEmpty: context.firstDeliveryDate },
      { name: 'Tipo de entrega', isEmpty: context.deliveryType },
      // { name: 'Vendedor', isEmpty: context.seller._id },
      { name: 'Moneda', isEmpty: context.paymentCurrency },
      // { name: 'Forma de pago', isEmpty: context.paymentType },
    ];
  }

  function emptyFieldExist() {
    const fields = getRequiredFormFields();
    const emptyField = (field) => field.isEmpty === '';

    return fields.some(emptyField);
  }

  function getEmptyFieldsList() {
    const fields = getRequiredFormFields();
    const emptyFields = fields.filter((field) => field.isEmpty === '');

    return emptyFields.map((emptyField) => emptyField.name);
  }

  async function CreateOrderNote() {
    const newOrder = transformContextToOrder();
    try {
      const result = await postOrderNote(newOrder);
      const { _id } = result;
      toast.success('La nota de pedido ha sido creada. En unos instantes podrá editarla.', {
        onClose: () => history.push(`/orderdetail?id=${_id}&edit=true`),
        autoClose: 3000
      });
    } catch (error) {
      let errorMsg = error.msg;
      if (errorMsg === 'ORDER_ALREADY_EXISTS') {
        errorMsg = 'El número de orden ya existe.';
      }
      SweetAlert.fire({
        title: 'Un error ha ocurrido',
        html: `<p>No se ha podido crear la nota de pedido por la siguiente razón:</p><pre>${errorMsg}</pre>`,
        confirmButtonText: 'Ok',
      });
    }
  }

  const handleOnClick = (event) => {
    event.preventDefault();

    if (emptyFieldExist()) {
      const emptyFieldsList = getEmptyFieldsList()
        .map((field) => `<li>${field}</li>`)
        .join('');
      SweetAlert.fire({
        title: 'Hay campos sin completar',
        html: `<p>La orden no puede ser creada con los siguientes campos vacíos:</p>
          <ul>${emptyFieldsList}</ul>`,
        confirmButtonText: 'Ok',
      });
    } else {
      setDisableSubmit(true);
      CreateOrderNote();
    }
  };

  const handleOnChange = (name, value) => {
    setContext({ ...context, [name]: value });
  };

  const { orderNoteNotes } = context;
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row mt-4">
              <div className="col-md-12 mb-3">
                <label htmlFor="orderNoteNotes">Observaciones</label>
                <textarea
                  id="orderNoteNotes"
                  className="form-control"
                  placeholder="Escriba aquí sus observaciones"
                  value={orderNoteNotes}
                  onChange={(event) => handleOnChange('orderNoteNotes', event.target.value)}
                />
              </div>
              <button
                className="btn btn-primary r-7 btnsubmit"
                onClick={(event) => handleOnClick(event)}
                type="submit"
                disabled={disableSubmit}
              >
                Finalizar
              </button>
              <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Observations;
