// Mixins
import { SelectCustom } from './mixins';

const Component = ({ data, onChange }) => {
  if (!data || data.length === 0) {
    return null;
  }
  const hasUnitsOtions = [
    { label: 'Metros', value: false },
    { label: 'Unidades', value: true },
  ];

  const measures = [
    { label: 'Milimetros', value: 'mm' },
    { label: 'Centimetros', value: 'cm' },
    { label: 'Metros', value: 'mts' },
    { label: 'Unidades', value: 'units' },
  ];

  return (
    <>
      {data.map((attributes) => {
        const { id, label, name, values } = attributes;
        const placeholder = `Seleccione el ${label.toLowerCase()}`;
        return (
          <div key={id}>
            <label htmlFor={name} className="text-capitalize">
              * {label}:
            </label>

            <SelectCustom
              placeholder={placeholder}
              options={values}
              id={name}
              name={name}
              onChange={(option) => onChange(name, option)}
            />
          </div>
        );
      })}

      <div>
        <label htmlFor="hasUnits" className="text-capitalize">
          Unidad de medida:
        </label>

        <SelectCustom
          options={measures}
          defaultValue={measures[2]}
          id="hasUnits"
          name="hasUnits"
          onChange={(option) => {
            if (option.value === 'units') {
              onChange('hasUnits', hasUnitsOtions[1]);
              onChange('measures', option);
            } else {
              onChange('hasUnits', option);
              onChange('measures', option);
            }
          }}
        />
      </div>
    </>
  );
};

export default Component;
