import styled from 'styled-components'
import { CardBody } from 'reactstrap';

const styledCardBody = `
  .item-group {
    width: 100%;
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &.item-group-observations {
      align-items: flex-start;

      .item-label {
        margin-top: 10px;
      }
    }

    .item-label {
      font-size: 14px;
      margin-right: 10px;
      color: #6c757d;

      @media (min-width: 1024px) {
        font-size: 18px;
      }
    }

    .item-value {
      width: 50%;
      font-size: 14px;

      @media (min-width: 768px) {
        width: 60%;
      }

      @media (min-width: 998px) {
        width: 70%;
      }

      @media (min-width: 1024px) {
        width: 50%;
        font-size: 18px;
      }

      input {
        background-color: transparent;
        color: #000;
      }
    }

    label {
      padding-top: 5px;

      @media (min-width: 1024px) {
        padding-top: 0px;
        vertical-align: -2px;
      }
    }

    .react-datepicker-wrapper {
      display: inline-block !important;

      @media (min-width: 1024px) {
        width: 55%;
      }

      .item-date-picker {
        background-color: #ffffff;
        color: #000;
        font-size: 14px;

        @media (min-width: 1024px) {
          font-size: 18px;
        }
      }
    }

    .item-select {
      display: inline-block;
      width: 100%;

      @media (min-width: 1024px) {
        width: 57%;
      }

      & > div {
        & > div {
          &:first-child {
            background-color: #ffffff;
            padding: 0px;
            border: 0px;
            height: 38px;
          }

          &:last-child {
            display: none;
          }

          & > div {
            color: #000000;
            font-size: 14px;

            @media (min-width: 1024px) {
              font-size: 18px;
            }
          }
        }
      }
    }

    .form-check {
      display: none;
    }
  }
`

export const CardBodyCustom = styled(CardBody)`
  ${styledCardBody}
`

export const Heading = styled('div')`
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #eee;
`

export const Title = styled('h5')`
  margin: 0 0 0 10px;
`

export const EmptyState = styled('div')`
  background-color: #f5f5f5;
  width: 100%;
  color: #999;
  line-height: 70px;
  font-weight: 600;
  text-align: center;
`
