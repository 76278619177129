import { useEffect, useState } from 'react';
import './Modal.scss';

const Form = ({ data, readOnly, batchIndex, moveIndex, updateObsevation }) => {
  const [observation, setObservation] = useState(data.observations);

  const handleChange = (event) => {
    setObservation(event.currentTarget.value);
  };

  useEffect(() => {
    updateObsevation(observation, batchIndex, moveIndex);
  }, [observation]);
  return <textarea disabled={readOnly} className="observations-area" value={observation} onChange={handleChange} />;
};

export default Form;
