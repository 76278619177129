export const getTotalBultos = (newData) => {
  let totalQuantity = 0;

  Object.keys(newData.productList).forEach((productIndex) => {
    const productRow = newData.productList[productIndex];

    Object.keys(productRow.partialDelivery).forEach((partialIndex) => {
      const packageQuantity = productRow.partialDelivery[partialIndex].packagesQuantity;
      totalQuantity += packageQuantity;
    });
  });

  return totalQuantity;
};
