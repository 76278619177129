import styled from 'styled-components';
import { CardTitle } from 'reactstrap';

export const CardText = styled('div')`
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const ColumnRight = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  @media (min-width: 1024px) {
    margin-top: 0px;
  }
`;

export const Label = styled('span')`
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
`;

export const CardTitleCustom = styled(CardTitle)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const InputQuantity = styled('input')`
  line-height: 30px;
  padding: 0px 10px;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;

  &:focus {
    outline-color: #ccc;
    border: 1px solid #ccc;
  }

  @media (min-width: 1024px) {
    margin-top: 0px;
    margin-left: 15px;
  }
`;

export const Input = styled('input')`
  width: 15px;
  height: 15px;
  margin-left: 10px;
  vertical-align: -2px;
`;
export const InputDate = styled('input')`
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 0px;
  margin-left: 15px;
  line-height: 30px;
  padding: 0px 10px;
`;

export const Group = styled('div')`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    align-items: center;
    flex-direction: row;
  }
`;

export const GroupBaseline = styled('span')`
  display: flex;
  align-items: baseline;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const ContainerResultOrdersSelected = styled('div')`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    margin-top: 0px;
    margin-left: 15px;
  }
`;

export const ItemOrder = styled('div')`
  background-color: #ccc;
  padding: 0px 10px;
  border-radius: 4px;
  line-height: 28px;
  margin-left: 15px;
`;
