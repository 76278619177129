import React from 'react';

// Fontawesome Icons
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// My imports
import PartialDelivery from '../PartialDelivery';
import { ARS, USD } from '../../../constant';

// Styles
import '../Invoice.scss';

const ProductDetail = ({ order, noProductsText }) => {
  // Icons
  const iconDollar = <FontAwesomeIcon icon={faDollarSign} />;

  // Data
  const { currency, productList } = order;

  // Product List
  const noProducts = <div className="text-uppercase text-center there-are-no-products">{noProductsText}</div>;

  function getTotalOrder(currency) {
    const totalRowPrice = [];
    productList.forEach((row) => totalRowPrice.push(row.price * row.quantity).toFixed(currency === 'dollars' ? 4 : 2));
    const price = totalRowPrice.reduce((a, b) => a + b, 0);

    if (currency === 'dollars') {
      // Format as dollars with a comma for thousands and four decimal places for cents
      const parts = price.toFixed(4).toString().split('.');
      const dollars = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const cents = parts[1] || '0000'; // Handle missing cents, default to '0000' for four decimal places
      return '$' + dollars + '.' + cents;
    } else if (currency === 'pesos') {
      // Format as Argentine pesos with two decimal places
      return '$' + price.toFixed(2).replace(/\d(?=(\d{3})+\,)/g, '$&.');
    } else {
      // Handle other currencies or unsupported currencies
      return 'Unsupported currency';
    }
  }


  const products = productList.map((p, index) => {
    const { quantity, price, packed, product, sterilized, partialDelivery } = p;
    const { name, code: codigo } = product;
    const deliveredQuantity = partialDelivery.reduce((accumulator, current) => accumulator + current.quantity, 0);
    const packaging = packed.reduce((accumulator, current) => `${accumulator}${current.name}, `, '');

    return (
      <>
        <tr className="prod-row">
          <td className="text-uppercase">{index + 1}</td>
          <td className="text-uppercase">{`${codigo} ${name}`}</td>
          <td className="text-uppercase">{packaging}</td>
          <td className="text-uppercase">{sterilized ? 'SÍ' : 'NO'}</td>
          <td className="text-uppercase">{quantity}</td>
          <td className="text-uppercase">{deliveredQuantity}</td>
          <td className="text-uppercase">{quantity - deliveredQuantity}</td>
          <td className="text-uppercase">
            {currency === 'pesos' ? ARS : USD} {(price).toFixed(4)}
          </td>
          <td className="text-uppercase">
            {currency === 'pesos' ? ARS : USD} {(price * quantity).toFixed(4)}
          </td>

        </tr>

        <PartialDelivery data={partialDelivery} />

      </>

    );
  });

  return (
    <div className="body-pdf table-responsive">
      <table className="table">
        <thead>

          <tr>
            <th>#</th>
            <th width="30%">ARTÍCULO</th>
            <th>ENVASADO</th>
            <th>ESTÉRIL</th>
            <th>
              CANTIDAD
              <br />
              PEDIDA
            </th>
            <th>
              CANTIDAD
              <br />
              ENTREGADA
            </th>
            <th>
              CANTIDAD
              <br />
              RESTANTE
            </th>
            <th>{iconDollar} POR UNIDAD</th>
            <th>{iconDollar} TOTAL</th>
          </tr>
        </thead>

        <tbody>{products}</tbody>

      </table>

      {getTotalOrder(currency) != 0 && <div><hr size="5px" color="black" />
        <h4 style={{ float: 'right' }}>
          Total {currency !== 'dollars' ? '$: ' : 'U$D: '}
          {getTotalOrder(currency)}
        </h4></div>}

      {products.length === 0 ? noProducts : ''}
    </div>
  );
};

export default ProductDetail;
