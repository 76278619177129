/* eslint no-underscore-dangle: 0 */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint no-shadow: 0 */

import React, { useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import _ from 'underscore';
import Select from 'react-select';
import patchClient from '../../services/patchClient';
import { ClientContext } from '../../context/ClientContext';
import postClient from '../../services/postClient';
import { CREATE, READ, UPDATE } from '../../constant';
import SpinnerButton from '../../components/Button';
// Select

const ClientForm = (props) => {
  const { clientContext, modalContext } = useContext(ClientContext);
  const [modal, setModal] = modalContext;
  const [client, setClient] = clientContext;

  const { data, action, isEditable, purchaseConditions } = props;

  const purchaseConditionsData = purchaseConditions?.data?.map((purchaseCondition) => ({
    label: purchaseCondition.code
      ? `${purchaseCondition.code} - ${purchaseCondition.description}`
      : `${purchaseCondition.description}`,
    value: {
      // eslint-disable-next-line no-underscore-dangle
      _id: purchaseCondition._id,
      code: purchaseCondition.code,
      description: purchaseCondition.description,
    },
  }));

  const handleSubmit = async (values, { setSubmitting }) => {
    if (action === UPDATE) {
      patchClient(values).then((res) => {
        if (res.errors) {
          toast.error(`El cliente no se pudo modificar porque ocurrió un error: ${res.errors.msg}`, {autoClose: 3000});
        } else {
          const clientIndex = client.findIndex((c) => c._id === values._id);
          const newData = _.clone(client);
          newData[clientIndex] = values;
          setClient(newData);
          setModal(!modal);
          setSubmitting(false);
          toast.success('El cliente ha sido modificado.', {autoClose: 3000});
        }
      });
    }

    if (action === CREATE) {
      postClient(values).then((res) => {
        if (res.errors) {
          toast.error(`El cliente no se pudo crear porque ocurrió un error: ${res.errors.msg}`, {autoClose: 3000});
        } else {
          const newData = _.clone(client);
          const newValues = { ...values, _id: res._id };
          newData.push(newValues);
          setClient(newData);
          setModal(!modal);
          setSubmitting(false);
          toast.success('El cliente ha sido creado.', {autoClose: 3000});
        }
      }).catch((error) => {
        const messages = {
          'CLIENT_ALREADY_EXISTS': 'El cliente ya existe.'
        }
        toast.error(`El cliente no se pudo crear porque ocurrió un error: ${messages[error.msg]}`, {autoClose: 3000});
      });
    }
  };

  const editInitialValues = {
    _id: data._id || '',
    code: data.code || '',
    brandName: data.brandName || '',
    companyName: data.companyName || '',
    cuit: data.cuit || '',
    phone: data.phone || '',
    email: data.email || '',
    address: data.address || '',
    deliveryAddress: data.deliveryAddress || '',
    shipment: data.shipment || '',
    paymentType: data.paymentType || '',
    anmat: data.anmat || false,
    province: data.province || '',
    locality: data.locality || '',
    purchaseCondition: data.purchaseCondition || '',
    deliveryTime: data.deliveryTime || '',
    sanitaryDocumentation: data.sanitaryDocumentation || false,
  };

  const createInitialValues = {
    code: '',
    brandName: '',
    companyName: '',
    cuit: '',
    phone: '',
    email: '',
    address: '',
    deliveryAddress: '',
    shipment: '',
    paymentType: '',
    anmat: false,
    province: '',
    locality: '',
    purchaseCondition: '',
    deliveryTime: '',
    sanitaryDocumentation: false,
  };

  const inputColor = 'black';

  // StyleSelect
  const styleSelect = {
    control: (base) => ({
      ...base,
      border: '1px solid #ced4da',
      boxShadow: 'none',
      padding: 0,
      fontSize: '0.87rem',
    }),
  };

  return (
    <Formik
      initialValues={action === UPDATE || action === READ ? editInitialValues : createInitialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        code: Yup.string().required('Este campo es requerido'),
      })}
    >
      {(props) => {
        const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } = props;
        // const handlePurchaseConditionChange = (entityName, value) => {
        //   setData({
        //     ...data,
        //     [entityName]: value,
        //   });
        // };
        return (
          <form className="theme-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                {/* code */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Código</label>
                  <input
                    className={errors.code && touched.code ? 'form-control error-field' : 'form-control'}
                    type="text"
                    name="code"
                    placeholder="Ingrese código"
                    disabled={isSubmitting || action === UPDATE || action === READ || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.code}
                  />
                  {errors.code && touched.code && <div className="input-feedback">{errors.code}</div>}
                </div>
                {/* brandName */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Nombre</label>
                  <input
                    className={errors.brandName && touched.brandName ? 'form-control error-field' : 'form-control'}
                    type="text"
                    name="brandName"
                    placeholder="Ingrese nombre"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.brandName}
                    style={{ color: inputColor }}
                  />
                  {errors.brandName && touched.brandName && <div className="input-feedback">{errors.brandName}</div>}
                </div>
                {/* companyName */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Razón Social</label>
                  <input
                    className={errors.companyName && touched.companyName ? 'form-control error-field' : 'form-control'}
                    type="text"
                    name="companyName"
                    placeholder="Ingrese razón social"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                    style={{ color: inputColor }}
                  />
                  {errors.companyName && touched.companyName && (
                    <div className="input-feedback">{errors.companyName}</div>
                  )}
                </div>
                {/* cuit */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">CUIT</label>
                  <input
                    className={errors.cuit && touched.cuit ? 'form-control error-field' : 'form-control'}
                    type="number"
                    name="cuit"
                    placeholder="Ingrese cuit"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cuit}
                    style={{ color: inputColor }}
                  />
                  {errors.cuit && touched.cuit && <div className="input-feedback">{errors.cuit}</div>}
                </div>
                {/* phone */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Teléfono</label>
                  <input
                    className={errors.phone && touched.phone ? 'form-control error-field' : 'form-control'}
                    type="tel"
                    name="phone"
                    placeholder="Ingrese teléfono"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    style={{ color: inputColor }}
                  />
                  {errors.phone && touched.phone && <div className="input-feedback">{errors.phone}</div>}
                </div>
                {/* email */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Email</label>
                  <input
                    className={errors.email && touched.email ? 'form-control error-field' : 'form-control'}
                    type="email"
                    name="email"
                    placeholder="Ingrese email"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    style={{ color: inputColor }}
                  />
                  {errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
                </div>
                {/* address */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Dirección Fiscal/Comercial</label>
                  <input
                    className={errors.address && touched.address ? 'form-control error-field' : 'form-control'}
                    type="text"
                    name="address"
                    placeholder="Ingrese dirección fiscal/comercial"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                    style={{ color: inputColor }}
                  />
                  {errors.address && touched.address && <div className="input-feedback">{errors.address}</div>}
                </div>
                {/* deliveryAddress */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Dirección de Entrega</label>
                  <input
                    className={
                      errors.deliveryAddress && touched.deliveryAddress ? 'form-control error-field' : 'form-control'
                    }
                    type="text"
                    name="deliveryAddress"
                    placeholder="Ingrese dirección de entrega"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.deliveryAddress}
                    style={{ color: inputColor }}
                  />
                  {errors.deliveryAddress && touched.deliveryAddress && (
                    <div className="input-feedback">{errors.deliveryAddress}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                {/* shipment */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Transporte</label>
                  <input
                    className={errors.shipment && touched.shipment ? 'form-control error-field' : 'form-control'}
                    type="text"
                    name="shipment"
                    placeholder="Ingrese transporte"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.shipment}
                    style={{ color: inputColor }}
                  />
                  {errors.shipment && touched.shipment && <div className="input-feedback">{errors.shipment}</div>}
                </div>
                {/* paymentType */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Forma De Pago</label>
                  <input
                    className={errors.paymentType && touched.paymentType ? 'form-control error-field' : 'form-control'}
                    type="text"
                    name="paymentType"
                    placeholder="Ingrese forma de pago"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.paymentType}
                    style={{ color: inputColor }}
                  />
                  {errors.paymentType && touched.paymentType && (
                    <div className="input-feedback">{errors.paymentType}</div>
                  )}
                </div>
                {/* province */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Provincia</label>
                  <input
                    className={errors.province && touched.province ? 'form-control error-field' : 'form-control'}
                    type="text"
                    name="province"
                    placeholder="Ingrese provincia"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.province}
                    style={{ color: inputColor }}
                  />
                  {errors.province && touched.province && <div className="input-feedback">{errors.province}</div>}
                </div>
                {/* locality */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Localidad</label>
                  <input
                    className={errors.locality && touched.locality ? 'form-control error-field' : 'form-control'}
                    type="text"
                    name="locality"
                    placeholder="Ingrese localidad"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.locality}
                    style={{ color: inputColor }}
                  />
                  {errors.locality && touched.locality && <div className="input-feedback">{errors.locality}</div>}
                </div>
                {/* purchaseCondition */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Condición de Venta</label>
                  <Select
                    className={errors.purchaseCondition && touched.purchaseCondition ? 'error-field' : ''}
                    isDisabled={isSubmitting || !isEditable}
                    onChange={(e) => setFieldValue('purchaseCondition', e.label)}
                    onBlur={handleBlur}
                    defaultValue={purchaseConditionsData.filter((option) => option.label === values.purchaseCondition)}
                    options={purchaseConditionsData}
                    styles={styleSelect}
                  />
                  {errors.purchaseCondition && touched.purchaseCondition && (
                    <div className="input-feedback">{errors.purchaseCondition}</div>
                  )}
                </div>
                {/* deliveryTime */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Horario de Entrega</label>
                  <input
                    className={
                      errors.deliveryTime && touched.deliveryTime ? 'form-control error-field' : 'form-control'
                    }
                    type="text"
                    name="deliveryTime"
                    placeholder="Ingrese horario de entrega"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.deliveryTime}
                    style={{ color: inputColor }}
                  />
                  {errors.deliveryTime && touched.deliveryTime && (
                    <div className="input-feedback">{errors.deliveryTime}</div>
                  )}
                </div>
                {/* anmat */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">ANMAT</label>
                  <input
                    className={
                      errors.anmat && touched.anmat
                        ? 'form-control error-field dmx-modal-custom-checkbox'
                        : 'form-control dmx-modal-custom-checkbox'
                    }
                    type="checkbox"
                    name="anmat"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.anmat || false}
                    style={{ color: inputColor }}
                  />
                  {errors.anmat && touched.anmat && <div className="input-feedback">{errors.anmat}</div>}
                </div>
                {/* sanitaryDocumentation */}
                <div className="form-group">
                  <label className="col-form-label pt-0 font-weight-bold">Documentación Sanitaria</label>
                  <input
                    className={
                      errors.sanitaryDocumentation && touched.sanitaryDocumentation
                        ? 'form-control error-field dmx-modal-custom-checkbox'
                        : 'form-control dmx-modal-custom-checkbox'
                    }
                    type="checkbox"
                    name="sanitaryDocumentation"
                    disabled={isSubmitting || !isEditable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.sanitaryDocumentation || false}
                    style={{ color: inputColor }}
                  />
                  {errors.sanitaryDocumentation && touched.sanitaryDocumentation && (
                    <div className="input-feedback">{errors.sanitaryDocumentation}</div>
                  )}
                </div>
              </div>
            </div>

            {action === READ ? (
              ''
            ) : (
              <div className="form-group form-row mt-3 mb-0">
                <SpinnerButton
                  className="btn btn-primary btn-block dmx-modal-save-button"
                  isLoading={isSubmitting}
                  disabled={!isEditable}
                  type="submit"
                >
                  Guardar
                </SpinnerButton>
              </div>
            )}

            {errors.loginerror && <div className="input-feedback">{errors.loginerror}</div>}
          </form>
        );
      }}
    </Formik>
  );
};

export default ClientForm;
