import styled from 'styled-components';

export const Batches = styled('div')`
  &.table-responsive {
    table {
      thead {
        background-color: #eee;
        height: 65px;
      }

      tbody {
        tr {
          border-bottom: 1px solid #eeeeee;

          &.product-cut {
            border-bottom: 2px solid black;
          }

          td {
            padding: 5px 10px;
          }
        }
      }
    }
  }
`;
