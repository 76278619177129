export const rules = {
  reception: {
    order_status: ['released_partially', 'released'],
    static: ['np:list', 'np:edit:file'],
    dynamic: {
      'np:view': (props) => {
        if (!props?.status) return false;
        return ['released_partially', 'released', 'archived'].includes(props?.status);
      },
      'np:edit': (props) => {
        if (!props?.status) return false;
        return ['released_partially', 'released', 'archived'].includes(props?.status);
      },
    },
  },
  viewer: {
    order_status: ['processing', 'ready', 'approved', 'preparation', 'released', 'released_partially'],
    static: [
      'np:list',
      'np:view',
      'mp:list',
      'product:list',
      'client:list',
      'op:list',
      'op:view',
      'op:list:type',
      'op:list:state',

      'ws:list',
      'ws:preview',
    ],
    dynamic: {
      'op:edit:state': ({ status }) => {
        if (!status) return false;
        return [].includes(status);
      },
    },
  },
  admin: {
    order_status: ['processing', 'ready', 'approved', 'preparation', 'released', 'released_partially'],
    static: [
      'np:list',
      'np:view',
      'np:create',
      'np:edit',
      'np:archive',
      'np:delete',
      'np:edit:state',
      'np:edit:product',

      'np:edit:product:observations',
      'np:vis:product:observations',
      'np:delete:product',

      'np:edit:partial_delivery',
      'np:create:partial_delivery',

      'np:edit:logistic:handler',
      'np:edit:logistic:firstDeliveryDate',
      'np:edit:logistic:deliveryType',
      'np:edit:logistic:orderDeliveryAddress',
      'np:edit:logistic:orderDeliveryTime',
      'np:edit:logistic:transport',
      'np:edit:logistic:packagesQuantity',
      'np:edit:logistic:takeAway',

      'np:edit:billing:seller',
      'np:edit:billing:currency',
      'np:edit:billing:orderPurchaseCondition',
      'np:edit:billing:orderNumber',
      'np:edit:billing:invoiced',

      'mp:list',
      'mp:edit',
      'mp:delete',
      'mp:create',
      'mp:edit:stockControl',

      'op:list',
      'op:list:type',
      'op:list:state',
      'op:edit',

      'op:delete',
      'op:create',
      'op:create:type',
      'op:view',

      'product:list',
      'product:edit',
      'product:create',

      'client:list',
      'client:edit',
      'client:create',

      'ws:list',
      'ws:preview',
      'ws:edit',
      'ws:delete',
      'ws:create',
    ],
    dynamic: {
      'op:edit:state': ({ status }) => {
        if (!status) return false;
        return ['requested', 'pending', 'inProgress', 'done'].includes(status);
      },
      'op:edit:poInformation': ({ status }) => {
        if (!status) return false;
        return ['requested', 'pending', 'inProgress'].includes(status);
      },
      'op:edit:unidadesFabricar': ({ status }) => {
        if (!status) return false;
        return ['requested', 'pending', 'inProgress'].includes(status);
      },
      'op:edit:cuttingTable': ({ status }) => {
        if (!status) return false;
        return ['requested', 'pending', 'inProgress'].includes(status);
      },
      'op:edit:bags': ({ status }) => {
        if (!status) return false;
        return ['requested', 'pending', 'inProgress'].includes(status);
      },
      'op:edit:feedStock': ({ status }) => {
        if (!status) return false;
        return ['requested', 'pending', 'inProgress'].includes(status);
      },
      'op:edit:batch': ({ status }) => {
        if (!status) return false;
        return ['requested', 'pending', 'inProgress'].includes(status);
      },
      'op:edit:observations': ({ status }) => {
        if (!status) return false;
        return ['requested', 'pending', 'inProgress'].includes(status);
      },
    },
  },
  seller: {
    order_status: ['processing', 'ready', 'approved', 'preparation', 'released', 'released_partially'],
    static: [
      'np:list',
      'np:create',
      'np:edit:product',
      'np:delete:product',

      'np:edit:product:observations',
      'np:vis:product:observations',
      'np:edit:logistic:handler',
      'np:edit:logistic:firstDeliveryDate',
      'np:edit:logistic:deliveryType',
      'np:edit:logistic:orderDeliveryAddress',
      'np:edit:logistic:orderDeliveryTime',
      'np:edit:logistic:transport',
      'np:edit:logistic:packagesQuantity',
      'np:edit:logistic:takeAway',

      'np:edit:billing:seller',
      'np:edit:billing:currency',
      'np:edit:billing:orderPurchaseCondition',
      'np:edit:billing:orderNumber',
      'np:edit:billing:invoiced',

      'product:list',
      'client:list',
      'mp:list',
      'product:view:files',
    ],
    dynamic: {
      'np:edit:state': (prop) => {
        if (!prop?.status) return false;
        return ['processing', 'ready'].includes(prop?.status);
      },
      'np:edit:product': (prop) => {
        if (!prop?.status) return false;
        return ['new', 'processing', 'ready'].includes(prop?.status);
      },
      'np:edit:product:observations': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:logistic:handler': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:logistic:firstDeliveryDate': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:logistic:deliveryType': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:logistic:orderDeliveryAddress': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:logistic:orderDeliveryTime': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:logistic:transport': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:logistic:packagesQuantity': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:logistic:takeAway': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:billing:seller': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:billing:currency': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:billing:orderPurchaseCondition': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:billing:orderNumber': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit:billing:invoiced': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:edit': (props) => {
        if (!props?.status) return false;
        return ['processing'].includes(props.status);
      },
      'np:delete:product': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing', 'ready'].includes(props.status);
      },
      'np:view': ({ sellerId, userId, status }) => {
        if (!sellerId || !userId) return false;
        return (
          sellerId === userId &&
          [
            'new',
            'processing',
            'ready',
            'approved',
            'preparation',
            'released',
            'released_partially',
            'archived',
          ].includes(status)
        );
      },
      'np:delete': (props) => {
        if (!props?.status) return false;
        return ['new', 'processing'].includes(props.status);
      },
    },
  },
  analyst: {
    order_status: ['ready', 'approved', 'released', 'released_partially'],
    static: [
      'np:list',
      'np:edit:product:observations',
      'np:edit:product:observations',
      'np:vis:product:observations',
      'np:archive',

      'product:list',
      'client:list',

      'ws:list',
      'ws:preview',
    ],
    dynamic: {
      'np:edit': (props) => {
        if (!props?.status) return false;
        return ['ready', 'approved', 'released', 'released_partially', 'archived'].includes(props?.status);
      },
      'np:edit:state': (props) => {
        if (!props?.status) return false;
        return ['ready', 'approved'].includes(props?.status);
      },
      'np:view': ({ status }) => {
        if (!status) return false;
        return ['ready', 'approved', 'released', 'released_partially', 'archived'].includes(status);
      },
    },
  },
  handler: {
    order_status: ['approved', 'preparation', 'released_partially', 'released'],
    static: [
      'np:list',
      'np:create:partial_delivery',
      'np:vis:product:observations',
      'np:edit:partial_delivery',
      'np:edit:logistic:orderDeliveryAddress',
      'np:edit:logistic:orderDeliveryTime',
      'np:edit:logistic:transport',
      'np:edit:product:observations',
      'np:edit:logistic:takeAway',

      'product:list',
      'client:list',

      'mp:list',
      'mp:edit',
      'mp:edit:stockControl',

      'op:list',
      'op:list:state',
      'op:create',

      'ws:list',
      'ws:preview',
    ],
    dynamic: {
      'np:edit': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released_partially'].includes(props?.status);
      },
      'np:edit:state': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released_partially', 'released'].includes(props?.status);
      },
      'np:edit:logistic:orderDeliveryTime': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released', 'released_partially'].includes(props?.status);
      },
      'np:edit:logistic:transport': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released', 'released_partially'].includes(props?.status);
      },
      'np:edit:partial_delivery': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released', 'released_partially'].includes(props?.status);
      },
      'np:edit:logistic:orderDeliveryAddress': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released', 'released_partially'].includes(props?.status);
      },
      'np:edit:product:observations': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released', 'released_partially'].includes(props?.status);
      },
      'np:edit:logistic:takeAway': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released', 'released_partially'].includes(props?.status);
      },
      'np:view': ({ handlerId, userId, status }) => {
        if (!handlerId || !userId) return false;
        return (
          handlerId === userId &&
          ['approved', 'preparation', 'released', 'released_partially', 'archived'].includes(status)
        );
      },
      'op:list:type': ({ type }) => {
        if (!type) return false;
        return ['semi-elaborado', 'semi-elaborado-2'].includes(type);
      },
      'op:view': ({ status }) => {
        if (!status) return false;
        return ['requested', 'pending', 'inProgress', 'done', 'archived'].includes(status);
      },
      'op:edit': ({ status }) => {
        if (!status) return false;
        return ['requested', 'done'].includes(status);
      },
      'op:edit:state': ({ status }) => {
        if (!status) return false;
        return ['requested', 'done'].includes(status);
      },
      'op:edit:observations': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:feedStock': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:poInformation': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:unidadesFabricar': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:create:type': ({ type }) => {
        if (!type) return false;
        return ['semi-elaborado', 'semi-elaborado-2'].includes(type);
      },
    },
  },
  feedstockAdmin: {
    static: [
      'mp:list',
      'mp:edit',
      'mp:delete',
      'mp:create',
      'mp:edit:stockControl',

      'op:list',
      'op:list:state',

      'ws:list',
      'ws:preview',
      'ws:edit',
      'ws:delete',
      'ws:create',
    ],
    dynamic: {
      'op:list:type': ({ type }) => {
        if (!type) return false;
        return ['semi-elaborado', 'semi-elaborado-2'].includes(type);
      },
      'op:view': ({ status }) => {
        if (!status) return false;
        return ['pending', 'inProgress'].includes(status);
      },
      'op:edit': ({ status }) => {
        if (!status) return false;
        return ['pending', 'inProgress'].includes(status);
      },
      'op:edit:cuttingTable': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
      'op:edit:bags': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
      'op:edit:feedStock': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
      'op:edit:batch': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
      'op:edit:state': ({ status }) => {
        if (!status) return false;
        return ['pending', 'inProgress'].includes(status);
      },
      'op:edit:observations': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
      'op:edit:unidadesFabricar': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
    },
  },
  productionAdmin: {
    order_status: ['approved'],
    static: [
      'np:list',
      'op:list',
      'op:list:state',
      'op:list:type',
      'op:delete',
      'op:create',
      'op:create:type',
      'op:view',
      'mp:list',
      'product:list',

      'ws:list',
      'ws:preview',
    ],
    dynamic: {
      'np:view': ({ status }) => {
        if (!status) return false;
        return ['approved'].includes(status);
      },
      'op:edit': ({ status }) => {
        if (!status) return false;
        return ['requested', 'done', 'archived'].includes(status);
      },
      'op:edit:poInformation': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:unidadesFabricar': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:bags': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:feedStock': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:batch': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:state': ({ status }) => {
        if (!status) return false;
        return ['requested', 'done'].includes(status);
      },
      'op:edit:observations': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
    },
  },
  cutter: {
    static: ['op:list', 'op:list:state'],
    dynamic: {
      'op:list:type': ({ type }) => {
        if (!type) return false;
        return ['semi-elaborado', 'semi-elaborado-2'].includes(type);
      },
      'op:view': ({ status }) => {
        if (!status) return false;
        return ['pending', 'inProgress'].includes(status);
      },
      'op:edit': ({ status }) => {
        if (!status) return false;
        return ['pending', 'inProgress'].includes(status);
      },
      'op:edit:cuttingTable': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
      'op:edit:bags': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
      'op:edit:feedStock': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
      'op:edit:batch': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
      'op:edit:state': ({ status }) => {
        if (!status) return false;
        return ['pending', 'inProgress'].includes(status);
      },
      'op:edit:observations': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
    },
  },
  inplantAdmin: {
    static: [
      'mp:list',
      'mp:edit',
      'mp:edit:stockControl',
      'op:list',
      'op:create',
      'op:delete',

      'ws:list',
      'ws:preview',
    ],
    dynamic: {
      'op:edit:state': ({ status }) => {
        if (!status) return false;
        return ['requested', 'inProgress', 'done'].includes(status);
      },
      'op:list:type': ({ type }) => {
        if (!type) return false;
        return ['in plant'].includes(type);
      },
      'op:list:state': ({ state }) => {
        if (!state) return false;
        return ['requested', 'inProgress', 'done', 'archived'].includes(state);
      },
      'op:create:type': ({ type }) => {
        if (!type) return false;
        return ['in plant'].includes(type);
      },
      'op:view': ({ status }) => {
        if (!status) return false;
        return ['requested', 'inProgress', 'done', 'archived'].includes(status);
      },
      'op:edit': ({ status }) => {
        if (!status) return false;
        return ['requested', 'inProgress', 'done'].includes(status);
      },
      'op:edit:poInformation': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:unidadesFabricar': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:cuttingTable': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
      'op:edit:bags': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
      'op:edit:feedStock': ({ status }) => {
        if (!status) return false;
        return ['requested', 'inProgress'].includes(status);
      },
      'op:edit:batch': ({ status }) => {
        if (!status) return false;
        return ['inProgress'].includes(status);
      },
      'op:edit:observations': ({ status }) => {
        if (!status) return false;
        return ['requested', 'inProgress'].includes(status);
      },
    },
  },
  productionHandler: {
    order_status: ['approved', 'preparation', 'released_partially', 'released'],
    static: [
      'np:list',
      'np:create:partial_delivery',
      'np:vis:product:observations',
      'np:edit:partial_delivery',
      'np:edit:logistic:orderDeliveryAddress',
      'np:edit:logistic:orderDeliveryTime',
      'np:edit:logistic:transport',
      'np:edit:product:observations',
      'np:edit:logistic:takeAway',
      'product:list',
      'client:list',
      'mp:list',
      'op:list',
      'op:list:state',
      'op:list:type',
      'op:delete',
      'op:create',
      'op:create:type',
      'op:view',
    ],
    dynamic: {
      'np:edit': (props) => {
        if (!props.status || !props.handler) return false;
        return (
          props.handler._id === props.currentUser._id &&
          ['preparation', 'approved', 'released_partially'].includes(props.status)
        );
      },
      'np:edit:state': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released_partially', 'released'].includes(props?.status);
      },
      'np:edit:logistic:orderDeliveryTime': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released', 'released_partially'].includes(props?.status);
      },
      'np:edit:logistic:transport': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released', 'released_partially'].includes(props?.status);
      },
      'np:edit:partial_delivery': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released', 'released_partially'].includes(props?.status);
      },
      'np:edit:logistic:orderDeliveryAddress': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released', 'released_partially'].includes(props?.status);
      },
      'np:edit:product:observations': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released', 'released_partially'].includes(props?.status);
      },
      'np:edit:logistic:takeAway': (props) => {
        if (!props?.status) return false;
        return ['approved', 'preparation', 'released', 'released_partially'].includes(props?.status);
      },
      'op:edit': ({ status }) => {
        if (!status) return false;
        return ['requested', 'done', 'archived'].includes(status);
      },
      'op:edit:poInformation': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:unidadesFabricar': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:bags': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:feedStock': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:batch': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'op:edit:state': ({ status }) => {
        if (!status) return false;
        return ['requested', 'done'].includes(status);
      },
      'op:edit:observations': ({ status }) => {
        if (!status) return false;
        return ['requested'].includes(status);
      },
      'np:view': ({ handlerId, userId, status }) => {
        if ((!status && !handlerId) || !userId) return false;
        const statusApproved = ['approved'].includes(status);
        const statusWithUserId =
          handlerId === userId && ['preparation', 'released_partially', 'released'].includes(status);
        return statusWithUserId + statusApproved;
      },
    },
  },
};

export default rules;
