import styled from 'styled-components'

export const DateContainer = styled('div')`
  display: flex;
`

export const DateGroup = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  border-right: 1px solid #efeef0;
  padding-right: 10px;
`

export const Label = styled('div')`
  font-size: 10px;
  color: #999;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
`

export const Date = styled('div')`
  font-size: 11px;
  color: gray;
`
