import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { checkDataPermission } from '../../../../../../../components/CanRBac';

// mixins
import { SelectContainer } from './mixins';

const Component = ({ handlers, value, handleChangeDeposit, currentUser, productionOrder }) => {
  const optionsHandlers = handlers?.map((handler) => {
    return {
      value: handler?.name,
      label: handler?.name,
      _id: handler?._id,
    };
  });

  const customStyles = {
    singleValue: (base) => ({
      ...base,
      padding: 5,
      borderRadius: 5,
      color: 'black',
      display: 'flex',
    }),
    valueContainer: (provided) => ({
      ...provided,
      width: '160px',
    }),
  };

  return (
    <SelectContainer>
      <Select
        name="selectDeposit"
        styles={customStyles}
        isSearchable={false}
        options={optionsHandlers}
        isDisabled={
          !checkDataPermission(currentUser.userType, 'op:edit:poInformation', { status: productionOrder?.state })
        }
        defaultValue={value ? { label: value?.name, value: value?.name, _id: value?.id } : {}}
        onChange={(e) => {
          handleChangeDeposit(e);
        }}
      />
    </SelectContainer>
  );
};

const state = ({ productionOrderStore, userStore }) => {
  const { data: currentUser } = userStore.information;
  const { data: productionOrder } = productionOrderStore.productionOrder;

  return {
    productionOrder,
    currentUser,
  };
};

export default connect(state)(Component);
