import { CSSProperties } from 'react';
import Spinner from '../../Spinner';

interface ComponentProps {
  isLoading: boolean;
  disabled?: boolean;
  form: string;
  children: any;
  className?: string;
  onClick?: (data?: any) => void;
  style?: CSSProperties;
}

const Component = ({ isLoading, children, disabled, className, ...rest }: ComponentProps) => {
  return (
    <button disabled={isLoading || disabled} className={`btn btn-primary ${className || ''}`} type="submit" {...rest}>
      {isLoading ? <Spinner size="small" /> : children}
    </button>
  );
};

export default Component;
