import rbacRules from './rbac-rules';

const check = (rules, role, action, data) => {
  const permissions = rules[role];

  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const checkDataPermission = (role, action, data) => {
  return check(rbacRules, role, action, data);
};

const CanRBac = (props) => (check(rbacRules, props.role, props.perform, props.data) ? props.yes() : props.no());

CanRBac.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default CanRBac;
export { checkDataPermission };
