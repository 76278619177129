import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import services from '../../services';
import {
  GET_PRODUCTION_ORDER_ALL,
  GET_PRODUCTION_ORDER_ALL_SUCCESS,
  GET_PRODUCTION_ORDER_ALL_ERROR,
  DELETE_PRODUCTION_ORDER,
  DELETE_PRODUCTION_ORDER_SUCCESS,
  DELETE_PRODUCTION_ORDER_ERROR,
  GET_PRODUCTION_ORDER,
  GET_PRODUCTION_ORDER_SUCCESS,
  GET_PRODUCTION_ORDER_ERROR,
  PRODUCTION_ORDER_UPDATE_CURRENT,
  PATCH_PRODUCTION_ORDER,
  PATCH_PRODUCTION_ORDER_SUCCESS,
  PATCH_PRODUCTION_ORDER_ERROR,
  SET_PRODUCTION_ORDER_READED,
} from '../types/production-order';

const user = ({ userStore }) => userStore.information.data;
const productionOrder = ({ productionOrderStore }) => productionOrderStore.productionOrder.data;

function* getProductionOrderAll({ payload }): any {
  try {
    const result = yield call(services.productionOrder.getProductionOrdersAll, payload);
    yield put({ type: GET_PRODUCTION_ORDER_ALL_SUCCESS, payload: result });
  } catch (error: any) {
    yield put({ type: GET_PRODUCTION_ORDER_ALL_ERROR, payload: error.message });
  }
}

function* deleteProductionOrder({ payload }) {
  try {
    const { response, error } = yield call(services.productionOrder.deleteProductionOrder, payload);

    if (!!error) {
      yield put({ type: DELETE_PRODUCTION_ORDER_ERROR, payload: error.message });
    } else {
      yield put({ type: DELETE_PRODUCTION_ORDER_SUCCESS, payload: response });
    }
  } catch (error: any) {
    yield put({ type: DELETE_PRODUCTION_ORDER_ERROR, payload: 'Error al eliminar la Orden de Produción' });
  }
}

function* getProductionOrder({ payload }): any {
  try {
    const result = yield call(services.productionOrder.getProductionOrders, payload);
    yield put({ type: GET_PRODUCTION_ORDER_SUCCESS, payload: result });
  } catch (error: any) {
    yield put({ type: GET_PRODUCTION_ORDER_ERROR, payload: error.message });
  }
}

function* patchProductionOrder({ payload }): any {
  const currentUser = yield select(user);

  const { response, error } = yield call(services.productionOrder.patchProductionOrder, {
    ...payload,
    watchers: [currentUser._id],
  });

  if (!error) {
    yield all([
      put({ type: PATCH_PRODUCTION_ORDER_SUCCESS, payload: response }),
      put({ type: PRODUCTION_ORDER_UPDATE_CURRENT, payload: response }),
    ]);
  } else {
    yield put({ type: PATCH_PRODUCTION_ORDER_ERROR, payload: error });
  }
}

function* setProductionOrderReaded(): any {
  const currentUser = yield select(user);
  const currentProductionOrder = yield select(productionOrder);

  try {
    const { response, error } = yield call(services.productionOrder.patchProductionOrder, {
      ...currentProductionOrder,
      id: currentProductionOrder._id,
      watchers: [...currentProductionOrder.watchers, currentUser._id].flat(),
    });

    if (!error) {
      yield put({ type: PATCH_PRODUCTION_ORDER_SUCCESS, payload: response });
    } else {
      yield put({ type: PATCH_PRODUCTION_ORDER_ERROR, payload: error });
    }
  } catch {}
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_PRODUCTION_ORDER_ALL, getProductionOrderAll);
  yield takeLatest<any>(GET_PRODUCTION_ORDER, getProductionOrder);
  yield takeLatest<any>(SET_PRODUCTION_ORDER_READED, setProductionOrderReaded);
  yield takeLatest<any>(DELETE_PRODUCTION_ORDER, deleteProductionOrder);
  yield takeLatest<any>(PATCH_PRODUCTION_ORDER, patchProductionOrder);
}
