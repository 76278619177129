/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import DatePicker from '../../../../components/Datepicker';
import './style.scss';

const PartialDeliveryRow = ({ isEditable, data, removePartialDeliveryRow, handlePartialDeliveryChange }) => {
  const [orderPartial, setOrderPartial] = useState();

  const defaultMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 9, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const columns = [
    {
      sortable: false,
      center: false,
      minWidth: '5%',
      maxWidth: '5%',
    },
    {
      name: 'Fecha',
      sortable: false,
      center: false,
      allowOverflow: true,
      minWidth: '16%',
      maxWidth: '16%',
      cell: (row, index) => {
        return (
          <div className="subrow-datepicker">
            <input
              type="date"
              className="form-control dmx-input w-100"
              id="date"
              value={
                row.deliveryDate ? row.deliveryDate.slice(0, 10) : new Date(Date.now()).toISOString().slice(0, 10) || ''
              }
              disabled={!isEditable}
              onChange={(e) => handlePartialDeliveryChange(data._id, index, 'deliveryDate', e.target.value)}
            />
          </div>
        );
      },
    },
    {
      name: 'Cantidad',
      sortable: false,
      center: false,
      allowOverflow: true,
      minWidth: '8%',
      maxWidth: '8%',
      cell: (row, index) => (
        <MaskedInput
          mask={createNumberMask({
            ...defaultMaskOptions,
          })}
          className="form-control dmx-input w-100"
          id={`quantity-${row.id}`}
          name="quantity"
          defaultValue={row.quantity}
          onChange={(e) =>
            handlePartialDeliveryChange(data._id, index, e.target.name, e.target.value.replace(/\D+/g, ''))
          }
          disabled={!isEditable}
        />
      ),
    },
    {
      name: 'Nro de Lote',
      sortable: false,
      center: false,
      allowOverflow: true,
      minWidth: '15%',
      maxWidth: '15%',
      cell: (row, index) => (
        <input
          type="text"
          className="form-control dmx-input w-100"
          id={`batchNumber-${row.id}`}
          name="batchNumber"
          defaultValue={row.batchNumber}
          onChange={(e) => handlePartialDeliveryChange(data._id, index, e.target.name, e.target.value)}
          disabled={!isEditable}
        />
      ),
    },
    {
      name: 'Bultos',
      sortable: false,
      center: false,
      allowOverflow: true,
      minWidth: '8%',
      maxWidth: '8%',
      cell: (row, index) => (
        <input
          type="number"
          className="form-control dmx-input w-100"
          id={`packagesQuantity-${row.id}`}
          name="packagesQuantity"
          defaultValue={row.packagesQuantity}
          onChange={(e) => handlePartialDeliveryChange(data._id, index, e.target.name, e.target.value)}
          disabled={!isEditable}
        />
      ),
    },
    {
      name: 'Vencimiento',
      sortable: false,
      center: false,
      allowOverflow: true,
      minWidth: '16%',
      maxWidth: '16%',
      cell: (row, index) => {
        return (
          <div className="subrow-datepicker">
            <input
              type="date"
              className="form-control dmx-input w-100"
              id={`expirationDate-${row.id}`}
              value={row.expirationDate ? row.expirationDate.slice(0, 10) : ''}
              disabled={!isEditable}
              onChange={(e) => handlePartialDeliveryChange(data._id, index, 'expirationDate', e.target.value)}
            />
          </div>
        );
      },
    },
    {
      name: 'Observaciones',
      sortable: false,
      center: false,
      allowOverflow: true,
      minWidth: '26%',
      maxWidth: '26%',
      cell: (row, index) => (
        <input
          type="text"
          className="form-control dmx-input w-100"
          id={`observations-${row.id}`}
          name="observations"
          defaultValue={row.observations}
          onChange={(e) => handlePartialDeliveryChange(data._id, index, e.target.name, e.target.value)}
          disabled={!isEditable}
        />
      ),
    },
    {
      // name: '',
      minWidth: '5%',
      maxWidth: '5%',
      sortable: false,
      center: true,
      omit: !isEditable,
      cell: (row, index) => (
        <div className="text-danger p-1 dmx-action-button">
          <FontAwesomeIcon
            title="Quitar entrega parcial"
            aria-hidden="true"
            icon={faTimesCircle}
            onClick={() => removePartialDeliveryRow(data.id, index)}
          />
        </div>
      ),
    },
  ];

  const orderPartialDelivery = () => {
    const dataPartial = data.partialDelivery;
    const order = dataPartial.sort((a, b) => {
      if (a.deliveryDate < b.deliveryDate) {
        return -1;
      }
      if (a.deliveryDate > b.deliveryDate) {
        return 1;
      }

      return 0;
    });
    setOrderPartial(order);
  };

  useEffect(() => {
    orderPartialDelivery();
  }, []);

  return (
    <DataTable
      columns={columns}
      data={data && orderPartial}
      noDataComponent={<div className="p-3">No hay entregas parciales</div>}
    />
  );
};

export default PartialDeliveryRow;
