import { useEffect, useMemo, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Input from '../../../components/Form/Input';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

// components
import Select from 'react-select';
import Spinner from '../../../components/Spinner';
import DragAndDrop from '../../../components/drag-and-drop';

import { typeOptions } from '../../../constant/order-production-types';

// store
import { getProductionOrderAll } from '../../../store/actions/production-order';

// permisos
import CanRBac from '../../../components/CanRBac';
import { ToggleSwitch } from 'react-dragswitch';
import Statistics from '../components/Statistics';
import './styles.scss';
import { CustomerDto } from '../../../types/customer';
import { User } from '../../../types/user';
import { ProductionOrderDto } from '../../../types/production-order';
import { getAllCustomers } from '../../../store/actions/customer';
import { Product } from '../../../types/product';
import { getAllProducts } from '../../../store/actions/product';
import { resetDeleteProductionOrder } from '../../../store/actions/production-order';
import { Kanban } from './styles';
import services from '../../../services';

const initialPagination: Pagination = {
  requested: { currentPage: 1, thereAreItems: true, isLoading: false },
  pending: { currentPage: 1, thereAreItems: true, isLoading: false },
  inProgress: { currentPage: 1, thereAreItems: true, isLoading: false },
  done: { currentPage: 1, thereAreItems: true, isLoading: false },
  archived: { currentPage: 1, thereAreItems: true, isLoading: false },
}

const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

const initialFilters: Filters = {};

const DIMEX_OP_FILTERS = 'DIMEX_OP_FILTERS';

const typeOptions3 = [
  { value: 'semi-elaborado', label: 'Semi Elaborado' },
  { value: 'semi-elaborado-2', label: 'Semi Elaborado 2' },
];

interface Pagination {
  [key: string]: { currentPage: number, thereAreItems: boolean, isLoading: boolean }
}
interface Filters {
  [key: string]: string | number;
}

interface ComponentProps {
  customers: CustomerDto[];
  customersStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  products: Product[];
  productsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  productionOrderList: ProductionOrderDto[];
  productionOrderStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  currentUser: User;
}

const Component = ({
  productionOrderList,
  productionOrderStates,
  currentUser,
  customers,
  customersStates,
  products,
  productsStates,
}: ComponentProps) => {
  const dispatch = useDispatch();

  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
  const [badgeFiltersNumber, setBadgeFiltersNumber] = useState<number>(0);
  const [startFiltering, setStartFiltering] = useState(false)
  const [data, setData] = useState<ProductionOrderDto[]>([]);
  const [filtersSelected, setFiltersSelected] = useState<Filters>({});
  const [currentPagination, setCurrentPagination] = useState<Pagination>(initialPagination);

  /**
   * Deribamos ordenes a sus respectivas columnas, segun su estado
   */

  const columnsDrag = useMemo((): { id: string; name: string; items?: ProductionOrderDto[] }[] => {
    return [
      {
        id: 'requested',
        name: 'Solicitada',
        items: data?.filter((order: ProductionOrderDto) => order.state === 'requested'),
      },
      {
        id: 'pending',
        name: 'Pendiente',
        items: data?.filter((order: ProductionOrderDto) => order.state === 'pending'),
      },
      {
        id: 'inProgress',
        name: 'En Progreso',
        items: data?.filter((order: ProductionOrderDto) => order.state === 'inProgress'),
      },
      {
        id: 'done',
        name: 'Finalizada',
        items: data?.filter((order: ProductionOrderDto) => order.state === 'done'),
      },
      {
        id: 'archived',
        name: 'Archivada',
        items: data?.filter((order: ProductionOrderDto) => order.state === 'archived'),
      },
    ];
  }, [data]);

  const removeDuplicates = (arr) => {
    const uniqueIds = {}; // Object to store unique _id values

    return arr.reduce((accumulator, current) => {
      if (!uniqueIds[current._id]) {
        // If _id is not in the uniqueIds object, mark it as seen and add it to the accumulator
        uniqueIds[current._id] = true;
        accumulator.push(current);
      }
      return accumulator;
    }, []);
  }

  const fetchData = async ({ state, pagination = currentPagination }: {
    state: 'requested' | 'pending' | 'inProgress' | 'done' | 'archived', pagination?: Pagination
  }) => {
    const itemsPerPage = 15;
    const currentPage = pagination[state].currentPage

    let payload: Filters = {
      ...filtersSelected,
      state,
      limit: itemsPerPage,
      page: pagination[state].currentPage,
    };

    setCurrentPagination((oldState) => ({ ...oldState, [state]: { ...oldState[state], isLoading: true } }))

    services.productionOrder.getProductionOrdersAll(payload)
      .then((res) => {
        setData((oldState => removeDuplicates([...oldState, ...res])))
        setCurrentPagination((oldState) => ({ ...oldState, [state]: { ...oldState[state], currentPage: currentPage + 1, thereAreItems: res.length >= itemsPerPage, isLoading: false } }))
      })
  };

  const setInitialFilters = () => {
    const filtersSaved = window.localStorage.getItem(DIMEX_OP_FILTERS);

    if (!!filtersSaved && isValidJSON(filtersSaved) && Object.keys(JSON.parse(filtersSaved)).length > 0) {
      setFiltersSelected(JSON.parse(filtersSaved));
    }

    setStartFiltering(true)
  };

  const calcBadgeFiltersNumber = () => {
    const excludedKeys = ['code', 'type', 'state'];
    let count = 0
    // filtersSelected['state'] === initialFilters.state && excludedKeys.push('state');
    if (!!filtersSelected && Object.keys(filtersSelected).length > 0) {
      count = Object.keys(filtersSelected).filter((key) => !excludedKeys.includes(key)).length;
    }

    setBadgeFiltersNumber(count);
  };

  useEffect(() => {
    if (startFiltering) {
      window.localStorage.setItem(DIMEX_OP_FILTERS, JSON.stringify(filtersSelected || {}));

      setData([]);
      calcBadgeFiltersNumber();

      if (filtersSelected?.state === 'archived') {
        fetchData({ state: "archived", pagination: initialPagination });
      } else {
        fetchData({ state: "requested", pagination: initialPagination });
        fetchData({ state: "pending", pagination: initialPagination });
        fetchData({ state: "inProgress", pagination: initialPagination });
        fetchData({ state: "done", pagination: initialPagination });
      }
    }
  }, [filtersSelected, startFiltering]);

  useEffect(() => {
    setInitialFilters();
    !customers && dispatch(getAllCustomers());
    !products && dispatch(getAllProducts());
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card dmx-card-mt shadow-sm m-b-15 p-15">
            <div className="row">
              <div className="col-md-2">
                <Input
                  name="code"
                  placeholder="Código de OP"
                  value={filtersSelected?.code}
                  onChange={(event) => {
                    if (!!event?.target?.value) {
                      setFiltersSelected({ ...filtersSelected, code: event?.target?.value });
                    } else {
                      delete filtersSelected?.code;
                      setFiltersSelected({ ...initialFilters, ...filtersSelected });
                    }
                  }}
                  style={{ margin: 0 }}
                />
              </div>
              <div className="col-md-2">
                <Select
                  classNamePrefix="react-select"
                  placeholder="Tipo"
                  options={currentUser.userType === 'handler' ? typeOptions3 : typeOptions}
                  isClearable={true}
                  value={typeOptions.filter((type) => {
                    return type.value === filtersSelected?.type;
                  })}
                  onChange={(option) => {
                    if (!!option?.value) {
                      setFiltersSelected({ ...filtersSelected, type: option?.value });
                    } else {
                      delete filtersSelected?.type;
                      setFiltersSelected({ ...initialFilters, ...filtersSelected });
                    }
                  }}
                />
              </div>
              <div className="col-md-2">
                <Dropdown isOpen={isDropdownOpened} style={{ width: '100%' }}>
                  <DropdownToggle outline color="secondary" onClick={() => setIsDropdownOpened(!isDropdownOpened)}>
                    Más filtros <Badge color="secondary">{badgeFiltersNumber}</Badge>
                  </DropdownToggle>

                  <DropdownMenu style={{ width: '300px' }}>
                    <DropdownItem>
                      <div className="filter-by-archived">
                        <ToggleSwitch
                          checked={filtersSelected?.state === 'archived'}
                          onColor="blue"
                          onChange={(e) => {
                            if (e) {
                              setFiltersSelected({ ...filtersSelected, state: 'archived' });
                            } else {
                              delete filtersSelected?.state;
                              setFiltersSelected({ ...initialFilters, ...filtersSelected });
                            }
                          }}
                        />

                        <span>Mostrar archivadas</span>
                      </div>
                    </DropdownItem>
                    <DropdownItem>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Cliente"
                        isLoading={!customers || customersStates.loading}
                        isClearable={true}
                        options={customers?.map((customer) => ({ label: customer.companyName, value: customer._id }))}
                        value={customers
                          ?.filter((customer) => {
                            return customer._id === filtersSelected?.client;
                          })
                          ?.map((customer) => ({ label: customer.companyName, value: customer._id }))}
                        onChange={(option) => {
                          if (!!option?.value) {
                            setFiltersSelected({ ...filtersSelected, client: option?.value });
                          } else {
                            delete filtersSelected?.client;
                            setFiltersSelected({ ...initialFilters, ...filtersSelected });
                          }
                        }}
                      />
                    </DropdownItem>
                    <DropdownItem>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Producto"
                        isLoading={!products || productsStates.loading}
                        isClearable={true}
                        options={products?.map((product) => ({ label: product.name, value: product._id }))}
                        value={products
                          ?.filter((product) => {
                            return product._id === filtersSelected?.product;
                          })
                          ?.map((product) => ({ label: product.name, value: product._id }))}
                        onChange={(option) => {
                          if (!!option?.value) {
                            setFiltersSelected({ ...filtersSelected, product: option?.value });
                          } else {
                            delete filtersSelected?.product;
                            setFiltersSelected({ ...initialFilters, ...filtersSelected });
                          }
                        }}
                      />
                    </DropdownItem>
                    {/* <DropdownItem>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Estado"
                        isLoading={false}
                        isClearable={true}
                        options={columnsDrag
                          ?.filter((state) => state.id !== 'archived')
                          ?.map((state) => ({ value: state.id, label: state.name }))}
                        // value={columnsDrag
                        //   ?.filter((state) => {
                        //     return state.id === filtersSelected['state'];
                        //   })
                        //   ?.map((state) => ({ value: state.id, label: state.name }))}
                        defaultValue={columnsDrag
                          ?.filter((state) => {
                            return filtersSelected['state'] && (filtersSelected['state'] as string).includes(state.id);
                          })
                          ?.map((state) => {
                            return { value: state.id, label: state.name };
                          })}
                        onChange={(option) => {
                          if (!!option?.value) {
                            setFiltersSelected({ ...filtersSelected, state: option?.value });
                          } else {
                            delete filtersSelected['state'];
                            setFiltersSelected({ ...initialFilters, ...filtersSelected });
                          }
                        }}
                      />
                    </DropdownItem> */}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-2 col-btn-create">
                <CanRBac
                  role={currentUser?.userType}
                  perform="op:create"
                  yes={() => {
                    return (
                      <Link to="/orderproductionnew" className="btn btn-success btn-create">
                        <FontAwesomeIcon icon={faPlus} /> Nueva Ord. Prod.
                      </Link>
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <Statistics filtersSelected={filtersSelected} startFiltering={startFiltering} />

          <div style={{ height: 15 }} />

          <div className="card shadow-sm">
            <div className="card-body production-order-kanban datatable-react">
              {productionOrderStates.loading ? (
                <Spinner size="large" isPageLoader />
              ) : (
                <Kanban>
                  {/* {updateProductionOrderStates.loading && (
                    <Spinner size="large" isPageLoader className="kanban-loader" />
                  )} */}

                  <DragAndDrop
                    onDrop={setFiltersSelected}
                    getMoreItems={fetchData}
                    pagination={currentPagination}
                    columnsDrag={columnsDrag.filter((column) =>
                      filtersSelected?.state === 'archived' ? column.id === 'archived' : column.id !== 'archived',
                    )}
                    detailLink={'/orderproductiondetail'}
                  />
                </Kanban>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const state = ({ productionOrderStore, userStore, customerStore, productStore }) => {
  const { data: productionOrderList, states: productionOrderStates } = productionOrderStore.productionOrderAll;
  const { data: customers, states: customersStates } = customerStore;
  const { data: products, states: productsStates } = productStore;

  return {
    productionOrderStates,
    currentUser: userStore.information.data,
    productionOrderList,
    customers,
    customersStates,
    products,
    productsStates,
  };
};

export default connect(state)(Component);
