/* eslint no-underscore-dangle: 0 */
import { useState, createContext } from 'react';
import { connect } from 'react-redux';
import { checkDataPermission } from '../components/CanRBac';

export const NewProductionOrderContext = createContext();

const Component = ({ children, currentUser }) => {
  const [data, setData] = useState({
    createDate: '',
    product: {
      _id: '',
      productCategoryLabel: '',
    },
    batch: [],
    feedstock: [],
    quantity: '',
    type: currentUser.userType === 'inplantAdmin' ? 'in plant': 'semi-elaborado',
    origin: {
      type: '',
    },
    observations: '',
    state: 'requested',
    originType: 'PRODUCTION',
  });

  return <NewProductionOrderContext.Provider value={[data, setData]}>{children}</NewProductionOrderContext.Provider>;
};

const state = ({ userStore }) => {
  const { data: currentUser } = userStore.information;
  return {
    currentUser,
  };
};

export default connect(state)(Component);

