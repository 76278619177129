import { getToken } from '../utils/authUtils';

export default function getFeedstockId(id) {
  return fetch(`${process.env.REACT_APP_API_URL}/feedstock/${id}`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${getToken()}`,
    }),
  }).then((response) => response.json());
}
