import {
  GET_PRODUCTION_ORDER_BAG_ALL,
  GetProductionOrderBagAll,
  GET_PRODUCTION_ORDER_BAG_ALL_RESET,
  GetProductionOrderBagAllReset,
  GET_PRODUCTION_ORDER_BAG,
  GetProductionOrderBag,
  GET_PRODUCTION_ORDER_BAG_RESET,
  GetProductionOrderBagReset,
  UPDATE_PRODUCTION_ORDER_BAG,
  UpdateProductionOrderBag,
  UPDATE_PRODUCTION_ORDER_BAG_RESET,
  UpdateProductionOrderBagReset,
  CREATE_PRODUCTION_ORDER_BAG,
  CreateProductionOrderBag,
  CREATE_PRODUCTION_ORDER_BAG_RESET,
  CreateProductionOrderBagReset,
  DELETE_PRODUCTION_ORDER_BAG,
  DeleteProductionOrderBag,
  DELETE_PRODUCTION_ORDER_BAG_RESET,
  DeleteProductionOrderBagReset,
  PRODUCTION_ORDER_BAG_UPDATE_CURRENT,
  ProductionOrderBagUpdateCurrent,
  SelectedProductionOrderBag,
  SELECTED_PRODUCTION_ORDER_BAG,
} from '../types/production-order-bag';

export const getProductionOrderBagAll = (payload): GetProductionOrderBagAll => {
  return {
    type: GET_PRODUCTION_ORDER_BAG_ALL,
    payload,
  };
};

export const getProductionOrderBagAllReset = (): GetProductionOrderBagAllReset => {
  return {
    type: GET_PRODUCTION_ORDER_BAG_ALL_RESET,
    payload: null,
  };
};

export const getProductionOrderBag = (payload): GetProductionOrderBag => {
  return {
    type: GET_PRODUCTION_ORDER_BAG,
    payload,
  };
};

export const getProductionOrderBagReset = (): GetProductionOrderBagReset => {
  return {
    type: GET_PRODUCTION_ORDER_BAG_RESET,
    payload: null,
  };
};

export const updateProductionOrderBag = (payload): UpdateProductionOrderBag => {
  return {
    type: UPDATE_PRODUCTION_ORDER_BAG,
    payload,
  };
};

export const updateProductionOrderBagReset = (): UpdateProductionOrderBagReset => {
  return {
    type: UPDATE_PRODUCTION_ORDER_BAG_RESET,
    payload: null,
  };
};

export const createProductionOrderBag = (payload): CreateProductionOrderBag => {
  return {
    type: CREATE_PRODUCTION_ORDER_BAG,
    payload,
  };
};

export const deleteProductionOrderBagReset = (): DeleteProductionOrderBagReset => {
  return {
    type: DELETE_PRODUCTION_ORDER_BAG_RESET,
    payload: null,
  };
};

export const deleteProductionOrderBag = (payload): DeleteProductionOrderBag => {
  return {
    type: DELETE_PRODUCTION_ORDER_BAG,
    payload,
  };
};

export const createProductionOrderBagReset = (): CreateProductionOrderBagReset => {
  return {
    type: CREATE_PRODUCTION_ORDER_BAG_RESET,
    payload: null,
  };
};

export const productionOrderBagUpdateCurrent = (payload): ProductionOrderBagUpdateCurrent => {
  return {
    type: PRODUCTION_ORDER_BAG_UPDATE_CURRENT,
    payload,
  };
};

export const setSelectedBag = (payload): SelectedProductionOrderBag => {
  return {
    type: SELECTED_PRODUCTION_ORDER_BAG,
    payload,
  };
};
