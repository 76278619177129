import { connect, useDispatch } from 'react-redux';
import Input from '../../../../components/Form/Input';
import Spinner from '../../../../components/Spinner';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';
import SpinnerButton from '../../../../components/Button';
import { useEffect, useState } from 'react';
import {
  resetUpdateUserProfile,
  getUserProfile,
  updateUserProfile,
  updateUserInformation,
} from '../../../../store/actions/user';

const userType = {
  seller: 'Ventas',
  handler: 'Depósito',
  'account analyst': 'Cuentas Corrientes',
  billing: 'Facturación',
  logistics: 'Logística',
};

const rules = Yup.object().shape({
  name: Yup.string().required('Este campo es requerido.'),
  lastname: Yup.string().required('Este campo es requerido.'),
  phone: Yup.string().required('Este campo es requerido.'),
  city: Yup.string().required('Este campo es requerido.'),
});

const ProfileForm = ({
  userProfileInformation,
  userProfileStates,
  userUpdateProfileStates,
  userUpdateMainInformationStates,
}) => {
  const { loading: userProfileLoading } = userProfileStates;
  const {
    loading: userUpdateMainInformationLoading,
    error: userUpdateMainInformationError,
    success: userUpdateMainInformationSuccess,
  } = userUpdateMainInformationStates;
  const { loading: userUpdateProfileLoading } = userUpdateProfileStates;

  const dispatch = useDispatch();
  const [formData, setFormData] = useState();

  const handleOnSubmit = (values) => {
    SweetAlert.fire({
      title: '¿Está seguro?',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      const { isConfirmed } = result;
      if (isConfirmed) {
        setFormData(values);
        dispatch(updateUserInformation(values));
      }
    });
  };

  useEffect(() => {
    dispatch(resetUpdateUserProfile());
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    if (formData && userUpdateMainInformationSuccess) {
      dispatch(updateUserProfile({ ...userProfileInformation, name: formData.name, lastname: formData.lastname }));
      toast.success('Usuario actualizado correctamente.', {autoClose: 3000});
    }

    if (userUpdateMainInformationError) {
      toast.error(`No se ha podido actualizar porque ocurrió un error.`, {autoClose: 3000});
    }
  }, [userUpdateMainInformationError, userUpdateMainInformationSuccess]);

  if (userProfileLoading || !userProfileInformation) {
    return <Spinner size="big" />;
  }

  return (
    <Formik
      {...(userProfileInformation && { initialValues: userProfileInformation })}
      onSubmit={handleOnSubmit}
      validationSchema={rules}
    >
      {(props) => {
        const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
        const isSubmitting = userUpdateMainInformationLoading || userUpdateProfileLoading;
        return (
          <div className="card dmx-card-mt">
            <form onSubmit={handleSubmit}>
              <div className="card-header">
                <h6>Mi Perfil</h6>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      placeholder="Nombre"
                      name="name"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      touched={touched}
                      errors={errors}
                      options={{ label: 'Nombre' }}
                    />
                  </div>

                  <div className="col-sm-6">
                    <Input
                      type="text"
                      placeholder="Apellido"
                      name="lastname"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastname}
                      touched={touched}
                      errors={errors}
                      options={{ label: 'Apellido' }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <Input
                      type="email"
                      placeholder="Email"
                      name="lastname"
                      disabled
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      touched={touched}
                      errors={errors}
                      options={{ label: 'Email' }}
                    />
                  </div>

                  <div className="col-sm-6">
                    <Input
                      type="text"
                      placeholder="Puesto"
                      name="userType"
                      disabled
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={userType[userProfileInformation?.userType]}
                      touched={touched}
                      errors={errors}
                      options={{ label: 'Puesto' }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      placeholder="Teléfono"
                      name="phone"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      touched={touched}
                      errors={errors}
                      options={{ label: 'Teléfono' }}
                    />
                  </div>

                  <div className="col-sm-6">
                    <Input
                      type="text"
                      placeholder="Ciudad"
                      name="city"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      touched={touched}
                      errors={errors}
                      options={{ label: 'Ciudad' }}
                    />
                  </div>
                </div>
              </div>

              <div className="card-footer text-right">
                <SpinnerButton className="btn btn-primary" isLoading={isSubmitting} type="submit">
                  Actualizar Datos
                </SpinnerButton>
              </div>

              <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

const state = ({ profileStore, userStore }) => {
  const { states: userUpdateMainInformationStates } = userStore.updateInformation;
  const { data: userProfileInformation, states: userProfileStates } = profileStore.information;
  const { states: userUpdateProfileStates } = profileStore.updateInformation;

  return {
    userProfileInformation,
    userProfileStates,
    userUpdateProfileStates,
    userUpdateMainInformationStates,
  };
};
export default connect(state)(ProfileForm);
