import Select from 'react-select';
import { Formik } from 'formik';
import { useDispatch, connect } from 'react-redux';
import { updateProductionOrderBag } from '../../../../../../../store/actions/production-order-bag';
import Submit from '../../../../../../../components/Form/Submit';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { WorkshopOrderBagDto } from '../../../../../../../types/production-order-bag';
import { hideModal } from '../../../../../../../store/actions/modal';
import { Center } from './styles';

export const schema = Yup.object().shape({
  status: Yup.string(),
  workshop: Yup.string(),
});

interface FormValues {
  status: string;
  workshop: string;
}

export const initialValues: FormValues = {
  status: '',
  workshop: '',
};

interface ComponentProps {
  workshops?: { label: string; value: string }[];
  states?: { label: string; value: string }[];
  setSelectedBags: React.Dispatch<React.SetStateAction<WorkshopOrderBagDto[]>>;
  selectedBags: WorkshopOrderBagDto[];
  updateProductionOrderbagStates: {
    loading: boolean;
    success: boolean;
  };
}

const Component = ({
  workshops,
  states,
  selectedBags,
  setSelectedBags,
  updateProductionOrderbagStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);

  const onSubmit = ({ values, actions }) => {
    selectedBags.forEach((bag) => {
      dispatch(
        updateProductionOrderBag({
          ...bag,
          status: values.status || bag.status,
          workshop: values.workshop || bag.workshop,
        }),
      );
    });

    setSelectedBags([]);
  };

  useEffect(() => {
    if (updateProductionOrderbagStates.success) {
      dispatch(hideModal());
    }
  }, [updateProductionOrderbagStates]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({ touched, errors, values, setFieldValue, handleChange, handleBlur, handleReset, handleSubmit }) => {
        return (
          <form
            className="theme-form"
            onReset={handleReset}
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            id="bulk-actions"
          >
            <Center>
              <span>Acciones: </span>

              {!!states && (
                <Select
                  menuPosition="fixed"
                  className="basic-multi-select w-100"
                  placeholder="Estado"
                  options={states}
                  name="status"
                  isDisabled={updateProductionOrderbagStates?.loading || !selectedBags || selectedBags.length <= 0}
                  onChange={({ value }) => setFieldValue('status', value)}
                />
              )}

              {!!workshops && (
                <Select
                  menuPosition="fixed"
                  className="basic-multi-select w-100"
                  placeholder="Taller"
                  options={workshops}
                  name="workshop"
                  isDisabled={updateProductionOrderbagStates?.loading || !selectedBags || selectedBags.length <= 0}
                  onChange={({ value }) => setFieldValue('workshop', value)}
                />
              )}

              <Submit
                isLoading={updateProductionOrderbagStates?.loading}
                disabled={updateProductionOrderbagStates.loading || !selectedBags || selectedBags.length <= 0}
                form="bulk-actions"
              >
                Guardar
              </Submit>
            </Center>
          </form>
        );
      }}
    </Formik>
  );
};

const state = ({ productionOrderBagStore }) => {
  const { states: updateProductionOrderbagStates } = productionOrderBagStore.updateProductionOrderBag;

  return {
    updateProductionOrderbagStates,
  };
};

export default connect(state)(Component);
