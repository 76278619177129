import action from '../helpers/rest-client';

export class ErrorCustomer extends Error {}

class CustomerService {
  public async getAll() {
    return action
      .Get({
        url: `/clients/all`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const customer = new CustomerService();
export default customer;
