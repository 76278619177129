export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS';
export const GET_ALL_CUSTOMERS_SUCCESS = 'GET_ALL_CUSTOMERS_SUCCESS';
export const GET_ALL_CUSTOMERS_ERROR = 'GET_ALL_CUSTOMERS_ERROR';

export interface GetAllCustomers {
  type: typeof GET_ALL_CUSTOMERS;
  payload: any;
}
export interface GetAllCustomersSuccess {
  type: typeof GET_ALL_CUSTOMERS_SUCCESS;
  payload: null;
}
export interface GetAllCustomersError {
  type: typeof GET_ALL_CUSTOMERS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetAllCustomers | GetAllCustomersSuccess | GetAllCustomersError;
