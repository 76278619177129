/* eslint no-underscore-dangle: 0 */

import { getToken } from '../utils/authUtils';
import { PATCH } from '../constant';

async function markOrderReadByUser(orderId, watchers) {
  return fetch(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, {
    method: PATCH,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ watchers }),
  }).then((response) => response.json());
}

export default markOrderReadByUser;
