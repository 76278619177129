/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { faCube } from '@fortawesome/free-solid-svg-icons';
import { Col, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';

const FeedstockBox = ({ feedstockData, attributes, isEditable, handleChange, selectChange }) => {
  attributes = attributes.filter((attribute) => attribute.name !== 'provider');

  const attributeNames = {
    type: 'Tipo',
    grammage: 'Gramaje',
    color: 'Color',
    provider: 'Proveedor',
    width: 'Ancho (m)',
    measure: 'Medidas',
  };

  const createFormFields = () => {
    const name = (
      <>
        <div className="mb-1 dmx-underline">
          <span className="text-muted">Nombre: </span>
          <input
            className="font-weight-bold p-2"
            disabled
            value={(feedstockData.feedstockCategoryId && feedstockData.feedstockCategoryId.name) || ''}
          />
        </div>
      </>
    );
    const formFields = [];
    formFields.push(name);
    const formAttributes =
      attributes &&
      attributes?.map((attribute) => {
        const options = attribute?.value?.map((val) => ({ value: val, label: val, attribute: attribute.name }));
        return (
          <>
            <label key={uuidv4()} className="text-capitalize col-form-label">
              {attributeNames[attribute.name]}
            </label>
            <Select
              isDisabled
              defaultValue={options.filter((option) => option.value === feedstockData[attribute.name])}
              name={attribute.name}
              options={options}
              onChange={(option) => selectChange(option)}
            />
          </>
        );
      });

    return formFields.concat(formAttributes);
  };

  return (
    <Col xl="6" sm="6">
      <Card>
        <CardBody className="card-body-detail">
          <div className="media dmx-delimeter dmx-headerCard">
            <div className="justify-start">
              <span className="mr-2">
                <FontAwesomeIcon icon={faCube} />
              </span>
              <h5 className="m-0 font-weight-bold">Materia Prima</h5>
            </div>
          </div>
          <div className="project-widgets mt-2 infoCard">
            <div className="mb-1 dmx-underline">
              <span className="text-muted">Código: </span>
              <input
                type="text"
                className="font-weight-bold p-2"
                disabled={!isEditable}
                handleChange={handleChange}
                value={feedstockData.code || ''}
              />
            </div>
            {createFormFields()}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default FeedstockBox;
