import { Card, CardBody, CardTitle } from 'reactstrap';

import { connect } from 'react-redux';
import { useEffect, useState } from 'react';

import './styles.scss';
import { Tabs, Button, EmptyState } from './styles';
import BagsIn from './components/BagsIn';
import BagsOut from './components/BagsOut';
import { ProductionOrderDto } from '../../../../../types/production-order';
import { checkDataPermission } from '../../../../../components/CanRBac';
import { User } from '../../../../../types/user';
import ObservationModal from './components/Observations';

interface ComponentProps {
  allProductionOrderBags;
  productionOrder: ProductionOrderDto;
  currentUser: User;
}

const Component = ({ allProductionOrderBags, productionOrder, currentUser }: ComponentProps) => {
  const [hasFeedstock, setHasFeedStock] = useState<boolean>();
  const [hasBatch, setHasBatch] = useState<boolean>();
  const [totalEntrada, setTotalEntrada] = useState(0);
  const [totalSalida, setTotalSalida] = useState(0);
  const [activeTab, setActiveTab] = useState('salida');

  useEffect(() => {
    setTotalSalida(
      allProductionOrderBags
        ?.filter((productionOrder) => productionOrder.status !== 'inDeposit')
        .map((bag) => bag.units)
        .reduce((a, b) => a + Number(b), 0),
    );

    setTotalEntrada(
      allProductionOrderBags
        ?.filter((productionOrder) => productionOrder.status === 'inDeposit')
        .map((bag) => bag.units)
        .reduce((a, b) => a + Number(b), 0),
    );
  }, [allProductionOrderBags]);

  useEffect(() => {
    if (!!productionOrder) {
      const hasFeedstock = !!productionOrder?.feedstock && productionOrder?.feedstock?.length > 0;
      const hasBatch = !!productionOrder?.batch && productionOrder?.batch?.length > 0;

      setHasFeedStock(hasFeedstock)
      setHasBatch(hasBatch)
    }
  }, [productionOrder]);

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4" className="card-bags">
          Unidades de Corte
          {hasFeedstock && hasBatch && (totalSalida ||
            totalEntrada) && (
              <span>
                {'salida' === activeTab && `${totalSalida} ${totalSalida === 1 ? 'unidad' : 'unidades'}`}
                {'entrada' === activeTab && `${totalEntrada} ${totalEntrada === 1 ? 'unidad' : 'unidades'}`}
              </span>
            )}
        </CardTitle>

        {!hasFeedstock || !hasBatch ? (
          <EmptyState>Agrega la materia prima y selecciona los lotes antes de continuar con los bolsones.</EmptyState>
        ) : (
          <>
            <Tabs>
              <Button onClick={() => setActiveTab('salida')} active={'salida' === activeTab}>
                Salida
              </Button>

              {totalSalida > 0 && (
                <Button onClick={() => setActiveTab('entrada')} active={'entrada' === activeTab}>
                  Entrada
                </Button>
              )}
            </Tabs>

            <div style={{ height: '50px' }} />

            {activeTab === 'entrada' && totalSalida > 0 && <BagsIn />}
            {activeTab === 'salida' && <BagsOut />}

            <ObservationModal />
          </>
        )}
      </CardBody>
    </Card>
  );
};

const state = ({ productionOrderBagStore, productionOrderStore, userStore }) => {
  const { data: currentUser } = userStore.information;
  const { data: allProductionOrderBags } = productionOrderBagStore.productionOrderBagAll;
  const { data: productionOrder } = productionOrderStore.productionOrder;
  return {
    currentUser,
    allProductionOrderBags,
    productionOrder,
  };
};

export default connect(state)(Component);
