import { parseJSON } from './parseJSON';
import { getToken } from '../utils/authUtils';
import { POST } from '../constant';

const postFile = (file, orderId, orderNoteId, type = 'order') => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    if (type === 'order') {
      formData.append('orderId', orderId);
      formData.append('orderNoteId', orderNoteId);
    } else if (type === 'feedstock') {
      formData.append('id', orderNoteId);
      formData.append('feedstockId', orderNoteId);
    } else {
      formData.append('id', orderNoteId);
      formData.append('productId', orderNoteId);
    }

    fetch(`${process.env.REACT_APP_API_URL}/files`, {
      method: POST,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      body: formData,
    })
      .then(parseJSON)
      .then((res) => {
        if (!res.ok) {
          return reject(res.json.errors);
        }

        return resolve(res.json);
      })
      .catch((error) => {
        return reject({
          networkError: error.message,
        });
      });
  });
};

export default postFile;
