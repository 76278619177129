import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import { checkDataPermission } from '../../../../components/CanRBac';

const ObsForm = ({ currentUser, handleProductChange, observations, observationIndex, isEditable }) => {
  return (
    <Col xl="12">
      <div className="project-widgets mt-2 infoCard">
        <div className="mb-1">
          <textarea
            name="observations"
            className="font-weight-bold observations-area"
            value={observations}
            disabled={checkDataPermission(currentUser?.userType, 'np:edit:product') ? !isEditable : true}
            onChange={(e) => handleProductChange(observationIndex, 'observations', e.target.value)}
          />
          <span>*Luego de editar, guardar los cambios en nota de pedido</span>
        </div>
      </div>
    </Col>
  );
};

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(ObsForm);
