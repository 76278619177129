import getTotalEgress from './getTotalEgress';
import getTotalIngress from './getTotalIngress';

const getMpMeters = (moves, units) => {
  let totalIngress = getTotalIngress(moves, units);
  let totalEgress = getTotalEgress(moves, units);

  return totalIngress - totalEgress;
};

export default getMpMeters;
