import { useState, useEffect } from 'react';
import getOrderNotes from '../services/getFeedstockCategories';

const useFeedstockCategoriesFetcher = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getOrderNotes()
      .then((orders) => {
        setData(orders);
        setIsLoading(false);
        setIsError(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return { data, isLoading, isError };
};

export default useFeedstockCategoriesFetcher;
