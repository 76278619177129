import { connect, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { hideModal } from '../../../store/actions/modal';

import { Formik } from 'formik';
import Button from '../../../components/Button';

import * as Yup from 'yup';
import { useEffect, useState } from 'react';

import Form from '../components/Form'
import services from '../../../services';
import { WorkshopDto } from '../../../types/workshop';
import { newWorkshop, resetNewWorkshop } from '../../../store/actions/workshop';
import { toast } from 'react-toastify';
import CanRBac from '../../../components/CanRBac';
import { User } from '../../../types/user';

let typingTimeout

const schemaValidation = Yup.object().shape({
  name: Yup.string().required('Este campo es requerido.').test(
    "checkWorkshopExist",
    "El nombre del taller ya se encuentra en uso.",
    function async(name) {
      //clear timeout if user is typing and timeout is set
      return new Promise((resolve, reject) => {
        //delay until user stops typing
        //check if name is valid
        if (!name) return resolve(true);

        //clear timeout if user is typing and timeout is set
        if (typingTimeout) clearTimeout(typingTimeout);

        typingTimeout = setTimeout(async () => {


          const response = await services.workshop.getAll({ name }) as { response: WorkshopDto[] };

          if (response?.response && response?.response?.length > 0) {
            return resolve(false);
          }

          if (!response?.response || response?.response?.length <= 0) {
            return resolve(true);
          }
        }, 1000);
      });
    }
  ),
  address: Yup.string().required('Este campo es requerido.'),
  phone: Yup.string().required('Este campo es requerido.'),
  contact: Yup.string().nullable(),
  deliveryPeriod: Yup.string().nullable(),
  freight: Yup.string().nullable(),
  ProductCategory: Yup.array().min(1, 'Este campo es requerido.').nullable(),
});

const initialValues = {
  name: '',
  address: '',
  phone: '',
  contact: '',
  freight: '',
  deliveryPeriod: '',
  ProductCategory: [],
};

interface ComponentProps {
  visibleModal: string;
  newWorkshopStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  currentUser: User
}

const Component = ({ visibleModal, newWorkshopStates, currentUser }: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const dispatch = useDispatch();

  const submitForm = ({ values, actions: formActions }) => {
    dispatch(newWorkshop(values))
  };

  useEffect(() => {
    if (newWorkshopStates.success) {
      toast.success('El taller se creo correctamente', { autoClose: 3000 });
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetNewWorkshop());
      }, 3500);
    }

    if (newWorkshopStates.error) {
      toast.error('Error al crear taller.', { autoClose: 3000 });
      setTimeout(() => {
        dispatch(resetNewWorkshop());
      }, 3500);
    }
  }, [newWorkshopStates]);

  return (
    <Modal isOpen={visibleModal === 'modal-new-workshop'} centered toggle={() => dispatch(hideModal())}>
      <ModalHeader toggle={() => dispatch(hideModal())}>Nuevo taller</ModalHeader>

      <ModalBody>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            if (submitForm) submitForm({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={true}
          validationSchema={schemaValidation}
          enableReinitialize
        >
          {(props) => {
            const { handleSubmit } = props
            return (
              <form
                name="new-workshop-form"
                id="new-workshop-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                style={{
                  width: '100%',
                }}
              >
                <Form {...props} loading={newWorkshopStates.loading} />
              </form>
            );
          }}
        </Formik>
      </ModalBody>

      <ModalFooter>
        <Button type="button" outline color="primary" onClick={() => dispatch(hideModal())}>
          Cancelar
        </Button>

        <CanRBac
          role={currentUser?.userType}
          perform='ws:create'
          yes={() =>
            <Button isLoading={newWorkshopStates.loading} type="submit" color="primary" form="new-workshop-form">
              Guardar taller
            </Button>
          }
        />
      </ModalFooter>
    </Modal>
  );
};

const states = ({ modalStore, workshopStore, userStore }) => {
  const { visibleModal } = modalStore;
  const { states: newWorkshopStates } = workshopStore.newWorkshop;
  const { data: currentUser } = userStore.information;

  return {
    visibleModal,
    newWorkshopStates,
    currentUser
  };
};

export default connect(states)(Component);
