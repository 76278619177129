/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { faShippingFast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Col, Card } from 'reactstrap';

// Mixins
import { CardBodyCustom, Heading, Title } from '../mixins'

// Swtich
import { ToggleSwitch } from 'react-dragswitch';
import 'react-dragswitch/dist/index.css';

// DatePicker
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';

// Select
import Select from 'react-select';

import { checkDataPermission } from '../../../components/CanRBac';

// StyleSelect
const styleSelect = {
  control: (base) => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
    },
  }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#007bff' : 'transparent',
      color: isFocused ? '#fff' : '#000',
      cursor: isFocused ? 'pointer' : 'default',
    };
  },
};

const deliveryOptions = [
  { value: 'none', label: 'Ninguno' },
  { value: 'single', label: 'Única' },
  { value: 'weekly', label: 'Semanal' },
  { value: 'biweekly', label: 'Quincenal' },
  { value: 'monthly', label: 'Mensual' },
  { value: 'bimonthly', label: 'Bimensual' },
  { value: 'quarterly', label: 'Trimestral' },
  { value: 'biannual', label: 'Semestral' },
  { value: 'annual', label: 'Anual' },
];

// const depotOptions = [
//   { value: 1, label: 'Girardot' },
//   { value: 2, label: 'Tronador' },
//   { value: 3, label: 'Laboratorio' },
// ];

const LogisticForm = ({
  isEditable,
  handleChange,
  handler,
  handlerData,
  firstDeliveryDate,
  deliveryType,
  orderDeliveryAddress,
  orderDeliveryTime,
  // depot,
  takeAway,
  productList,
  shipment,
  currentUser,
}) => {
  registerLocale('es', es);
  const [checked, setChecked] = useState(false);

  const getTotalPackages = () => {
    const reducer = (accumulator, product) => {
      const { partialDelivery } = product;

      let packages = 0;
      partialDelivery.forEach((delivery) => {
        packages += Number(delivery.packagesQuantity);
      });

      return accumulator + packages;
    };

    return productList.reduce(reducer, 0);
  };

  return (
    <Col lg="6">
      <Card>
        <Heading>
          <FontAwesomeIcon icon={faShippingFast} />
          <Title>Logística</Title>
        </Heading>

        <CardBodyCustom>
          <div className="project-widgets mt-2 infoCard">
            <div className="item-group dmx-underline">
              <div className="item-label">Depósito:</div>
              <div className="item-value">
                <Select
                  isDisabled={
                    checkDataPermission(currentUser?.userType, 'np:edit:logistic:handler') ? !isEditable : true
                  }
                  onChange={(options) => handleChange('handler', options.handler)}
                  defaultValue={{ label: handler ? handler.name : '' }}
                  options={handlerData}
                  styles={styleSelect}
                />
              </div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">Fecha de 1° entrega:</div>
              <div className="item-value">
                <DatePicker
                  locale="es"
                  disabled={
                    checkDataPermission(currentUser?.userType, 'np:edit:logistic:firstDeliveryDate') ? !isEditable : true
                  }
                  selected={Date.parse(firstDeliveryDate)}
                  calendarClassName="calendar-logistic-form"
                  className="item-date-picker"
                  onChange={(date) => handleChange('firstDeliveryDate', date.toISOString())}
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="time"
                  // showTimeSelect
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">Tipo de entrega:</div>
              <div className="item-value">
                <Select
                  isDisabled={
                    checkDataPermission(currentUser?.userType, 'np:edit:logistic:deliveryType') ? !isEditable : true
                  }
                  onChange={(e) => handleChange('deliveryType', e.value)}
                  className="item-select"
                  defaultValue={deliveryOptions.filter((option) => option.value === deliveryType)}
                  options={deliveryOptions}
                  styles={styleSelect}
                />
              </div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">Dirección de entrega:</div>
              <div className="item-value">
                <input
                  disabled={
                    checkDataPermission(currentUser?.userType, 'np:edit:logistic:orderDeliveryAddress')
                      ? !isEditable
                      : true
                  }
                  name="orderDeliveryAddress"
                  value={orderDeliveryAddress}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">Hora de entrega:</div>
              <div className="item-value">
                <input
                  disabled={
                    checkDataPermission(currentUser?.userType, 'np:edit:logistic:orderDeliveryTime')
                      ? !isEditable
                      : true
                  }
                  name="orderDeliveryTime"
                  value={orderDeliveryTime}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">Transporte:</div>
              <div className="item-value">
                <input
                  disabled={
                    checkDataPermission(currentUser?.userType, 'np:edit:logistic:transport') ? !isEditable : true
                  }
                  name="transport"
                  value={shipment}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
            </div>

            <div className="item-group dmx-underline">
              <div className="item-label">Cantidad de bultos:</div>
              <div className="item-value">
                <input
                  disabled={
                    checkDataPermission(currentUser?.userType, 'np:edit:logistic:packagesQuantity')
                      ? !isEditable
                      : true
                  }
                  type="number"
                  readOnly="true"
                  name="packagesQuantity"
                  value={getTotalPackages()}
                />
              </div>
            </div>

            {/* Se comenta por card DI-334 */}
            {/* <div className="mb-1 dmx-underline">
              <span className="text-muted">Depósito: </span>
              <Select
                placeholder="Elija un depósito"
                isDisabled={!isEditable}
                onChange={(e) => handleChange('depot', e.value)}
                className="font-weight-bold"
                defaultValue={depotOptions.filter((option) => option.value === depot)}
                options={depotOptions}
                styles={styleSelect}
              />
            </div> */}
            {/* <div className="mb-1 form-check">
              <label className="form-check-label pt-3" htmlFor="takeAway">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="takeAway"
                  name="takeAway"
                  disabled={!isEditable}
                  checked={takeAway || false}
                  onChange={(e) => handleChange('takeAway', e.target.checked)}
                />
                Retira
              </label>
            </div> */}

            <div className="item-group dmx-underline">
              <div className="item-label">Retira:</div>
              <div className="item-value">
                <label className="form-check-label" htmlFor="takeAway">
                  <ToggleSwitch
                    disabled={checkDataPermission(currentUser?.userType, 'np:edit:logistic:takeAway') ? !isEditable : true}
                    id="takeAway"
                    onColor="green"
                    checked={takeAway || checked}
                    onChange={(e) => {
                      setChecked(e);
                      handleChange('takeAway', e);
                    }}
                  />
                </label>
              </div>
            </div>
          </div>
        </CardBodyCustom>
      </Card>
    </Col>
  );
};

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(LogisticForm);
