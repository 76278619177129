import { useState, useEffect } from 'react';
import getEmployees from '../services/getEmployees';

const useEmployeesFetcher = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let isMounted = true;          
    setIsLoading(true);

    getEmployees()
      .then((employees) => {
        if (isMounted){
          setData(employees);
          setIsLoading(false);
          setIsError(false);
        } 
        
      })
      .catch(() => {
        if (isMounted){
        setIsLoading(false);
        setIsError(true);
        }
      });

      return () => { isMounted = false }; 
  }, []);

  return { data, isLoading, isError };
};

export default useEmployeesFetcher;
