import action from '../helpers/rest-client';

export class ErrorBags extends Error { }

class WorkshopService {
  public async create(payload) {
    return action
      .Post({
        url: `${process.env.REACT_APP_API_URL}/workshops`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getAll(filters) {
    const queryString = new URLSearchParams(filters).toString();
    const sort = '';

    return action
      .Get({
        url: `${process.env.REACT_APP_API_URL}/workshops?${!!queryString ? `${queryString}&${sort}` : sort}`,
      })
      .then((response) => {
        return { response: response.data };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getOne({ idWorkshop }) {
    return action
      .Get({
        url: `${process.env.REACT_APP_API_URL}/workshops/${idWorkshop}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async updateOne(payload) {
    return action
      .Patch({
        url: `${process.env.REACT_APP_API_URL}/workshops/${payload._id}`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const workshop = new WorkshopService();
export default workshop;
