import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect, useDispatch } from 'react-redux';
import Spinner from '../../../../components/Spinner';
import { getProductionOrderBagAll } from '../../../../store/actions/production-order-bag';
import { GetProductionOrderBag } from '../../../../store/types/production-order-bag';
import { Product } from '../../../../types/product';
import { ProductionOrderDto } from '../../../../types/production-order';
import { ProductionOrderBag } from '../../../../types/production-order-bag';
import { WorkshopDto } from '../../../../types/workshop';
import Bags from '../Bags'

const customStyles = {
  headCells: {
    style: {
      paddingLeft: '4px',
      paddingRight: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: '4px',
      paddingRight: '4px',
    },
  },
};

interface WorkshopOrderBagDto {
  ProductionOrder: ProductionOrderDto;
  code: string;
  date: string;
  product: Product;
  status: string;
  units: number;
  workshop: WorkshopDto;
  _id: string;
}

interface GroupedBag {
  _id: string,
  name: string,
  bags: WorkshopOrderBagDto[],
}

interface ComponentProps {
  currentWorkshop,
  bags: WorkshopOrderBagDto[]
}

const Component = ({ currentWorkshop, bags }: ComponentProps) => {
  const [groupedBags, setGroupedBags] = useState<GroupedBag[]>([])
  const dispatch = useDispatch()

  const columns = [{
    name: 'Producto',
    selector: 'name',
    sortable: false,
  }, {
    name: 'Merma',
    minWidth: '100px',
    maxWidth: '100px',
    cell: (row) => {
      const unitsInDeposit = row.bags
        ?.filter((productionOrder) => productionOrder.status === 'inDeposit')
        .map((bag) => bag.units)
        .reduce((a, b) => a + b, 0);

      const unitsInWorkshop = row.bags
        ?.filter((productionOrder) => productionOrder.status === 'inWorkshop')
        .map((bag) => bag.units)
        .reduce((a, b) => a + b, 0);

      return unitsInDeposit - unitsInWorkshop
    },
    sortable: false,
  }]

  useEffect(() => {
    dispatch(getProductionOrderBagAll({ idWorkshop: currentWorkshop?._id }));
  }, [])

  useEffect(() => {
    if (bags && bags.length > 0) {
      const filteredBags = bags.filter(bag => bag.status !== 'inTransit')

      const groupedBags: GroupedBag[] = filteredBags.reduce((result: GroupedBag[], bag) => {
        const productId = bag.product._id;

        const existingGroup = result.find((group) => group._id === productId);

        if (existingGroup) {
          existingGroup.bags.push(bag);
        } else {
          const newGroup: GroupedBag = {
            _id: bag.product._id,
            name: bag.product.name,
            bags: [bag],
          };
          result.push(newGroup);
        }

        return result;
      }, []);


      const sortedBagsArray = Object.values(groupedBags).map((group) => {
        const sortedBags = group?.bags?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        return { ...group, bags: sortedBags };
      });

      setGroupedBags(sortedBagsArray);
    }
  }, [bags])

  if (groupedBags.length <= 0) {
    <Spinner size={'large'} />
  }

  return <div className="data-table-container">
    <DataTable
      customStyles={customStyles}
      columns={columns}
      data={groupedBags}
      noDataComponent={<div className="p-3">No hay registros para mostrar</div>}
      expandableRows
      expandableRowDisabled={(row) => {
        return row.bags && row.bags.length <= 0;
      }}
      expandableRowsComponent={<Bags />}
    />
  </div>

}

const state = ({ workshopStore, productionOrderBagStore }) => {
  const { data: currentWorkshop, states: currentWorkshopStates } = workshopStore.workshop;
  const { data: editedWorkshop, states: editedWorkshopStates } = workshopStore.editWorkshop;
  const { data: bags } = productionOrderBagStore.productionOrderBagAll
  return {
    currentWorkshop,
    currentWorkshopStates,
    editedWorkshop,
    editedWorkshopStates,
    bags
  };
};

export default connect(state)(Component);