import { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { CardHeader, Col, Card, CardBody } from 'reactstrap';

// permisos
import CanRBac from '../../../components/CanRBac';

// components
import BatchList from './BatchList';
import Spinner from '../../../components/Spinner';

// modals
import ModalNewBatch from '../modals/ModalNewLote';

// store
import { getFeedStock } from '../../../store/actions/raw-material';

// helpers
import { getUrlParamByName } from '../../../helpers/get-url-param';

const BatchBox = ({
  data,
  setData,
  productData,
  isEditable,
  newMove,
  updateBatch,
  updateMove,
  deleteMove,
  deleteBatch,
  currentUser,
  toggle,
  hasUnits,
}) => {
  const orderId = getUrlParamByName('id');
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    dispatch(getFeedStock(orderId));
  }, [])

  if (!data) {
    return <Spinner size="large" isPageLoader />
  };

  return (
    <>
      <Col xl>
        <Card>
          <CardHeader>
            <div className="media-body">
              <h5 className="ml-3">LOTES</h5>
            </div>
          </CardHeader>

          <CardBody>
            <div className="stock-table">
              <BatchList
                currentDataMP={data}
                currentUser={currentUser}
                data={data.stockControl}
                productName={data.name}
                productData={productData}
                isEditable={isEditable}
                newMove={newMove}
                updateBatch={updateBatch}
                updateMove={updateMove}
                deleteMove={deleteMove}
                deleteBatch={deleteBatch}
                toggle={toggle}
                hasUnits={hasUnits}
              />
            </div>

            {isEditable ? (
              <CanRBac
                perform="mp:edit:stockControl"
                role={currentUser?.userType}
                yes={() => (
                  <div className="mt-3">
                    <div>
                      <button className="btn btn-primary" type="button" onClick={() => toggleModal()}>
                        Agregar
                      </button>
                    </div>
                  </div>
                )}
              />
              ) : (
              <></>
            )}
          </CardBody>
        </Card>
      </Col>

      <ModalNewBatch isOpen={modal} toggle={toggleModal} data={data} setData={setData} />
    </>
  );
};

const state = ({ rawMaterialStore }) => {
  const { data: feedStock, states: feedStockStates } = rawMaterialStore.feedStock

  return {
    feedStock,
    feedStockStates,
  }
}

export default connect(state)(BatchBox);
