/* eslint no-underscore-dangle: 0 */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DataTableExtensions from '@innenpkg/react-data-table-component-extensions';
import '@innenpkg/react-data-table-component-extensions/dist/index.css';
import './Clients.scss';
import { ToastContainer, toast } from 'react-toastify';
import _ from 'underscore';
import { faPlus, faEdit, faTimesCircle, faEye, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SweetAlert from 'sweetalert2';
import { CREATE, READ, UPDATE } from '../../constant';

import useClientsFetcher from '../../hooks/useClientsFetcher';
import deleteClient from '../../services/deleteClient';
import Spinner from '../../components/Spinner';
import DmxModal from './DmxModal';
import { ClientContext } from '../../context/ClientContext';
import usePurchaseConditionsFetcher from '../../hooks/usePurchaseConditionsFetcher';
import getClients from '../../services/getClients';
import SpinnerButton from '../../components/Button';
import { notifications } from '../../constant';
import { connect } from 'react-redux';

//permissions
import CanRBac from '../../components/CanRBac';
import { rules } from '../../components/CanRBac/rbac-rules';

const Clients = ({ currentUser }) => {
  const purchaseConditions = usePurchaseConditionsFetcher();
  const clients = useClientsFetcher();
  const [client, setClient] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const { clientContext, modalContext } = useContext(ClientContext);
  const [modal, setModal] = modalContext;
  const [data, setData] = clientContext;
  const [action, setAction] = useState();
  const [page, setPage] = useState();
  const [itemsForPage, setItemsForPage] = useState();

  const handleRefresh = async () => {
    setIsFetching(true);
    try {
      await getClients().then((result) => {
        const newData = result;
        setData(newData.sort(compare));
      });
      toast.success(notifications.tableUpdateSuccess, { autoClose: 3000 });
    } catch (error) {
      toast.error(`${notifications.tableUpdateError} ${error.msg}`, { autoClose: 3000 });
    }
    setIsFetching(false);
  };

  function compare(a, b) {
    const textA = a.brandName ? a.brandName.toUpperCase() : a.brandName;
    const textB = b.brandName ? b.brandName.toUpperCase() : b.brandName;

    let comparison = 0;
    if (textA < textB) {
      comparison = -1;
    } else if (textA > textB) {
      comparison = 1;
    }
    return comparison;
  }

  useEffect(() => {
    setData(clients.data.sort(compare));
  }, [clients.data]);

  useEffect(() => {
    setClient(client);
  }, [modal]);

  useEffect(() => {
    const lastPageLS = localStorage.getItem('lastPageClients');
    const itemsForPageLS = localStorage.getItem('itemsForPageClients');

    if (lastPageLS === null || itemsForPageLS === null) {
      setPage(1);
      setItemsForPage(10);
    } else if (lastPageLS == 1) {
      setPage(1);
      setItemsForPage(itemsForPageLS);
    } else {
      setPage(lastPageLS);
      setItemsForPage(itemsForPageLS);
    }
  }, []);
  if (clients.isLoading) {
    return <Spinner size="large" isPageLoader />;
  }

  // if (clients.isError) {
  //   return <h1>An error has ocurred.</h1>;
  // }

  const paginationText = {
    rowsPerPageText: 'Clientes por página:',
    rangeSeparatorText: 'de',
  };

  const eventLastPage = (page) => {
    setPage(page);
  };
  const eventItemsForPage = (currentRowsPerPage) => {
    setItemsForPage(currentRowsPerPage);
  };
  const postLastPageLocal = () => {
    localStorage.setItem('lastPageClients', page);
    localStorage.setItem('itemsForPageClients', itemsForPage);
  };
  const toggleModal = () => {
    setModal(!modal);
  };

  const executeRowClick = (clientInfo) => {
    setClient(clientInfo);
    setAction(READ);
    toggleModal();
    setIsEditable(false);
    postLastPageLocal();
  };

  const handleRowClicked = (clientInfo) => {
    executeRowClick(clientInfo);
    postLastPageLocal();
  };

  const executeEditAction = (clientInfo) => {
    setClient(clientInfo);
    setAction(UPDATE);
    toggleModal();
    setIsEditable(true);
    postLastPageLocal();
  };

  const handleEditAction = (clientInfo) => {
    executeEditAction(clientInfo);
    postLastPageLocal();
  };

  function deleteTableRow(clientId) {
    const clientIndex = data.findIndex((c) => c._id === clientId);
    const newData = _.clone(data);
    newData.splice(clientIndex, 1);
    setData(newData);
  }

  const executeDeleteAction = (clientId) => {
    deleteClient(clientId).then((res) => {
      if (res.msg === 'DELETED') {
        deleteTableRow(clientId);
        toast.success('Cliente borrado satisfactoriamente.', { autoClose: 3000 });
      } else {
        toast.error(`No se pudo borrar el cliente porque ocurrió un error:${res.msg}`, { autoClose: 3000 });
      }
    });
  };

  const handleDeleteAction = (clientId) => {
    SweetAlert.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado, no podrá ser recuperado.',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        executeDeleteAction(clientId);
      }
    });
  };

  const executeCreateAction = () => {
    setAction(CREATE);
    toggleModal();
    setIsEditable(true);
  };

  const handleCreateAction = () => {
    executeCreateAction();
  };

  const actionButtons = (row) => (
    <>
      <CanRBac
        role={currentUser.userType}
        perform="client:edit"
        yes={() => (
          <>
            <div className="text-secondary p-1 dmx-action-button">
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEditAction(row)} />
            </div>
            <div className="text-danger p-1 dmx-action-button">
              <FontAwesomeIcon icon={faTimesCircle} onClick={() => handleDeleteAction(row._id)} />
            </div>
          </>
        )}
        no={() => null}
      />
    </>
  );

  const columns = [
    {
      name: 'Código',
      selector: 'code',
      sortable: true,
      center: false,
    },
    {
      name: 'Nombre Comercial',
      selector: 'brandName',
      sortable: true,
      center: false,
      grow: 2,
    },
    {
      name: 'Razón Social',
      selector: 'companyName',
      sortable: true,
      center: false,
      grow: 2,
    },
    {
      name: 'CUIT',
      selector: 'cuit',
      sortable: true,
      center: false,
      grow: 1,
    },
    {
      name: 'Dirección Fiscal/Comercial',
      selector: 'address',
      sortable: true,
      center: false,
      grow: 2,
    },
    {
      name: 'Acciones',
      center: false,
      cell: (row, index) => actionButtons(row, index),
    },
  ];

  const tableData = {
    columns,
    data,
  };

  let icon;
  let title;

  switch (action) {
    case UPDATE:
      icon = <FontAwesomeIcon icon={faEdit} />;
      title = 'Edición de cliente';
      break;

    case READ:
      icon = <FontAwesomeIcon icon={faEye} />;
      title = 'Visualización de cliente';
      break;

    case CREATE:
      icon = <FontAwesomeIcon icon={faPlus} />;
      title = 'Creación de cliente';
      break;

    default:
      break;
  }

  return (
    <>
      <div className="container-fluid page-clients">
        <div className="page-header">
          <CanRBac
            role={currentUser.userType}
            perform="client:create"
            yes={() => (
              <>
                <Link to="#/newClientModal" className="btn btn-success btn-create" onClick={handleCreateAction}>
                  <FontAwesomeIcon icon={faPlus} /> Nuevo Cliente
                </Link>
              </>
            )}
            no={() => null}
          />
        </div>

        <div className="card shadow-sm">
          <div className="card-body datatable-react">
            <div className="d-flex flex-row-reverse">
              <SpinnerButton type="button" onClick={() => handleRefresh()} isLoading={isFetching} disabled={isFetching}>
                <FontAwesomeIcon icon={faSyncAlt} /> Refrescar
              </SpinnerButton>
            </div>
            <DataTableExtensions
              {...tableData}
              export={false}
              print={false}
              filterHidden={false}
              filterPlaceholder="Buscar..."
            >
              <DataTable
                paginationComponentOptions={paginationText}
                pagination
                columns={columns}
                data={data}
                striped
                center
                persistTableHead
                highlightOnHover
                paginationDefaultPage={page}
                paginationPerPage={itemsForPage}
                onChangePage={eventLastPage}
                onChangeRowsPerPage={eventItemsForPage}
                noDataComponent={<div className="dmx-empty-table">No hay registros para mostrar</div>}
                responsive
                onRowClicked={(row) => handleRowClicked(row)}
              />
            </DataTableExtensions>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <DmxModal
        isOpen={modal}
        toggle={toggleModal}
        isEditable={isEditable}
        icon={icon}
        title={title}
        data={client}
        action={action}
        purchaseConditions={purchaseConditions}
      />
    </>
  );
};

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(Clients);
