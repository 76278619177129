import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import Submit from '../../../../components/Form/Submit';
import { hideModal } from '../../../../store/actions/modal';
import { ErrorMessage } from './styles';

const Component = ({ visibleModal, handleChangeMerma, updateOrderStates, productionOrder, productionOrderBags }) => {
  const [workshopDifference, setWorkshopDifference] = useState(0);
  const [cutDifference, setCutDifference] = useState(0);
  const [merma, setMerma] = useState<{ cutDifference: number; workshopDifference: number; comments: string }>({
    workshopDifference: 0,
    cutDifference: 0,
    comments: productionOrder?.merma?.comments || '',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateOrderStates.success) {
      dispatch(hideModal());
    }
  }, [updateOrderStates]);

  useEffect(() => {
    setMerma({ ...merma, workshopDifference, cutDifference });
  }, [workshopDifference, cutDifference]);

  useEffect(() => {
    const hasBags = !!productionOrderBags && productionOrderBags?.length > 0;
    const unitsQty = productionOrder.quantity;

    let unitsInDeposit = 0;
    let unitsInWorkshop = 0;
    let unitsInTransit = 0;

    if (hasBags) {
      unitsInDeposit = productionOrderBags
        ?.filter((productionOrderBag) => productionOrderBag.status === 'inDeposit')
        .map((bag) => bag.units)
        .reduce((a, b) => a + b, 0);

      unitsInWorkshop = productionOrderBags
        ?.filter((productionOrderBag) => productionOrderBag.status === 'inWorkshop')
        .map((bag) => bag.units)
        .reduce((a, b) => a + b, 0);

      unitsInTransit = productionOrderBags
        ?.filter((productionOrderBag) => productionOrderBag.status === 'inTransit')
        .map((bag) => bag.units)
        .reduce((a, b) => a + b, 0);

      setWorkshopDifference(unitsInWorkshop - unitsInDeposit);
      setCutDifference(unitsQty - unitsInWorkshop - unitsInTransit);
    }
  }, [productionOrderBags]);

  return (
    <Modal
      isOpen={visibleModal === 'modal-merma'}
      toggle={() => dispatch(hideModal())}
      size="md"
      fullscreen="md"
      centered
      autoFocus={false}
      role="document"
    >
      <ModalHeader toggle={() => dispatch(hideModal())}>Existe una merma de {cutDifference} unidades</ModalHeader>

      <ModalBody toggle={() => dispatch(hideModal())}>
        {cutDifference > 0 && `Detectamos que ${cutDifference} unidades no fueron cortadas.`}
        {workshopDifference > 0 && (
          <>
            <div style={{ height: 16 }} />
            {cutDifference > 0 ? 'Tambien n' : 'N'}otamos que aun hay {workshopDifference} unidades no fueron devueltas
            por los talleres.
          </>
        )}
        <div style={{ height: 16 }} />
        Si deseas cerrar esta orden de producción, indicar a que se debe esta diferencia o bien puedes corregirla
        ingresando a la OP.
        <div style={{ height: 24 }} />
        <textarea
          name="observations"
          className="form-control"
          disabled={updateOrderStates.loading}
          value={merma.comments}
          onChange={(e) => setMerma({ ...merma, comments: e.target.value })}
        />
        <ErrorMessage>El campo debe ser completado para de continuar.</ErrorMessage>
      </ModalBody>

      <ModalFooter>
        <Button
          type="button"
          outline
          color="primary"
          disabled={updateOrderStates.loading}
          onClick={() => {
            dispatch(hideModal());
            window.location.reload();
          }}
        >
          Cancelar
        </Button>

        <Button
          type="button"
          color="primary"
          disabled={merma.comments === '' || updateOrderStates.loading}
          onClick={() => handleChangeMerma(merma)}
        >
          Finalizar OP
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const state = ({ modalStore, productionOrderStore, productionOrderBagStore }) => {
  const { data: updateOrder, states: updateOrderStates } = productionOrderStore.updateOrder;
  const { data: productionOrderBags } = productionOrderBagStore.productionOrderBagAll;
  const { visibleModal } = modalStore;

  return {
    visibleModal,
    productionOrderBags,
    updateOrderStates,
  };
};

export default connect(state)(Component);
