import { useMemo } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { checkDataPermission } from '../../../../components/CanRBac';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/ReactSelect';
import useProductCategoriesFetcher from '../../../../hooks/useProductCategoriesFetcher';
import { User } from '../../../../types/user';

interface ComponentProps {
  loading: boolean;
  values;
  errors;
  touched;
  handleSubmit;
  handleReset;
  handleBlur;
  handleChange;
  setFieldValue;
  editMode?: boolean
  currentUser: User
}

const Component = ({
  loading,
  values,
  errors,
  touched,
  handleSubmit,
  handleReset,
  handleBlur,
  handleChange,
  setFieldValue,
  editMode,
  currentUser
}: ComponentProps) => {
  const productCategories = useProductCategoriesFetcher();

  const productsCategories = useMemo(() => {
    return productCategories?.data?.map((productCategory: { name: string, _id: string }) => {
      return {
        label: productCategory.name,
        value: productCategory._id
      }
    })
  }, [productCategories])

  return (
    <>
      <Row>
        <Col>
          <Input
            disabled={loading || !checkDataPermission(currentUser.userType, 'ws:edit') || !checkDataPermission(currentUser.userType, 'ws:create')}
            error={errors['name']}
            touched={touched['name']}
            value={values['name']}
            onChange={handleChange}
            onBlur={handleBlur}
            name="name"
            type="text"
            options={{ label: 'Nombre del taller *' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            disabled={loading || !checkDataPermission(currentUser.userType, 'ws:edit') || !checkDataPermission(currentUser.userType, 'ws:create')}
            error={errors['address']}
            touched={touched['address']}
            value={values['address']}
            onChange={handleChange}
            onBlur={handleBlur}
            name="address"
            type="address"
            options={{ label: 'Dirección *' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            disabled={loading || !checkDataPermission(currentUser.userType, 'ws:edit') || !checkDataPermission(currentUser.userType, 'ws:create')}
            error={errors['phone']}
            touched={touched['phone']}
            value={values['phone']}
            onChange={handleChange}
            onBlur={handleBlur}
            name="phone"
            type="phone"
            options={{ label: 'Teléfono *' }}
          />
        </Col>
        <Col>
          <Input
            disabled={loading || !checkDataPermission(currentUser.userType, 'ws:edit') || !checkDataPermission(currentUser.userType, 'ws:create')}
            error={errors['contact']}
            touched={touched['contact']}
            value={values['contact']}
            onChange={handleChange}
            onBlur={handleBlur}
            name="contact"
            type="text"
            options={{ label: 'Contacto' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            disabled={loading || !checkDataPermission(currentUser.userType, 'ws:edit') || !checkDataPermission(currentUser.userType, 'ws:create')}
            error={errors['freight']}
            touched={touched['freight']}
            value={values['freight']}
            onChange={handleChange}
            onBlur={handleBlur}
            name="freight"
            type="text"
            options={{ label: 'Flete' }}
          />
        </Col>
        <Col>
          <Input
            disabled={loading || !checkDataPermission(currentUser.userType, 'ws:edit') || !checkDataPermission(currentUser.userType, 'ws:create')}
            error={errors['deliveryPeriod']}
            touched={touched['deliveryPeriod']}
            value={values['deliveryPeriod']}
            onChange={handleChange}
            onBlur={handleBlur}
            name="deliveryPeriod"
            type="text"
            options={{ label: 'Periodo de entrega' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            isMulti
            isClearable
            disabled={loading || !checkDataPermission(currentUser.userType, 'ws:edit') || !checkDataPermission(currentUser.userType, 'ws:create')}
            error={errors['ProductCategory']}
            touched={touched['ProductCategory']}
            items={productsCategories}
            onChange={handleChange}
            onBlur={handleBlur}
            name="ProductCategory"
            options={{ label: 'Productos *' }}
          />
        </Col>
      </Row>
    </>
  );
};

const states = ({ userStore }) => {
  const { data: currentUser } = userStore.information;

  return {
    currentUser
  };
};

export default connect(states)(Component);
