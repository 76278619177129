import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Details } from './styles';
import logo from '../../../../../assets/images/logo.png';

interface ComponentProps {
  children: ReactNode;
}

const Component = ({ children }: ComponentProps) => {
  return (
    <div className="table-responsive">
      <table className="table">
        <tbody>
          <tr>
            <td>
              <img src={logo} alt="" />
            </td>

            <td>
              <Details>
                <p>
                  <FontAwesomeIcon icon={faExclamationTriangle} /> <span className="font-weight-bold">USO INTERNO</span>
                </p>

                {children}
              </Details>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Component;
