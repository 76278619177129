/* eslint no-underscore-dangle: 0 */
import { getToken } from '../utils/authUtils';
import { PATCH } from '../constant';

async function patchProduct(data) {
  return fetch(`${process.env.REACT_APP_API_URL}/products/${data._id}`, {
    method: PATCH,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
}

export default patchProduct;
