import React, { useState, createContext } from 'react';

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [client, setClient] = useState({});
  const [toggle, setToggle] = useState(false);

  return (
    <ClientContext.Provider value={{ clientContext: [client, setClient], modalContext: [toggle, setToggle] }}>
      {children}
    </ClientContext.Provider>
  );
};
