import action from '../helpers/rest-client';

export class ErrorProduct extends Error {}

class ProductService {
  public async getAll() {
    return action
      .Get({
        url: `/products/all`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const product = new ProductService();
export default product;
