import styled from 'styled-components'


export const Print = styled('div')``
export const WorkshopName = styled('h1')``
export const Card = styled('div')`

`
export const ProductName = styled('h3')`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
`
export const List = styled('ul')`
  background: white;
  border-radius: 10px;
  box-shadow: -1px 1px 5px 0px rgba(0,0,0,.1);
`
export const ListItem = styled('li')`
display: flex;
align-items: flex-start;
gap: 10px;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0,0,0,.1);
`

export const WorkshopInformation = styled('div')`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`
export const Information = styled('div')``