import { getToken } from '../utils/authUtils';
import { GET } from '../constant';

export default function getProfile() {
  return fetch(`${process.env.REACT_APP_API_URL}/profile`, {
    method: GET,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json());
}
