import { useContext } from 'react';
import Select from 'react-select';
import Spinner from '../../../../components/Spinner';
import { toast } from 'react-toastify';
import { NewProductionOrderContext } from '../../../../context/NewProductionOrderContext';
import { StepsInput } from './mixins';

const ProductInformation = (props) => {
  const { isLoading, products, feedstocks } = props;
  const [context, setContext] = useContext(NewProductionOrderContext);

  if (isLoading || !feedstocks.data.length) {
    return <Spinner isPageLoader />;
  }

  const productsData = products.data.map((product) => ({
    label: product.name,
    value: { ...product },
  }));

  const feedStock = feedstocks.data.filter((feedstock) => feedstock.feedstockCategoryId.productionType === 'FEEDSTOCK');
  const supplies = feedstocks.data.filter((supply) => supply.feedstockCategoryId.productionType === 'SUPPLY');

  const feedstocksData = [
    {
      label: 'MATERIA PRIMA',
      options: feedStock.map((feedstock) => {
        const { name, _id, color, feedstockCategoryId, stock, grammage } = feedstock;
        return {
          label: name,
          value: _id,
          extra: {
            color,
            name: feedstockCategoryId.name,
            stock,
            grammage,
          },
        };
      }),
    },
    {
      label: 'INSUMOS',
      options: supplies.map((supply) => {
        const { name, _id, color, feedstockCategoryId, stock, grammage } = supply;
        return {
          label: name,
          value: _id,
          extra: {
            color,
            name: feedstockCategoryId.name,
            stock,
            grammage,
          },
        };
      }),
    },
  ];

  const handleOnChange = (name, value) => {
    if (name === 'productName') {
      setContext({
        ...context,
        product: value,
      });
    } else if (name === 'feedstockName') {
      setContext({
        ...context,
        feedstock: value.map((v) => v.value),
      });
    } else if (name === 'batchName') {
      const filterOptions = value.map((option) => ({
        name: option.label,
        _id: option.value._id,
        movementsDetail: option.value.movementsDetail,
        mpMeters: option.value.mpMeters,
        opMeters: option.value.opMeters,
        garment: option.value.garment,
      }));

      setContext({
        ...context,
        batch: filterOptions,
      });
    } else {
      setContext({
        ...context,
        [name]: value,
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row mt-4">
              <div className="col-md-12 mb-3">
                <label htmlFor="productName" className="form-label">
                  Producto
                </label>

                <Select
                  defaultValue={productsData.filter((option) => option.value._id === context.product._id)}
                  id="productName"
                  placeholder="Seleccione un producto"
                  options={productsData}
                  autoComplete
                  onChange={(option) => handleOnChange('productName', option.value)}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="feedstockName" className="form-label">
                  Materia Prima
                </label>

                <Select
                  id="feedstockName"
                  placeholder="Seleccione una materia prima"
                  value={feedstocks?.data
                    ?.filter((item) => context?.feedstock?.includes(item._id))
                    .map((item) => {
                      const { name, _id, color, feedstockCategoryId, stock, grammage } = item;
                      return {
                        label: name,
                        value: _id,
                        extra: {
                          color,
                          name: feedstockCategoryId?.name,
                          stock,
                          grammage,
                        },
                      };
                    })}
                  options={feedstocksData}
                  autoComplete
                  onChange={(option, actionMeta) => {
                    if (actionMeta.action === 'select-option' && actionMeta.option?.extra?.stock <= 0) {
                      toast.info(`${actionMeta.option?.label} no cuenta con lotes o metros disponibles`, {
                        autoClose: 3000,
                      });
                    }
                    handleOnChange('feedstockName', option);
                  }}
                  isMulti
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="quantity" className="form-label">
                  Unidades a fabricar
                </label>

                <StepsInput
                  className="form-control dmx-input w-100"
                  type="number"
                  name="quantity"
                  onWheel={(event) => event.currentTarget.blur()}
                  defaultValue={context.quantity}
                  placeholder="Unidades a fabricar"
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ProductInformation;
