import { ProductionOrderDto } from '../../../../../types/production-order';
import { Observations } from './styles';

interface Observations {
    observations: ProductionOrderDto["observations"];
}

const Component = ({ observations }: Observations) => {

    return (
        <Observations>
            <table>
                <tr>
                    <td>
                        <strong> Observaciones:</strong>  { observations }
                    </td>
                </tr>
            </table>
        </Observations>
    );
};

export default Component;