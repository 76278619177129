import { call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';

import { GET_ALL_CUSTOMERS, GET_ALL_CUSTOMERS_SUCCESS, GET_ALL_CUSTOMERS_ERROR } from '../types/customer';

/**
 * Pending Task
 * Check if store is filled before call to BFF.
 */

function* getAllCustomers() {
  try {
    const { response, error } = yield call(services.customer.getAll);

    if (error) {
      yield put({ type: GET_ALL_CUSTOMERS_ERROR, payload: error });
    } else {
      yield put({ type: GET_ALL_CUSTOMERS_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: GET_ALL_CUSTOMERS_ERROR, payload: 'Error al buscar los clientes.' });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_ALL_CUSTOMERS, getAllCustomers);
}
