import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Row, Col } from 'reactstrap';
import { patchProductionOrder } from '../../../../../../../../../store/actions/production-order';
import { checkDataPermission } from '../../../../../../../../../components/CanRBac';
import { User } from '../../../../../../../../../types/user';

const emptyState = <div className="dmx-empty-table">No hay materias primas seleccionadas</div>;

interface ComponentProps {
  productionOrder;
  currentUser: User;
}

const Component = ({ productionOrder, currentUser }: ComponentProps) => {
  const [data, setData] = useState<{ label: string; value: string }[]>();

  const dispatch = useDispatch();

  const columns = [
    { name: 'Materia Prima', selector: 'label', grow: 2 },
    {
      name: 'Prioritaria',
      grow: 0.3,
      center: true,
      headerClassName: 'your-class-name',
      cell: (row, index) => {
        return (
          <input
            type="radio"
            className=""
            checked={row.value === productionOrder?.principalFeedstockCode}
            name="principalFeedstockCode"
            onChange={(event) => updateProductionOrder(row.value)}
          />
        );
      },
    },
  ];

  const updateProductionOrder = (IdPrincipalFeedStock) => {
    dispatch(
      patchProductionOrder({
        id: productionOrder?._id,
        ...productionOrder,
        principalFeedstockCode: IdPrincipalFeedStock,
      }),
    );
  };

  useEffect(() => {
    if (!!productionOrder) {
      setData(productionOrder?.feedstock?.map((item) => ({ label: item?.name, value: item?._id })));
    }
  }, [productionOrder]);

  if (!data) {
    return null;
  }

  return (
    <Row>
      <Col>
        <DataTable
          columns={columns}
          disabled={!checkDataPermission(currentUser.userType, 'op:edit:feedStock', { status: productionOrder?.state })}
          data={[...data]}
          striped
          responsive
          persistTableHead
          noDataComponent={emptyState}
        />
      </Col>
    </Row>
  );
};

const state = ({ productionOrderStore, userStore }) => {
  const { data: currentUser } = userStore.information;
  const { data: productionOrder } = productionOrderStore?.productionOrder;
  const { states: productionOrderStates } = productionOrderStore?.productionOrder;

  return {
    currentUser,
    productionOrder,
    productionOrderStates,
  };
};

export default connect(state)(Component);
