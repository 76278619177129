/* eslint no-underscore-dangle: 0 */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useContext,useEffect } from 'react';
import Select from 'react-select';
import Spinner from '../../components/Spinner';
import { NewOrderNoteContext } from '../../context/NewOrderNoteContext';

const CustomerInformation = (props) => {
  const { isLoading, data, setIsDisable } = props;
  const [context, setContext] = useContext(NewOrderNoteContext);



  const clientNameOptions = data?.data?.map((customer) => ({
    value: customer._id,
    label: `${customer.brandName} (${customer.companyName})`,
    client: {
      _id: customer._id,
      brandName: customer.brandName,
      companyName: customer.companyName,
      cuit: customer.cuit,
      phone: customer.phone,
      email: customer.email,
      address: customer.address,
      deliveryAddress: customer.deliveryAddress,
      shipment: customer.shipment,
      paymentType: customer.paymentType,
      deliveryTime: customer.deliveryTime,
    },
  }));

  // const clientCompanyNameOptions = data.data.map((customer) => ({
  //   value: customer._id,
  //   label: customer.companyName,
  //   client: {
  //     _id: customer._id,
  //     brandName: customer.brandName,
  //     companyName: customer.companyName,
  //     cuit: customer.cuit,
  //     phone: customer.phone,
  //     email: customer.email,
  //     address: customer.address,
  //     deliveryAddress: customer.deliveryAddress,
  //     shipment: customer.shipment,
  //     paymentType: customer.paymentType,
  //   },
  // }));

    const handleOnChange = (name, value) => {
      if (name === 'clientCompanyName' || name === 'clientName') {
        setContext({
          ...context,
          client: value,
        });
        setIsDisable(true)
      } else {
        setContext({
          ...context,
          [name]: value,
        });
        setIsDisable(true)
      }
    };
    const disable = () => {
      if (context.id !== '') {
        setIsDisable(true)
      } else {
        setIsDisable(false)
      }
    }
    useEffect(() => {
      disable()
    }, [])

  if (isLoading) {
    return <Spinner isPageLoader />;
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <form className="needs-validation" noValidate>
            <div className="form-row mt-4">
              {/* <div className="col-md-12 mb-3">
                <label htmlFor="orderNoteId" className="form-label">
                  Nro de Nota de Pedido
                </label>
                <input
                  value={orderNoteId}
                  id="orderNoteId"
                  className="form-control"
                  type="number"
                  min="1"
                  placeholder="Ingrese el número de Nota de pedido"
                  onChange={(event) => handleOnChange('orderNoteId', event.target.value)}
                />
              </div> */}
              <div className="col-md-12 mb-3">
                <label htmlFor="clientName" className="form-label">
                  Nombre (Razón Social) (Obligatorio)
                </label>
                <Select
                  defaultValue={clientNameOptions.filter((option) => option.value === context.client._id)}
                  id="clientName"
                  placeholder="Seleccione un cliente"
                  options={clientNameOptions}
                  autoComplete
                  onChange={(option) => handleOnChange('clientName', option.client)}
                />
              </div>
              {/* <div className="col-md-12 mb-3">
                <label htmlFor="clientCompanyName" className="form-label">
                  Razón Social
                </label>
                <Select
                  defaultValue={clientCompanyNameOptions.filter((option) => option.value === client._id)}
                  id="clientCompanyName"
                  placeholder="Seleccione un cliente"
                  options={clientCompanyNameOptions}
                  onChange={(option) => handleOnChange('clientCompanyName', option.client)}
                />
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CustomerInformation;
