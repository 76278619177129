import {
  initialState,
  Actions,
  State,
  NEW_WORKSHOP_ORDER,
  NEW_WORKSHOP_ORDER_SUCCESS,
  NEW_WORKSHOP_ORDER_ERROR,
  RESET_NEW_WORKSHOP_ORDER,
  RESET_WORKSHOP_ORDER,
  RESET_EDIT_WORKSHOP_ORDER,
  EDIT_WORKSHOP_ORDER,
  EDIT_WORKSHOP_ORDER_SUCCESS,
  EDIT_WORKSHOP_ORDER_ERROR,
  GET_WORKSHOP_ORDER,
  GET_WORKSHOP_ORDER_SUCCESS,
  GET_WORKSHOP_ORDER_ERROR,
  GET_ALL_WORKSHOPS_ORDERS,
  GET_ALL_WORKSHOPS_ORDERS_SUCCESS,
  GET_ALL_WORKSHOPS_ORDERS_ERROR,
  GET_ALL_WORKSHOPS_ORDERS_RESET,
} from '../types/workshop-order';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_WORKSHOPS_ORDERS:
      return {
        ...state,
        workshopsOrders: {
          data: [],
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_WORKSHOPS_ORDERS_SUCCESS:
      return {
        ...state,
        workshopsOrders: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_WORKSHOPS_ORDERS_ERROR:
      return {
        ...state,
        workshopsOrders: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_ALL_WORKSHOPS_ORDERS_RESET:
      return {
        ...state,
        workshopsOrders: {
          data: [],
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case GET_WORKSHOP_ORDER:
      return {
        ...state,
        workshopOrder: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_WORKSHOP_ORDER_SUCCESS:
      return {
        ...state,
        workshopOrder: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_WORKSHOP_ORDER_ERROR:
      return {
        ...state,
        workshopOrder: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_WORKSHOP_ORDER:
      return {
        ...state,
        workshopOrder: initialState.workshopOrder,
      };
    case NEW_WORKSHOP_ORDER:
      return {
        ...state,
        newWorkshopOrder: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_WORKSHOP_ORDER_SUCCESS:
      return {
        ...state,
        newWorkshopOrder: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_WORKSHOP_ORDER_ERROR:
      return {
        ...state,
        newWorkshopOrder: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_WORKSHOP_ORDER:
      return {
        ...state,
        newWorkshopOrder: initialState.newWorkshopOrder,
      };
    case EDIT_WORKSHOP_ORDER:
      return {
        ...state,
        editWorkshopOrder: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case EDIT_WORKSHOP_ORDER_SUCCESS:
      return {
        ...state,
        editWorkshopOrder: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case EDIT_WORKSHOP_ORDER_ERROR:
      return {
        ...state,
        editWorkshopOrder: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_EDIT_WORKSHOP_ORDER:
      return {
        ...state,
        editWorkshopOrder: initialState.editWorkshopOrder,
      };
    default:
      return state;
  }
};

export default reducers;
