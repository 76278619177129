import '../Invoice.scss';

const PartialDelivery = ({ data }) => {
  const locale = 'es-SP';
  const options = {
    timeZone: 'UTC',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  // Armo el encabezado de la tabla solo si tengo datos para mostrar.
  const header =
    data.length > 0 ? (
      <tr className="pd-header">
        <td>&nbsp;</td>
        <td>Fecha</td>
        <td>Entregado</td>
        <td>Lote #</td>
        <td>Bultos</td>
        <td>Vencimiento</td>
        <td>Observaciones</td>
      </tr>
    ) : (
      ''
    );

  // Armo el cuerpo de la tabla.
  const body = data.map((item) => {
    const { deliveryDate, quantity, batchNumber, expirationDate, observations, packagesQuantity } = item;

    // seteamos formatos de fechas
    const formatedDeliveryDate = new Date(deliveryDate).toLocaleString(locale, options);
    const formatedExpirationDate = new Date(expirationDate).toLocaleString(locale, options);

    return (
      <tr className="pd-body">
        <td>&nbsp;</td>
        <td>{formatedDeliveryDate}</td>
        <td>{quantity}</td>
        <td>{batchNumber}</td>
        <td>{packagesQuantity}</td>
        <td>{formatedExpirationDate}</td>
        <td>{observations}</td>
      </tr>
    );
  });

  return (
    <>
      {header}
      {body}
    </>
  );
};

export default PartialDelivery;
