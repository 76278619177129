import action from '../helpers/rest-client';

export class ErrorProductionOrder extends Error { }

class ProductionOrderService {
  public async getProductionOrdersAll(filters) {
    const queryString = new URLSearchParams(filters).toString();
    const sort = '';

    return action
      .Get({
        url: `/productionOrders/all?${!!queryString ? `${queryString}&${sort}` : sort}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.warn(error);
        // throw new ErrorProductionOrder(error.response.state);
      });
  }

  public async getProductionOrdersStatistics(filters) {
    const queryString = new URLSearchParams(filters).toString();
    const sort = '';

    return action
      .Get({
        url: `/productionOrders/statistics?${!!queryString ? `${queryString}&${sort}` : sort}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.warn(error);
        // throw new ErrorProductionOrder(error.response.state);
      });
  }

  public async getProductionOrders(payload) {
    return action
      .Get({
        url: `/productionOrders/${payload}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.warn(error);
        // throw new ErrorProductionOrder(error.response.status);
      });
  }

  public async patchProductionOrder({ id, ...rest }) {
    return action
      .Patch({
        url: `${process.env.REACT_APP_API_URL}/productionOrders/${id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async deleteProductionOrder(id) {
    return action
      .Del({
        url: `${process.env.REACT_APP_API_URL}/productionOrders/${id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const productionOrder = new ProductionOrderService();
export default productionOrder;
