interface ComponentProps {
  children: string;
  visible: boolean;
  style?;
  className?;
}

const Component = ({ children, visible = false, className, ...rest }: ComponentProps) => {
  if (!visible) {
    return null;
  }

  return (
    <div className={`input-feedback ${className ? className : ''}`} {...rest}>
      {children}
    </div>
  );
};

export default Component;
