export const getToken = () => {
  return localStorage.getItem('token');
};

export const setToken = (token) => {
  try {
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    authUser.token = token;
    localStorage.setItem('authUser', JSON.stringify(authUser));
  } catch (error) {
    return null;
  }

  return token;
};
