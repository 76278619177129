import { put, call, takeLatest } from 'redux-saga/effects';
import services from '../../services';

import {
  NEW_WORKSHOP_ORDER,
  NEW_WORKSHOP_ORDER_SUCCESS,
  NEW_WORKSHOP_ORDER_ERROR,
  EDIT_WORKSHOP_ORDER,
  EDIT_WORKSHOP_ORDER_SUCCESS,
  EDIT_WORKSHOP_ORDER_ERROR,
  GET_WORKSHOP_ORDER,
  GET_WORKSHOP_ORDER_SUCCESS,
  GET_WORKSHOP_ORDER_ERROR,
  GET_ALL_WORKSHOPS_ORDERS,
  GET_ALL_WORKSHOPS_ORDERS_SUCCESS,
  GET_ALL_WORKSHOPS_ORDERS_ERROR,
  GET_ALL_WORKSHOPS_ORDERS_RESET,
} from '../types/workshop-order';

function* newWorkshopOrder({ payload }): any {
  const { response, error } = yield call(services.workshopOrder.create, payload);

  if (error) {
    yield put({ type: NEW_WORKSHOP_ORDER_ERROR, payload: error.message });
  } else {
    yield put({ type: NEW_WORKSHOP_ORDER_SUCCESS, payload: response });
  }
}

function* editWorkshopOrder({ payload }): any {
  const { response, error } = yield call(services.workshopOrder.updateOne, payload);

  if (error) {
    yield put({ type: EDIT_WORKSHOP_ORDER_ERROR, payload: error.message });
  } else {
    yield put({ type: EDIT_WORKSHOP_ORDER_SUCCESS, payload: response });
  }
}

function* getWorkshopOrder({ payload }): any {
  const { response, error } = yield call(services.workshopOrder.getOne, payload);

  if (error) {
    yield put({ type: GET_WORKSHOP_ORDER_ERROR, payload: error.message });
  } else {
    yield put({ type: GET_WORKSHOP_ORDER_SUCCESS, payload: response[0] });
  }
}

function* getAllWorkshopsOrders({ payload }): any {
  const { response, error } = yield call(services.workshopOrder.getAll, payload);

  if (error) {
    yield put({ type: GET_ALL_WORKSHOPS_ORDERS_ERROR, payload: error.message });
  } else {
    yield put({ type: GET_ALL_WORKSHOPS_ORDERS_SUCCESS, payload: response });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEW_WORKSHOP_ORDER, newWorkshopOrder);
  yield takeLatest<any>(EDIT_WORKSHOP_ORDER, editWorkshopOrder);
  yield takeLatest<any>(GET_WORKSHOP_ORDER, getWorkshopOrder);
  yield takeLatest<any>(GET_ALL_WORKSHOPS_ORDERS, getAllWorkshopsOrders);
}
