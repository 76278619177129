import { createRef, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ReactToPrint from 'react-to-print';

import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Spinner from '../../../components/Spinner';
import Invoice from './components/Invoice';
import Breadcrumb from '../../../components/common/breadcrumb';

import { ProductionOrderDto } from '../../../types/production-order';
import { getProductionOrder } from '../../../store/actions/production-order';

interface ComponentProps {
  productionOrder: ProductionOrderDto;
  productionOrderStates: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
  match;
}

const Component = ({ match, productionOrder, productionOrderStates }: ComponentProps) => {
  const dispatch = useDispatch();
  const componentRef = createRef<HTMLDivElement>();
  const [productionOrderId, setProductionOrderId] = useState<string>();

  useEffect(() => {
    if (!!match && match.params) {
      setProductionOrderId(match.params.id);
    }
  }, [match]);

  useEffect(() => {
    if (!productionOrder && !!productionOrderId) {
      dispatch(getProductionOrder(productionOrderId));
    }
  }, [productionOrderId]);

  if (!productionOrder || productionOrderStates.loading) {
    return <Spinner size="large" isPageLoader />;
  }

  return (
    <Container>
      <Row style={{ marginTop: '22px', marginBottom: '50px' }}>
        <Col>
          <Breadcrumb
            parentLink={`/orderproductiondetail/${productionOrderId}`}
            style={{ display: 'inline-block', marginLeft: '15px' }}
          />
        </Col>

        <Col>
          <h5>Impresión de orden de producción #{productionOrder.code}</h5>
        </Col>

        <Col style={{ textAlign: 'right' }}>
          <ReactToPrint
            copyStyles
            documentTitle={`Orden de Producción - ${productionOrder.code}`}
            trigger={() => (
              <button className="btn btn-primary shadow-lg" type="button">
                <FontAwesomeIcon icon={faPrint} /> Imprimir
              </button>
            )}
            content={() => componentRef.current}
          />
        </Col>
      </Row>

      <Row>
        <Col style={{ width: '210mm', height: '297mm', padding: '0px' }}>
          <div ref={componentRef} style={{ height: '100%', marginBottom: '100px' }}>
            <Invoice />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const state = ({ productionOrderStore }) => {
  const { data: productionOrder, states: productionOrderStates } = productionOrderStore.productionOrder;

  return {
    productionOrder,
    productionOrderStates,
  };
};

export default connect(state)(Component);
