import { useEffect, useState } from 'react';
import { ProductionOrderDto } from '../../../../../types/production-order';
import { ProductionOrder } from './styles';

interface ComponentProps {
  productionOrder: ProductionOrderDto;
}

const Component = ({ productionOrder }: ComponentProps) => {
  const [customer, setCustomer] = useState();

  useEffect(() => {
    setCustomer(productionOrder?.orderNote[0]?.client?.companyName || '');
  }, [productionOrder]);

  return (
    <ProductionOrder className="table-responsive">
      <table className="table">
        <tbody>
          <tr>
            <td width={'50%'}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <strong>Código del producto:</strong> {productionOrder?.product.code}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Nombre del producto:</strong> {productionOrder?.product?.name}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Unidades a fabricar:</strong> {productionOrder?.quantity}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Depósito:</strong> {productionOrder?.handler?.lastname}, {productionOrder?.handler?.name}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td width={'50%'}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <strong>Prioridad:</strong> {productionOrder?.priority ? 'SI' : 'NO'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Tipo:</strong> {productionOrder?.type}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Cliente:</strong> {customer}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </ProductionOrder>
  );
};

export default Component;
