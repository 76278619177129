import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { checkDataPermission } from '../../../../../../../../../components/CanRBac';
import productionOrder from '../../../../../../../../../services/production-order';
import { Batch } from '../../../../../../../../../types/batch';
import { ProductionOrderDto } from '../../../../../../../../../types/production-order';
import { User } from '../../../../../../../../../types/user';

const emtpyState = <div className="dmx-empty-table">No hay lotes seleccionados</div>;

interface ComponentProps {
  productionOrder: ProductionOrderDto;
  onBatchStockSelection: (data) => void;
  productionOrderStates: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };
  currentUser: User;
}

const Component = ({ productionOrder, onBatchStockSelection, currentUser }: ComponentProps) => {
  if (!productionOrder) {
    return null;
  }

  const columns = [
    { name: 'Lote', selector: 'batchId', grow: 2 },
    { name: 'Metros disponibles', selector: 'mpMeters', grow: 2 },
    {
      name: 'Metros utilizados',
      grow: 2,
      cell: (row, index) => {
        return row.movementsDetail
          .filter((movement) => movement.productionOrderId === productionOrder._id)
          .reduce(function (acc, obj) {
            return Number(acc) + Number(obj.totalMeters);
          }, 0);
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={productionOrder.batch}
      disabled={
        (currentUser.userType === 'productionAdmin' && productionOrder?.state === 'requested') ||
        !checkDataPermission(currentUser.userType, 'op:edit:batch', { status: productionOrder?.state })
      }
      striped
      responsive
      persistTableHead
      highlightOnHover
      noDataComponent={emtpyState}
      onRowClicked={(row) => {
        onBatchStockSelection(row);
      }}
    />
  );
};

const state = ({ productionOrderStore, userStore }) => {
  const { data: currentUser } = userStore.information;
  const { data: productionOrder } = productionOrderStore.productionOrder;
  const { states: productionOrderStates } = productionOrderStore.productionOrder;

  return {
    currentUser,
    productionOrder,
    productionOrderStates,
  };
};

export default connect(state)(Component);
