import styled from 'styled-components';

export const DragDropContainer = styled('div')`
  padding-bottom: 20px;
  overflow: auto;
`;

export const Scroll = styled('div')`
  display: flex;
  min-width: 100%;
`;

export const SectionColumn = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin: 0px;
  border-radius: 4px;

  &:last-child {
    margin-right: 0px;
  }
`;
