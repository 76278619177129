import styled from 'styled-components';

export const Center = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  max-width: 500px;

  .basic-multi-select {
    max-width: 145px;
  }
`;
