import { WorkshopDto } from '../../../types/workshop';

export const GET_WORKSHOPS = 'GET_WORKSHOPS';
export const GET_WORKSHOPS_SUCCESS = 'GET_WORKSHOPS_SUCCESS';
export const GET_WORKSHOPS_ERROR = 'GET_WORKSHOPS_ERROR';

export interface GetWorkshops {
  type: typeof GET_WORKSHOPS;
  payload: null;
}
export interface GetWorkshopsSuccess {
  type: typeof GET_WORKSHOPS_SUCCESS;
  payload: {
    data: WorkshopDto[];
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: null;
    nextPage: null;
  };
}
export interface GetWorkshopsError {
  type: typeof GET_WORKSHOPS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: {
    data: WorkshopDto[];
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: null;
    nextPage: null;
  } | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetWorkshops | GetWorkshopsSuccess | GetWorkshopsError;
