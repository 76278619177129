import { getToken } from '../utils/authUtils';

async function deleteClient(clientId) {
  return fetch(`${process.env.REACT_APP_API_URL}/clients/${clientId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }).then((response) => response.json());
}

export default deleteClient;
