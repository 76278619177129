import { getToken } from '../utils/authUtils';
import { GET } from '../constant';

const getBatches = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/batches/all`, {
    method: GET,
    headers: new Headers({
      Authorization: `Bearer ${getToken()}`,
    }),
  }).then((response) => response.json());
};

export default getBatches;
