import React, { useEffect, useState } from 'react';
import './OrderDetail.scss';
import { Col, Container, Row } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';

// Sweetalert
import SweetAlert from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';

// Icons
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';

// My Imports
import ClientForm from './ClientForm';
import BillingForm from './BillingForm';
import LogisticForm from './LogisticForm';
import ObsForm from './ObsForm';
import DetailForm from './DetailForm';
import FilesForm from './FilesForm';
import ProductObservationModal from './DetailForm/Modal';

// Hooks
import useOrderNoteFetcher from '../../hooks/useOrderNoteFetcher';
import useEmployeesFetcher from '../../hooks/useEmployeesFetcher';
import useClientsFetcher from '../../hooks/useClientsFetcher';
import useProductsFetcher from '../../hooks/useProductsFetcher';
import usePackedTypesFetcher from '../../hooks/usePackedTypesFetcher';
import usePurchaseConditionsFetcher from '../../hooks/usePurchaseConditionsFetcher';
import markOrderReadByUser from '../../services/markOrderReadByUser';
import patchOrderNote from '../../services/patchOrderNote';
// Spinner
import Spinner from '../../components/Spinner';

// SelectState
import SelectState from '../../components/SelectState';

// Breadcrumb
import Breadcrumb from '../../components/common/breadcrumb';

import { statusColors, statusOptions } from '../../constant/index';
import { filterOptByPermissions } from '../../utils/permissionsUtil';

import CanRBac from '../../components/CanRBac';
import { rules } from '../../components/CanRBac/rbac-rules';

const Projectdetails = ({ currentUser }) => {
  let orderId = new URLSearchParams(useLocation().search);
  orderId = orderId.get('id');
  let isEditable = new URLSearchParams(useLocation().search);
  isEditable = isEditable.get('edit') === 'true';

  // CONSTS
  const orderNote = useOrderNoteFetcher(orderId);
  const employees = useEmployeesFetcher();
  const clients = useClientsFetcher();
  const products = useProductsFetcher();
  const packedTypes = usePackedTypesFetcher();
  const purchaseConditions = usePurchaseConditionsFetcher();

  // Redirect
  const history = useHistory();

  // Unexpected dangling '_' in '_id'
  /* eslint no-underscore-dangle: 0 */

  const clientsData = clients.data?.map((client) => ({
    label: client.brandName,
    client: {
      _id: client._id,
      brandName: client.brandName,
      companyName: client.companyName,
      cuit: client.cuit,
      address: client.address,
      deliveryAddress: client.deliveryAddress,
      shipment: client.shipment,
    },
  }));

  let sellers = [];
  let handlers = [];
  if (!employees.isLoading) {
    sellers = employees.data?.docs?.filter((employee) => employee.userType === 'seller');
    handlers = employees.data?.docs?.filter((employee) => employee.userType === 'handler' || employee.userType === 'productionHandler');
  }

  const sellersData = sellers?.map((seller) => ({
    label: seller.name,

    seller: {
      role: seller.role,
      userType: seller.userType,
      verified: seller.verified,
      _id: seller._id,
      name: seller.name,
      email: seller.email,
      verification: seller.verification,
      createdAt: seller.createdAt,
      updatedAt: seller.updatedAt,
    },
  }));

  const handlerData = handlers?.map((handler) => ({
    label: handler.name,

    handler: {
      role: handler.role,
      userType: handler.userType,
      verified: handler.verified,
      _id: handler._id,
      name: handler.name,
      email: handler.email,
      verification: handler.verification,
      createdAt: handler.createdAt,
      updatedAt: handler.updatedAt,
    },
  }));

  // STATES
  const [data, setData] = useState({
    orderDeliveryAddress: '',
    firstDeliveryDate: '',
    deliveryType: '',
    currency: '',
    observations: '',
    deliveryPercent: '',
    _id: '',
    orderNoteId: '',
    createDate: '',
    status: '',
    paymentType: '',
    depot: '',
    transport: '',
    packagesQuantity: '',
    takeAway: false,
    orderDeliveryTime: '',
    invoiced: false,
    purchaseCondition: '',
    orderPurchaseCondition: '',

    productList: [
      {
        quantity: '',
        prevQuantity: '',
        sterilized: '',
        price: '',
        product: '',
        size: '',
        colour: '',

        partialDelivery: [
          {
            quantity: '',
            prevQuantity: '',
            _id: '',
            deliveryDate: '',
            batchNumber: '',
            observations: '',
          },
        ],

        packed: [
          {
            _id: '',
            name: '',
          },
        ],
      },
    ],

    client: {
      _id: '',
      brandName: '',
      companyName: '',
      cuit: '',
      address: '',
      deliveryAddress: '',
      shipment: '',
    },

    seller: {
      _id: '',
      role: '',
      userType: '',
      verified: '',
      name: '',
      email: '',
      verification: '',
      createdAt: '',
      updatedAt: '',
    },

    handler: {
      _id: '',
      role: '',
      userType: '',
      verified: '',
      name: '',
      email: '',
      verification: '',
      createdAt: '',
      updatedAt: '',
    },
  });

  const [modal, setModal] = useState(false);
  const [observationIndex, setObservationIndex] = useState();

  const toggleModal = () => {
    setModal(!modal);
  };

  const addObservation = (index) => {
    toggleModal();
    setObservationIndex(index);
  };

  const saveOrder = () => {
    SweetAlert.fire({
      title: '¿Desea guardar la nota de pedido?',
      // text: 'Leyenda debajo titulo',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (data.handler) {
          if (data.handler._id === '') {
            delete data.handler;
          }
        }

        delete data.attachments;

        // Campos Obligatorios
        const emptyDetail = data.productList.length === 0;
        const statusReady = data.status === 'ready';
        const fielRequiredArticle = data.productList.some((item) => item.product._id === '');
        const fielRequiredOrderedQuantity = data.productList.some((item) => item.quantity === '');
        const fielRequiredUnitPrice = data.productList.some((item) => item.price === '');

        // Marcamos como "no leida" la np.
        const newWatchers = [currentUser._id];

        if (emptyDetail && statusReady) {
          toast.error('No puede tener estado Lista sin antes tener Detalles', { autoClose: 3000 });
        } else if (fielRequiredArticle) {
          toast.error('El campo "Articulo" es obligatorio.', { autoClose: 3000 });
        } else if (fielRequiredOrderedQuantity) {
          toast.error('El campo "Cantidad pedida" es obligatorio.', { autoClose: 3000 });
        } else if (fielRequiredUnitPrice) {
          toast.error('El campo "Precio unitario" es obligatorio.', { autoClose: 3000 });
        } else {
          patchOrderNote(data).then((res) => {
            if (res.errors) {
              toast.error(`Ha ocurrido un error:${res.errors.msg}`, { autoClose: 3000 });
            } else {
              markOrderReadByUser(orderId, newWatchers);
              toast.success('La nota de pedido ha sido guardada.', { autoClose: 3000 });
            }
          });
        }
      }
    });
  };

  const addProductRow = () => {
    const productListState = [...data.productList];

    const emptyObject = {
      id: uuidv4(),
      quantity: '',
      prevQuantity: '',
      sterilized: false,
      price: '',
      packed: [],
      partialDelivery: [],
      size: '',
      colour: '',
      product: {
        _id: '',
        name: '',
        code: '',
        productCategoryId: {
          _id: '',
          name: '',
        },
      },
    };

    productListState.push(emptyObject);
    setData({
      ...data,
      productList: productListState,
    });
  };

  const deleteProductRow = (index) => {
    const newData = _.clone(data);
    newData.productList.splice(index, 1);
    setData({ ...newData });
  };

  const addPartialDeliveryRow = (index) => {
    const productListState = _.clone(data.productList);
    productListState[index].partialDelivery.push({
      id: uuidv4(),
      quantity: 0,
      prevQuantity: 0,
      deliveryDate: new Date().toISOString(),
      expirationDate: new Date().toISOString(),
    });

    setData({
      ...data,
      productList: productListState,
    });
  };

  const removePartialDeliveryRow = (id, index) => {
    const productListState = _.clone(data.productList);
    const partialList = productListState.find((item) => item.id === id);
    partialList.partialDelivery.splice(index, 1);
    setData({
      ...data,
      productList: productListState,
    });
  };

  const handlePartialDeliveryChange = (_id, index, name, value) => {
    const productListState = _.clone(data.productList);
    const partialList = productListState.find((item) => item._id === _id);
    partialList.partialDelivery[index][name] = value;
    if (name === 'expirationDate' || name === 'deliveryDate') {
      partialList.partialDelivery[index][name] = new Date(value).toISOString();
    }
    setData({
      ...data,
      productList: productListState,
    });
  };

  const handleChange = (entityName, value) => {
    setData({
      ...data,
      [entityName]: value,
    });
  };

  const handleProductChange = (index, itemName, value) => {
    const productListState = [...data.productList];
    let productToState;

    // Observations
    if (itemName === 'observations') {
      productToState = {
        ...data.productList[index],
        observations: value,
      };
    }

    // Product
    if (itemName === 'product') {
      productToState = {
        ...data.productList[index],
        product: value,
      };
    }

    // Sterilized
    if (itemName === 'sterilized') {
      productToState = {
        ...data.productList[index],
        sterilized: value,
      };
    }

    // Size
    if (itemName === 'size') {
      productToState = {
        ...data.productList[index],
        size: value,
      };
    }

    // Color
    if (itemName === 'colour') {
      productToState = {
        ...data.productList[index],
        colour: value,
      };
    }

    // Quantity
    if (itemName === 'quantity') {
      productToState = {
        ...data.productList[index],
        // prevQuantity: data.productList[index].quantity,
        quantity: value,
      };
    }

    // Price
    if (itemName === 'price') {
      productToState = {
        ...data.productList[index],
        price: value,
      };
    }

    // PackedType
    if (itemName === 'packed') {
      const filterOptions = value?.map((option) => ({
        name: option.packed.name,
        _id: option.packed._id,
      }));

      productToState = {
        ...data.productList[index],
        packed: filterOptions,
      };
    }

    productListState[index] = productToState;

    setData({
      ...data,
      productList: productListState,
    });
  };

  const handleKeyDown = (e) => {
    const code = e.which ? e.which : e.keyCode;
    if (code === 190 || code === 46 || code === 110) {
      let input = e.target.value;
      input += ',';
      e.target.value = input;
    }
  };

  const checkOrderWatchers = (newData) => {
    if (newData && newData.watchers && newData.watchers.indexOf(currentUser._id) === -1) {
      newData.watchers.push(currentUser._id);
      markOrderReadByUser(orderId, newData.watchers);
      setData({ ...newData });
    }
  };

  const addIdToRowTableData = (rowData) => {
    return rowData?.productList?.map((item) => {
      const partialDelivery = item?.partialDelivery?.map((partial) => {
        return {
          ...partial,
          id: uuidv4(),
        };
      });
      return {
        ...item,
        partialDelivery,
        id: uuidv4(),
      };
    });
  };

  useEffect(() => {
    const newData = _.clone(orderNote.data);
    if (newData) {
      newData.productList = addIdToRowTableData(newData);
    }
    setData({ ...newData });

    if (newData && newData.productList) {
      // Recorro los product list para guardar la cantidad previa a la edición
      Object.keys(newData.productList).forEach((productIndex) => {
        const productRow = newData.productList[productIndex];
        productRow.prevQuantity = productRow.quantity;
        // Recorro las partial delivery para guardar la cantidad previa a la edición
        Object.keys(productRow.partialDelivery).forEach((partialIndex) => {
          productRow.partialDelivery[partialIndex].prevQuantity = productRow.partialDelivery[partialIndex].quantity;
        });
      });
      setData({
        ...newData,
      });
    }

    checkOrderWatchers(newData);
  }, [orderNote.data]);

  if (orderNote.isLoading || employees.isLoading || clients.isLoading) {
    return <Spinner size="large" isPageLoader />;
  }

  /**
   * This function check if all products in an Order were delivered at 100%
   * @returns Boolean
   */

  const wereAllProductsDelivered = () => {
    const reducer = (pending, product) => {
      const { quantity } = product;

      let delivered = 0;
      product.partialDelivery.forEach((partial) => {
        delivered += Number(partial.quantity);
      });

      return pending + quantity - delivered;
    };

    return data.productList.reduce(reducer, 0) <= 0;
  };

  /**
   * Fire Sweet
   */

  const AlertOrderNoteCantBeReleased = () => {
    SweetAlert.fire({
      title: 'Acción no permitida',
      text: `La NP solo puede ser liberada si la entrega esta completada al 100%.
      Por favor verificar los datos de la NP.`,
      showCancelButton: false,
      confirmButtonText: 'Continuar',
      reverseButtons: true,
    });
  };

  /**
   * This function set the new Order status
   * @param {string} StatusVal
   * @returns {void}
   */

  const handleStatusChange = (statusVal) => {
    let status = statusVal;

    if (!wereAllProductsDelivered() && statusVal === 'released') {
      AlertOrderNoteCantBeReleased();
      status = data.status;
    }

    setData({
      ...data,
      status,
    });
  };

  const { orderNoteId } = data;

  return (
    <div className="page-order-detail">
      <div>
        <Container fluid>
          <Row>
            <Col>
              <div className="heading">
                <h5 className="pageTitle">Nota de pedido #{orderNoteId}</h5>
                <CanRBac
                  role={currentUser?.userType}
                  perform="np:edit"
                  data={{ status: orderNote.data?.status, currentUser, handler: orderNote.data?.handler }}
                  yes={() => (
                    <SelectState
                      handleStatusChange={handleStatusChange}
                      statusOptions={isEditable ? filterOptByPermissions(currentUser, statusOptions) : statusOptions}
                      statusColors={statusColors}
                      actualStatus={data.status}
                      isEditable={isEditable && true}
                    />
                  )}
                  no={() => (
                    <SelectState
                      handleStatusChange={handleStatusChange}
                      statusOptions={isEditable ? filterOptByPermissions(currentUser, statusOptions) : statusOptions}
                      statusColors={statusColors}
                      actualStatus={data.status}
                      isEditable={false}
                    />
                  )}
                />
              </div>
            </Col>

            <Col>
              <div className="page-header">
                <Link to={`/printorder?id=${data._id}&origin=detail`} className="m-r-15">
                  <button type="button" className="btn btn-primary" hidden={isEditable}>
                    <FontAwesomeIcon icon={faFileAlt} /> Imprimir
                  </button>
                </Link>
                <Breadcrumb title="Nota de pedido" parent="Ordenes" parentLink="/orders" />
              </div>
            </Col>
          </Row>

          <Row className="projectdetails">
            <ClientForm
              handleChange={handleChange}
              clientsData={clientsData}
              client={data.client}
              orderNoteId={data.orderNoteId}
            />

            <CanRBac
              role={currentUser?.userType}
              perform="np:edit"
              data={{ status: orderNote.data?.status, currentUser, handler: orderNote.data?.handler }}
              yes={() => (
                <LogisticForm
                  currentUser={currentUser}
                  isEditable={isEditable && true}
                  handleChange={handleChange}
                  handlerData={handlerData}
                  handler={data.handler}
                  firstDeliveryDate={data.firstDeliveryDate}
                  deliveryType={data.deliveryType}
                  orderDeliveryAddress={data.orderDeliveryAddress}
                  orderDeliveryTime={data.orderDeliveryTime}
                  shipment={data.orderShipment}
                  packagesQuantity={data.packagesQuantity}
                  productList={data.productList}
                  takeAway={data.takeAway}
                  depot={data.depot}
                />
              )}
              no={() => (
                <LogisticForm
                  currentUser={currentUser}
                  isEditable={false}
                  handleChange={handleChange}
                  handlerData={handlerData}
                  handler={data.handler}
                  firstDeliveryDate={data.firstDeliveryDate}
                  deliveryType={data.deliveryType}
                  orderDeliveryAddress={data.orderDeliveryAddress}
                  orderDeliveryTime={data.orderDeliveryTime}
                  shipment={data.orderShipment}
                  packagesQuantity={data.packagesQuantity}
                  productList={data.productList}
                  takeAway={data.takeAway}
                  depot={data.depot}
                />
              )}
            />

            <CanRBac
              role={currentUser?.userType}
              perform="np:edit"
              data={{ status: orderNote.data?.status, currentUser, handler: orderNote.data?.handler }}
              yes={() => (
                <BillingForm
                  currentUser={currentUser}
                  isEditable={isEditable && true}
                  handleChange={handleChange}
                  sellersData={sellersData}
                  seller={data.seller}
                  paymentType={data.paymentType}
                  currency={data.currency}
                  orderPaymentType={data.orderPaymentType}
                  invoiced={data.invoiced}
                  purchaseCondition={data.client.purchaseCondition}
                  orderPurchaseCondition={data.orderPurchaseCondition}
                  purchaseConditions={purchaseConditions}
                  orderNumber={data.orderNumber}
                />
              )}
              no={() => (
                <BillingForm
                  currentUser={currentUser}
                  isEditable={false}
                  handleChange={handleChange}
                  sellersData={sellersData}
                  seller={data.seller}
                  paymentType={data.paymentType}
                  currency={data.currency}
                  orderPaymentType={data.orderPaymentType}
                  invoiced={data.invoiced}
                  purchaseCondition={data.client.purchaseCondition}
                  orderPurchaseCondition={data.orderPurchaseCondition}
                  purchaseConditions={purchaseConditions}
                  orderNumber={data.orderNumber}
                />
              )}
            />
            <CanRBac
              role={currentUser?.userType}
              perform="np:edit"
              data={{ status: orderNote.data?.status, currentUser, handler: orderNote.data?.handler }}
              yes={() => (
                <ObsForm
                  currentUser={currentUser}
                  isEditable={isEditable && true}
                  handleChange={handleChange}
                  observations={data.observations}
                />
              )}
              no={() => (
                <ObsForm
                  currentUser={currentUser}
                  isEditable={false}
                  handleChange={handleChange}
                  observations={data.observations}
                />
              )}
            />
          </Row>

          <Row className="projectmore">
            <CanRBac
              role={currentUser?.userType}
              perform="np:edit"
              data={{ status: orderNote.data?.status, currentUser, handler: orderNote.data?.handler }}
              yes={() => (
                <DetailForm
                  handleProductChange={handleProductChange}
                  handleChange={handleChange}
                  deleteProductRow={deleteProductRow}
                  addPartialDeliveryRow={addPartialDeliveryRow}
                  removePartialDeliveryRow={removePartialDeliveryRow}
                  handlePartialDeliveryChange={handlePartialDeliveryChange}
                  addProductRow={addProductRow}
                  products={products}
                  packedTypes={packedTypes}
                  isEditable={isEditable && true}
                  productList={data.productList}
                  currency={data.currency}
                  handleKeyDown={handleKeyDown}
                  addObservation={addObservation}
                />
              )}
              no={() => (
                <DetailForm
                  handleProductChange={handleProductChange}
                  handleChange={handleChange}
                  deleteProductRow={deleteProductRow}
                  addPartialDeliveryRow={addPartialDeliveryRow}
                  removePartialDeliveryRow={removePartialDeliveryRow}
                  handlePartialDeliveryChange={handlePartialDeliveryChange}
                  addProductRow={addProductRow}
                  products={products}
                  packedTypes={packedTypes}
                  isEditable={false}
                  productList={data.productList}
                  currency={data.currency}
                  handleKeyDown={handleKeyDown}
                  addObservation={addObservation}
                />
              )}
            />

            <CanRBac
              role={currentUser?.userType}
              perform="np:edit"
              data={{ status: orderNote.data?.status, currentUser, handler: orderNote.data?.handler }}
              yes={() => (
                <ProductObservationModal
                  isOpen={modal}
                  isEditable={isEditable && true}
                  toggle={toggleModal}
                  title="Observaciones"
                  observationIndex={observationIndex}
                  observations={data.productList[observationIndex] && data.productList[observationIndex].observations}
                  handleProductChange={handleProductChange}
                />
              )}
              no={() => (
                <ProductObservationModal
                  isOpen={modal}
                  isEditable={false}
                  toggle={toggleModal}
                  title="Observaciones"
                  observationIndex={observationIndex}
                  observations={data.productList[observationIndex] && data.productList[observationIndex].observations}
                  handleProductChange={handleProductChange}
                />
              )}
            />
          </Row>

          <Row className="filesForm">
            <CanRBac
              role={currentUser?.userType}
              perform="np:edit"
              data={{ status: orderNote.data?.status, currentUser, handler: orderNote.data?.handler }}
              yes={() => (
                <FilesForm
                  isEditable={isEditable && true}
                  orderId={data._id}
                  orderNoteId={data.orderNoteId}
                  files={data.attachments}
                />
              )}
              no={() =>
                currentUser.userType === 'reception' && isEditable ? (
                  <FilesForm
                    isEditable={true}
                    orderId={data._id}
                    orderNoteId={data.orderNoteId}
                    files={data.attachments}
                  />
                ) : (
                  <FilesForm
                    isEditable={false}
                    orderId={data._id}
                    orderNoteId={data.orderNoteId}
                    files={data.attachments}
                  />
                )
              }
            />
          </Row>

          <Row>
            <div className="container mb-4">
              <div className="row">
                <div className="col text-center">
                  {isEditable ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      disabled={!isEditable}
                      onClick={() => saveOrder()}
                    >
                      Guardar
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Row>
        </Container>

        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(Projectdetails);
