import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MovementList from '../../../../../../../components/MovementList';
import { ButtonContainer } from '../../../../styles';
import { toast } from 'react-toastify';
import Submit from '../../../../../../../components/Form/Submit';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ProductionOrderDto } from '../../../../../../../types/production-order';
import { Batch } from '../../../../../../../types/batch';
import { BatchMovement } from '../../../../../../../types/batch-movement';
import patchMaterialDetail from '../../../../../../../services/patchMaterialDetail';
import { getProductionOrder, productionOrderUpdateCurrent } from '../../../../../../../store/actions/production-order';
import { v4 as uuidv4 } from 'uuid';
import getMpMeters from '../../../../../../../utils/getMpMeters';
import getOpMeters from '../../../../../../../utils/getOpMeters';
import { move } from 'formik';

const numericFields = ['meters', 'reels', 'cuttingReturn', 'totalMeters', 'units'];

interface ComponentProps {
  isOpen: boolean;
  toggle: () => void;
  batchStockSelected?: Batch;
  productionOrder: ProductionOrderDto;
  updateRawMaterial: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({ batchStockSelected, productionOrder, updateRawMaterial, isOpen, toggle }: ComponentProps) => {
  const [isSubmmiting, setIsSubmmiting] = useState(false);
  const [limitOfMeters, setLimitOfMeters] = useState(0);
  const [movements, setMovements] = useState<BatchMovement[]>([]);
  const dispatch = useDispatch();

  const handleUpdate = ({ field, value, movement }) => {
    const movementId = movement._id;

    setMovements(
      movements.map((movement) => {
        if (movement._id === movementId) {
          if (numericFields.includes(field)) {
            movement[field] = Number(value);
            movement.totalMeters = movement.meters * movement.reels;
          } else {
            movement[field] = value;
          }
        }
        return movement;
      }),
    );
  };

  const handleRemove = (data) => {
    setMovements(movements.filter((movement) => movement._id !== data.movement._id));
  };

  const handleAdd = () => {
    setMovements([
      ...movements,
      {
        _id: uuidv4(),
        cuttingReturn: limitOfMeters,
        date: new Date(Date.now()).toISOString(),
        meters: 0,
        observations: '',
        reels: 0,
        totalMeters: 0,
        type: 'EGRESO',
        receipt: '',
        productionOrderId: productionOrder._id,
      },
    ]);
  };

  const getNewAndPreexistingMoves = (): BatchMovement[] => {
    const movementsIds = movements?.map((movementDetail) => movementDetail._id) || [];
    const preexistingMovementIds =
      batchStockSelected?.movementsDetail.map((movementDetail) => movementDetail._id) || [];

    const updatePreexistingMovement: BatchMovement[] =
      batchStockSelected?.movementsDetail
        ?.filter(
          (movementDetail) =>
            !movementDetail.productionOrderId ||
            movementDetail.productionOrderId !== productionOrder._id ||
            movementsIds.includes(movementDetail._id),
        )
        .map((movementDetail) => {
          return { ...movementDetail, ...movements.filter((movement) => movementDetail._id === movement._id)[0] };
        }) || [];

    const newMovements: BatchMovement[] = movements
      .filter((movement) => !preexistingMovementIds.includes(movement._id))
      .map((movement) => {
        const { _id, ...rest } = movement;
        return rest;
      });

    return [...updatePreexistingMovement, ...newMovements];
  };

  const saveEgress = () => {
    const movementsDetail: BatchMovement[] = getNewAndPreexistingMoves();
    const cuttingReturn = getMpMeters(movementsDetail, batchStockSelected?.units);
    setLimitOfMeters(cuttingReturn);

    if (cuttingReturn < 0) {
      toast.error(`No se pueden guardar los cambios. Superaste el total de metros disponibles.`, { autoClose: 3000 });
    } else {
      const editedFeedStock = productionOrder.feedstock.filter(
        (feedstock) =>
          feedstock.stockControl.filter((stock) => {
            return stock._id === batchStockSelected?._id;
          }).length > 0,
      )[0];

      const feedstock = {
        ...editedFeedStock,
        stockControl: editedFeedStock?.stockControl?.map((stock) => {
          if (stock._id === batchStockSelected?._id) {
            const mpMeters = getMpMeters(movementsDetail, batchStockSelected.units);
            const opMeters = getOpMeters(movementsDetail, batchStockSelected.units);
            return { ...batchStockSelected, mpMeters, opMeters, movementsDetail };
          }
          return stock;
        }),
      };

      setIsSubmmiting(true);

      patchMaterialDetail(feedstock).then((res) => {
        if (res.errors) {
          toast.error(`Ha ocurrido un error:${res.errors.msg}`, { autoClose: 3000 });
        } else {
          dispatch(
            productionOrderUpdateCurrent({
              ...productionOrder,
              batch: feedstock.stockControl.filter((batch) =>
                productionOrder.batch.map((batch) => batch._id).includes(batch._id),
              ),
              feedstock: [
                ...productionOrder.feedstock.map((feedstock) => {
                  if (feedstock._id === res._id) {
                    return { ...feedstock, ...res };
                  } else {
                    return { ...feedstock };
                  }
                }),
              ],
            }),
          );
          toast.success('Egreso de materia prima, agregado.', { autoClose: 3000 });
        }

        setIsSubmmiting(false);
      });
    }
  };

  const setDefaultMovements = () => {
    if (!!batchStockSelected && !!batchStockSelected?.movementsDetail) {
      const cuttingReturn = getMpMeters(batchStockSelected?.movementsDetail, batchStockSelected?.units);
      setLimitOfMeters(cuttingReturn);

      setMovements(
        batchStockSelected?.movementsDetail
          .filter((movementDetail) => productionOrder._id === movementDetail?.productionOrderId)
          .map((move) => ({
            ...move,
            cuttingReturn,
          })),
      );
    }
  };

  useEffect(() => {
    setDefaultMovements();
  }, [batchStockSelected]);

  useEffect(() => {
    const movementsDetail: BatchMovement[] = getNewAndPreexistingMoves();
    const cuttingReturn = getMpMeters(movementsDetail, batchStockSelected?.units);

    setLimitOfMeters(cuttingReturn);
  }, [movements]);

  if (!batchStockSelected) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" fullscreen="lg" centered autoFocus={false} role="document">
      <ModalHeader toggle={toggle}>
        {batchStockSelected.batchId} - Metros disponibles {limitOfMeters}
      </ModalHeader>

      <ModalBody toggle={toggle}>
        <MovementList
          data={[...movements]}
          readOnly={isSubmmiting}
          updateData={handleUpdate}
          removeMove={handleRemove}
          addMove={handleAdd}
        />

        {limitOfMeters > 0 && (
          <ButtonContainer>
            <Button type="button" color="success" onClick={handleAdd} disabled={isSubmmiting}>
              <FontAwesomeIcon icon={faPlus} /> Agregar Egreso
            </Button>
          </ButtonContainer>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          type="button"
          outline
          color="primary"
          disabled={isSubmmiting}
          onClick={() => {
            toggle();
            setDefaultMovements();
          }}
        >
          Cancelar
        </Button>

        <Submit form="" isLoading={isSubmmiting} onClick={saveEgress} style={{ width: 114 }}>
          Guardar
        </Submit>
      </ModalFooter>
    </Modal>
  );
};

const state = ({ productionOrderStore, rawMaterialStore, modalStore }) => {
  const { data: productionOrder } = productionOrderStore.productionOrder;
  const { states: updateRawMaterial } = rawMaterialStore.updateFeedStock;

  return {
    productionOrder,
    updateRawMaterial,
  };
};

export default connect(state)(Component);
