import { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, CardBody } from 'reactstrap';
import useEmployeesFetcher from '../../../../../hooks/useEmployeesFetcher';

// components
import Barcode from '../Barcode';
import SelectDeposit from './components/SelectDeposit';
import SelectType from './components/SelectType';
import SelectOrders from './components/SelectOrders';
import Dates from './components/Dates';

// mixins
import {
  ColumnRight,
  Label,
  CardTitleCustom,
  InputQuantity,
  Input,
  Group,
  GroupBaseline,
  ContainerResultOrdersSelected,
  ItemOrder,
  InputDate,
  CardText,
} from './mixins';
import { Divider } from '../../styles';

// constants
import { PARTIAL_DELIVERY_DATE } from '../../../../../constant';
import { connect } from 'react-redux';
import { ProductionOrderDto } from '../../../../../types/production-order';
import { checkDataPermission } from '../../../../../components/CanRBac';
import { User } from '../../../../../types/user';

interface props {
  dateCut: any;
  productionOrder: ProductionOrderDto;
  orders: any;
  handleChangeOrder: any;
  handleChangeQuantity: any;
  handleChangePrority: any;
  handleChangeDeposit: any;
  handleChangeType: any;
  handleChangeQuality: any;
  handleChangeExpirationDate: any;
  handleChangeOnCuttingTable: (value: boolean) => void;
  currentUser: User;
}
const Component = ({
  dateCut,
  productionOrder,
  orders,
  handleChangeOrder,
  handleChangeQuantity,
  handleChangePrority,
  handleChangeDeposit,
  handleChangeType,
  handleChangeQuality,
  handleChangeExpirationDate,
  handleChangeOnCuttingTable,
  currentUser,
}: props) => {
  const [customer, setCustomer] = useState();
  const dateCreation = moment(productionOrder?.createDate).locale('es').format(PARTIAL_DELIVERY_DATE);
  const updateDate = moment(productionOrder?.updateDate).locale('es').format(PARTIAL_DELIVERY_DATE);
  const employees = useEmployeesFetcher();

  const users: any = employees?.data;
  const { docs } = users;

  let allHandlers: any[] = [];
  if (!employees.isLoading) {
    allHandlers = docs.filter(
      (employee) => employee.userType === 'handler' || employee.userType === 'productionHandler',
    );
  }

  useEffect(() => {
    setCustomer(productionOrder?.orderNote[0]?.client?.companyName);
  }, [productionOrder]);

  return (
    <Card>
      <CardBody>
        <CardTitleCustom tag="h4">
          Producto
          <ColumnRight>
            <Dates dateCreation={dateCreation} updateDate={updateDate} dateCut={dateCut} />
            <Barcode data={productionOrder} />
          </ColumnRight>
        </CardTitleCustom>
        <Divider />
        <CardText>
          <Label>Código del producto:</Label> {productionOrder?.product.code}
        </CardText>
        <CardText>
          <Label>Nombre del producto:</Label> {productionOrder?.product?.name}
        </CardText>

        <CardText>
          <Label>Unidades a fabricar:</Label>
          <InputQuantity
            type="number"
            name="input-quantity"
            onWheel={(event) => event.currentTarget.blur()}
            disabled={
              !checkDataPermission(currentUser.userType, 'op:edit:unidadesFabricar', { status: productionOrder?.state })
            }
            defaultValue={productionOrder?.quantity}
            onChange={(e) => handleChangeQuantity(e.target.value)}
          />
        </CardText>

        <Divider />

        <CardText>
          <Group>
            <Label>Depósito:</Label>
            <SelectDeposit
              handlers={allHandlers}
              value={productionOrder?.handler}
              handleChangeDeposit={handleChangeDeposit}
            />
          </Group>
        </CardText>

        <CardText>
          <Label>Prioridad:</Label>
          <Input
            type="checkbox"
            name="input-priority"
            disabled={
              !checkDataPermission(currentUser.userType, 'op:edit:poInformation', { status: productionOrder?.state })
            }
            defaultChecked={productionOrder?.priority}
            onChange={(e) => handleChangePrority(e.target.checked)}
          />
        </CardText>

        <CardText>
          <Group>
            <Label>Tipo:</Label>
            <SelectType type={productionOrder?.type} handleChangeType={handleChangeType} />
          </Group>
        </CardText>

        <Divider />

        <CardText>
          <GroupBaseline>
            <Label>Origen:</Label>
            <SelectOrders
              isSearchable={true}
              orders={orders}
              defaultValue={productionOrder?.orderNote}
              handleChangeOrder={(value) => {
                setCustomer(value.client);
                handleChangeOrder(value);
              }}
            />
          </GroupBaseline>
        </CardText>

        <CardText>
          <GroupBaseline>
            <Label>Cliente:</Label>
            <ItemOrder>{customer}</ItemOrder>
          </GroupBaseline>
        </CardText>

        <Divider />

        <CardText>
          <GroupBaseline>
            <Label>Lote ANMAT:</Label>
            <InputQuantity
              type="text"
              disabled={
                !checkDataPermission(currentUser.userType, 'op:edit:poInformation', { status: productionOrder?.state })
              }
              defaultValue={productionOrder?.qualityBatch}
              onChange={(e) => handleChangeQuality(e.target.value)}
            />
          </GroupBaseline>
        </CardText>

        <CardText>
          <GroupBaseline>
            <Label>Fecha de vencimiento:</Label>
            <InputDate
              type="date"
              disabled={
                !checkDataPermission(currentUser.userType, 'op:edit:poInformation', { status: productionOrder?.state })
              }
              defaultValue={
                productionOrder?.expirationDate
                  ? new Date(productionOrder.expirationDate).toISOString().slice(0, 10)
                  : ''
              }
              onChange={(e) => handleChangeExpirationDate(e.target.value)}
            />
          </GroupBaseline>
        </CardText>

        {productionOrder.state === 'done' && (
          <>
            <Divider />

            <CardText>
              <GroupBaseline>
                <CardText>
                  <Label>Merma unidades no fabricadas: </Label> {productionOrder?.merma?.cutDifference}
                </CardText>
              </GroupBaseline>

              <GroupBaseline>
                <CardText>
                  <Label>Merma unidades no devueltas: </Label> {productionOrder?.merma?.workshopDifference}
                </CardText>
              </GroupBaseline>

              <CardText>
                <Label>Merma comentarios: </Label> {productionOrder?.merma?.comments}
              </CardText>
            </CardText>
          </>
        )}
      </CardBody>
    </Card>
  );
};

const state = ({ productionOrderStore, userStore }) => {
  const { data: currentUser } = userStore.information;
  const { data: productionOrder } = productionOrderStore.productionOrder;

  return {
    productionOrder,
    currentUser,
  };
};

export default connect(state)(Component);
