import DataTable from 'react-data-table-component';
import { customStyles } from './settings';
import { noRecords } from '../../../../constant';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import MoveList from '../MoveList';
import '../../MaterialDetail.scss';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimesCircle, faEdit } from '@fortawesome/free-solid-svg-icons';

// Permisos
import { checkDataPermission } from '../../../../components/CanRBac';

import Barcode from './Barcode';

const BatchList = ({
  currentDataMP,
  currentUser,
  data,
  productName,
  productData,
  isEditable,
  newMove,
  updateBatch,
  updateMove,
  deleteMove,
  deleteBatch,
  toggle,
  hasUnits,
}) => {
  const handleAddMove = (batchIndex) => {
    newMove(batchIndex);
  };

  const handleChange = (index, name, value) => {
    let newData = data;
    newData[index] = { ...newData[index], [name]: value };
    updateBatch(newData);
  };

  const handleEditRow = (index) => {
    let newData = data;
    newData[index] = { ...newData[index], editAllowed: true };
    updateBatch(newData);
  };

  const handleDelete = (id) => {
    SweetAlert.fire({
      title: '¿Desea borrar el lote seleccionado?',
      text: 'Una vez eliminado no podrá ser recuperado.',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteBatch(id)
      }
    });
  };

  const canBeRemoved = (row, id) => {
    const hasStock = row.movementsDetail.filter(movement => !!movement.productionOrderId).length > 0
    if(hasStock){
      toast.info('No es posible borrar el lote seleccionado.',{showCancelButton: true, faTimesCircle , autoClose: 3000});
    }else{
      handleDelete(id)
    }
  }

  const isAllowed = (row) => {
    if (row.editAllowed) {
      return true;
    } else if (row.isNew !== undefined) {
      return true;
    } else {
      return false;
    }
  };

  const actionButtons = (row, batchIndex) => (
    <>
      {/* Add move action button */}
      <div
        id="addMove"
        className="text-success p-1 dmx-action-button"
        data-toggle="tooltip"
        data-placement="top"
        title="Agregar ingreso/egreso"
      >
        <FontAwesomeIcon icon={faPlus} onClick={() => handleAddMove(batchIndex)} />
      </div>

      <div className="text-secondary p-1 dmx-action-button">
        <FontAwesomeIcon icon={faEdit} onClick={() => handleEditRow(batchIndex)} />
      </div>

      {/* Remove new batch action button */}
      <div
        id="deleteBatch"
        className="text-success p-1 dmx-action-button"
        data-toggle="tooltip"
        data-placement="top"
        title="Agregar ingreso/egreso"
      >
        <FontAwesomeIcon icon={faTimesCircle} color={'red'} onClick={() => canBeRemoved(row, batchIndex)} />
      </div>
    </>
  );

  const columns = [
    {
      name: '#',
      sortable: false,
      center: false,
      minWidth: '3%',
      maxWidth: '3%',
      cell: (row, index) => {
        return index + 1;
      },
    },
    {
      name: 'Lote',
      sortable: false,
      center: false,
      cell: (row, index) => {
        return (
          <input
            name="batchId"
            defaultValue={row.batchId || ''}
            className="form-control dmx-input w-100 notext"
            disabled={row.isNew === undefined || row.isNew || !isEditable}
            onChange={(e) => handleChange(index, e.target.name, e.target.value)}
          />
        );
      },
    },
    {
      name: 'Remito',
      sortable: false,
      center: false,
      cell: (row, index) => (
        <input
          type="text"
          className="form-control dmx-input w-100"
          name="receipt"
          defaultValue={row.receipt || ''}
          disabled={!isEditable || !isAllowed(row)}
          onChange={(event) => handleChange(index, event.target.name, event.target.value)}
        />
      ),
    },
    {
      name: 'Proveedor',
      sortable: false,
      center: false,
      cell: (row, index) => (
        <input
          type="text"
          className="form-control dmx-input w-100"
          name="provider"
          defaultValue={row.provider || ''}
          disabled={!isEditable || !isAllowed(row)}
          onChange={(event) => handleChange(index, event.target.name, event.target.value)}
        />
      ),
    },
    {
      name: 'Proveedor Lote',
      sortable: false,
      center: false,
      cell: (row, index) => (
        <input
          type="text"
          className="form-control dmx-input w-100"
          name="providerBatch"
          defaultValue={row.providerBatch || ''}
          disabled={!isEditable || !isAllowed(row)}
          onChange={(event) => handleChange(index, event.target.name, event.target.value)}
        />
      ),
    },
    {
      name: hasUnits ? 'Materia prima (u)' : 'Materia prima (m)',
      sortable: false,
      center: false,
      maxWidth: '10%',
      cell: (row) => {
        return (
          <input
            type="number"
            name="mpMeters"
            value={row.mpMeters.toFixed(0) || 0}
            className="form-control dmx-input w-100 notext"
            disabled
          />
        );
      },
    },
    {
      name: hasUnits ? 'Materia prima fábrica (u)' : 'Materia prima fábrica (m)',
      sortable: false,
      center: false,
      maxWidth: '10%',
      cell: (row) => {
        return (
          <input
            type="number"
            name="opMeters"
            value={row.opMeters.toFixed(0) || 0}
            className="form-control dmx-input w-100 notext"
            disabled
          />
        );
      },
    },
  ];

  if (isEditable && checkDataPermission(currentUser?.userType, 'mp:edit:stockControl')) {
    columns.push({
      sortable: false,
      center: false,
      cell: (row, index) => actionButtons(row, index),
    });
  }

  return (
    <DataTable
      customStyles={customStyles}
      columns={columns}
      data={data}
      noDataComponent={<div className="p-3">{noRecords}</div>}
      expandableRows
      expandableRowDisabled={(row) => {
        return row.movementsDetail && row.movementsDetail.length === 0;
      }}
      expandableRowsComponent={
        <MoveList
          currentDataMP={currentDataMP}
          productData={productData}
          productName={productName}
          isEditable={isEditable}
          updateMove={updateMove}
          deleteMove={deleteMove}
          currentUser={currentUser}
          toggle={toggle}
          isAllowed={isAllowed}
          hasUnits={hasUnits}
        />
      }
    />
  );
};

export default BatchList;
