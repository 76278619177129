import { useState, useEffect } from 'react';
import getPurchaseConditions from '../services/getPurchaseConditions';

const usePurchaseConditionsFetcher = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getPurchaseConditions()
      .then((purchaseConditions) => {
        setData(purchaseConditions);
        setIsLoading(false);
        setIsError(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return { data, isLoading, isError };
};

export default usePurchaseConditionsFetcher;
