import { put, call, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import {
  GET_FEED_STOCK,
  GET_FEED_STOCK_SUCCESS,
  GET_FEED_STOCK_ERROR,
  GET_ALL_FEED_STOCK,
  GET_ALL_FEED_STOCK_SUCCESS,
  GET_ALL_FEED_STOCK_ERROR,
  GET_ALL_FEED_STOCK_CATEGORIES,
  GET_ALL_FEED_STOCK_CATEGORIES_SUCCESS,
  GET_ALL_FEED_STOCK_CATEGORIES_ERROR,
  PATCH_FEED_STOCK,
  PATCH_FEED_STOCK_SUCCESS,
  PATCH_FEED_STOCK_ERROR,
} from '../types/raw-material';

function* getAllFeedStockCategories(): any {
  try {
    const result = yield call(services.rawMaterial.getAllFeedStockCategories);
    yield put({ type: GET_ALL_FEED_STOCK_CATEGORIES_SUCCESS, payload: result });
  } catch (error: any) {
    yield put({ type: GET_ALL_FEED_STOCK_CATEGORIES_ERROR, payload: error.message });
  }
}

function* getAllFeedStock(): any {
  try {
    const result = yield call(services.rawMaterial.getAllFeedStock);
    yield put({ type: GET_ALL_FEED_STOCK_SUCCESS, payload: result });
  } catch (error: any) {
    yield put({ type: GET_ALL_FEED_STOCK_ERROR, payload: error.message });
  }
}

function* getFeedStock({ payload }): any {
  try {
    const result = yield call(services.rawMaterial.getFeedStock, payload);
    yield put({ type: GET_FEED_STOCK_SUCCESS, payload: result });
  } catch (error: any) {
    yield put({ type: GET_FEED_STOCK_ERROR, payload: error.message });
  }
}

function* patchFeedStock({ payload }): any {
  try {
    const result = yield call(services.rawMaterial.patchFeedStock, payload);
    yield put({ type: PATCH_FEED_STOCK_SUCCESS, payload: result });
  } catch (error: any) {
    yield put({ type: PATCH_FEED_STOCK_ERROR, payload: error.message });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_FEED_STOCK, getFeedStock);
  yield takeLatest<any>(GET_ALL_FEED_STOCK_CATEGORIES, getAllFeedStockCategories);
  yield takeLatest<any>(GET_ALL_FEED_STOCK, getAllFeedStock);
  yield takeLatest<any>(PATCH_FEED_STOCK, patchFeedStock);
}
