import {
  initialState,
  Actions,
  State,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  RESET_UPDATE_USER_PROFILE,
  RESET_USER_PROFILE,
} from '../types/profile';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_USER_PROFILE:
      return {
        ...state,
        information: {
          data: state.information.data,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        information: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case GET_USER_PROFILE_ERROR:
      return {
        ...state,
        information: {
          data: null,
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        updateInformation: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updateInformation: {
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        updateInformation: {
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case RESET_USER_PROFILE:
      return initialState;

    case RESET_UPDATE_USER_PROFILE:
      return {
        ...state,
        updateInformation: {
          states: {
            loading: false,
            success: false,
            error: false,
          },
        },
      };
    default:
      return state;
  }
};

export default reducers;
