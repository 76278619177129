import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';

const Component = ({ component: RouteComponent, userInformation, userStates, ...rest }: any) => {
  const { hasValidToken } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(routeProps) => (!!hasValidToken ? <RouteComponent {...routeProps} /> : <Redirect to={'/login'} />)}
    />
  );
};

const state = ({ userStore }) => {
  const { data: userInformation, states: userStates } = userStore.information;
  return {
    userInformation,
    userStates,
  };
};

export default connect(state)(Component);
