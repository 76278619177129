export const productionOrderStates: readonly ProductionOrderState[] = [
  { value: 'requested', label: 'Solicitada' },
  { value: 'pending', label: 'Pendiente' },
  { value: 'inProgress', label: 'En Progreso' },
  { value: 'done', label: 'Finalizada' },
  { value: 'archived', label: 'Archivada' },
];

export interface ProductionOrderState {
  value: string;
  label: string;
  color?: string;
}
