import { useState, useEffect, useRef } from 'react'
import Barcode from 'react-barcode'
import ReactToPrint from 'react-to-print'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

// images
import LogoDimex from '../../assets/images/logo.png'
import LogoInnenImage from '../../assets/images/logo-innen.jpeg'

// mixins
import {
  ButtonPrintContainer,
  ContainerComponent,
  Sticker,
  Separator,
  QRCodeCustom,
  Heading,
  CurrentDate,
  LogoContainer,
  Image,
  LogoClaim,
  Body,
  Wrapper,
  Lote,
  ProductWrapper,
  ProductName,
  ProductCode,
  CantMts,
  Footer,
  BarcodeContainer,
  QRCodeInnen,
  LogoInnen,
} from './mixins'


const printInvoiceButton = (
  <FontAwesomeIcon title="Imprimir Barcode" icon={faPrint} color={'grey'} />
)

const Component = ({ currentDataMP, data, moveIndex }) => {
  const [movementMeters, setMovementMeters] = useState(0)
  const [currentDate, setCurrentDate] = useState('')
  const componentRef = useRef()

  // get current date
  const current = new Date()
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`

  // get total meters with format
  const movementMetersTotal = data?.movementsDetail[moveIndex]?.meters
  const formatMeters = movementMetersTotal?.toLocaleString(undefined, { maximumFractionDigits: 2 }).replace(',', '.')

  // generate product name
  const productName = currentDataMP?.feedstockCategoryId?.name
  const productType = currentDataMP?.type === null ? '' : currentDataMP?.type
  const productGrammage = currentDataMP?.grammage === null ? '' : currentDataMP?.grammage
  const productColor = currentDataMP?.color === null ? '' : currentDataMP?.color
  const productWidth = currentDataMP?.width === null ? '' : currentDataMP?.width + ' mts'
  const productFinal = 
    productName + ' ' +
    productType + ' ' +
    productGrammage + ' ' +
    productColor + ' ' +
    productWidth

  // get current url
  const currentURL = window.location.href

  useEffect(() => {
    setMovementMeters(formatMeters)
  }, [formatMeters])

  useEffect(() => {
    setCurrentDate(date)
  }, [date])

  if (data?.barcode === undefined) {
    return null
  }

  return (
    <>
      <ButtonPrintContainer>
        <ReactToPrint pageStyle="@page { size: 105mm 148mm }" trigger={() => printInvoiceButton} content={() => componentRef.current} />
      </ButtonPrintContainer>

      <ContainerComponent>
        <Sticker ref={componentRef}>
          <Heading>
            <CurrentDate>Fecha: {currentDate}</CurrentDate>
            
            <LogoContainer>
              <Image src={LogoDimex} width="290" alt="Logo Dimex"/>
              <LogoClaim>Uso Confidencial</LogoClaim>
            </LogoContainer>
          </Heading>

          <Body>
            <Wrapper>
              <ProductWrapper>
                <ProductName>{productFinal}</ProductName>
                <ProductCode>{currentDataMP?.code}</ProductCode>
              </ProductWrapper>
  
              <QRCodeCustom size={200} value={currentURL} includeMargin={false} />
            </Wrapper>
            <Separator />

            <CantMts>{movementMeters} {movementMeters <= 1 ? 'MT' : 'MTS'}</CantMts>
            <Separator />

            {data?.barcode !== '' && <Lote>{data?.barcode}</Lote>}
            <Separator />

            <BarcodeContainer>
              <Barcode
                width={3}
                height={200}
                fontSize={40}
                value={data?.barcode || ''}
                format={"CODE128"}
              />
            </BarcodeContainer>
          </Body>

          <Footer>
            <QRCodeInnen size={100} value="www.innen.com.ar" />
            <LogoInnen width="200px" src={LogoInnenImage} alt="Logo Innen" />
          </Footer>
        </Sticker>
      </ContainerComponent>
    </>
  )
}
  
export default Component
