import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import services from '../../../../services';
import productionOrder from '../../../../services/production-order';
import { ProductionOrderDto } from '../../../../types/production-order';
import { User } from '../../../../types/user';
import { Statistics, Loading } from './styles';

interface ComponentProps {
  productionOrders?: string[];
  userInformation: User;
  startFiltering?: boolean
  filtersSelected
}

const Component = ({ productionOrders = [], userInformation, filtersSelected = {}, startFiltering }: ComponentProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<{
    totalUnits: number
    cutUnitsOut: number
    cutUnitsIn: number
    transitUnits: number
    workshopsUnits: number
    warehouseUnits: number
  }>({
    totalUnits: 0,
    cutUnitsOut: 0,
    cutUnitsIn: 0,
    transitUnits: 0,
    workshopsUnits: 0,
    warehouseUnits: 0,
  });

  const fetchData = async () => {
    let payload = productionOrders.length > 0 ? { productionOrders } : {
      ...filtersSelected,
      showArchived: filtersSelected.state === 'archived',
      userType: userInformation.userType
    };

    setIsLoading(true)



    services.productionOrder.getProductionOrdersStatistics(payload)
      .then((res) => {
        setData(res)
        setIsLoading(false)
      })
  };

  useEffect(() => {
    if (startFiltering) {
      fetchData()
    }
  }, [startFiltering, filtersSelected])

  return (
    <Statistics>
      <div className="card dmx-card-mt shadow-sm statistics-card cut m-t-0 m-b-0 p-15">
        {isLoading && <Loading color="rebeccapurple" />}
        <figure></figure>
        <div className="statistics-card-information">
          <div className="statistics-card-title">En corte</div>

          <div className="statistics-card-values">
            <div className="statistics-card-percent">
              {data?.cutUnitsOut > 0 && data?.totalUnits > 0 ? ((data?.cutUnitsOut * 100) / data?.totalUnits).toFixed(2) : '0.00'}
              <span>%</span>
            </div>
            <span>
              {data?.cutUnitsOut} unidades de {data?.totalUnits}
            </span>
          </div>
        </div>
      </div>
      <div className="card dmx-card-mt shadow-sm statistics-card transit m-t-0 m-b-0 p-15">
        {isLoading && <Loading color="darkcyan" />}
        <figure></figure>
        <div className="statistics-card-information">
          <div className="statistics-card-title">En Transito</div>

          <div className="statistics-card-values">
            <div className="statistics-card-percent">
              {data?.transitUnits > 0 && data?.cutUnitsOut > 0 ? ((data?.transitUnits * 100) / data?.cutUnitsOut).toFixed(2) : '0.00'}
              <span>%</span>
            </div>
            <span>
              {data?.transitUnits} unidades de {data?.cutUnitsOut}
            </span>
          </div>
        </div>
      </div>
      <div className="card dmx-card-mt shadow-sm statistics-card workshop m-t-0 m-b-0 p-15">
        {isLoading && <Loading />}
        <figure></figure>
        <div className="statistics-card-information">
          <div className="statistics-card-title">En taller</div>

          <div className="statistics-card-values">
            <div className="statistics-card-percent">
              {data?.workshopsUnits > 0 && data?.cutUnitsOut > 0 ? ((data?.workshopsUnits * 100) / data?.cutUnitsOut).toFixed(2) : '0.00'}
              <span>%</span>
            </div>
            <span>
              {data?.workshopsUnits} unidades de {data?.cutUnitsOut}
            </span>
          </div>
        </div>
      </div>
      <div className="card dmx-card-mt shadow-sm statistics-card warehouse m-t-0 m-b-0 p-15">
        {isLoading && <Loading color="darksalmon" />}
        <figure></figure>
        <div className="statistics-card-information">
          <div className="statistics-card-title">En deposito</div>

          <div className="statistics-card-values">
            <div className="statistics-card-percent">
              {data?.warehouseUnits > 0 && data?.cutUnitsOut > 0 ? ((data?.warehouseUnits * 100) / data?.cutUnitsOut).toFixed(2) : '0.00'}
              <span>%</span>
            </div>
            <span>
              {data?.warehouseUnits} unidades de {data?.cutUnitsOut}
            </span>
          </div>
        </div>
      </div>
    </Statistics>
  );
};
const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;

  return {
    userInformation,
  };
};

export default connect(state)(Component);
