import { WorkshopOrderDto } from '../../types/workshop';

export const NEW_WORKSHOP_ORDER = 'NEW_WORKSHOP_ORDER';
export const NEW_WORKSHOP_ORDER_SUCCESS = 'NEW_WORKSHOP_ORDER_SUCCESS';
export const NEW_WORKSHOP_ORDER_ERROR = 'NEW_WORKSHOP_ORDER_ERROR';

export const RESET_NEW_WORKSHOP_ORDER = 'RESET_NEW_WORKSHOP_ORDER';
export const RESET_NEW_WORKSHOP_ORDER_SUCCESS = 'RESET_NEW_WORKSHOP_ORDER_SUCCESS';
export const RESET_NEW_WORKSHOP_ORDER_ERROR = 'RESET_NEW_WORKSHOP_ORDER_ERROR';

export const EDIT_WORKSHOP_ORDER = 'EDIT_WORKSHOP_ORDER';
export const EDIT_WORKSHOP_ORDER_SUCCESS = 'EDIT_WORKSHOP_ORDER_SUCCESS';
export const EDIT_WORKSHOP_ORDER_ERROR = 'EDIT_WORKSHOP_ORDER_ERROR';

export const RESET_EDIT_WORKSHOP_ORDER = 'RESET_EDIT_WORKSHOP_ORDER';
export const RESET_EDIT_WORKSHOP_ORDER_SUCCESS = 'RESET_EDIT_WORKSHOP_ORDER_SUCCESS';
export const RESET_EDIT_WORKSHOP_ORDER_ERROR = 'RESET_EDIT_WORKSHOP_ORDER_ERROR';

export const RESET_WORKSHOP_ORDER = 'RESET_WORKSHOP_ORDER';
export const RESET_WORKSHOP_ORDER_SUCCESS = 'RESET_WORKSHOP_ORDER_SUCCESS';
export const RESET_WORKSHOP_ORDER_ERROR = 'RESET_WORKSHOP_ORDER_ERROR';

export const GET_WORKSHOP_ORDER = 'GET_WORKSHOP_ORDER';
export const GET_WORKSHOP_ORDER_SUCCESS = 'GET_WORKSHOP_ORDER_SUCCESS';
export const GET_WORKSHOP_ORDER_ERROR = 'GET_WORKSHOP_ORDER_ERROR';

export const GET_ALL_WORKSHOPS_ORDERS = 'GET_ALL_WORKSHOPS_ORDERS';
export const GET_ALL_WORKSHOPS_ORDERS_SUCCESS = 'GET_ALL_WORKSHOPS_ORDERS_SUCCESS';
export const GET_ALL_WORKSHOPS_ORDERS_ERROR = 'GET_ALL_WORKSHOPS_ORDERS_ERROR';
export const GET_ALL_WORKSHOPS_ORDERS_RESET = 'GET_ALL_WORKSHOPS_ORDERS_RESET';

export interface NewWorkshopOrder {
  type: typeof NEW_WORKSHOP_ORDER;
  payload: WorkshopOrderDto;
}

export interface NewWorkshopOrderSuccess {
  type: typeof NEW_WORKSHOP_ORDER_SUCCESS;
  payload: null;
}

export interface NewWorkshopOrderError {
  type: typeof NEW_WORKSHOP_ORDER_ERROR;
  payload: boolean | string;
}

export interface ResetEditWorkshopOrder {
  type: typeof RESET_EDIT_WORKSHOP_ORDER;
  payload: null;
}

export interface ResetEditWorkshopOrderSuccess {
  type: typeof RESET_EDIT_WORKSHOP_ORDER_SUCCESS;
  payload: null;
}

export interface ResetEditWorkshopOrderError {
  type: typeof RESET_EDIT_WORKSHOP_ORDER_ERROR;
  payload: boolean | string;
}

export interface ResetNewWorkshopOrder {
  type: typeof RESET_NEW_WORKSHOP_ORDER;
  payload: null;
}

export interface ResetNewWorkshopOrderSuccess {
  type: typeof RESET_NEW_WORKSHOP_ORDER_SUCCESS;
  payload: null;
}

export interface ResetNewWorkshopOrderError {
  type: typeof RESET_NEW_WORKSHOP_ORDER_ERROR;
  payload: boolean | string;
}

export interface ResetWorkshopOrder {
  type: typeof RESET_WORKSHOP_ORDER;
  payload: null;
}

export interface ResetWorkshopOrderSuccess {
  type: typeof RESET_WORKSHOP_ORDER_SUCCESS;
  payload: null;
}

export interface ResetWorkshopOrderError {
  type: typeof RESET_WORKSHOP_ORDER_ERROR;
  payload: boolean | string;
}

export interface EditWorkshopOrder {
  type: typeof EDIT_WORKSHOP_ORDER;
  payload: WorkshopOrderDto;
}

export interface EditWorkshopOrderSuccess {
  type: typeof EDIT_WORKSHOP_ORDER_SUCCESS;
  payload: null;
}

export interface EditWorkshopOrderError {
  type: typeof EDIT_WORKSHOP_ORDER_ERROR;
  payload: boolean | string;
}

export interface GetWorkshopOrder {
  type: typeof GET_WORKSHOP_ORDER;
  payload: string;
}

export interface GetWorkshopOrderSuccess {
  type: typeof GET_WORKSHOP_ORDER_SUCCESS;
  payload: null;
}

export interface GetWorkshopOrderError {
  type: typeof GET_WORKSHOP_ORDER_ERROR;
  payload: boolean | string;
}

export interface GetAllWorkshopsOrders {
  type: typeof GET_ALL_WORKSHOPS_ORDERS;
  payload?: { [key: string]: string | number };
}

export interface GetAllWorkshopsOrdersSuccess {
  type: typeof GET_ALL_WORKSHOPS_ORDERS_SUCCESS;
  payload: null;
}

export interface GetAllWorkshopsOrdersError {
  type: typeof GET_ALL_WORKSHOPS_ORDERS_ERROR;
  payload: boolean | string;
}

export interface GetAllWorkshopsOrdersReset {
  type: typeof GET_ALL_WORKSHOPS_ORDERS_RESET;
  payload: null;
}

export interface State {
  workshopOrder: {
    data: WorkshopOrderDto | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  workshopsOrders: {
    data: WorkshopOrderDto[] | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  newWorkshopOrder: {
    data: WorkshopOrderDto[] | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  editWorkshopOrder: {
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
}

export const initialState: State = {
  workshopOrder: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  workshopsOrders: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  newWorkshopOrder: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  editWorkshopOrder: {
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
};

export type Actions =
  | NewWorkshopOrder
  | NewWorkshopOrderSuccess
  | NewWorkshopOrderError
  | ResetEditWorkshopOrder
  | ResetEditWorkshopOrderSuccess
  | ResetEditWorkshopOrderError
  | ResetNewWorkshopOrder
  | ResetNewWorkshopOrderSuccess
  | ResetNewWorkshopOrderError
  | ResetWorkshopOrder
  | ResetWorkshopOrderSuccess
  | ResetWorkshopOrderError
  | EditWorkshopOrder
  | EditWorkshopOrderSuccess
  | EditWorkshopOrderError
  | GetWorkshopOrder
  | GetWorkshopOrderSuccess
  | GetWorkshopOrderError
  | GetAllWorkshopsOrders
  | GetAllWorkshopsOrdersSuccess
  | GetAllWorkshopsOrdersError
  | GetAllWorkshopsOrdersReset;
