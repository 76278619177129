import ProfileForm from './components/profile-form'
import PasswordForm from './components/password-form'

const Profile = () => {
  return (
    <div className="container-fluid">
      <div className="edit-profile">
        <div className="row">
          <div className="col-lg-12">
            <ProfileForm />
            <PasswordForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
