export const customStyles = {
  headCells: {
    style: {
      paddingLeft: '10px', // override the cell padding for head cells
      paddingRight: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '10px',
    },
    checkbox: {
      width: '15px',
      height: '15px',
    }
  },
};
