import { getToken } from '../utils/authUtils';

export default function getOrderNotes() {
  return fetch(`${process.env.REACT_APP_API_URL}/orders/all`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${getToken()}`,
    }),
  }).then((response) => response.json());
}

export async function getOrderNotesPaginated({ limit, page, n_Archived, userType, userId, status, isAdmin, clientId }) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/orders/page?limit=${limit}&page=${page}&archived=${n_Archived}&userType=${userType}&userId=${userId}&status=${status}&admin=${isAdmin}&clientId=${clientId}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    },
  );
  return await response.json();
}
