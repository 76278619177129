import './Spinner.scss';

interface ComponentProps {
  size?: 'large' | 'medium' | 'small';
  isPageLoader?: boolean;
  style?;
  className?: string;
}

const Component = ({ size = 'small', className, isPageLoader, ...rest }: ComponentProps) => {
  const loader = isPageLoader ? 'page-loader' : '';

  return (
    <div className={`spinner-box ${loader} ${className}`} {...rest}>
      <div className={`spinner spinner-${size}`} />
    </div>
  );
};

export default Component;
