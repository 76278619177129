import { FeedstockCategory } from '../../types/feedstock-categoy';
import { FeedStockDto } from '../../types/raw-material';

export const GET_ALL_FEED_STOCK_CATEGORIES = 'GET_ALL_FEED_STOCK_CATEGORIES';
export const GET_ALL_FEED_STOCK_CATEGORIES_SUCCESS = 'GET_ALL_FEED_STOCK_CATEGORIES_SUCCESS';
export const GET_ALL_FEED_STOCK_CATEGORIES_ERROR = 'GET_ALL_FEED_STOCK_CATEGORIES_ERROR';

export const GET_FEED_STOCK = 'GET_FEED_STOCK';
export const GET_FEED_STOCK_SUCCESS = 'GET_FEED_STOCK_SUCCESS';
export const GET_FEED_STOCK_ERROR = 'GET_FEED_STOCK_ERROR';

export const GET_ALL_FEED_STOCK = 'GET_ALL_FEED_STOCK';
export const GET_ALL_FEED_STOCK_SUCCESS = 'GET_ALL_FEED_STOCK_SUCCESS';
export const GET_ALL_FEED_STOCK_ERROR = 'GET_ALL_FEED_STOCK_ERROR';

export const PATCH_FEED_STOCK = 'PATCH_FEED_STOCK';
export const PATCH_FEED_STOCK_SUCCESS = 'PATCH_FEED_STOCK_SUCCESS';
export const PATCH_FEED_STOCK_ERROR = 'PATCH_FEED_STOCK_ERROR';

export interface GetAllFeedStockCategories {
  type: typeof GET_ALL_FEED_STOCK_CATEGORIES;
  payload: null;
}

export interface GetAllFeedStockCategoriesSuccess {
  type: typeof GET_ALL_FEED_STOCK_CATEGORIES_SUCCESS;
  payload: null;
}

export interface GetAllFeedStockCategoriesError {
  type: typeof GET_ALL_FEED_STOCK_CATEGORIES_ERROR;
  payload: boolean | string;
}

export interface GetFeedStock {
  type: typeof GET_FEED_STOCK;
  payload: null;
}

export interface GetFeedStockSuccess {
  type: typeof GET_FEED_STOCK_SUCCESS;
  payload: null;
}

export interface GetFeedStockError {
  type: typeof GET_FEED_STOCK_ERROR;
  payload: boolean | string;
}

export interface GetAllFeedStock {
  type: typeof GET_ALL_FEED_STOCK;
  payload: null;
}

export interface GetAllFeedStockSuccess {
  type: typeof GET_ALL_FEED_STOCK_SUCCESS;
  payload: null;
}

export interface GetAllFeedStockError {
  type: typeof GET_ALL_FEED_STOCK_ERROR;
  payload: boolean | string;
}

export interface PatchFeedStock {
  type: typeof PATCH_FEED_STOCK;
  payload: null;
}

export interface PatchFeedStockSuccess {
  type: typeof PATCH_FEED_STOCK_SUCCESS;
  payload: null;
}

export interface PatchFeedStockError {
  type: typeof PATCH_FEED_STOCK_ERROR;
  payload: boolean | string;
}

export interface State {
  allFeedStockCategories: {
    data: FeedstockCategory[] | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  allFeedStock: {
    data: FeedStockDto[] | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  feedStock: {
    data: FeedStockDto[] | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  updateFeedStock: {
    data: FeedStockDto[] | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
}

export const initialState: State = {
  allFeedStockCategories: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  allFeedStock: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  feedStock: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  updateFeedStock: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
};

export type Actions =
  | GetAllFeedStockCategories
  | GetAllFeedStockCategoriesSuccess
  | GetAllFeedStockCategoriesError
  | GetFeedStock
  | GetFeedStockSuccess
  | GetFeedStockError
  | GetAllFeedStock
  | GetAllFeedStockSuccess
  | GetAllFeedStockError
  | PatchFeedStock
  | PatchFeedStockSuccess
  | PatchFeedStockError;
