import styled from 'styled-components';

export const EmptyState = styled('div')`
  background-color: #f5f5f5;
  width: 100%;
  color: #999;
  line-height: 70px;
  font-weight: 600;
  text-align: center;
`;
