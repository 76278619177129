import { Link } from 'react-router-dom';
import { Book, Users, Box, Hexagon, Columns, Scissors } from 'react-feather';
import UserPanel from './UserPanel';
import logo from '../../../assets/images/logo.png';
import workshop from '../../../assets/images/icons/tailoring.svg';
import './Sidebar.scss';

import { ProfileProvider } from '../../../context/ProfileContext';

import { connect } from 'react-redux';

// Permisos
import CanRBac from '../../CanRBac';

const Workshop = (props) => {
  return <svg  {...props} id="Layer_1" fill="white" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m56 13.1c-.6 0-1.1-.5-1.1-1.1v-6.2c0-1.2-.9-2.2-2-2.2h-1.8c-1.2 0-2.2 1-2.2 2.2v6.1c0 .6-.5 1.1-1.1 1.1s-1.1-.5-1.1-1.1v-6.1c0-2.4 2-4.4 4.4-4.4h1.8c2.3 0 4.2 2 4.2 4.4v6.2c0 .6-.5 1.1-1.1 1.1z" /></g><g><path d="m63 50.5c-.6 0-1.1-.5-1.1-1.1v-33.1c0-1.9-1.5-3.3-3.3-3.3h-35.9c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1h35.9c3 0 5.5 2.5 5.5 5.5v33.1c0 .6-.5 1.1-1.1 1.1z" /></g><g><path d="m41.7 50.5c-.7 0-1.1-.5-1.1-1.1.2-4 1-11.7 2.3-15.1.4-1 .2-2.2-.4-3.1s-1.6-1.4-2.6-1.4h-17.2c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1h17.1c1.8 0 3.4.8 4.4 2.3s1.3 3.4.7 5.1c-1.2 3.2-1.9 10.9-2.1 14.5 0 .5-.5 1-1.1 1z" /></g><g><path d="m18.3 33.8h-7.5c-3 0-5.5-2.5-5.5-5.5v-16c0-3 2.5-5.5 5.5-5.5h7.5c3 0 5.5 2.5 5.5 5.5v16c0 3-2.5 5.5-5.5 5.5zm-7.5-24.8c-1.9 0-3.3 1.5-3.3 3.3v16c0 1.9 1.5 3.3 3.3 3.3h7.5c1.9 0 3.3-1.5 3.3-3.3v-16c0-1.9-1.5-3.3-3.3-3.3z" /></g><g><path d="m14.6 45.2c-.6 0-1.1-.5-1.1-1.1v-11.4c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1v11.4c0 .6-.5 1.1-1.1 1.1z" /></g><g><path d="m63 21.4h-23.2c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1h23.2c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1z" /></g><g><path d="m55.5 32.7c-.6 0-1.1-.5-1.1-1.1v-1.9c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1v1.9c0 .6-.5 1.1-1.1 1.1z" /></g><g><path d="m55.5 38.7c-.6 0-1.1-.5-1.1-1.1v-1.9c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1v1.9c0 .6-.5 1.1-1.1 1.1z" /></g><g><path d="m55.5 44.7c-.6 0-1.1-.5-1.1-1.1v-1.9c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1v1.9c0 .6-.5 1.1-1.1 1.1z" /></g><g><path d="m58.6 62.6h-53.2c-3 0-5.5-2.5-5.5-5.5 0-4.9 4-8.9 8.9-8.9h54.2c.6 0 1.1.5 1.1 1.1v7.8c0 3.1-2.4 5.5-5.5 5.5zm-49.8-12.2c-3.7 0-6.7 3-6.7 6.7 0 1.9 1.5 3.3 3.3 3.3h53.2c1.9 0 3.3-1.4 3.3-3.3v-6.7z" /></g></g></svg>
}

const Component = ({ openSidebar, currentUser }) => {
  return (
    <>
      <div className={`page-sidebar ${openSidebar}`}>
        <div className="main-header-left d-none d-lg-block">
          <div className="logo-wrapper logo-wrapper-center">
            <Link to="/orders">
              <img className="logo-dimex lazyloaded" src={logo} alt="" />
            </Link>
          </div>
        </div>

        <div className="sidebar custom-scrollbar">
          <ProfileProvider>
            <UserPanel />
          </ProfileProvider>

          <ul className="sidebar-menu" id="myDIV">
            <CanRBac
              role={currentUser?.userType}
              perform="np:list"
              yes={() => {
                return (
                  <li>
                    <Link to="/orders" className="sidebar-header active">
                      <Book />
                      <span>Notas de pedido</span>
                    </Link>
                  </li>
                );
              }}
            />

            <CanRBac
              role={currentUser?.userType}
              perform="client:list"
              yes={() => {
                return (
                  <li>
                    <Link to="/clients" className="sidebar-header active">
                      <Users />
                      <span>Clientes</span>
                    </Link>
                  </li>
                );
              }}
            />

            <CanRBac
              role={currentUser?.userType}
              perform="product:list"
              yes={() => {
                return (
                  <li>
                    <Link to="/products" className="sidebar-header active">
                      <Box />
                      <span>Productos</span>
                    </Link>
                  </li>
                );
              }}
            />

            <CanRBac
              role={currentUser?.userType}
              perform="mp:list"
              yes={() => {
                return (
                  <li>
                    <Link to="/rawmaterial" className="sidebar-header active">
                      <Hexagon />
                      <span>Materia prima</span>
                    </Link>
                  </li>
                );
              }}
            />

            <CanRBac
              role={currentUser?.userType}
              perform="op:list"
              yes={() => {
                return (
                  <li>
                    <Link to="/orderproduction" className="sidebar-header active">
                      <Columns />
                      <span>Orden de Producción</span>
                    </Link>
                  </li>
                );
              }}
            />

            <CanRBac
              role={currentUser?.userType}
              perform="ws:list"
              yes={() => {
                return (
                  <li>
                    <Link to="/workshops" className="sidebar-header active">
                      <Workshop style={{ marginRight: 14 }} />
                      <span>Talleres</span>
                    </Link>
                  </li>
                );
              }}
            />
            {/*
            <li>
              <Link to="/workshop-order" className="sidebar-header active">
                <Columns />
                <span>Talleres</span>
              </Link>
            </li>

            <li>
              <CanRBac
                role={currentUser?.userType}
                perform="op:list"
                yes={() => {
                  return (
                    <Link to="/cuts" className="sidebar-header active">
                      <Scissors />
                      <span>Cortes</span>
                    </Link>
                  );
                }}
              />
            </li>
            */}
          </ul>
        </div>
      </div>
    </>
  );
};

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(Component);
