/* eslint no-underscore-dangle: 0 */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { faPlus, faEdit, faTimesCircle, faEye, faSyncAlt, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import _ from 'underscore';
import DataTableExtensions from '@innenpkg/react-data-table-component-extensions';
import '@innenpkg/react-data-table-component-extensions/dist/index.css';
import { CREATE, READ, UPDATE } from '../../constant';
import getProducts from '../../services/getProducts';
import './Products.scss';

import deleteProduct from '../../services/deleteProduct';
import useProductsFetcher from '../../hooks/useProductsFetcher';
import Spinner from '../../components/Spinner';
import DmxModal from './DmxModal';
import UploadModal from './DmxUpload';
import { ProductContext } from '../../context/ProductContext';
import SpinnerButton from '../../components/Button';
import { notifications } from '../../constant';

import { connect } from 'react-redux';

//permissions
import CanRBac from '../../components/CanRBac';
import { rules } from '../../components/CanRBac/rbac-rules';

const Products = ({ currentUser }) => {
  const [isFetching, setIsFetching] = useState(false);
  let productList = useProductsFetcher();
  const { isLoading, isError } = productList;
  const [product, setProduct] = useState({});
  const { productContext, modalContext, upModal } = useContext(ProductContext);
  const [modal, setModal] = modalContext;
  const [uploadModal, setUploadModal] = upModal;
  const [data, setData] = productContext;
  const [action, setAction] = useState();
  const [attributes, setAttributes] = useState();
  const [page, setPage] = useState();
  const [itemsForPage, setItemsForPage] = useState();
  const [prodData, setProdData] = useState();

  const handleRefresh = async () => {
    setIsFetching(true);
    try {
      await getProducts().then((result) => {
        const newData = result.sort(compare);
        setData(newData);
      });
      toast.success(notifications.tableUpdateSuccess, { autoClose: 3000 });
    } catch (error) {
      toast.error(`${notifications.tableUpdateError} ${error.msg}`, { autoClose: 3000 });
    }
    setIsFetching(false);
  };

  function compare(a, b) {
    const orderA = a.name.toUpperCase();
    const orderB = b.name.toUpperCase();

    let comparison = 0;
    if (orderA > orderB) {
      comparison = 1;
    } else if (orderA < orderB) {
      comparison = -1;
    }
    return comparison;
  }

  useEffect(() => {
    const newData = productList.data.sort(compare);
    setData(newData);
  }, [productList.data]);

  useEffect(() => {
    setProduct(product);
  }, [modal]);

  useEffect(() => {
    const lastPageLS = localStorage.getItem('lastPageProducts');
    const itemsForPageLS = localStorage.getItem('itemsForPageProducts');

    if (lastPageLS === null || itemsForPageLS === null) {
      setPage(1);
      setItemsForPage(10);
    } else if (lastPageLS == 1) {
      setPage(1);
      setItemsForPage(itemsForPageLS);
    } else {
      setPage(lastPageLS);
      setItemsForPage(itemsForPageLS);
    }
  }, []);

  if (isLoading) {
    return <Spinner size="large" isPageLoader />;
  }

  if (isError) {
    return <h1>Un error ha ocurrido</h1>;
  }

  const paginationText = {
    rowsPerPageText: 'Productos por página:',
    rangeSeparatorText: 'de',
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const jsonToArray = (json) => {
    const result = [];
    // eslint-disable-next-line no-restricted-syntax,guard-for-in,prefer-const
    for (let attribute in json) {
      const item = { name: attribute, value: json[attribute] };
      result.push(item);
    }
    return result;
  };

  const eventLastPage = (page) => {
    setPage(page);
  };
  const eventItemsForPage = (currentRowsPerPage) => {
    setItemsForPage(currentRowsPerPage);
  };
  const postLastPageLocal = () => {
    localStorage.setItem('lastPageProducts', page);
    localStorage.setItem('itemsForPageProducts', itemsForPage);
  };

  const executeRowClick = (productInfo) => {
    const attributeList = productInfo.productCategoryId.productAttributesId;
    const newAttributes = jsonToArray(attributeList)
      .filter((item) => item.name !== '_id')
      .filter((item) => item.value)
      .filter((item) => item.value.length);

    setAttributes(newAttributes);
    setProduct(productInfo);
    setAction(READ);
    toggleModal();
  };

  const handleRowClick = (productInfo) => {
    executeRowClick(productInfo);
    postLastPageLocal();
  };

  const executeEditAction = (productInfo) => {
    const attributeList = productInfo.productCategoryId.productAttributesId;
    const newAttributes = jsonToArray(attributeList)
      .filter((item) => item.name !== '_id')
      .filter((item) => item.value)
      .filter((item) => item.value.length);

    setAttributes(newAttributes);
    setProduct(productInfo);
    setAction(UPDATE);
    toggleModal();
    postLastPageLocal();
  };

  const handleEditAction = (productInfo) => {
    executeEditAction(productInfo);
    postLastPageLocal();
  };

  function deleteTableRow(productId) {
    const productIndex = data.findIndex((order) => order._id === productId);
    const newData = _.clone(data);
    newData.splice(productIndex, 1);
    setData(newData);
  }

  function executeDeleteAction(productId) {
    deleteProduct(productId).then((res) => {
      if (res.msg === 'DELETED') {
        deleteTableRow(productId);
        toast.success('El producto ha sido borrado.', { autoClose: 3000 });
      } else {
        toast.error(`No se pudo borrar el producto porque ocurrió un error:${res.msg}`, { autoClose: 3000 });
      }
    });
  }

  const handleDeleteAction = (productId) => {
    SweetAlert.fire({
      title: '¿Desea borrar el producto?',
      text: 'Una vez eliminado no podrá ser recuperado.',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        executeDeleteAction(productId);
      }
    });
    postLastPageLocal();
  };

  const executeCreateAction = () => {
    setProduct({
      _id: '',
      productCategoryId: '604577ea710af48ac0a260e6',
      code: '',
      description: '',
      name: '',
      stock: '',
    });
    setAction(CREATE);
    toggleModal();
    postLastPageLocal();
  };

  const handleCreateAction = () => {
    executeCreateAction();
  };

  const handleUpdateAction = (productInfo) => {
    setAction(UPDATE);
    toggleUploadModal();
    postLastPageLocal();
    setProdData(productInfo);
  };

  const actionButtons = (row) => (
    <>
      <CanRBac
        role={currentUser.userType}
        perform="product:edit"
        yes={() => (
          <>
            <div className="text-secondary p-1 dmx-action-button">
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEditAction(row)} />
            </div>
            <div className="text-secondary p-1 dmx-action-button">
              <FontAwesomeIcon icon={faUpload} onClick={() => handleUpdateAction(row)} />
            </div>
            <div className="text-danger p-1 dmx-action-button">
              <FontAwesomeIcon icon={faTimesCircle} onClick={() => handleDeleteAction(row._id)} />
            </div>
          </>
        )}
        no={() => null}
      />

      {currentUser.userType === 'seller' && (
        <CanRBac
          role={currentUser.userType}
          perform="product:view:files"
          yes={() => (
            <div className="text-secondary p-1 dmx-action-button">
              <FontAwesomeIcon icon={faUpload} onClick={() => handleUpdateAction(row)} />
            </div>
          )}
        />
      )}
    </>
  );

  const columns = [
    {
      name: 'Código',
      selector: 'code',
      sortable: true,
      center: false,
      grow: 0.3,
    },
    {
      name: 'Nombre',
      selector: 'name', // Obligatorio.
      sortable: true,
      center: false,
      grow: 4,
    },
    {
      name: 'Stock',
      selector: 'stock', // Obligatorio.
      sortable: true,
      center: false,
      grow: 0.5,
    },
    {
      name: 'Stock Comprometido',
      selector: 'compromisedStock', // Obligatorio.
      sortable: true,
      center: false,
      grow: 0.5,
      omit: true,
    },
    {
      name: 'Acciones',
      center: false,
      cell: (row) => actionButtons(row),
      grow: 0.5,
    },
  ];

  const tableData = {
    columns,
    data,
  };

  let icon;
  let title;

  switch (action) {
    case UPDATE:
      icon = faEdit;
      title = 'Edición de producto';
      break;

    case READ:
      icon = faEye;
      title = 'Visualización de producto';
      break;

    case CREATE:
      icon = faPlus;
      title = 'Creación de producto';
      break;

    default:
      break;
  }

  const toggleUploadModal = () => {
    setUploadModal(!uploadModal);
  };

  return (
    <>
      <div className="container-fluid page-products">
        <div className="page-header">
          <CanRBac
            role={currentUser.userType}
            perform="product:create"
            yes={() => (
              <Link to="#/newProductModal" className="btn btn-success btn-create-product" onClick={handleCreateAction}>
                <FontAwesomeIcon icon={faPlus} /> Nuevo Producto
              </Link>
            )}
            no={() => null}
          />
        </div>

        <div className="card shadow-sm">
          <div className="card-body datatable-react">
            <div className="d-flex flex-row-reverse">
              <SpinnerButton type="button" onClick={() => handleRefresh()} isLoading={isFetching} disabled={isFetching}>
                <FontAwesomeIcon icon={faSyncAlt} /> Refrescar
              </SpinnerButton>
            </div>

            <DataTableExtensions
              {...tableData}
              export={false}
              print={false}
              filterHidden={false}
              filterPlaceholder="Buscar..."
            >
              <DataTable
                paginationComponentOptions={paginationText}
                pagination
                columns={columns}
                data={data}
                striped
                center
                persistTableHead
                highlightOnHover
                paginationDefaultPage={page}
                paginationPerPage={itemsForPage}
                onChangePage={eventLastPage}
                onChangeRowsPerPage={eventItemsForPage}
                noDataComponent={<div className="dmx-empty-table">No hay registros para mostrar</div>}
                responsive
                onRowClicked={(row) => handleRowClick(row)}
              />
            </DataTableExtensions>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <DmxModal
        isOpen={modal}
        toggle={toggleModal}
        icon={<FontAwesomeIcon icon={icon} />}
        title={title}
        data={product}
        action={action}
        attributes={attributes || []}
      />

      <CanRBac
        role={currentUser.userType}
        perform="product:edit"
        yes={() => (
          <UploadModal
            isOpen={uploadModal}
            toggle={toggleUploadModal}
            data={prodData}
            action={action}
            refetch={handleRefresh}
          />
        )}
      />

      {currentUser.userType === 'seller' && (
        <CanRBac
          role={currentUser.userType}
          perform="product:view:files"
          yes={() => (
            <UploadModal
              isOpen={uploadModal}
              toggle={toggleUploadModal}
              data={prodData}
              action={action}
              refetch={handleRefresh}
              viewOnly={true}
            />
          )}
        />
      )}
    </>
  );
};
const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(Products);
