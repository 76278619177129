import './styles.scss';
import slugify from 'slugify';
import Select from 'react-select';

const SelectStyle = {
  control: (base, state) => ({
    ...base,
    border: '0 !important',
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important',
    },
  }),
};

interface ComponentProps {
  name: string;
  value?: string;
  disabled?: boolean;
  error?: {};
  touched?: {};
  placeholder?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  onKeyPress?: (event: Event) => void;
  options: {
    label?: string;
  };

  menuPosition?;
  defaultValue?;
  items?;
}

const Component = ({
  options,
  error,
  touched,
  onFocus,
  onBlur,
  onChange,
  onKeyPress,
  items,
  ...rest
}: ComponentProps) => {
  const { label } = options;

  const handleOnChange = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    !!callback && callback(event);
  };

  return (
    <div className={`form-group`}>
      {!!label && <label className="col-form-label pt-0">{label}</label>}

      <select
        className={`form-control ${!!error && !!touched ? 'error-field' : ''}`}
        onChange={(event) => handleOnChange(event, onChange)}
        onBlur={(event) => handleOnBlur(event, onBlur)}
        onFocus={(event) => handleOnFocus(event, onFocus)}
        {...rest}
      >
        {items.map((item, index) => {
          return (
            <option value={item.value} key={`option-${index}-value-${slugify(String(item.value))}`}>
              {item.label}
            </option>
          );
        })}
      </select>

      {!!error && !!touched && <div className="input-feedback">{error}</div>}
    </div>
  );
};

export default Component;
