import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import { connect } from 'react-redux';

import UserLogo from './UserLogo';

const Component = ({ user }) => {
  const roles = {
    seller: 'Ventas',
    handler: 'Depósito',
    analyst: 'Cuentas Corrientes',
    billing: 'Facturación',
    logistics: 'Logística',
    feedstockAdmin: 'Stock',
    productAdmin: 'Producción',
  };

  return (
    <>
      <div className="sidebar-user text-center">
        <div>
          <UserLogo width="40px" height="40px" color="#fff" />
          <div className="profile-edit">
            <Link to="/profile">
              <Edit />
            </Link>
          </div>
        </div>
        <h6 className="mt-3 f-14">{user?.name}</h6>
        <p>{roles[user?.userType]}</p>
      </div>
    </>
  );
};

const state = ({ userStore }) => {
  return {
    user: userStore.information.data,
  };
};

export default connect(state)(Component);
