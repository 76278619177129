import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, connect } from 'react-redux';

import Submit from '../../../../../../../components/Form/Submit';
import Textarea from '../../../../../../../components/Form/TextArea';
import { hideModal } from '../../../../../../../store/actions/modal';
import { updateProductionOrderBag } from '../../../../../../../store/actions/production-order-bag';
import { Formik } from 'formik';
import { useState } from 'react';

import * as Yup from 'yup';
import { checkDataPermission } from '../../../../../../../components/CanRBac';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const schema = Yup.object().shape({
  observations: Yup.string(),
});

interface FormValues {
  observations: string;
}

export const initialValues: FormValues = {
  observations: '',
};

interface ComponentProps {
  selectedProductionOrderBag;
  updateProductionOrderbagStates;
  visibleModal;
  currentUser;
  productionOrder;
}

const Component = ({
  selectedProductionOrderBag,
  updateProductionOrderbagStates,
  visibleModal,
  currentUser,
  productionOrder,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(updateProductionOrderBag({ ...selectedProductionOrderBag, ...values }));
  };

  return (
    <Modal
      isOpen={visibleModal === 'modal-observaciones'}
      toggle={() => dispatch(hideModal())}
      centered
      className="product-observation-modal"
    >
      <ModalHeader toggle={() => dispatch(hideModal())}>
        <FontAwesomeIcon icon={faCommentDots} /> Observaciones
      </ModalHeader>

      <ModalBody>
        <Formik
          initialValues={{
            observations: selectedProductionOrderBag?.observations || '',
          }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({ touched, errors, values, handleChange, handleBlur, handleReset, handleSubmit }) => {
            return (
              <form
                className="theme-form"
                onReset={handleReset}
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="add-bag-observations-form"
              >
                <Textarea
                  options={{}}
                  name="observations"
                  disabled={false}
                  value={values['observations']}
                  touched={touched['observations']}
                  error={errors['observations']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </form>
            );
          }}
        </Formik>
      </ModalBody>

      <ModalFooter>
        {checkDataPermission(currentUser.userType, 'op:edit:bags', { status: productionOrder.state }) && (
          <>
            <Button type="button" color="dark" outline onClick={() => dispatch(hideModal())}>
              Cancelar
            </Button>
            <Submit isLoading={updateProductionOrderbagStates?.loading} form="add-bag-observations-form">
              Guardar
            </Submit>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

const state = ({ userStore, modalStore, productionOrderStore, productionOrderBagStore }) => {
  const { data: currentUser } = userStore.information;
  const { data: productionOrder } = productionOrderStore.productionOrder;
  const { visibleModal } = modalStore;
  const { data: selectedProductionOrderBag } = productionOrderBagStore.selectedProductionOrderBag;
  const { state: updateProductionOrderbagStates } = productionOrderBagStore.updateProductionOrderBag;
  return {
    currentUser,
    productionOrder,
    visibleModal,
    selectedProductionOrderBag,
    updateProductionOrderbagStates,
  };
};

export default connect(state)(Component);
