import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { toast, ToastContainer } from 'react-toastify';

// components
import Column from './components/Column';
import MermaModal from '../../pages/ProductionOrder/components/MermaModal';

// mixins
import { DragDropContainer, Scroll, SectionColumn } from './mixins';

// store
import { patchProductionOrder, resetUpdateProductionOrder } from '../../store/actions/production-order';
import { checkDataPermission } from '../CanRBac';
import { hideModal, showModal } from '../../store/actions/modal';
import { ProductionOrderDto } from '../../types/production-order';

interface ComponentProps {
  columnsDrag, onDrop, detailLink, updateProductionOrderStates, currentUser, getMoreItems?, pagination?
}

const Component = ({ columnsDrag, onDrop, detailLink, updateProductionOrderStates, currentUser, getMoreItems, pagination }: ComponentProps) => {
  const [tempResult, setTempResult] = useState();
  const [productionOrder, setProductionOrder] = useState(0);
  const [columns, setColumns] = useState<{ id: string; name: string; items: ProductionOrderDto[] }[]>(columnsDrag);
  const dispatch = useDispatch();

  const update = (result) => {
    const { source, destination } = result;

    if (source?.droppableId !== destination?.droppableId) {
      const sourceColumn = columns[source?.droppableId];
      const destColumn = columns[destination?.droppableId];
      const sourceItems = [...sourceColumn?.items];
      const destItems = [...destColumn?.items];
      const [removed] = sourceItems.splice(source?.index, 1);
      destItems.splice(destination?.index, 0, removed);

      /**
       * cardDraggeada: card que se draggeo
       * columnDestination: columna destino donde se draggeo la card
       */
      const cardDraggeada = sourceColumn?.items[source.index];
      const columnDestination = destColumn?.name;

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });

      if (cardDraggeada.state !== columnDestination) {
        const newData = {
          id: result.draggableId,
          state: columns[result.destination.droppableId].id,
          cuttingTable: false,
        };

        dispatch(patchProductionOrder(newData));
      }
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const onDragEnd = ({ result, columns, setColumns, dispatch }) => {
    const { source, destination } = result;

    const srcState = columns[source?.droppableId]?.id;
    const destState = columns[destination?.droppableId]?.id;

    //Solicitada > Actualiza solo a En Progreso
    let permitedStates = ['inProgress'];
    if (srcState === 'requested' && !permitedStates.includes(destState)) {
      srcState !== destState && toast.error('Movimiento no permitido', { autoClose: 3000 });
      return false;
    }

    //Pendiente > Actualiza solo a En Progreso
    permitedStates = ['inProgress'];
    if (srcState === 'pending' && !permitedStates.includes(destState)) {
      srcState !== destState && toast.error('Movimiento no permitido', { autoClose: 3000 });
      return false;
    }

    //En Progreso > Actualiza solo a Finalizada o Pendiente
    permitedStates = ['done', 'pending'];
    if (srcState === 'inProgress' && !permitedStates.includes(destState)) {
      srcState !== destState && toast.error('Movimiento no permitido', { autoClose: 3000 });
      return false;
    }

    //Finalizada > Actualiza solo a Archivada
    permitedStates = ['archived', ...(currentUser?.userType === 'admin' ? ['inProgress'] : [])];
    if (srcState === 'done' && !permitedStates.includes(destState)) {
      srcState !== destState && toast.error('Movimiento no permitido', { autoClose: 3000 });
      return false;
    }

    /**
     * source.droppableId: id de la columna donde estaba la card
     * destination.droppableId: id de la columna donde se draggeo la card
     */
    const sourceColumn = columns[source?.droppableId];
    const cardDraggeada = sourceColumn?.items[source.index];

    const hasFeedstock = !!cardDraggeada?.feedstock && cardDraggeada?.feedstock?.length > 0;
    const hasBatch = !!cardDraggeada?.batch && cardDraggeada?.batch?.length > 0;
    const hasBags = !!cardDraggeada?.bags && cardDraggeada?.bags?.length > 0;
    const hasMermaComments = !!cardDraggeada?.merma?.comments && cardDraggeada?.merma?.comments !== '';
    const unitsQty = cardDraggeada.quantity;
    let unitsInDeposit = 0;

    if (hasBags) {
      unitsInDeposit = cardDraggeada?.bags
        ?.filter((productionOrderBag) => productionOrderBag.status === 'inDeposit')
        .map((bag) => bag.units)
        .reduce((a, b) => a + b, 0);
    }

    setProductionOrder(cardDraggeada);

    if (destState !== 'done') {
      update(result);
    } else {
      if (hasBags && hasFeedstock && hasBatch) {
        if (unitsQty > unitsInDeposit && !hasMermaComments) {
          setTempResult(result);
          dispatch(showModal('modal-merma'));
        } else {
          update(result);
        }
      } else {
        const bagsMessage = `La OP no cuenta con bolsones. Carga los bolsones en la OP para poder finalizar la OP.`;
        const rawMaterialMessage = `No se indico materia prima y/o lotes en la OP. Carga esta informacion para poder finalizarla.`;

        toast.error(`${!hasFeedstock || !hasBatch ? rawMaterialMessage : bagsMessage}`, {
          autoClose: 5000,
        });
      }
    }
  };

  useEffect(() => {
    setColumns(columnsDrag);
  }, [columnsDrag]);

  useEffect(() => {
    if (updateProductionOrderStates.success) {
      dispatch(resetUpdateProductionOrder());
      dispatch(hideModal());
      onDrop();
    }
  }, [updateProductionOrderStates]);

  return (
    <DragDropContainer>
      <Scroll>
        <DragDropContext onDragEnd={(result) => onDragEnd({ result, columns, setColumns, dispatch })}>
          {!!columns && (
            <>
              {Object.entries(columns)
                .filter(([id, column]) =>
                  checkDataPermission(currentUser.userType, 'op:list:state', { state: column?.id }),
                )
                .map(([id, column]) => {
                  return (
                    <SectionColumn key={id}>
                      <Droppable droppableId={id} key={id}>
                        {(provided) => {
                          return <Column data={column} link={detailLink} provided={provided} getMoreItems={getMoreItems} pagination={pagination} />;
                        }}
                      </Droppable>
                    </SectionColumn>
                  );
                })}
            </>
          )}
        </DragDropContext>
      </Scroll>

      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <MermaModal
        handleChangeMerma={() => {
          update(tempResult);
        }}
        productionOrder={productionOrder}
      />
    </DragDropContainer>
  );
};

const state = ({ productionOrderStore, userStore }) => {
  const { data: currentUser } = userStore.information;
  const { states: updateProductionOrderStates } = productionOrderStore.updateOrder;

  return {
    currentUser,
    updateProductionOrderStates,
  };
};

export default connect(state)(Component);
