import { getToken } from '../utils/authUtils';
import { GET } from '../constant/index';

export default function getFeedstockCategories() {
  return fetch(`${process.env.REACT_APP_API_URL}/feedstockCategory/all`, {
    method: GET,
    headers: new Headers({
      Authorization: `Bearer ${getToken()}`,
    }),
  }).then((response) => response.json());
}
