import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faPhoneAlt, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Breadcrumb from '../../../components/common/breadcrumb';

import {
  Print,
  WorkshopName,
  Card,
  ProductName,
  List,
  ListItem,
  WorkshopInformation,
  Information,
} from './styles';
import { WorkshopDto } from '../../../types/workshop';
import { ProductionOrderDto } from '../../../types/production-order';
import { Product } from '../../../types/product';
import { Col, Container, Row } from 'reactstrap';

interface WorkshopOrderBagDto {
  ProductionOrder: ProductionOrderDto;
  code: string;
  date: string;
  product: Product;
  status: string;
  units: number;
  workshop: WorkshopDto;
  _id: string;
}

interface GroupedBag {
  _id: string,
  name: string,
  bags: WorkshopOrderBagDto[],
}

interface ComponentProps {
  currentWorkshop: WorkshopDto
  bags: WorkshopOrderBagDto[]
}

const printInvoiceButton = <FontAwesomeIcon title="Imprimir Barcode" icon={faPrint} color={'grey'} />;

const Component = ({ currentWorkshop, bags }: ComponentProps) => {
  const [groupedBags, setGroupedBags] = useState<GroupedBag[]>([])
  const componentRef = useRef();

  useEffect(() => {
    if (bags && bags.length > 0) {
      const filteredBags = bags.filter(bag => bag.status !== 'inTransit')

      const groupedBags: GroupedBag[] = filteredBags.reduce((result: GroupedBag[], bag) => {
        const productId = bag.product._id;

        const existingGroup = result.find((group) => group._id === productId);

        if (existingGroup) {
          existingGroup.bags.push(bag);
        } else {
          const newGroup: GroupedBag = {
            _id: bag.product._id,
            name: bag.product.name,
            bags: [bag],
          };
          result.push(newGroup);
        }

        return result;
      }, []);


      const sortedBagsArray = Object.values(groupedBags).map((group) => {
        const sortedBags = group?.bags?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        return { ...group, bags: sortedBags };
      });

      setGroupedBags(sortedBagsArray);
    }
  }, [bags])

  return (
    <>
      <div style={{ height: 22 }} />

      <Container>
        <Row>
          <Col>
            <Breadcrumb
              parentLink={`/workshops/${currentWorkshop?._id}`}
              style={{ display: 'inline-block', marginLeft: '15px' }}
            />
          </Col>

          <Col>
            <h5 style={{ textAlign: 'center' }}>Impresión movimientos del taller {currentWorkshop?.name}</h5>
          </Col>

          <Col style={{ textAlign: 'right' }}>
            {!!componentRef && componentRef?.current && (
              <ReactToPrint
                copyStyles
                documentTitle={`Taller - ${currentWorkshop?.name}`}
                trigger={() => (
                  <button className="btn btn-primary shadow-lg" type="button">
                    <FontAwesomeIcon icon={faPrint} /> Imprimir
                  </button>
                )}
                content={() => (!!componentRef?.current ? componentRef.current : null)}
              />
            )}
          </Col>
        </Row>
      </Container>

      <div style={{ height: 40 }} />

      <Print ref={componentRef}>
        <div style={{ height: 10 }} />

        <Container>
          <Row>
            <Col>
              <WorkshopName>{currentWorkshop?.name}</WorkshopName>
            </Col>
            <Col>
              <WorkshopInformation>
                <Information><FontAwesomeIcon icon={faMapPin} color="black" /> {currentWorkshop?.address}</Information>
                <Information><FontAwesomeIcon icon={faPhoneAlt} color="black" /> {currentWorkshop?.phone}</Information>
              </WorkshopInformation>
            </Col>
          </Row>
        </Container>

        {groupedBags?.map(group => {
          const unitsInDeposit = group.bags
            ?.filter((productionOrder) => productionOrder.status === 'inDeposit')
            .map((bag) => bag.units)
            .reduce((a, b) => a + b, 0);

          const unitsInWorkshop = group.bags
            ?.filter((productionOrder) => productionOrder.status === 'inWorkshop')
            .map((bag) => bag.units)
            .reduce((a, b) => a + b, 0);

          const merma = unitsInDeposit - unitsInWorkshop

          return (
            <Card>
              <Container>
                <Row>
                  <Col>
                    <div style={{ height: 40 }} />
                    <ProductName><b>{group?.name}</b> <span>Merma: {merma} unidades</span></ProductName>
                    <div style={{ height: 10 }} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <List >
                      {group?.bags.map(bag => {
                        let icon = <></>;

                        if (bag.status === 'inDeposit') {
                          icon = <FontAwesomeIcon icon={faArrowLeft} color="red" />;
                        }

                        if (bag.status === 'inWorkshop') {
                          icon = <FontAwesomeIcon icon={faArrowRight} color="green" />;
                        }

                        if (bag.status === 'inTransit') {
                          icon = <FontAwesomeIcon icon={faArrowRight} color="green" />;
                        }

                        let text = '';

                        if (bag.status === 'inDeposit') {
                          text = 'Devolución del taller';
                        }

                        if (bag.status === 'inWorkshop') {
                          text = 'Entrega al taller';
                        }

                        if (bag.status === 'inTransit') {
                          text = 'En transito';
                        }

                        return (

                          <ListItem>
                            <span style={{ textAlign: 'center' }}>{icon}</span>
                            <span style={{ width: 150 }}>{text}</span>
                            <span style={{ width: 150 }}>{bag.units} unidades</span>
                            <span>{`OP${bag?.ProductionOrder?.code}`}</span>
                          </ListItem>
                        )
                      })}
                    </List>
                  </Col>
                </Row>
              </Container>
            </Card>
          )
        })}

        <div style={{ height: 40 }} />
      </Print>
    </>
  );
};

const state = ({ workshopStore, userStore, productionOrderBagStore }) => {
  const { data: currentWorkshop, states: currentWorkshopStates } = workshopStore.workshop;
  const { data: bags } = productionOrderBagStore.productionOrderBagAll
  const { data: currentUser } = userStore.information;


  return {
    currentWorkshop,
    currentWorkshopStates,
    bags,
    currentUser
  };
};

export default connect(state)(Component);