import { put, call, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import { GET_ORDERS_ALL, GET_ORDERS_ALL_SUCCESS, GET_ORDERS_ALL_ERROR } from '../types/orders';

function* getOrdersAll({ payload }): any {
  try {
    const result = yield call(services.orders.getOrderssAll, payload);
    yield put({ type: GET_ORDERS_ALL_SUCCESS, payload: result });
  } catch (error: any) {
    yield put({ type: GET_ORDERS_ALL_ERROR, payload: error.message });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_ORDERS_ALL, getOrdersAll);
}
