import {
  ColumnDropp,
  ColHeader,
  ColContent,
  ToggleColumn,
  HeaderTotal,
  ColumnInformation,
  Name,
  Total,
  ButtonLoadMore
} from './mixins';
import Spinnner from '../../../../components/Spinner'
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Card from '../Card';
import { useState } from 'react';
import { checkDataPermission } from '../../../CanRBac';
import { connect } from 'react-redux';

const Component = ({ data, link, provided, currentUser, getMoreItems, pagination }) => {
  const [isOpened, setIsOpened] = useState<boolean>(true);
  const { name, items } = data;

  return (
    <ColumnDropp isOpened={isOpened}>
      <ColHeader isOpened={isOpened}>
        {isOpened && <strong>{name}</strong>}

        <HeaderTotal isOpened={isOpened}>
          {isOpened && <span>{items?.length}</span>}

          <ToggleColumn
            className="toogle-column"
            isOpened={isOpened}
            onClick={() => {
              setIsOpened(!isOpened);
            }}
          >
            {isOpened ? <FontAwesomeIcon icon={faArrowLeft} /> : <FontAwesomeIcon icon={faArrowRight} />}
          </ToggleColumn>
        </HeaderTotal>
      </ColHeader>

      <ColContent ref={provided.innerRef} {...provided.droppableProps} isOpened={isOpened}>
        {isOpened ? (
          <>
            {items
              ?.filter((item) => checkDataPermission(currentUser.userType, 'op:list:type', { type: item.type }))
              .map((item, index) => {
                return (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                    isDragDisabled={
                      !checkDataPermission(currentUser.userType, 'op:edit:state', { status: item?.state })
                    }
                  >
                    {(provided, snapshot) => {
                      return <Card item={item} provided={provided} snapshot={snapshot} detailLink={link} />;
                    }}
                  </Draggable>
                );
              })}

            {provided.placeholder}

            {getMoreItems && pagination[data?.id].thereAreItems &&
              <ButtonLoadMore type='button' onClick={() => getMoreItems({ state: data?.id })}>
                {pagination[data?.id].isLoading ? (
                  <div style={{ display: "flex", gap: 10 }}><Spinnner /> Cargando</div>
                ) : (
                  "Cargar más items"
                )}
              </ButtonLoadMore>
            }
          </>
        ) : (
          <ColumnInformation>
            <Name>{name}</Name> <Total>{items?.length}</Total>
          </ColumnInformation>
        )}
      </ColContent>
    </ColumnDropp>
  );
};

const state = ({ userStore }) => {
  const { data: currentUser } = userStore.information;

  return {
    currentUser,
  };
};

export default connect(state)(Component);
