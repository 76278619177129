import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { noRecords, moveTypes } from '../../constant';
import { faTimesCircle, faArrowRight, faArrowLeft, faCommentDots, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MovementList.scss';

const MovementList = ({ data, readOnly, updateData, removeMove, addMove }) => {
  const handleChange = (event) => {
    updateData(event);
  }

  const handleDelete = (event) => {
    removeMove(event);
  }

  const columns = [
    {
      name: '',
      sortable: false,
      minWidth: '1%',
      maxWidth: '1%',
      cell: (row) => {
        let icon = <FontAwesomeIcon icon={faArrowRight} color="green" />;

        if (row.type === moveTypes.egress) {
          icon = <FontAwesomeIcon icon={faArrowLeft} color="red" />;
        }

        return row.isNew === undefined ? <p>{icon}</p> : '';
      },
    },
    {
      name: 'Fecha',
      selector: 'date',
      sortable: false,
      center: false,
      grow: 1.5,
      cell: (row, index) => {
        return (
          <div className="subrow-datepicker">
            <input
              type="date"
              className="form-control dmx-input w-100"
              id="date"
              value={row.date ? row.date.slice(0, 10) : ''}
              disabled={readOnly}
              onChange={(event) => handleChange({movement: row, index, field: 'date', value: event.target.value})}
            />
          </div>
        );
      },
    },
    {
      name: 'Rollos',
      sortable: false,
      center: false,
      grow: 0.5,
      cell: (row, index) => (
        <input
          type="number"
          min="0"
          className="form-control dmx-input w-100"
          name="reels"
          defaultValue={row.reels || 0}
          disabled={readOnly}
          onChange={(event) => handleChange({movement: row, index, field: 'reels', value: event.target.value})}
        />
      ),
    },
    {
      name: 'Metros',
      sortable: false,
      center: false,
      grow: 0.5,
      cell: (row, index) => (
        <input
          type="number"
          min="0"
          className="form-control dmx-input w-100"
          name="meters"
          defaultValue={row.meters || 0}
          disabled={readOnly}
          onChange={(event) => handleChange({movement: row, index, field: 'meters', value: event.target.value})}
        />
      ),
    },
    {
      name: 'Metros Totales',
      sortable: false,
      center: false,
      grow: 0.8,
      cell: (row) => {
        return  (
          <input
            type="number"
            min="0"
            className="form-control dmx-input w-100"
            name="meters"
            value={row.totalMeters.toFixed(2)}
            disabled={true}
          />
        )
      },
    },
    {
      name: '',
      sortable: false,
      right: true,
      grow: 0.01,
      cell: (row, index) => (
        <div onClick={(event) => handleDelete({movement: row, index, field: 'cuttingReturn', value: event.target.value})}>
          <FontAwesomeIcon icon={faTimesCircle} color="#ff0000" />
        </div>
      ),
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={[...data]}
        noDataComponent={<div className="dmx-empty-table">No hay movimientos para mostrar.</div>}
      />
    </>
  );
}

export default MovementList;
