import { useState, useEffect, useRef } from 'react';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

// images

import LogoDimex from '../../../../../../../assets/images/logo.png';
import LogoInnenImage from '../../../../../../../assets/images/logo-innen.jpeg';

// mixins
import {
  ButtonPrintContainer,
  ContainerComponent,
  Sticker,
  Separator,
  QRCodeCustom,
  Heading,
  CurrentDate,
  LogoContainer,
  Image,
  LogoClaim,
  Body,
  Wrapper,
  Lote,
  ProductWrapper,
  ProductName,
  ProductCode,
  CantMts,
  Footer,
  BarcodeContainer,
  QRCodeInnen,
  LogoInnen,
} from './mixins';

interface ComponentProps {
  data: {
    PoBatchCode: number;
    PoBatchId: string;
    PoBagId: string;
    QualityBatch;
    date: string;
    ProductCode: string;
    productName: string;
    Type: string;
    ExpirationDate: string;
    workshop: string;
    units: string;
  };
  index: number;
}
const printInvoiceButton = <FontAwesomeIcon title="Imprimir Barcode" icon={faPrint} color={'grey'} />;

const Component = ({ data, index }: ComponentProps) => {
  const [currentDate, setCurrentDate] = useState('');
  const componentRef = useRef();

  // get current date
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const expirationDate = new Date(data.ExpirationDate);

  // get current url
  const currentURL = window.location.href;

  useEffect(() => {
    setCurrentDate(date);
  }, [date]);

  if (data?.PoBagId === undefined) {
    return null;
  }

  return (
    <>
      <ButtonPrintContainer>
        {!!componentRef && componentRef?.current && (
          <ReactToPrint
            pageStyle="@page { size: 105mm 148mm }"
            trigger={() => printInvoiceButton}
            content={() => (!!componentRef?.current ? componentRef.current : null)}
          />
        )}
      </ButtonPrintContainer>

      <ContainerComponent>
        <Sticker ref={componentRef}>
          <Heading>
            <CurrentDate>Fecha: {currentDate}</CurrentDate>

            <LogoContainer>
              {/* <Image src={LogoDimex} width="290" alt="Logo Dimex" /> */}
              <LogoClaim>Uso Confidencial</LogoClaim>
            </LogoContainer>
          </Heading>

          <Body>
            <Wrapper>
              <ProductName>{data.productName}</ProductName>

              {/* <QRCodeCustom size={200} value={currentURL} includeMargin={false} /> */}
            </Wrapper>

            <Separator />
            {data.Type === 'in plant' && !!data.QualityBatch && (
              <ProductCode>Lote Anmat: {data.QualityBatch}</ProductCode>
            )}
            {data.Type === 'in plant' && !!data.ExpirationDate && (
              <ProductCode>
                Fecha de vencimiento:{' '}
                {`${expirationDate.getDate() + 1}/${expirationDate.getMonth() + 1}/${expirationDate.getFullYear()}`}
              </ProductCode>
            )}
            <ProductCode>Número OP {data.PoBatchCode}</ProductCode>
            {!!data.workshop && <ProductCode>{data.workshop}</ProductCode>}
            <ProductCode>Unidades: {data.units}</ProductCode>
            <Separator />

            <BarcodeContainer>
              <Barcode width={3} height={200} fontSize={40} value={data?.PoBagId || ''} format={'CODE128'} />
            </BarcodeContainer>
          </Body>

          <Footer>
            <QRCodeInnen size={100} value="www.innen.com.ar" />
            <LogoInnen width="200px" src={LogoInnenImage} alt="Logo Innen" />
          </Footer>
        </Sticker>
      </ContainerComponent>
    </>
  );
};

export default Component;
