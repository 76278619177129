import action from '../helpers/rest-client';
import { User } from '../types/user';

export class ErrorUser extends Error {}

class UserService {
  public getUserInformation() {
    const user: User = JSON.parse(localStorage.getItem('user') || '{}');
    return { ...user };
  }

  public async updateUserInformation(payload) {
    const { _id: userId } = payload;

    return action
      .Patch({
        url: `/users/${userId}`,
        body: payload,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorUser(error.message);
      });
  }

  public async getUserProfile() {
    return action
      .Get({
        url: '/profile',
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorUser(error.message);
      });
  }

  public async updateUserProfile(payload) {
    return action
      .Patch({
        url: '/profile',
        body: payload,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorUser(error.message);
      });
  }
}

const user = new UserService();
export default user;
