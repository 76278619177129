/* eslint no-underscore-dangle: 0 */
/* eslint no-shadow: 0 */

import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';
import changePassword from '../../../../services/changePassword';
import SpinnerButton from '../../../../components/Button';

const PasswordForm = () => {
  const executeSubmitAction = async (values, { setSubmitting }) => {
    setSubmitting(true);
    changePassword(values).then((res) => {
      if (res.errors) {
        toast.error(`No se ha podido actualizar porque ocurrió un error: ${res.errors.msg}`, {autoClose: 3000});
      } else {
        setSubmitting(false);
        toast.success('Contraseña actualizada correctamente.', {autoClose: 3000});
      }
    });
  };

  const handleSubmitAction = (values, { setSubmitting }) => {
    SweetAlert.fire({
      title: '¿Está seguro?',
      // text: 'Una vez eliminada, no podrá ser recuperada.',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        executeSubmitAction(values, { setSubmitting });
      }
    });
  };

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        confirm: '',
      }}
      onSubmit={handleSubmitAction}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string()
          .required('Campo obligatorio')
          .matches(
             /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%.,+'"\-;#/()=¡*¿?&])[A-Za-z\d@$!%.,+'"\-;#/()=¡*¿?&]{8,}$/,
             'Debe contener al menos 8 caracteres. Una mayúscula, un número y un símbolo.',
          ),
        confirm: Yup.string()
          .required('Campo obligatorio')
          .oneOf([Yup.ref('newPassword'), null], 'Las nuevas contraseñas deben coincidir'),
        oldPassword: Yup.string().required('Campo obligatorio'),
      })}
    >
      {(props) => {
        const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
        return (
          <form className="card dmx-card-mt" onSubmit={handleSubmit}>
            <div className="card-header">
              <h6>Cambiar contraseña</h6>
              <div className="card-options">
                <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
                  <i className="fe fe-chevron-up" />
                </a>
                <a className="card-options-remove" href="javascript" data-toggle="card-remove">
                  <i className="fe fe-x" />
                </a>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 col-md-6">
                  <div className="form-group">
                    <p className="form-label mb-1">Contraseña actual</p>
                    <input
                      className={
                        errors.oldPassword && touched.oldPassword ? 'form-control error-field' : 'form-control'
                      }
                      type="password"
                      placeholder="Contraseña actual"
                      name="oldPassword"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.oldPassword}
                    />
                    {errors.oldPassword && touched.oldPassword && (
                      <div className="input-feedback">{errors.oldPassword}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-6">
                  <div className="form-group">
                    <p className="form-label mb-1">Nueva Contraseña</p>
                    <input
                      className={
                        errors.newPassword && touched.newPassword ? 'form-control error-field' : 'form-control'
                      }
                      type="password"
                      placeholder="Nueva contraseña"
                      name="newPassword"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassword}
                    />
                    {errors.newPassword && touched.newPassword && (
                      <div className="input-feedback">{errors.newPassword}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-6">
                  <div className="form-group">
                    <p className="form-label mb-1">Confirmación</p>
                    <input
                      className={errors.confirm && touched.confirm ? 'form-control error-field' : 'form-control'}
                      type="password"
                      placeholder="Confirme la contraseña"
                      name="confirm"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirm}
                    />
                    {errors.confirm && touched.confirm && <div className="input-feedback">{errors.confirm}</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer text-right">
              <SpinnerButton className="btn btn-primary " isLoading={isSubmitting} type="submit">
                Actualizar Contraseña
              </SpinnerButton>
            </div>
            <ToastContainer
              position="bottom-center"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default PasswordForm;
