import { OrdersData } from '../../types/orders'

export const GET_ORDERS_ALL = 'GET_ORDERS_ALL'
export const GET_ORDERS_ALL_SUCCESS = 'GET_ORDERS_ALL_SUCCESS'
export const GET_ORDERS_ALL_ERROR = 'GET_ORDERS_ALL_ERROR'
export const GET_ORDERS_ALL_RESET = 'GET_ORDERS_ALL_RESET'

export interface GetOrdersAll {
  type: typeof GET_ORDERS_ALL
  payload: null
}

export interface GetOrdersAllSuccess {
  type: typeof GET_ORDERS_ALL_SUCCESS
  payload: null
}

export interface GetOrdersAllError {
  type: typeof GET_ORDERS_ALL_ERROR
  payload: boolean | string
}

export interface GetOrdersAllReset {
  type: typeof GET_ORDERS_ALL_RESET
  payload: null
}

export interface State {
  ordersAll: {
    data: OrdersData[] | null
    states: {
      loading: boolean
      error: boolean | string
      success: boolean
    }
  },
}

export const initialState: State = {
  ordersAll: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
}

export type Actions =
  | GetOrdersAll
  | GetOrdersAllSuccess
  | GetOrdersAllError
  | GetOrdersAllReset
