export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export interface ShowModal {
  type: typeof SHOW_MODAL;
  payload: string;
}

export interface HideModal {
  type: typeof HIDE_MODAL;
  payload: null;
}

export interface State {
  visibleModal: string | null;
}

export const initialState: State = {
  visibleModal: null,
};

export type Actions = ShowModal | HideModal;
